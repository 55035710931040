import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Loader from "../Loader";

import InformationModal from "../InformationModal";
import { useAuth } from "../../providers/authProvider";
import SelectWrapper from "../SelectWrapper";
import { commonApi } from "../../services/commonServices";

const EMPLOYEE_ROLE_EXECUTIVE = 1;
const PAGE_SIZE = 30;

const AddNotificationEmailModal = ({ submitFunction, onSubmit, onClose }) => {
  const [authContext] = useAuth();
  const [email, setEmail] = useState();
  const [serviceLocation, setServiceLocation] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!serviceLocation) {
      return setInformationModal({
        isOpen: true,
        title: "Add Notification Email",
        body: "Please select a service location",
      });
    }
    setLoading(true);
    try {
      await submitFunction({
        email,
        serviceLocationId: serviceLocation.id,
      });
      onSubmit();
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Add Notification Email",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  useEffect(() => {
    const fetchData = async () => {
      const serviceLocations = await commonApi.getServiceLocations({});
      const selectedServiceLocation = serviceLocations.data.find(
        (sl) => sl.id === authContext.serviceLocationId
      );
      if (selectedServiceLocation) {
        setServiceLocation(selectedServiceLocation);
      }
    };
    if (authContext.serviceLocationId) {
      fetchData();
    }
  }, [authContext.serviceLocationId]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Add Notification Email
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label for="email">
                    <span>Email</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Enter the email..."
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label for="email">
                    <span>Service Location</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <SelectWrapper
                    isRequired={true}
                    entity="service location"
                    formatItemFunction={(c) => {
                      return {
                        label: c.name,
                        value: c.id,
                      };
                    }}
                    fetchFunction={
                      parseInt(authContext.currentUser.roleId) ===
                      EMPLOYEE_ROLE_EXECUTIVE
                        ? commonApi.getServiceLocations
                        : () => authContext.currentUser.serviceLocations
                    }
                    fetchParameters={{
                      pageSize: PAGE_SIZE,
                    }}
                    defaultSelected={serviceLocation}
                    onSelected={(serviceLocation) =>
                      setServiceLocation(serviceLocation)
                    }
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddNotificationEmailModal;
