import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  CardFooter,
} from "reactstrap";
import InformationModal from "../../../components/InformationModal";
import { utils } from "../../../utils/utils";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTablePagination from "../../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../../components/advanceTable/AdvanceTable";

import {
  ACTIONS,
  useAuditMergedLocations,
} from "../../../providers/auditMergedLocationsProvider";
import { auditMergedLocationsApi } from "../../../services/auditMergedLocations";

const columns = () => [
  {
    accessor: "author.firstName",
    Header: "Merged By",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { author } = rowData.row.original;
      return author ? ` ${author?.firstName} ${author?.lastName} ` : "-";
    },
  },
  {
    accessor: "createdAt",
    Header: "Merged On",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { createdAt } = rowData.row.original;
      return utils.formatDateTime(createdAt, "MM/DD/YYYY h:mm a") || "-";
    },
  },
  {
    accessor: "customerLocationsDeleted",
    Header: "Merged Location",
    disableSortBy: true,
    Cell: (rowData) => {
      const { customerLocationsDeleted } = rowData.row.original;
      return (
        <>
          {customerLocationsDeleted?.length
            ? customerLocationsDeleted
                .map((customerLocationDeleted) =>
                  utils.getAddress(customerLocationDeleted.customerLocation)
                )
                .join("; ")
            : "-"}
        </>
      );
    },
  },
  {
    accessor: "customerLocation",
    Header: "Parent Location",
    disableSortBy: true,
    Cell: (rowData) => {
      const { customerLocation } = rowData.row.original;
      return customerLocation && customerLocation.shipToCode
        ? utils.getAddress(customerLocation)
        : "-";
    },
  },
];

const AudtiMergedLocations = () => {
  const [audtiMergedLocationsContext, setAuditMergedLocationsContext] =
    useAuditMergedLocations();

  const [loading, setLoading] = useState(true);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          audtiMergedLocationsContext.sortBy === sortBy?.id &&
          audtiMergedLocationsContext.direction === direction
        ) {
          return;
        }
        setAuditMergedLocationsContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setAuditMergedLocationsContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      audtiMergedLocationsContext.direction,
      audtiMergedLocationsContext.sortBy,
      setAuditMergedLocationsContext,
    ]
  );

  const setAuditMergedLocationsContextCb = useCallback(
    (data) => setAuditMergedLocationsContext(data),
    [setAuditMergedLocationsContext]
  );

  useEffect(() => {
    setLoading(true);
    auditMergedLocationsApi
      .getMergedLocations({
        search: audtiMergedLocationsContext.search,
        page: audtiMergedLocationsContext.page - 1,
        pageSize: audtiMergedLocationsContext.sizePerPage,
        sortBy: audtiMergedLocationsContext.sortBy,
        direction: audtiMergedLocationsContext.direction,
      })
      .then((mergedLocations) => {
        setLoading(false);
        setAuditMergedLocationsContextCb({
          action: ACTIONS.GET_MERGED_LOCATIONS_SUCCESS,
          payload: { mergedLocations },
        });
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setAuditMergedLocationsContextCb,
    audtiMergedLocationsContext.sortBy,
    audtiMergedLocationsContext.direction,
    audtiMergedLocationsContext.sizePerPage,
    audtiMergedLocationsContext.page,
    audtiMergedLocationsContext.search,
    audtiMergedLocationsContext.refresh,
  ]);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns()}
          data={audtiMergedLocationsContext.mergedLocations?.data || []}
          pageSize={audtiMergedLocationsContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: audtiMergedLocationsContext.sortBy,
            direction: audtiMergedLocationsContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Audit of Merged Locations</h3>
                <small className="text-muted ml-2 pt-1">
                  ({audtiMergedLocationsContext.mergedLocations.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={() =>
                    setAuditMergedLocationsContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {audtiMergedLocationsContext.mergedLocations?.data?.length > 0 ? (
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={audtiMergedLocationsContext.mergedLocations.count}
                pageCount={
                  audtiMergedLocationsContext.mergedLocations.totalPages
                }
                currentPage={audtiMergedLocationsContext.page - 1}
                onPageChange={(page) =>
                  setAuditMergedLocationsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={audtiMergedLocationsContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setAuditMergedLocationsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default AudtiMergedLocations;
