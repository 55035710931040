import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import Collapse from "reactstrap/lib/Collapse";
import JSAItemAnswer from "./JSAItemAnswer";

const parseJSAAnswers = (JSAAnswers) => {
  const sectionData = {};
  const sections = JSAAnswers.reduce((p, c) => {
    const entry = {
      ...c.jsaItem,
      answer: c.answer,
    };
    delete entry.jsaSection;
    delete entry.jsaSectionId;
    p[c.jsaItem.jsaSectionId] = [...(p[c.jsaItem.jsaSectionId] || []), entry];
    sectionData[c.jsaItem.jsaSectionId] = {
      id: c.jsaItem.jsaSectionId,
      name: c.jsaItem.jsaSection.name,
      order: c.jsaItem.jsaSection.order,
    };
    return p;
  }, {});
  const parsedData = Object.keys(sectionData).map((sectionId) => ({
    ...sectionData[sectionId],
    items: sections[sectionId],
  }));
  return parsedData;
};

const JSAModal = ({ employeeWorkDay, onClose }) => {
  const [sections, setSections] = useState([]);
  const [sectionsOpen, setSectionsOpen] = useState({});

  useEffect(() => {
    const JSAAnswers = employeeWorkDay.jsaAnswers || null;
    const sections = parseJSAAnswers(JSAAnswers);
    setSections(sections);
  }, [employeeWorkDay.jsaAnswers]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Job Safety Analysis</ModalHeader>
      <ModalBody>
        {sections
          .sort((x, y) => x.order - y.order)
          .map((section) => (
            <div className="mb-3" key={section.id}>
              <Button
                color="primary"
                key={section.id}
                onClick={() =>
                  setSectionsOpen({
                    ...sectionsOpen,
                    [section.id]: !sectionsOpen[section.id],
                  })
                }
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-row justify-content-between m-1 align-middle">
                  <span>{section.name || `Section ${section.order}`}</span>
                  <FontAwesomeIcon
                    icon={
                      sectionsOpen[section.id] ? faChevronDown : faChevronRight
                    }
                    fixedWidth
                    className="my-1"
                  />
                </div>
              </Button>
              <Collapse isOpen={sectionsOpen[section.id]} className="p-2">
                {section.items
                  .sort((x, y) => x.order - y.order)
                  .map((item) => (
                    <JSAItemAnswer item={item} key={item.id} />
                  ))}
              </Collapse>
            </div>
          ))}
      </ModalBody>
      <ModalFooter className="justify-content-end mt-n3">
        <Button color={"primary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default JSAModal;
