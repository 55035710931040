import React from "react";
import { DayPicker, addToRange } from "react-day-picker";

import "react-day-picker/dist/style.css";

import DateRangeLabel from "./DateRangeLabel";

const numberOfMonths = 1;

const DateRangePicker = ({
  classes = [],
  label,
  startDate,
  endDate,
  onSelection,
  align = "center",
}) => {
  const from = startDate ? new Date(startDate) : undefined;
  const to = endDate ? new Date(endDate) : undefined;

  const handleDayClick = (day) => {
    const newRange = addToRange(day, { from, to });
    onSelection(newRange || { from: day, to: day });
  };

  const modifiers = { start: from, end: to };

  return (
    <div
      className={`calendar-container border rounded p-3 ${classes.join(" ")}`}
    >
      <div className="m-0 text-center overflow-hidden">
        {!from && !to && (
          <span className="text-muted float-left">
            Please select the first day.
          </span>
        )}
        {from && !to && (
          <span className="text-muted float-left">
            Please select the last day.
          </span>
        )}
        {from && to && (
          <DateRangeLabel
            background="white"
            title={label}
            startDate={from}
            endDate={to}
          />
        )}
      </div>
      <DayPicker
        className={`bg-white rounded border my-3 d-flex align-items-center justify-content-${align} pb-2`}
        numberOfMonths={numberOfMonths}
        mode="range"
        selected={[from, { from, to }]}
        defaultMonth={from}
        modifiersClassNames={{
          selected: "my-selected",
          today: "my-today",
          range_start: "my-range_start",
          range_end: "my-range_end",
        }}
        modifiers={modifiers}
        onDayClick={handleDayClick}
      />
    </div>
  );
};

export default DateRangePicker;
