import React, { useState } from "react";

import moment from "moment";

import { Badge, Button, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../../../providers/workOrderDetailsProvider";
import InformationModal from "../../../InformationModal";
import ServiceNoteForm from "../../workOrderServiceNotes/ServiceNoteForm";

const ServicePerformed = ({
  serviceNotes,
  employeeWorkDayId,
  workOrderCraneId,
}) => {
  const [, setWorkOrderDetailsContext] = useWorkOrderDetails();
  const [isNewNote, setIsNewNote] = useState(false);
  const [seviceNoteState, setServiceNoteState] = useState({});
  const [serviceNoteModal, setServiceNoteModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const openNoteModal = (serviceNote) => {
    setServiceNoteState(serviceNote);
    setServiceNoteModal({
      isOpen: true,
      title: "Update Service Note",
      rawBody: true,
      body: <ServiceNoteForm serviceNote={serviceNote} onClose={onClose} />,
    });
  };

  const onClose = () => {
    setServiceNoteModal({ isOpen: false, title: "", body: "" });
    setInformationModal({
      isOpen: true,
    });
  };

  return (
    <ListGroup className="my-2 col-12 px-0">
      <ListGroupItem
        className="d-flex justify-content-between align-items-center bg-lighter font-500"
        tag="div"
      >
        Service Performed
        <Button
          color="success"
          size="sm"
          className="ml-2 rounded"
          onClick={() => {
            setIsNewNote(true);
            openNoteModal(
              {
                employeeWorkDayId: employeeWorkDayId[0].id,
                workOrderCraneId,
              },
              true
            );
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2 text-white" />
          Add Note
        </Button>
      </ListGroupItem>
      {serviceNotes.length ? (
        <ListGroupItem
          className="d-flex flex-column justify-content-between align-items-center"
          tag="div"
        >
          {serviceNotes.map((serviceNote) => (
            <div key={serviceNote.id} className="w-100 d-flex my-1">
              <div
                style={{ backgroundColor: "#FDF1DB" }}
                className="p-2 rounded text-muted flex-grow-1 rounded d-flex cursor-pointer"
              >
                <div className="flex-grow-1 text-break">{serviceNote.note}</div>
                <div className="d-flex justify-content-between align-items-center ml-2">
                  <Badge color="primary" className="d-flex">
                    <small>
                      {moment(serviceNote.updatedAt).format("h:mm a")}
                    </small>
                  </Badge>
                  <Badge className="d-flex ml-2">
                    <small>
                      By{" "}
                      {`${serviceNote.author?.firstName} ${serviceNote.author?.lastName}`}
                    </small>
                  </Badge>
                  <Button
                    size="sm"
                    className="rounded ml-2"
                    color="warning"
                    onClick={() => {
                      setIsNewNote(false);
                      openNoteModal(serviceNote);
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} className="mr-2" />
                    <span>Edit</span>
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </ListGroupItem>
      ) : (
        <ListGroupItem
          className="d-flex justify-content-between align-items-center"
          tag="div"
        >
          <small className="text-muted">No information to show</small>
        </ListGroupItem>
      )}
      {serviceNoteModal.isOpen ? (
        <ServiceNoteForm
          isNewNote={isNewNote}
          serviceNote={seviceNoteState}
          onSubmit={() => {
            setServiceNoteModal({
              isOpen: false,
            });
            setInformationModal({
              isOpen: true,
              title: isNewNote ? "Add Service Note" : "Update Service Note",
              body: isNewNote
                ? "Service Note added successfully."
                : "Service Note updated successfully",
            });
          }}
          onClose={() => {
            setServiceNoteModal({
              isOpen: false,
            });
          }}
          onError={(err) => {
            setServiceNoteModal({
              isOpen: false,
            });
            setInformationModal({
              isOpen: true,
              title: "Error",
              body:
                err?.response?.data[0].msg ||
                "There was an error with your request.",
            });
          }}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() => {
            setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            });
            setWorkOrderDetailsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </ListGroup>
  );
};

export default ServicePerformed;
