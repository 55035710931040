import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  Table,
  Badge,
} from "reactstrap";
import { useAuth } from "../../../providers/authProvider";
import { workOrdersApi } from "../../../services/workOrdersServices";
import { utils } from "../../../utils/utils";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const WorkOrdersAssociatedModal = ({ craneId, onClose }) => {
  const [authContext] = useAuth();
  const navigate = useNavigate();
  const [workOrders, setWorkOrders] = useState([]);
  const [loading, setLoading] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    try {
      const getWorkOrdersAssociated = async () => {
        setLoading(true);
        const workOrdersAssociated = await workOrdersApi.getWorkOrders({
          craneId,
          serviceLocationId: authContext.serviceLocationId,
        });
        setWorkOrders(workOrdersAssociated);
        setLoading(false);
      };

      getWorkOrdersAssociated();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  }, [craneId, authContext.serviceLocationId]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="md">
      <ModalHeader close={closeBtn}>Work Orders Associated</ModalHeader>
      <ModalBody>
        <>
          {loading ? (
            <Loader size={"sm"} />
          ) : !workOrders.length ? (
            <div className="d-flex justify-content-center align-items-center">
              No work orders
            </div>
          ) : (
            <ListGroup flush className="text-left border rounded col-12 px-0">
              <Table className="col-12 px-0 border rounded mb-0">
                <thead className="small text-muted">
                  <tr className="bg-lighter">
                    <th>WO #</th>
                    <th>Service Requested</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {workOrders.map((element) => (
                    <tr
                      onClick={() =>
                        navigate(`/work-orders/${element.id}/details`)
                      }
                      key={element.id}
                      className="cursor-pointer"
                    >
                      <td>
                        {element.jobNo
                          ? `#${element.jobNo}`
                          : `Without WO# (${element.id})`}
                      </td>
                      <td>{utils.getWoServiceRequested(element)}</td>
                      <td>
                        <Badge color={utils.getJobTypeColor(element.jobType)}>
                          {element.jobType}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ListGroup>
          )}
        </>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WorkOrdersAssociatedModal;
