import React, { useCallback, useEffect, useState } from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import classnames from "classnames";

import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";
import { workOrdersApi } from "../../services/workOrdersServices";

import InformationModal from "./../../components/InformationModal";
import WorkOrderStatus from "../../components/admin/workOrders/WorkOrderStatus";
import WorkOrderJobDetails from "../../components/admin/workOrders/WorkOrderJobDetails";
import WorkOrderWorkPerformed from "../../components/admin/workOrders/workPerformed/WorkOrderWorkPerformed";
import {
  useWorkOrderDetails,
  ACTIONS,
  TABS,
  STATUSES,
} from "../../providers/workOrderDetailsProvider";
import { utils } from "../../utils/utils";
import WorkOrderReports from "../../components/admin/workOrders/reports/WorkOrderReports";
import MakeRecurringWorkOrderModal from "../../components/admin/workOrders/MakeRecurringWorkOrderModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useWorkOrderStatus } from "../../providers/workOrderStatusProvider";
import ChangeStatusModal from "../../components/admin/workOrders/ChangeStatusModal";

const TYPE_INSPECTION = "Inspection";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const WorkOrderDetails = () => {
  const navigate = useNavigate();
  const { workOrderId } = useParams();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [workOrderStatusContext] = useWorkOrderStatus();

  const [loading, setLoading] = useState();
  const [makeRecurringModal, setMakeRecurringModal] = useState();
  const [changeStatusModal, setChangeStatusModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onMarkAsInvoiced = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setLoading(true);
        try {
          const status = workOrderStatusContext.workOrderStatus.find(
            (status) => status.name === STATUSES.STATUS_INVOICED
          );
          if (!status) {
            throw Error("Status invoiced not found");
          }
          await workOrdersApi.updateWorkOrder({
            ...workOrderDetails.workOrder,
            statusId: status.id,
          });
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Mark as Invoiced",
            body: "Work Order Marked as Invoiced Successfully.",
            onClose: () => {
              setInformationModal({
                isOpen: false,
                title: "",
                body: "",
                onClose: null,
              });
              setWorkOrderDetails({
                action: ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Mark as Invoiced",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Mark as Invoiced",
      body: `
            <span class="text-center">
              Do you confirm you want to mark this WO as invoiced?
            </span>
          `,
      confirmColor: "warning",
    });
  };

  const getWorkOrder = useCallback(async () => {
    setLoading(true);
    try {
      const workOrder = await workOrdersApi.getWorkOrderById({
        id: workOrderId,
      });
      setLoading(false);
      setWorkOrderDetails({
        action: ACTIONS.GET_WORKORDER_SUCCESS,
        payload: { workOrder },
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Work Order Details",
        body: "There was an error with your request.",
        onClose: () => navigate("/work-orders/all"),
      });
    }
  }, [navigate, setWorkOrderDetails, workOrderId]);

  const toggle = (tab) => {
    if (workOrderDetails.activeTab !== tab) {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_TAB,
        payload: {
          tab,
        },
      });
    }
  };

  // Load WorkOrder
  useEffect(() => {
    getWorkOrder();
    return () => {
      setWorkOrderDetails({
        action: ACTIONS.RESET,
      });
    };
  }, [
    getWorkOrder,
    workOrderId,
    navigate,
    workOrderDetails.refresh,
    setWorkOrderDetails,
  ]);

  const goBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate("/work-orders/all");
    }
  };

  const onSeeNoLeadTrips = () =>
    navigate(`/admin/no-lead-trips/${workOrderDetails.workOrder.id}`);

  return (
    <Container fluid>
      <Header className="mb-3 ">
        <HeaderTitle className="d-flex flex-column align-items-start">
          <span className="col-12 px-0 col-lg-6 text-dark">
            Work Order Information
          </span>
          <Button size="sm" className="rounded mt-1" onClick={goBack}>
            <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
            Back
          </Button>
        </HeaderTitle>
      </Header>
      <Row>
        {!workOrderDetails.workOrder || loading ? (
          <Loader />
        ) : (
          <>
            <Col className="flex-grow-1">
              <Card className="py-2">
                <CardHeader className="d-flex justify-content-between">
                  <div className="mr-3 flex-grow-1">
                    <div className="h4">
                      <span>Customer:</span>
                      <span
                        onClick={() =>
                          navigate(
                            `/customers/${workOrderDetails.workOrder.customerId}/details`
                          )
                        }
                        className="ml-1 text-primary text-underline cursor-pointer"
                      >
                        {
                          workOrderDetails.workOrder.customerWorkOrder
                            ?.customerName
                        }
                      </span>
                      {workOrderDetails.workOrder.customerWorkOrder
                        ?.customerNo ? (
                        <span className="ml-4 text-muted">
                          {
                            workOrderDetails.workOrder.customerWorkOrder
                              ?.customerNo
                          }
                        </span>
                      ) : null}
                    </div>
                    <div>
                      <span>Customer Location:</span>
                      <span className="ml-1 text-muted">
                        {workOrderDetails.workOrder.customerLocation
                          ? utils.getAddress(
                              workOrderDetails.workOrder.customerLocation
                            )
                          : "-"}
                      </span>
                    </div>
                    {workOrderDetails.workOrder.quotedJob && (
                      <Badge className="mt-2">Quoted Job</Badge>
                    )}
                  </div>
                  <div className="flex-shrink-0 text-right d-flex flex-column">
                    <div className="h4">
                      <span>Work Order #:</span>
                      <span className="text-muted ml-1">
                        {workOrderDetails.workOrder.jobNo ||
                          `Without WO# (${workOrderDetails.workOrder.id})`}
                      </span>
                    </div>
                    <div>
                      <span>Service Location:</span>
                      <span className="text-muted ml-1">
                        {workOrderDetails.workOrder.serviceLocation?.name ||
                          "-"}
                      </span>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pb-1 overflow-x-auto">
                  <WorkOrderStatus />
                </CardBody>
              </Card>
              <Nav
                tabs
                className="d-flex align-items-center work-order-details-nav cursor-pointer mt-3"
              >
                <div className="d-flex flex-grow-1">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: workOrderDetails.activeTab === TABS.JOB_TAB,
                      })}
                      onClick={() => toggle(TABS.JOB_TAB)}
                    >
                      Job Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          workOrderDetails.activeTab ===
                          TABS.WORK_PERFORMED_TAB,
                      })}
                      onClick={() => toggle(TABS.WORK_PERFORMED_TAB)}
                    >
                      Work Performed
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: workOrderDetails.activeTab === TABS.REPORT_TAB,
                      })}
                      onClick={() => toggle(TABS.REPORT_TAB)}
                    >
                      Reports
                    </NavLink>
                  </NavItem>
                </div>
                <div className="d-flex align-items-center mb-2">
                  {workOrderDetails.workOrder.tripsWithoutLeads?.length ? (
                    <Alert color="danger mb-0">
                      <div className="px-2 py-1 small text-danger">
                        <span className="text-danger">
                          There are dates without lead assigned.
                        </span>
                        <span
                          onClick={onSeeNoLeadTrips}
                          className="text-link text-danger ml-1 text-underline"
                        >
                          Click here to review
                        </span>
                      </div>
                    </Alert>
                  ) : null}
                  {workOrderDetails.workOrder.status.name ===
                  STATUSES.STATUS_READY_TO_INVOICE ? (
                    <Button
                      size="sm"
                      className="rounded ml-2"
                      color="warning"
                      onClick={onMarkAsInvoiced}
                    >
                      Mark as Invoiced
                    </Button>
                  ) : null}
                  <Button
                    size="sm"
                    className="ml-2 rounded"
                    color="info"
                    onClick={() => setChangeStatusModal(true)}
                  >
                    Change Status
                  </Button>
                  {workOrderDetails.workOrder.jobType === TYPE_INSPECTION &&
                  !workOrderDetails.workOrder.recurringWorkOrders?.length ? (
                    <Button
                      size="sm"
                      className="rounded ml-2"
                      color="warning"
                      onClick={() => setMakeRecurringModal(true)}
                    >
                      Make Recurring
                    </Button>
                  ) : null}
                  {workOrderDetails.workOrder.recurringWorkOrders?.length ? (
                    <Badge
                      color="secondary"
                      className="cursor-default ml-2"
                      pill
                    >
                      Recurring Work Order
                    </Badge>
                  ) : null}
                </div>
              </Nav>
              <TabContent activeTab={workOrderDetails.activeTab}>
                <TabPane tabId={TABS.JOB_TAB}>
                  <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                    {workOrderDetails.activeTab === TABS.JOB_TAB ? (
                      <WorkOrderJobDetails />
                    ) : null}
                  </Card>
                </TabPane>
              </TabContent>
              <TabContent activeTab={workOrderDetails.activeTab}>
                <TabPane tabId={TABS.WORK_PERFORMED_TAB}>
                  <Card className="box-shadow-none border-left border-bottom border-right">
                    <CardBody>
                      {workOrderDetails.activeTab ===
                      TABS.WORK_PERFORMED_TAB ? (
                        <WorkOrderWorkPerformed />
                      ) : null}
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
              <TabContent activeTab={workOrderDetails.activeTab}>
                <TabPane tabId={TABS.REPORT_TAB}>
                  <Card className="box-shadow-none border-left border-bottom border-right">
                    <CardBody>
                      {workOrderDetails.activeTab === TABS.REPORT_TAB ? (
                        <WorkOrderReports />
                      ) : null}
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </Col>
          </>
        )}
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : makeRecurringModal ? (
        <MakeRecurringWorkOrderModal
          onSubmit={() => setMakeRecurringModal(false)}
          onClose={() => setMakeRecurringModal(false)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : changeStatusModal ? (
        <ChangeStatusModal
          onClose={() => setChangeStatusModal(false)}
          onSubmit={() => {
            setChangeStatusModal(false);
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </Container>
  );
};

export default WorkOrderDetails;
