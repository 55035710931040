import moment from "moment";
import React from "react";

import { Col, Row } from "reactstrap";

import Badge from "reactstrap/lib/Badge";

import {
  STATUSES,
  useWorkOrderDetails,
} from "../../../providers/workOrderDetailsProvider";
import { useWorkOrderStatus } from "../../../providers/workOrderStatusProvider";

const WorkOrderStatus = () => {
  const [workOrderStatusContext] = useWorkOrderStatus();
  const [workOrderDetails] = useWorkOrderDetails();

  const statusLog = (workOrder, statusName, index) => {
    const statusLog = workOrder.statusLog.find(
      (log) => log.status.name === statusName
    );
    let time = statusLog?.updatedAt || statusLog?.createdAt;
    if (!statusLog && statusName === STATUSES.STATUS_PENDING) {
      time = time || workOrder.createdAt;
    } else if (!statusLog) {
      return null;
    }
    const author = statusLog?.updateAuthor || statusLog?.author;
    return (
      <div
        className={`small d-flex flex-column align-items-${
          index === 0
            ? "start"
            : index === workOrderStatusContext.workOrderStatus.length - 1
            ? "end"
            : "center"
        } mt-2 ${workOrder.status.name !== statusName ? "text-muted" : ""}`}
      >
        <small>{time ? moment(time).format("MM-DD-YYYY HH:mm") : "-"}</small>
        {statusLog ? (
          <small>
            By {author ? `${author.firstName} ${author.lastName}` : "N/A"}
          </small>
        ) : null}
      </div>
    );
  };

  return (
    <Col
      className="d-flex flex-column align-items-center col-12 pt-3 border-top"
      style={{ minWidth: 1200 }}
    >
      <h4 className="mb-4">Work Order Status</h4>
      <div className="min-width-900 col-12">
        <div className="d-flex justify-content-between align-items-center col-12">
          <div className="progress-line w-100 mx-0 border-top"></div>
        </div>
        <Row className="justify-content-between mx-0 position-relative">
          <Row className="justify-content-between status-container mx-0">
            {workOrderStatusContext.workOrderStatus.map((status, i) => (
              <div
                key={status.id}
                className={`d-flex flex-column align-items-${
                  i === 0
                    ? "start"
                    : i === workOrderStatusContext.workOrderStatus.length - 1
                    ? "end"
                    : "center"
                }`}
              >
                <Badge
                  color={
                    workOrderDetails.workOrder.status.name === status.name
                      ? "success"
                      : "lighter"
                  }
                >
                  {status.name}
                </Badge>
                {statusLog(workOrderDetails.workOrder, status.name, i)}
              </div>
            ))}
          </Row>
        </Row>
      </div>
    </Col>
  );
};

export default WorkOrderStatus;
