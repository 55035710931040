import axios from "axios";
import config from "../config/config";

export const postContractAnswerApi = {
  updateContractAnswer: (data) => {
    return axios(`/api/core/post-contract-answers`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((error) => ({ error }));
  },
};
