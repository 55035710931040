import React from "react";

import { Col, FormGroup, Label } from "reactstrap";

import { workOrdersApi } from "../../../../services/workOrdersServices";

import {
  useEditRecurringWorkOrders,
  ACTIONS,
} from "../../../../providers/editRecurringWorkOrdersProvider";
import SelectWrapper from "../../../SelectWrapper";

const PAGE_SIZE = 30;

const TYPE_INSPECTION = "Inspection";

const QuarterlySelector = ({ quarter }) => {
  const [editRecurringWorkOrdersContext, setEditRecurringWorkOrdersContext] =
    useEditRecurringWorkOrders();

  return (
    <FormGroup className="d-flex align-items-center">
      <Col className="col-4 px-0">
        <Label className="mb-0" for={`${quarter}-quarter`}>
          Q{quarter}
        </Label>
      </Col>
      <Col className="col-8 px-0">
        <SelectWrapper
          key={`${editRecurringWorkOrdersContext.selectedCustomer?.id}-
              ${editRecurringWorkOrdersContext.selectedCustomerLocation?.id}-
              ${editRecurringWorkOrdersContext.selectedServiceLocation?.id}
            `}
          entity="work order"
          formatItemFunction={(c) => {
            const workOrder = c.workOrder || c;
            return {
              label: workOrder.jobNo
                ? `#${workOrder.jobNo}`
                : `Without WO# (${workOrder.id})`,
              value: workOrder.id,
            };
          }}
          fetchFunction={workOrdersApi.getWorkOrders}
          fetchParameters={{
            customerId: editRecurringWorkOrdersContext.selectedCustomer.id,
            customerLocationId:
              editRecurringWorkOrdersContext.selectedCustomerLocation.id,
            jobType: TYPE_INSPECTION,
            pageSize: PAGE_SIZE,
            serviceLocationId:
              editRecurringWorkOrdersContext.selectedServiceLocation.id,
          }}
          defaultSelected={
            editRecurringWorkOrdersContext.selectedWorkOrders[quarter]
          }
          onSelected={(workOrder) =>
            setEditRecurringWorkOrdersContext({
              action: ACTIONS.ON_SELECT_WORK_ORDER,
              payload: { workOrder, period: quarter },
            })
          }
        />
      </Col>
    </FormGroup>
  );
};

export default QuarterlySelector;
