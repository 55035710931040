import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import moment from "moment";

import { useAuth } from "../../../providers/authProvider";
import { reportsApi } from "../../../services/reportsServices";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import { faInfoCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipItem from "../../../components/TooltipItem";
import PeriodTypeSelector from "../../../components/PeriodTypeSelector";
import WeekSelector from "../../../components/WeekSelector";
import CustomPeriodSelector from "../../../components/CustomPeriodSelector";
import DailyActivityChart from "../../../components/admin/reports/DailyActivityChart";

const DATA_POINTS = {
  "Active App Users": {
    order: 1,
    hover:
      "Includes: Techs who have opened/closed a workday, have entered work time or non-job time. ",
    total: "Total Techs",
  },
  "Techs with a Closed Work Day": { order: 4, total: "Total Techs" },
  "Techs with Work Time": { order: 5, total: "Total Techs" },
  "Techs with Non-Job time": { order: 6, total: "Total Techs" },
  "Active Work Orders": {
    order: 9,
    hover:
      "Includes: Work Orders that have been opened/closed or have had data entered.",
    total: "Total Work Orders",
  },
  "Opened Work Orders": { order: 7, total: "Total Work Orders" },
  "Closed Work Orders": { order: 8, total: "Total Work Orders" },
  "Inactive App Users": {
    order: 2,
    hover:
      "Includes: Techs who have not opened/closed a workday, has not entered work time or non-job time. ",
    total: "Total Techs",
  },
};

const TYPE_WEEKLY = "TYPE_WEEKLY";

const DailyUsageReport = () => {
  const [authContext] = useAuth();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    const condition = {
      serviceLocationId: authContext.serviceLocationId,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    reportsApi
      .dailyUsage(condition)
      .then((result) => {
        setLoading(false);
        setData(result);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    authContext.currentUser,
    refresh,
    authContext.serviceLocationId,
    customEndDate,
    customStartDate,
    monday,
    periodType.value,
  ]);

  const onSeeMore = (card, data) =>
    setInformationModal({
      isOpen: true,
      title: card,
      rawBody: true,
      body: (
        <ListGroup>
          {data.length ? (
            data.map((d) => (
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2 bg-lighter"
                tag="div"
              >
                {d}
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem
              className="d-flex justify-content-between align-items-center py-2 bg-lighter"
              tag="div"
            >
              No entries to show
            </ListGroupItem>
          )}
        </ListGroup>
      ),
    });

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Usage Report</h3>
              <div className="d-flex align-items-center">
                <div className="d-flex justify-content-between">
                  {loading ? (
                    <div className="min-width-50">
                      <Loader size="sm" className="mr-3" />
                    </div>
                  ) : null}
                  <PeriodTypeSelector
                    periodType={periodType}
                    setPeriodType={setPeriodType}
                  />
                  {periodType.value === TYPE_WEEKLY ? (
                    <WeekSelector
                      loading={loading}
                      monday={monday}
                      setMonday={setMonday}
                    />
                  ) : (
                    <CustomPeriodSelector
                      defaultEndDate={customEndDate}
                      defaultStartDate={customStartDate}
                      onSubmit={(startDate, endDate) => {
                        setCustomStartDate(startDate);
                        setCustomEndDate(endDate);
                      }}
                    />
                  )}
                </div>
                <Button
                  size="sm"
                  className="rounded-circle custom-rounded-button d-flex ml-3"
                  color="secondary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : (
                <>
                  <div className="d-flex flex-wrap justify-content-center w-100">
                    {Object.keys(DATA_POINTS)
                      .sort(
                        (x, y) => DATA_POINTS[x].order - DATA_POINTS[y].order
                      )
                      .map((card, i) => {
                        let entries;
                        if (typeof data[card] === "object") {
                          //is array
                          entries = data[card].length;
                        } else {
                          entries = data[card];
                        }
                        const leftData = `${entries} out of ${
                          data[DATA_POINTS[card].total]
                        }`;
                        const rightData = `${
                          data[DATA_POINTS[card].total]
                            ? (
                                (entries / data[DATA_POINTS[card].total]) *
                                100
                              ).toFixed(2)
                            : 0
                        }%`;
                        return (
                          <div className="col-12 col-md-6" key={i}>
                            <Card className="border">
                              <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
                                <div></div>
                                <div className="small font-500 text-center">
                                  {DATA_POINTS[card].hover ? (
                                    <TooltipItem
                                      id={card
                                        .replaceAll(" ", "")
                                        .toLowerCase()}
                                      title={DATA_POINTS[card].hover}
                                    >
                                      {card}
                                    </TooltipItem>
                                  ) : (
                                    card
                                  )}
                                </div>
                                <div>
                                  {typeof data[card] === "object" ? (
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        onSeeMore(card, data[card])
                                      }
                                      icon={faInfoCircle}
                                      size="sm"
                                      className="cursor-pointer"
                                    />
                                  ) : null}
                                </div>
                              </CardHeader>
                              <CardBody>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span>{leftData}</span>
                                  <span>{rightData}</span>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        );
                      })}
                    <div className="col-12">
                      <Card className="border">
                        <CardHeader className="small font-500 pb-2 border-bottom text-center">
                          Activity Chart
                        </CardHeader>
                        <CardBody className="pt-0 pb-3">
                          <DailyActivityChart
                            timePerDate={
                              data["Active App Users Per Date"] || {}
                            }
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default DailyUsageReport;
