import React, { useState } from "react";

import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import { recurringWorkOrdersApi } from "../../../../services/recurringWorkOrdersServices";

import InformationModal from "../../../InformationModal";

import Loader from "../../../Loader";

import MonthlySelector from "./MonthlySelector";
import QuarterlySelector from "./QuarterlySelector";

import classnames from "classnames";
import {
  ACTIONS,
  MONTHS,
  QUARTERS,
  TABS,
  TYPES,
  useEditRecurringWorkOrders,
} from "../../../../providers/editRecurringWorkOrdersProvider";
import {
  useRecurringWorkOrders,
  ACTIONS as WO_ACTIONS,
} from "../../../../providers/recurringWorkOrdersProvider";
import BasicInfo from "./BasicInfo";

const EditRecurringWorkOrderModal = () => {
  const [, setRecurringWorkOrdersContext] = useRecurringWorkOrders();
  const [editRecurringWorkOrdersContext, setEditRecurringWorkOrdersContext] =
    useEditRecurringWorkOrders();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const [errors, setErrors] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();

    if (!editRecurringWorkOrdersContext.selectedCustomer) {
      return setInformationModal({
        isOpen: true,
        title: "Edit Recurring Work Order",
        body: "Please select a customer.",
      });
    }

    if (!editRecurringWorkOrdersContext.selectedCustomerLocation) {
      return setInformationModal({
        isOpen: true,
        title: "Edit Recurring Work Order",
        body: "Please select a customer location.",
      });
    }

    const customerId = editRecurringWorkOrdersContext.selectedCustomer.id;
    const customerLocationId =
      editRecurringWorkOrdersContext.selectedCustomerLocation.id;
    const serviceLocationId =
      editRecurringWorkOrdersContext.selectedServiceLocation.id;
    const type = editRecurringWorkOrdersContext.type;

    try {
      setLoading(true);
      if (
        customerId !==
          editRecurringWorkOrdersContext.recurringWorkOrder.customerId ||
        customerLocationId !==
          editRecurringWorkOrdersContext.recurringWorkOrder
            .customerLocationId ||
        serviceLocationId !==
          editRecurringWorkOrdersContext.recurringWorkOrder.serviceLocationId ||
        type !== editRecurringWorkOrdersContext.recurringWorkOrder.type
      ) {
        const data = {
          ...editRecurringWorkOrdersContext.recurringWorkOrder,
          customerId,
          customerLocationId,
          serviceLocationId,
          type: editRecurringWorkOrdersContext.type,
        };
        await recurringWorkOrdersApi.updateRecurringWorkOrder(data);
      }

      //estan en editRecurringWorkOrdersContext.recurringWorkOrder.recurringWorkOrders pero no en editRecurringWorkOrdersContext.selectedWorkOrders
      const toRemove =
        editRecurringWorkOrdersContext.recurringWorkOrder.recurringWorkOrders
          .filter(
            (originalWo) =>
              !Object.entries(
                editRecurringWorkOrdersContext.selectedWorkOrders
              ).find(([period, selectedWo]) => {
                const meetsWo =
                  originalWo.workOrderId ===
                  (selectedWo.workOrderId || selectedWo.id);
                const meetsPeriod =
                  type === TYPES.TYPE_MONTHLY
                    ? parseInt(originalWo.month) === parseInt(period)
                    : parseInt(originalWo.quarter) === parseInt(period);
                return meetsWo && meetsPeriod;
              })
          )
          .map((originalWo) => originalWo.id);

      await Promise.all(
        toRemove.map((id) =>
          recurringWorkOrdersApi.deleteWorkOrderRecurringWorkOrder({ id })
        )
      );

      //no estan en editRecurringWorkOrdersContext.recurringWorkOrder.recurringWorkOrders pero si en editRecurringWorkOrdersContext.selectedWorkOrders
      const toCreate = Object.entries(
        editRecurringWorkOrdersContext.selectedWorkOrders
      )
        .filter(
          ([period, selectedWo]) =>
            !editRecurringWorkOrdersContext.recurringWorkOrder.recurringWorkOrders.find(
              (originalWo) => {
                const meetsWo =
                  originalWo.workOrderId ===
                  (selectedWo.workOrderId || selectedWo.id);
                const meetsPeriod =
                  type === TYPES.TYPE_MONTHLY
                    ? parseInt(originalWo.month) === parseInt(period)
                    : parseInt(originalWo.quarter) === parseInt(period);
                return meetsWo && meetsPeriod;
              }
            )
        )
        .map(([period, selectedWo]) => {
          return {
            recurringWorkOrderId:
              editRecurringWorkOrdersContext.recurringWorkOrder.id,
            workOrderId: selectedWo.workOrderId || selectedWo.id,
            month: type === TYPES.TYPE_MONTHLY ? parseInt(period) : null,
            quarter: type === TYPES.TYPE_QUARTERLY ? parseInt(period) : null,
          };
        });

      await Promise.all(
        toCreate.map((wo) =>
          recurringWorkOrdersApi.createWorkOrderRecurringWorkOrder(wo)
        )
      );

      setLoading(false);

      setEditRecurringWorkOrdersContext({
        action: ACTIONS.ON_CLOSE_MODAL,
      });

      setRecurringWorkOrdersContext({
        action: WO_ACTIONS.REFRESH,
      });
    } catch (err) {
      setLoading(false);
      if (err.isAxiosError) {
        setErrors(err.response.data);
        return;
      }
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="md">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={
            <Button
              className="close"
              color="none"
              onClick={() =>
                setEditRecurringWorkOrdersContext({
                  action: ACTIONS.ON_CLOSE_MODAL,
                })
              }
            >
              &times;
            </Button>
          }
        >
          Edit Recurring WorkOrder
        </ModalHeader>
        <ModalBody>
          {errors && (
            <Alert color="dark">
              <div className="p-2">
                {(errors || []).map((error) => (
                  <div key={error.param}>
                    - {error.param}: {error.msg}
                  </div>
                ))}
              </div>
            </Alert>
          )}
          <Col>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <>
                <Nav tabs className="cursor-pointer">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          editRecurringWorkOrdersContext.activeTab ===
                          TABS.INFO_TAB,
                      })}
                      onClick={() =>
                        setEditRecurringWorkOrdersContext({
                          action: ACTIONS.ON_TAB_CHANGE,
                          payload: { tab: TABS.INFO_TAB },
                        })
                      }
                    >
                      Basic Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          editRecurringWorkOrdersContext.activeTab ===
                          TABS.ASSIGNATIONS_TAB,
                      })}
                      onClick={() =>
                        setEditRecurringWorkOrdersContext({
                          action: ACTIONS.ON_TAB_CHANGE,
                          payload: { tab: TABS.ASSIGNATIONS_TAB },
                        })
                      }
                    >
                      {editRecurringWorkOrdersContext.type ===
                      TYPES.TYPE_MONTHLY
                        ? "Monthly"
                        : "Quarterly"}{" "}
                      Assignation
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={editRecurringWorkOrdersContext.activeTab}
                >
                  <TabPane tabId={TABS.INFO_TAB}>
                    {editRecurringWorkOrdersContext.activeTab ===
                    TABS.INFO_TAB ? (
                      <BasicInfo />
                    ) : null}
                  </TabPane>
                  <TabPane tabId={TABS.ASSIGNATIONS_TAB}>
                    <Card className="col-12 border-radius-0 box-shadow-none border-left border-bottom border-right pt-3 mb-2">
                      {editRecurringWorkOrdersContext.type ===
                      TYPES.TYPE_MONTHLY
                        ? MONTHS.map((month) => (
                            <MonthlySelector month={month} key={month} />
                          ))
                        : QUARTERS.map((quarter) => (
                            <QuarterlySelector
                              quarter={quarter}
                              key={quarter}
                            />
                          ))}
                    </Card>
                  </TabPane>
                </TabContent>
              </>
            )}
          </Col>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button
                color={"secondary"}
                onClick={() =>
                  setEditRecurringWorkOrdersContext({
                    action: ACTIONS.ON_CLOSE_MODAL,
                  })
                }
              >
                Discard
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditRecurringWorkOrderModal;
