import {
  faExchangeAlt,
  faPlus,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import ConfirmationModal from "../../../components/ConfirmationModal";
import InformationModal from "../../../components/InformationModal";

import Loader from "../../../components/Loader";

import { useAuth } from "../../../providers/authProvider";
import { notificationApi } from "../../../services/notificationServices";
import AddActivityEmailModal from "../../../components/admin/AddActivityEmailModal";
import download from "downloadjs";
import moment from "moment";

const ActivityEmails = () => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState(true);
  const [activityEmails, setActivityEmails] = useState([]);
  const [refresh, setRefresh] = useState();
  const [addModal, setAddModal] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await notificationApi.getActivityEmails();
        setActivityEmails(result.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh, authContext.serviceLocationId]);

  const removeEmail = (activityEmail) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          setLoading(true);
          await notificationApi.deleteActivityEmails({
            id: activityEmail.id,
          });
          setLoading(false);
          setRefresh(!refresh);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Remove Activity Email",
            body: "Email removed successfully.",
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setLoading(false);
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Activity Email",
      body: `<span class="text-center">Do you confirm you want to remove ${activityEmail.email}?</span>`,
    });
  };

  const onSendGenerate = async () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          const report = await notificationApi.sendActivityEmails();
          download(
            report,
            `activity-report-${moment()
              .startOf("isoWeek")
              .format("YYYY-MM-DD")}.xlsx`,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          setConfirmationModal(initConfirmationModal);
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Send Activity Email",
      body: `<span class="text-center">Do you confirm you want to generate and send activity emails?</span>`,
    });
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Activity Emails</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
              size="sm"
              className="ml-2 rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={() => setAddModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              size="sm"
              className="ml-2 rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={onSendGenerate}
            >
              <FontAwesomeIcon icon={faExchangeAlt} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader />
          ) : activityEmails.length ? (
            <ListGroup flush className="border-top">
              <ListGroupItem
                className="py-2 border-bottom font-weight-bold bg-lighter text-muted small d-flex justify-content-between"
                tag="div"
              >
                <div className="col-5">Email</div>
                <div className="col-5">Service Location</div>
                <div className="col-2"></div>
              </ListGroupItem>
              {activityEmails.map((activityEmail, index) => (
                <ListGroupItem
                  key={index}
                  className="py-2 border-bottom d-flex justify-content-between align-items-center"
                  tag="div"
                >
                  <div className="col-5">{activityEmail.email}</div>
                  <div className="col-5">
                    {activityEmail.serviceLocations
                      ?.map((sl) => sl.name)
                      .join(", ") || "All"}
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <Button
                      className="ml-2 rounded d-flex align-items-center"
                      color="none"
                      size="sm"
                      onClick={() => removeEmail(activityEmail)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-danger"
                        size="lg"
                      />
                    </Button>
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <div className="text-center">No emails added</div>
          )}
        </CardBody>
      </Card>
      {addModal ? (
        <AddActivityEmailModal
          submitFunction={notificationApi.createActivityEmail}
          onSubmit={() => {
            setAddModal();
            setInformationModal({
              isOpen: true,
              title: "Add Activity Email",
              body: "Email added successfully.",
            });
            setRefresh(!refresh);
          }}
          onClose={() => setAddModal()}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default ActivityEmails;
