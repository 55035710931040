import React, { useState } from "react";

import { Badge, ListGroup, ListGroupItem } from "reactstrap";
import MaterialNoteFormModal from "./Edition/MaterialNoteFormModal";
import EditMaterialsModal from "./Edition/EditMaterialsModal";

const Materials = ({ workOrderMaterials, employeeWorkDayId, materials }) => {
  const [openModal, setOpenModal] = useState(false);
  const [workOrderMaterialsForm, setWorkOrderMaterialsForm] = useState({});
  const openNoteModal = (workOrderMaterial) => {
    setWorkOrderMaterialsForm(workOrderMaterial);
    setOpenModal(true);
  };
  return (
    <>
      <ListGroup className="my-2 col-12 px-0">
        <ListGroupItem
          className="d-flex justify-content-between align-items-center bg-lighter font-500"
          tag="div"
        >
          Materials
        </ListGroupItem>
        {workOrderMaterials.length ? (
          workOrderMaterials.map((workOrderMaterial, index) => (
            <ListGroupItem
              className="d-flex justify-content-between align-items-center"
              tag="div"
              key={index}
            >
              <div className="d-flex col-12 px-0 my-2">
                <div
                  style={{ backgroundColor: "#FDF1DB" }}
                  className="col-6 text-muted p-2 d-flex justify-content-between align-items-start rounded cursor-pointer"
                  onClick={() => openNoteModal(workOrderMaterial)}
                >
                  <small className="text-break">
                    {workOrderMaterial.notes || "No Description"}
                  </small>
                  <Badge className="ml-2">
                    <small>
                      By{" "}
                      {`${workOrderMaterial.author?.firstName} ${workOrderMaterial.author?.lastName}`}
                    </small>
                  </Badge>
                </div>
                <ListGroup className="flex-grow-1 ml-4">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center"
                    tag="div"
                  >
                    <span>Material</span>
                    <span>Count</span>
                    {workOrderMaterial.workOrderMaterial.length ? (
                      <span>Options</span>
                    ) : null}
                  </ListGroupItem>
                  {workOrderMaterial.workOrderMaterial.length ? (
                    workOrderMaterial.workOrderMaterial.map(
                      (workOrderMaterialItem, index) => (
                        <ListGroupItem
                          key={index}
                          className="d-flex align-items-center"
                          tag="div"
                        >
                          <span style={{ textAlign: "left", flex: 1 }}>
                            {workOrderMaterialItem.material.name}
                          </span>
                          <span style={{ textAlign: "center", flex: 1 }}>
                            {workOrderMaterialItem.count}
                          </span>
                          <span style={{ textAlign: "right", flex: 1 }}>
                            <EditMaterialsModal
                              actualMaterial={workOrderMaterialItem.material.id}
                              actualQuantity={workOrderMaterialItem.count}
                              workOrderMaterialsId={workOrderMaterial.id}
                              workOrderMaterialId={workOrderMaterialItem.id}
                              currentMaterialsList={
                                workOrderMaterial.workOrderMaterial
                              }
                              materials={materials}
                            />
                          </span>
                        </ListGroupItem>
                      )
                    )
                  ) : (
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center"
                      tag="div"
                    >
                      <small className="text-muted">No materials to show</small>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            </ListGroupItem>
          ))
        ) : (
          <ListGroupItem
            className="d-flex justify-content-between align-items-center"
            tag="div"
          >
            <small className="text-muted">No materials to show</small>
          </ListGroupItem>
        )}
      </ListGroup>
      <MaterialNoteFormModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        workOrderMaterialsForm={workOrderMaterialsForm}
        employeeWorkDayId={employeeWorkDayId}
      />
    </>
  );
};

export default Materials;
