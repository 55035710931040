import React, { useState } from "react";
import SidebarItem from "./SidebarItem";
import SidebarCategory from "./SidebarCategory";
import { useAuth } from "../../../providers/authProvider";
import { useNavigate, useLocation } from "react-router-dom";

const setBadgeText = (authContext, path) => {
  switch (path) {
    case "/work-orders/all/recently-added":
      return authContext.currentUser.recentlyAddedWOCount;
    default:
      return null;
  }
};

const SidebarMenu = ({ name, icon, to, entries }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onClickAction = (entry) => {
    if (location.pathname === entry.path) {
      return;
    }
    navigate(entry.path);
  };

  const [authContext] = useAuth();
  const [isOpen, setIsOpen] = useState(location.pathname.indexOf(to) === 0);

  return (
    <React.Fragment>
      <SidebarCategory
        name={name}
        icon={icon}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {entries
          .filter(
            (entry) =>
              entry.sidebar &&
              (!entry.roles ||
                entry.roles?.indexOf(authContext.currentUser.roleId) > -1)
          )
          .map((entry, index) => (
            <div key={index}>
              {!entries[index].entries ? (
                <SidebarItem
                  key={index}
                  icon={entry.icon}
                  to={entry.path}
                  name={entry.name}
                  action={() => onClickAction(entry)}
                  badgeColor={entry.badgeColor}
                  badgeText={setBadgeText(authContext, entry.path)}
                />
              ) : null}
              {entries[index].entries
                ? entries[index].entries
                    .sort((a, b) => b - a)
                    .map((key, indexChildren) =>
                      index === indexChildren ? (
                        <div key={indexChildren}>
                          <li className="sidebar-header">
                            {entries[index].name}
                          </li>
                          {entry.entries.map((route, ind) => (
                            <SidebarItem
                              key={ind}
                              name={route.name}
                              to={route.path}
                              action={() => onClickAction(route)}
                            />
                          ))}
                        </div>
                      ) : null
                    )
                : null}
            </div>
          ))}
      </SidebarCategory>
    </React.Fragment>
  );
};

export default SidebarMenu;
