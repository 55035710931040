import React, { useEffect, useState } from "react";

import { Button, ListGroup, ListGroupItem, Input } from "reactstrap";
import { postContractAnswerApi } from "../../../../services/postContractAnswerServices";
import { postContractQuestionsApi } from "../../../../services/postContractQuestionsServices";
import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../../../providers/workOrderDetailsProvider";
import InformationModal from "../../../InformationModal";
import ConfirmationModal from "../../../ConfirmationModal";
import Loader from "../../../Loader";

const DEFAULT_OPTIONS = ["True", "False"];
const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const WorkOrderCloseOut = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState();
  const [contractQuestions, setContractQuestions] = useState();
  const [note, setNote] = useState(
    workOrderDetails?.workOrder?.postContract?.note
  );
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const [wOContractQuestions, setWOContractQuestions] = useState(
    workOrderDetails?.workOrder?.postContract?.postContractQuestions
  );

  const updateQuestionAnswer = (index, answer) => {
    const wOContractQuestionsCopy = [...wOContractQuestions];
    wOContractQuestionsCopy[index].postContractAnswer.answer = answer;
    setWOContractQuestions(wOContractQuestionsCopy);
  };

  const updateQuestions = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setLoading(true);
        try {
          const postContractId = workOrderDetails?.workOrder?.postContract?.id;
          const postContractAnswers = wOContractQuestions.map((question) => ({
            answerId: question?.postContractAnswer?.id,
            questionId: question?.id,
            answer: question?.postContractAnswer?.answer,
          }));
          const requestResult =
            await postContractAnswerApi.updateContractAnswer({
              postContractAnswers,
              postContractId,
              note,
            });

          if (requestResult?.error) {
            throw new Error();
          }

          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Update Post Contract Answers",
            body: "Post Contract Answers Updated Successfully.",
            onClose: refresh,
          });
          setLoading(false);
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Update Post Contract Answers",
            body: "There was an error with your request.",
            onClose: refresh,
          });
          setLoading(false);
        }
        setEdit(false);
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Update Post Contract Answers",
      body: `
            <span class="text-center">
              Do you confirm you want to do the updates?
            </span>
          `,
      confirmColor: "danger",
    });
  };

  const refresh = () => {
    setWorkOrderDetails({
      action: ACTIONS.REFRESH,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await postContractQuestionsApi.getPostContractQuestions();
      setContractQuestions(result);
    };
    fetchData();
  }, []);

  const answearedQuestions = wOContractQuestions?.map((question, index) => (
    <ListGroupItem
      key={`woco-${index}`}
      className="d-flex justify-content-between align-items-center"
    >
      <div className="col-6 px-0">{question.name}</div>
      <div className="col-2 btn-sm rounded outline-none border border-secondary bg-secondary text-white text-center font-weight-bold">
        {question.postContractAnswer.answer.toUpperCase()}
      </div>
    </ListGroupItem>
  ));

  const editableQuestions = contractQuestions
    ?.filter((question) =>
      wOContractQuestions?.some((wOquestion) => wOquestion.id === question.id)
    )
    .map((question, index) => {
      const options = question?.options || DEFAULT_OPTIONS;
      const wOContractQuestion = wOContractQuestions.find(
        (wOQuestion) => wOQuestion.id === question.id
      );
      return (
        <ListGroupItem
          key={`woco-${index}`}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="col-6 px-0">{question.name}</div>
          <div className="col-6 px-0 d-flex justify-content-end">
            {options.map((option) => (
              <Button
                key={`woco-${index}-${option}`}
                onClick={() => {
                  updateQuestionAnswer(index, option);
                }}
                className={`col-3 btn-sm rounded outline-none border-secondary mr-3 
                  ${
                    wOContractQuestion.postContractAnswer.answer === option
                      ? "bg-secondary text-white"
                      : "bg-white text-secondary"
                  }`}
              >
                {option.toUpperCase()}
              </Button>
            ))}
          </div>
        </ListGroupItem>
      );
    });

  return wOContractQuestions?.length ? (
    <>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : loading ? (
        <Loader size={"sm"} />
      ) : null}
      <ListGroup className="mt-3 pb-2">
        <ListGroupItem className="d-flex justify-content-between align-items-center bg-lighter font-weight-bold">
          Work Order Close Out
          {!edit ? (
            <Button
              size="sm"
              className="rounded mb-1"
              color="warning"
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </Button>
          ) : (
            <div className="d-flex justify-content-between align-items-end">
              <Button
                size="sm"
                className="rounded mb-1 mr-2"
                color="warning"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                className="rounded mb-1"
                color="primary"
                onClick={updateQuestions}
              >
                Save
              </Button>
            </div>
          )}
        </ListGroupItem>
        {edit ? editableQuestions : answearedQuestions}
        <ListGroupItem
          key={`woco-job-notes`}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="col-6 px-0">Notes about this Job:</div>
          <div className="col-6 px-0 d-flex justify-content-end">
            {!edit ? (
              <div className="col-6 px-0 d-flex justify-content-start border rounded p-3">
                {note}
              </div>
            ) : (
              <Input
                type="textarea"
                placeholder="Enter notes about this Job"
                required={false}
                maxLength={255}
                value={note}
                onChange={(event) => {
                  setNote(event.target.value);
                }}
              />
            )}
          </div>
        </ListGroupItem>
      </ListGroup>
    </>
  ) : null;
};

export default WorkOrderCloseOut;
