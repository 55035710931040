import React, { useEffect, useState } from "react";

import { useAuth } from "../../../providers/authProvider";

import placeholder from "../../../assets/img/placeholder.png";

import Select from "react-select";

import makeAnimated from "react-select/animated";

import { FormGroup } from "reactstrap";

import { commonApi } from "../../../services/commonServices";
import Loader from "../../../components/Loader";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const EMPLOYEE_ROLE_EXECUTIVE = 1;
const EMPLOYEE_ROLE_REGIONAL_MANAGER = 7;
const EMPLOYEE_ROLE_PAYROLL_MANAGER = 8;
const MAX_PAGE_SIZE = 999;

const SideBarUser = () => {
  const [authContext, setAuthContext] = useAuth();
  const [loading, setLoading] = useState();

  const [serviceLocationSearch, setServiceLocationSearch] = useState();
  const [selectedServiceLocation, setSelectedServiceLocation] = useState();
  const [serviceLocations, setServiceLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await commonApi.getServiceLocations({
        pageSize: MAX_PAGE_SIZE,
      });
      const serviceLocations = [
        {
          id: null,
          name: "All Service Locations",
        },
        ...data.data,
      ];
      setAuthContext({
        ...authContext,
        serviceLocations,
      });
      setLoading(false);
    };
    if (!authContext.serviceLocations.length) {
      fetchData();
    }
  }, [authContext, setAuthContext]);

  useEffect(() => {
    if (
      parseInt(authContext.currentUser.roleId) === EMPLOYEE_ROLE_EXECUTIVE ||
      parseInt(authContext.currentUser.roleId) === EMPLOYEE_ROLE_PAYROLL_MANAGER
    ) {
      setServiceLocations([...authContext.serviceLocations]);
      if (authContext.serviceLocationId) {
        const selected = authContext.serviceLocations.find(
          (d) => parseInt(d.id) === parseInt(authContext.serviceLocationId)
        );
        if (selected) {
          setSelectedServiceLocation({
            value: selected.id,
            label: selected.name,
          });
        }
      } else {
        setSelectedServiceLocation({
          value: null,
          label: "All Service Locations",
        });
      }
    } else {
      setServiceLocations([
        {
          id: null,
          name: "All Service Locations",
        },
        ...authContext.currentUser.serviceLocations,
      ]);
      if (authContext.serviceLocationId) {
        const selected = authContext.currentUser.serviceLocations.find(
          (d) => d.id === authContext.serviceLocationId
        );
        if (selected) {
          setSelectedServiceLocation({
            value: selected.id,
            label: selected.name,
          });
        }
      } else {
        setSelectedServiceLocation({
          value: null,
          label: "All Service Locations",
        });
      }
    }
  }, [
    authContext.serviceLocations,
    authContext.currentUser.roleId,
    authContext.currentUser.serviceLocations,
    authContext.serviceLocationId,
  ]);

  return (
    <div className="sidebar-user p-3 d-flex flex-column align-items-center justify-content-center">
      <div
        className="rounded-circle bg-lightblue img-thumbnail d-flex align-items-center justify-content-center"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${placeholder})`,
          width: "96px",
          height: "96px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <span className="text-muted mt-1 font-500">{`${authContext.currentUser?.firstName} ${authContext.currentUser?.lastName}`}</span>
      <small className="text-muted text-underline mb-1">
        {authContext.currentUser.role?.name || ""}
      </small>
      {authContext.currentUser.serviceLocations?.length ? (
        <small className="text-muted">
          {authContext.currentUser.serviceLocations
            .map((sl) => sl.name)
            .join(", ")}
        </small>
      ) : null}
      {parseInt(authContext.currentUser.roleId) === EMPLOYEE_ROLE_EXECUTIVE ||
      parseInt(authContext.currentUser.roleId) ===
        EMPLOYEE_ROLE_REGIONAL_MANAGER ||
      parseInt(authContext.currentUser.roleId) ===
        EMPLOYEE_ROLE_PAYROLL_MANAGER ? (
        loading ? (
          <Loader size="sm" />
        ) : (
          <FormGroup className="small mt-3 width-100 mb-0">
            <Select
              id="serviceLocations"
              placeholder={
                <span className="text-muted">
                  Search for a Svc. Location...
                </span>
              }
              noOptionsMessage={() => "No service locations found"}
              styles={style}
              className="flex-grow-1 border rounded text-left"
              options={serviceLocations.map((c) => {
                return {
                  label: c.name,
                  value: c.id,
                };
              })}
              closeMenuOnSelect={true}
              components={animatedComponents}
              isSearchable
              isClearable
              isMulti={false}
              inputValue={serviceLocationSearch}
              value={selectedServiceLocation}
              onInputChange={setServiceLocationSearch}
              onChange={(serviceLocation) => {
                setAuthContext({
                  ...authContext,
                  serviceLocationId: serviceLocation?.value,
                });
                if (!serviceLocation) {
                  setSelectedServiceLocation({
                    value: null,
                    label: "All Service Locations",
                  });
                } else {
                  setSelectedServiceLocation(serviceLocation);
                }
                setServiceLocationSearch();
              }}
              required={true}
            />
          </FormGroup>
        )
      ) : null}
    </div>
  );
};

export default SideBarUser;
