import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Badge,
  CardFooter,
} from "reactstrap";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import InformationModal from "../../../../components/InformationModal";

import {
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTablePagination from "../../../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../../../components/advanceTable/AdvanceTable";
import { useAuth } from "../../../../providers/authProvider";
import CalendarGroupingModal from "./CalendarGroupingModal";
import { CalendarGroupingApi } from "../../../../services/calendarGroupingServices";
import {
  useCalendarGroupings,
  ACTIONS,
} from "../../../../providers/calendarGroupingProvider";
import TooltipItem from "../../../../components/TooltipItem";

const columns = (onEdit, onDelete) => [
  {
    accessor: "name",
    Header: "Name",
    disableSortBy: false,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { name } = rowData.row.original;
      const shouldTruncate = name.length > 21;
      const displayName = shouldTruncate ? `${name.slice(0, 21)}...` : name;
      const uniqueId = `tooltip-name-${rowData.row.id}`;
      return (
        <>
          {shouldTruncate ? (
            <TooltipItem title={name} id={uniqueId}>
              <span>{displayName}</span>
            </TooltipItem>
          ) : (
            <span>{name || "-"}</span>
          )}
        </>
      );
    },
  },
  {
    accessor: "order",
    Header: "Order",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { order } = rowData.row.original;
      return order || "-";
    },
  },
  {
    accessor: "color",
    Header: "Color",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-left" },
    Cell: (rowData) => {
      const { color } = rowData.row.original;
      return (
        <Badge
          style={{
            backgroundColor: color,
            width: "1.5em",
            height: "1.5em",
            display: "inline-block",
          }}
        />
      );
    },
  },
  {
    accessor: "serviceLocationId",
    Header: "Service Location",
    disableSortBy: false,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { serviceLocation } = rowData.row.original;
      return serviceLocation?.name;
    },
  },
  {
    accessor: "employees",
    Header: "Technicians",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: ({ row }) => {
      const { employees } = row.original;

      if (!employees.length) {
        return "-";
      }

      const displayNames = employees.map(
        (employee) => `${employee.firstName} ${employee.lastName}`
      );
      const shortDisplay = displayNames.slice(0, 2).join(", ");
      const fullDisplay = displayNames.join(", ");
      const uniqueId = `tooltip-${row.id}-${employees
        .map((e) => e.id)
        .join("-")}`;

      return (
        <>
          {displayNames.length >= 2 ? (
            <TooltipItem title={fullDisplay} id={uniqueId}>
              <span>{`${shortDisplay}...`}</span>
            </TooltipItem>
          ) : (
            fullDisplay
          )}
        </>
      );
    },
  },
  {
    accessor: "id",
    Header: "",
    disableSortBy: true,
    width: 320,
    cellProps: {
      className: "text-right",
    },
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        <Button
          size="sm"
          className="rounded"
          color="warning"
          onClick={() => onEdit(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faPen} className="mr-1" />
          <span>Edit</span>
        </Button>{" "}
        <Button
          size="sm"
          className="rounded ml-2"
          color="danger"
          onClick={() => onDelete(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
    ),
  },
];

const CalendarGrouping = () => {
  const [calendarGroupingsContext, setCalendarGroupingsContext] =
    useCalendarGroupings();
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(true);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createCalendarGrouping, setCreateCalendarGrouping] = useState(false);

  const [editCalendarGrouping, setEditCalendarGrouping] = useState(false);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          calendarGroupingsContext.sortBy === sortBy?.id &&
          calendarGroupingsContext.direction === direction
        ) {
          return;
        }
        setCalendarGroupingsContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setCalendarGroupingsContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      calendarGroupingsContext.direction,
      calendarGroupingsContext.sortBy,
      setCalendarGroupingsContext,
    ]
  );

  const onEdit = (calendarGrouping) => {
    setEditCalendarGrouping(calendarGrouping);
  };

  const onDelete = (calendarGrouping) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        CalendarGroupingApi.deleteCalendarGrouping(calendarGrouping)
          .finally(() => {
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Calendar Grouping",
              body: "Group deleted successfully",
            });
            setCalendarGroupingsContext({
              action: ACTIONS.REFRESH,
            });
          })
          .catch((error) => {
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Calendar Grouping",
              body:
                error?.response?.data[0].msg ||
                "There was an error with your request.",
            });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Delete Group",
      body: `<p class="text-center">Are you sure you want to delete this group?</p>
        <strong>${calendarGrouping.name}</strong>`,
      confirmColor: "danger",
    });
  };

  const setCalendarGroupingsContextCb = useCallback(
    (data) => setCalendarGroupingsContext(data),
    [setCalendarGroupingsContext]
  );

  useEffect(() => {
    setLoading(true);
    CalendarGroupingApi.getCalendarGrouping({
      search: calendarGroupingsContext.search,
      page: calendarGroupingsContext.page - 1,
      pageSize: calendarGroupingsContext.sizePerPage,
      sortBy: calendarGroupingsContext.sortBy,
      direction: calendarGroupingsContext.direction,
      serviceLocationId: authContext.serviceLocationId,
    })
      .then((calendarGroupings) => {
        setLoading(false);
        setCalendarGroupingsContextCb({
          action: ACTIONS.GET_CALENDAR_GROUPINGS_SUCCESS,
          payload: { calendarGroupings },
        });
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setCalendarGroupingsContextCb,
    calendarGroupingsContext.sortBy,
    calendarGroupingsContext.direction,
    calendarGroupingsContext.sizePerPage,
    calendarGroupingsContext.page,
    calendarGroupingsContext.search,
    calendarGroupingsContext.refresh,
    authContext.serviceLocationId,
  ]);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(onEdit, onDelete)}
          data={calendarGroupingsContext?.calendarGroupings?.data || []}
          pageSize={calendarGroupingsContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: calendarGroupingsContext.sortBy,
            direction: calendarGroupingsContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Calendar Grouping</h3>
                <small className="text-muted ml-2 pt-1">
                  ({calendarGroupingsContext?.calendarGroupings?.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={calendarGroupingsContext.search}
                    onChange={(evt) =>
                      setCalendarGroupingsContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-secondary text-white border-left-0 border-secondary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={() => setCreateCalendarGrouping(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button ml-3"
                  color="secondary"
                  onClick={() =>
                    setCalendarGroupingsContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {calendarGroupingsContext?.calendarGroupings?.data?.length ? (
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={calendarGroupingsContext?.calendarGroupings?.count}
                pageCount={
                  calendarGroupingsContext?.calendarGroupings?.totalPages
                }
                currentPage={calendarGroupingsContext.page - 1}
                onPageChange={(page) =>
                  setCalendarGroupingsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={calendarGroupingsContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setCalendarGroupingsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createCalendarGrouping ? (
        <CalendarGroupingModal
          onClose={() => {
            setCreateCalendarGrouping(false);
          }}
          onSubmit={() => {
            setCreateCalendarGrouping(false);
            setInformationModal({
              isOpen: true,
              title: "Calendar Grouping",
              body: "Calendar Grouping Created successfully.",
            });
            setCalendarGroupingsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : editCalendarGrouping ? (
        <CalendarGroupingModal
          calendarGroupingParam={editCalendarGrouping}
          onClose={() => {
            setEditCalendarGrouping(false);
          }}
          onSubmit={() => {
            setEditCalendarGrouping(false);
            setInformationModal({
              isOpen: true,
              title: "Calendar Grouping",
              body: "Calendar Grouping Updated Successfully.",
            });
            setCalendarGroupingsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default CalendarGrouping;
