import React, { useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { pdfjs } from "react-pdf";
import InformationModal from "../../../../components/InformationModal";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";
import CraneName from "../../cranes/CraneName";
import WorkPerformedReport from "./WorkPerformedReport";
import { reportsApi } from "../../../../services/reportsServices";
import Loader from "../../../Loader";
import socketIOClient from "socket.io-client";
import config from "../../../../config/config";
import { useAuth } from "../../../../providers/authProvider";
import download from "downloadjs";
import moment from "moment";

const WORK_PERFORMED_REPORT_TAB = "WORK_PERFORMED_REPORT_TAB";
const TYPE_INSPECTION = "Inspection";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let socket;

const ProgressModal = ({ progress }) => (
  <InformationModal
    title={"Download All Reports"}
    body={
      <div className="text-center text-muted mb-1">
        Generating reports: {`${progress.progress} of ${progress.total}..`}
      </div>
    }
    rawBody={true}
    closable={false}
  />
);

const WorkOrderReports = () => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();
  const [downloadProgress, setDownloadProgress] = useState();

  const [activeTab, setActiveTab] = useState(WORK_PERFORMED_REPORT_TAB);

  const onDownloadAllSocketActions = (socketToken, signupCallback) => {
    socket = socketIOClient(config.apiURL, { transports: ["websocket"] });

    socket.emit("signup", socketToken);

    socket.on("signupSuccess", () => {
      signupCallback();
    });

    socket.on("progress", (data) => {
      setDownloadProgress(data);
      if (data.reports) {
        setLoading(false);
        socket.disconnect();
        setDownloadProgress();
        const name = `job#${workOrderDetails.workOrder.jobNo}-${workOrderDetails.workOrder.jobType}-ticket-reports.tar`;
        const report = new Blob([data.reports], { type: "application/x-tar" });
        setInformationModal({
          isOpen: true,
          title: "Download all reports",
          body: "Reports generated successfully",
          onClose: () => {
            setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            });
            download(report, name, "application/x-tar");
          },
        });
      }
    });

    socket.on("disconnect", (data) => {
      console.log("disconnected", data);
    });
  };

  const onDownloadAll = async () => {
    onDownloadAllSocketActions(
      authContext.currentUser.socketToken,
      async () => {
        const queryData = {
          workOrderId: workOrderDetails.workOrder.id,
          socketToken: authContext.currentUser.socketToken,
          browserTimeOffset: moment().utcOffset() / 60,
        };
        setLoading(true);
        try {
          await reportsApi.workOrderDownloadAllPdf(queryData);
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Download all reports",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      }
    );
  };

  const workOrderCranes = workOrderDetails.workOrder.workOrderCranes.filter(
    (woc) => woc.craneInspections?.length
  );

  return (
    <Row className="mt-3">
      <Col sm="12" className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-start">
          <h4 className="mb-0">Reports</h4>
          {loading ? (
            <Loader size="sm" align="end" className="px-0 my-0" />
          ) : workOrderDetails.workOrder.jobType === TYPE_INSPECTION &&
            workOrderCranes.length ? (
            <Button
              color="success"
              size="sm"
              className="rounded ml-1"
              onClick={() => {
                onDownloadAll();
              }}
            >
              <span className="font-500">Download All</span>
              <FontAwesomeIcon icon={faDownload} className="ml-1" />
            </Button>
          ) : null}
        </div>
        <div className="tab tab-vertical h-100 mt-2">
          <Nav
            tabs
            className="cursor-pointer border-bottom"
            style={{ marginRight: -1, zIndex: 1, position: "relative" }}
          >
            <NavItem>
              <NavLink
                className={`border-left border-top border-bottom ${
                  activeTab === WORK_PERFORMED_REPORT_TAB
                    ? "active bg-lighter-tabs font-weight-bold"
                    : ""
                }`}
                onClick={() => {
                  if (activeTab !== WORK_PERFORMED_REPORT_TAB)
                    setActiveTab(WORK_PERFORMED_REPORT_TAB);
                }}
              >
                <div className="d-flex align-items-center">
                  {workOrderDetails.workOrder.jobType} Ticket
                </div>
              </NavLink>
            </NavItem>
            {workOrderDetails.workOrder.jobType === TYPE_INSPECTION
              ? workOrderCranes.map((workOrderCrane) => {
                  const tabId = `crane-${workOrderCrane.craneId}`;
                  return (
                    <NavItem key={workOrderCrane.craneId}>
                      <NavLink
                        className={`border-left border-top border-bottom ${
                          activeTab === tabId
                            ? "active bg-lighter-tabs font-weight-bold"
                            : ""
                        }`}
                        onClick={() => {
                          if (activeTab !== tabId) setActiveTab(tabId);
                        }}
                      >
                        <CraneName crane={workOrderCrane.crane} />
                      </NavLink>
                    </NavItem>
                  );
                })
              : null}
          </Nav>
          <TabContent activeTab={activeTab} className="h-100 p-0 border">
            <TabPane tabId={WORK_PERFORMED_REPORT_TAB}>
              <Card className="border-radius-0 box-shadow-none d-flex flex-column flex-grow-1 bg-lighter-tabs mb-0 h-100">
                <CardBody>
                  {activeTab === WORK_PERFORMED_REPORT_TAB ? (
                    <WorkPerformedReport />
                  ) : null}
                </CardBody>
              </Card>
            </TabPane>
            {workOrderDetails.workOrder.jobType === TYPE_INSPECTION
              ? workOrderCranes.map((workOrderCrane, i) => {
                  const tabId = `crane-${workOrderCrane.craneId}`;
                  return (
                    <TabPane key={i} tabId={tabId}>
                      <Card className="border-radius-0 box-shadow-none d-flex flex-column flex-grow-1 bg-lighter-tabs mb-0 h-100">
                        <CardBody>
                          {activeTab === tabId ? (
                            <WorkPerformedReport
                              workOrderCrane={workOrderCrane}
                            />
                          ) : null}
                        </CardBody>
                      </Card>
                    </TabPane>
                  );
                })
              : null}
          </TabContent>
        </div>
      </Col>
      {downloadProgress ? <ProgressModal progress={downloadProgress} /> : null}
      {informationModal.isOpen && (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                })
          }
        />
      )}
    </Row>
  );
};
export default WorkOrderReports;
