import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  UncontrolledCarousel,
} from "reactstrap";

const ModalPhotosCarousel = ({
  title,
  onClose,
  items = [],
  size = "md",
  closeButtonText = "Close",
  editPhotos = false,
  onOpenModalEditPhotos,
}) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose} size={size}>
      <ModalHeader close={closeBtn}>{title}</ModalHeader>
      <ModalBody>
        {items.length > 0 ? (
          <UncontrolledCarousel items={items} />
        ) : (
          "There are no photos to show"
        )}
      </ModalBody>
      <ModalFooter
        className={
          editPhotos ? "justify-content-between" : "justify-content-end"
        }
      >
        <Button color={"primary"} onClick={onClose}>
          {closeButtonText}
        </Button>{" "}
        {editPhotos ? (
          <Button color={"warning"} onClick={onOpenModalEditPhotos}>
            Edit Photos
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

export default ModalPhotosCarousel;
