import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../providers/authProvider";
import { LoginForm } from "./LoginForm";

const ROLE_CONTROLLER = 4;

const SignIn = () => {
  const [authContext] = useAuth();
  const location = useLocation();

  const redirectTo = () => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      return <Navigate to={params.get("returnURL")} />;
    }
    return (
      <Navigate
        to={
          authContext.currentUser.roleId === ROLE_CONTROLLER
            ? `/work-orders/ready-to-invoice`
            : `/work-orders/all`
        }
      />
    );
  };

  if (!authContext.currentUser) {
    return <LoginForm />;
  }

  return redirectTo(location);
};

export default SignIn;
