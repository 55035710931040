import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroup,
  Badge,
  InputGroupText,
  Button,
  CardFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import WorkOrdersCards from "../../components/admin/workOrders/WorkOrdersCards";

import InformationModal from "../../components/InformationModal";
import { useAuth } from "../../providers/authProvider";

import { ACTIONS, useWorkOrders } from "../../providers/workOrdersProvider";

import { workOrdersApi } from "../../services/workOrdersServices";
import { utils } from "../../utils/utils";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import { useWorkOrderStatus } from "../../providers/workOrderStatusProvider";

import { DebounceInput } from "react-debounce-input";

const subRouteStatus = (pathName, statuses) => {
  const [, route] = pathName.match(/\/work-orders\/(.+)/);
  if (route === "all") {
    return {
      name: "All",
    };
  }
  if (route === "recently-added") {
    return {
      name: "Recently Added",
    };
  }
  const status = statuses.find(
    (status) => status.name.toLowerCase().replaceAll(" ", "-") === route
  );
  return status;
};

const subRouteRecent = (pathName) =>
  pathName === "/work-orders/recently-added" ? true : null;

const columns = (onShowAssignationModal) => [
  {
    accessor: "jobNo",
    Header: "Work Order #",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const { jobNo } = rowData.row.original;
      return jobNo || "-";
    },
  },
  {
    accessor: "jobType",
    Header: "Type",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const { jobType } = rowData.row.original;
      if (!jobType) {
        return "-";
      }
      return (
        <Badge color={utils.getJobTypeColor(jobType)} size={18}>
          {jobType}
        </Badge>
      );
    },
  },
  {
    accessor: "customerWorkOrder.customerName",
    Header: "Customer",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 250,
    Cell: (rowData) => {
      const { customerWorkOrder } = rowData.row.original;
      return customerWorkOrder?.customerName || "-";
    },
  },
  {
    accessor: "customerLocation",
    Header: "Customer Location",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    disableSortBy: true,
    width: 250,
    Cell: (rowData) => {
      const { customerLocation } = rowData.row.original;
      return customerLocation ? utils.getAddress(customerLocation) : "-";
    },
  },
  {
    accessor: "comment",
    Header: "Comment",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    disableSortBy: true,
    width: 200,
    Cell: (rowData) => {
      const { svcCommentText, comment } = rowData.row.original;
      return svcCommentText ? svcCommentText : comment ? comment : "-";
    },
  },
  {
    accessor: "serviceLocation",
    Header: "Service Location",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    disableSortBy: true,
    width: 150,
    Cell: (rowData) => {
      const { serviceLocation } = rowData.row.original;
      return serviceLocation?.name || "-";
    },
  },
  {
    accessor: "startDate",
    Header: "Dates",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 220,
    Cell: (rowData) => {
      const { startDate, endDate } = rowData.row.original;
      return `${startDate ? utils.formatDate(startDate, "MM/DD/YYYY") : "N/A"}${
        endDate ? ` - ${utils.formatDate(endDate, "MM/DD/YYYY")}` : ""
      }`;
    },
  },
  {
    accessor: "workOrderTrips",
    Header: "Assigned To",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    disableSortBy: true,
    width: 200,
    Cell: (rowData) => {
      return (
        <Button
          size="sm"
          onClick={(evt) => onShowAssignationModal(evt, rowData.row.original)}
          className="rounded"
        >
          Show Assigned
        </Button>
      );
    },
  },
];

const WorkOrders = () => {
  const [authContext] = useAuth();
  const [workOrdersContext, setWorkOrdersContext] = useWorkOrders();
  const [workOrderStatusContext] = useWorkOrderStatus();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const location = useLocation();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          workOrdersContext.sortBy === sortBy?.id &&
          workOrdersContext.direction === direction
        ) {
          return;
        }
        setWorkOrdersContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setWorkOrdersContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      workOrdersContext.direction,
      workOrdersContext.sortBy,
      setWorkOrdersContext,
    ]
  );

  const setWorkOrdersContextCb = useCallback(
    (data) => setWorkOrdersContext(data),
    [setWorkOrdersContext]
  );

  const onSeeDetails = (workOrderId) =>
    navigate(`/work-orders/${workOrderId}/details`);

  useEffect(() => {
    setWorkOrdersContext({
      action: ACTIONS.PAGE_CHANGE,
      payload: { page: 1 },
    });
  }, [setWorkOrdersContext]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const status = subRouteStatus(
          location.pathname,
          workOrderStatusContext.workOrderStatus
        );
        const workOrders = await workOrdersApi.getWorkOrders({
          search: workOrdersContext.search,
          page: workOrdersContext.page - 1,
          pageSize: workOrdersContext.sizePerPage,
          sortBy: workOrdersContext.sortBy,
          direction: workOrdersContext.direction,
          statusId: status?.id,
          recent: subRouteRecent(location.pathname),
          serviceLocationId: authContext.serviceLocationId,
        });
        setWorkOrdersContextCb({
          action: ACTIONS.GET_WORKORDERS_SUCCESS,
          payload: {
            workOrders,
            statusId: status?.id,
          },
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      }
    };
    if (workOrderStatusContext.workOrderStatus?.length) {
      fetchData();
    }
  }, [
    setWorkOrdersContextCb,
    workOrderStatusContext.workOrderStatus,
    workOrdersContext.sortBy,
    workOrdersContext.direction,
    workOrdersContext.sizePerPage,
    workOrdersContext.page,
    workOrdersContext.search,
    workOrdersContext.refresh,
    location.pathname,
    authContext.serviceLocationId,
  ]);

  const onShowAssignationModal = (evt, workOrder) => {
    evt.stopPropagation();
    return setInformationModal({
      isOpen: true,
      title: "Work Order Assignations",
      rawBody: true,
      body: (
        <ListGroup>
          {workOrder.workOrderTrips
            .sort((x, y) => x.tripNumber - y.tripNumber)
            .map((trip) => (
              <>
                <ListGroupItem className="d-flex justify-content-between align-items-center bg-lighter font-weight-bold">
                  Trip {trip.tripNumber}
                </ListGroupItem>
                {trip.workOrderEmployees.map((workOrderEmployee) => (
                  <ListGroupItem className="d-flex justify-content-between align-items-center">
                    {utils.getEmployeeLabelWithPreferredName(
                      workOrderEmployee.employee
                    )}
                  </ListGroupItem>
                ))}
              </>
            ))}
        </ListGroup>
      ),
    });
  };

  const status = subRouteStatus(
    location.pathname,
    workOrderStatusContext.workOrderStatus
  );

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <WorkOrdersCards />
        <AdvanceTableWrapper
          columns={columns(onShowAssignationModal)}
          data={workOrdersContext.workOrders?.data || []}
          pageSize={workOrdersContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: workOrdersContext.sortBy,
            direction: workOrdersContext.direction,
          }}
        >
          <Card className="col-12 px-0">
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="d-flex flex-column align-items-start">
                <div className="d-flex mb-1 align-items-center">
                  <h3 className="mb-0">Work Orders</h3>
                  <small className="text-muted ml-2 pt-1">
                    ({workOrdersContext.workOrders?.count})
                  </small>
                </div>
                <Badge>{status?.name}</Badge>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m">
                  <DebounceInput
                    className="border-right-0 form-control"
                    minLength={1}
                    debounceTimeout={800}
                    maxLength="50"
                    placeholder="Search for a work order number.."
                    value={workOrdersContext.search}
                    onChange={(evt) =>
                      setWorkOrdersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-secondary text-white border-left-0 border-secondary cursor-pointer">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button ml-3"
                  color="secondary"
                  onClick={() =>
                    setWorkOrdersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {workOrdersContext.workOrders &&
              workOrdersContext.workOrders.data?.length ? (
                <AdvanceTable
                  table
                  onRowClick={(row) => onSeeDetails(row.original.id)}
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={workOrdersContext.workOrders?.count}
                pageCount={workOrdersContext.workOrders?.totalPages}
                currentPage={workOrdersContext.page - 1}
                onPageChange={(page) =>
                  setWorkOrdersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={workOrdersContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setWorkOrdersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default WorkOrders;
