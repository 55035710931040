import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Input,
  Form,
} from "reactstrap";

import { serviceNotesApi } from "../../../services/serviceNoteServices";
import Loader from "../../Loader";

const ServiceNoteForm = ({
  isNewNote,
  onClose,
  serviceNote,
  onSubmit,
  onError,
}) => {
  const [loading, setLoading] = useState();
  const [serviceNoteState, setServiceNoteState] = useState(serviceNote);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (isNewNote) {
        await serviceNotesApi.createWorkOrderServiceNote(serviceNoteState);
      } else {
        await serviceNotesApi.updateWorkOrderServiceNote(serviceNoteState);
      }
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      onError(err);
    }
  };

  const editOrAddTitle = isNewNote ? "Add" : "Edit";
  const saveOrAddButton = isNewNote ? "Add" : "Save";

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column align-items-start">
          <span>{editOrAddTitle} Service Note</span>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <Input
            type="textarea"
            placeholder="Enter the note"
            required={true}
            maxLength={10000}
            value={serviceNoteState?.note}
            onChange={(event) => {
              setServiceNoteState({
                ...serviceNoteState,
                note: event.target.value,
              });
            }}
          />
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"primary"} onClick={onClose}>
            Discard
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={isNewNote ? "success" : "warning"} type="submit">
              {saveOrAddButton}
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ServiceNoteForm;
