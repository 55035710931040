import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useAuth } from "../../../providers/authProvider";

import { commonApi } from "../../../services/commonServices";

import { employeesApi } from "../../../services/employeeServices";
import { rolesApi } from "../../../services/roleServices";

import InformationModal from "../../InformationModal";

import Loader from "../../Loader";

import SelectWrapper from "../../SelectWrapper";
import UserAssignationsModal from "./UserAssignationsModal";
import ConfirmationModal from "../../ConfirmationModal";

const MAX_PAGE_SIZE = 999;

const EMPLOYEE_ROLE_TECHNICIAN = 3;
const EMPLOYEE_ROLE_MANAGER = 2;
const EMPLOYEE_ROLE_REGIONAL_MANAGER = 7;
const EMPLOYEE_ROLE_ADMINISTRATOR = 5;
const EMPLOYEE_ROLE_EXECUTIVE = 1;
const EMPLOYEE_ROLE_CONTROLLER = 4;
const EMPLOYEE_ROLE_DEVELOPER = 6;

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const UserModal = ({ userId, onClose, onSubmit }) => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    serviceLocationId: "",
    sourceKey: "",
    roleId: "",
    isActive: true,
    emailSignature: "",
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [selectedServiceLocation, setSelectedServiceLocation] = useState();

  const [password, setPassword] = useState();
  const [loading, setLoading] = useState();

  const [roles, setRoles] = useState([]);
  const [openAssignmentsModal, setOpenAssignmentsModal] = useState();

  const [authContext, setAuthContext] = useAuth();

  const doSubmit = (e, forceDbSync) => {
    if (e) {
      e.preventDefault();
    }

    let serviceLocationIds;
    if (!selectedServiceLocation || selectedServiceLocation.length === 0) {
      serviceLocationIds = [];
    } else if (selectedServiceLocation?.length) {
      serviceLocationIds = selectedServiceLocation.map((ssl) => ssl.id);
    } else {
      serviceLocationIds = [selectedServiceLocation.id];
    }

    setLoading(true);
    if (user.id) {
      const {
        id,
        firstName,
        lastName,
        email,
        phone,
        isActive,
        sourceKey,
        roleId,
        employeeId,
        emailSignature,
      } = user;
      const data = {
        id,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        phone,
        isActive,
        serviceLocationIds,
        employeeId,
        sourceKey,
        roleId,
        emailSignature,
      };
      if (forceDbSync) {
        data.forceDbSync = true;
      }
      const promises = [employeesApi.updateEmployee(data)];
      if (password) {
        promises.push(
          employeesApi.updatePassword({
            id,
            password,
          })
        );
      }
      Promise.all(promises)
        .then(() => {
          setLoading(false);
          onSubmit();
          setAuthContext({
            currentUser: { ...authContext.currentUser, emailSignature },
          });
        })
        .catch((err) => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Update User",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        });
    } else {
      employeesApi
        .createEmployee({
          ...user,
          serviceLocationIds,
          password,
        })
        .then(() => {
          setLoading(false);
          onSubmit();
        })
        .catch((err) => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Create User",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        });
    }
  };

  // Load User
  useEffect(() => {
    if (userId) {
      setLoading(true);
      employeesApi
        .getEmployees({ id: userId })
        .then((result) => {
          setLoading(false);
          setUser(result);
          setSelectedServiceLocation(result.serviceLocations);
        })
        .catch(() => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Error",
            body: "There was an error with your request.",
          });
        });
    }
  }, [userId]);

  // Load Roles
  useEffect(() => {
    setLoading(true);
    rolesApi
      .getRoles({})
      .then(({ data }) => {
        setLoading(false);
        setRoles(data);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onForceSyncDb = () =>
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        doSubmit(null, true);
        setConfirmationModal(initConfirmationModal);
        return setInformationModal({
          isOpen: true,
          title: "Force DB Sync for user",
          body: "DB Sync forced successfully.",
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: `Force DB Sync for user`,
      body: `
        <span class="text-center">
          Do you want to force the DB sync for this user?
        </span>
      `,
      confirmColor: "warning",
    });

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : openAssignmentsModal ? (
    <UserAssignationsModal
      onClose={() => setOpenAssignmentsModal()}
      user={user}
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} size="xs">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {userId ? "Edit" : "Create"} User
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label>
                    First Name
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="firstName"
                    maxLength={100}
                    value={user.employee?.firstName || user.firstName}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        firstName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Last Name
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="lastName"
                    maxLength={100}
                    value={user.employee?.lastName || user.lastName}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        lastName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Email
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value.trim(),
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Phone</Label>
                  <Input
                    type="text"
                    name="phone"
                    maxLength={100}
                    value={user.phone}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        phone: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Role
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="select"
                    name="roleSelect"
                    id="roleSelect"
                    onChange={(e) => {
                      setUser({
                        ...user,
                        roleId: e.target.value,
                      });
                      setSelectedServiceLocation();
                    }}
                    value={user.roleId}
                    required
                  >
                    <option value={""}>Select a Role</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                {parseInt(user.roleId) === EMPLOYEE_ROLE_TECHNICIAN ||
                parseInt(user.roleId) === EMPLOYEE_ROLE_MANAGER ||
                parseInt(user.roleId) === EMPLOYEE_ROLE_REGIONAL_MANAGER ? (
                  <FormGroup>
                    <Label>
                      Service Location
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <SelectWrapper
                      isRequired={true}
                      key={`service-locations-${user.roleId}`}
                      isMulti={
                        parseInt(user.roleId) === EMPLOYEE_ROLE_REGIONAL_MANAGER
                      }
                      entity="service location"
                      formatItemFunction={(c) => {
                        return {
                          label: c.name,
                          value: c.id,
                        };
                      }}
                      fetchFunction={commonApi.getServiceLocations}
                      fetchParameters={{
                        pageSize: MAX_PAGE_SIZE,
                      }}
                      defaultSelected={selectedServiceLocation}
                      onSelected={(value) => setSelectedServiceLocation(value)}
                    />
                  </FormGroup>
                ) : null}
                <FormGroup>
                  <Label>Employee ID</Label>
                  <Input
                    type="text"
                    name="employeeId"
                    maxLength={100}
                    value={user.employeeId}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        employeeId: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Password
                    {!user.id ? (
                      <span className="text-danger ml-1">*</span>
                    ) : null}
                  </Label>
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="password"
                    maxLength={100}
                    value={password || ""}
                    onChange={(e) => setPassword(e.target.value)}
                    required={!user.id}
                  />
                </FormGroup>
                {(parseInt(user.roleId) === EMPLOYEE_ROLE_ADMINISTRATOR ||
                  parseInt(user.roleId) === EMPLOYEE_ROLE_EXECUTIVE ||
                  parseInt(user.roleId) === EMPLOYEE_ROLE_MANAGER ||
                  parseInt(user.roleId) === EMPLOYEE_ROLE_CONTROLLER ||
                  parseInt(user.roleId) === EMPLOYEE_ROLE_DEVELOPER ||
                  parseInt(user.roleId) === EMPLOYEE_ROLE_REGIONAL_MANAGER) && (
                  <Col sm="12 border rounded mt-4 mb-2">
                    <ReactQuill
                      placeholder={"Email Signature"}
                      theme={"snow"}
                      value={user.emailSignature}
                      onChange={(emailSignature) =>
                        setUser({
                          ...user,
                          emailSignature: emailSignature,
                        })
                      }
                    />
                  </Col>
                )}
                <FormGroup className="mb-0 text-center">
                  <Button
                    color="info"
                    onClick={() => setOpenAssignmentsModal(true)}
                  >
                    See Assignments
                  </Button>
                  {user.id ? (
                    <Button
                      className="ml-2"
                      color="warning"
                      onClick={onForceSyncDb}
                    >
                      Force Sync DB
                    </Button>
                  ) : null}
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;
