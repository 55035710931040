import moment from "moment";
import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Label,
  FormGroup,
  CustomInput,
} from "reactstrap";

import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import DateRangePicker from "../../DateRangePicker";
import { employeesApi } from "../../../services/employeeServices";
import { useAuth } from "../../../providers/authProvider";
import { employeeOffDaysApi } from "../../../services/employeeDaysOffServices";
import { utils } from "../../../utils/utils";
import ConfirmationModal from "../../ConfirmationModal";
import SelectWrapper from "../../SelectWrapper";

const REASONS = [
  { id: "OFF_DAY", label: "Off Day" },
  { id: "PTO", label: "PTO" },
  { id: "WEEKENDS_OFF", label: "Weekends Off" },
  { id: "ON_CALL", label: "On Call" },
  { id: "OTHER", label: "Other" },
];

const PAGE_SIZE = 30;

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const BlockDaysOffModal = ({ defaultData, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(defaultData ? defaultData.reason : null);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [authContext] = useAuth();

  const [startDate, setStartDate] = useState(
    defaultData ? moment(defaultData.startDate) : moment()
  );

  const [endDate, setEndDate] = useState(
    defaultData ? moment(defaultData.endDate) : moment()
  );

  const [selectedEmployee, setSelectedEmployee] = useState(
    defaultData
      ? {
          name: defaultData.employeeName,
          id: defaultData.employeeId,
        }
      : null
  );

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSelection = (range) => {
    setStartDate(range.from);
    setEndDate(range.to);
  };

  const doSubmit = async () => {
    if (!reason || !startDate || !endDate || !selectedEmployee) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "Please fill all the data.",
      });
    }
    try {
      setLoading(true);
      if (defaultData) {
        await employeeOffDaysApi.updateEmployeeOffDays({
          ...defaultData,
          reason,
          startDate: utils.formatDate(startDate),
          endDate: utils.formatDate(endDate),
          employeeId: selectedEmployee.id,
        });
        setInformationModal({
          isOpen: true,
          title: "Update Off Days",
          body: "Off Days Updated Successfully.",
          onClose: onSubmit,
        });
      } else {
        await employeeOffDaysApi.createEmployeeOffDays({
          reason,
          startDate: utils.formatDate(startDate),
          endDate: utils.formatDate(endDate),
          employeeId: selectedEmployee.id,
        });
        setInformationModal({
          isOpen: true,
          title: "Block Off Days",
          body: "Off Days Blocked Successfully.",
          onClose: onSubmit,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setLoading(true);
        try {
          await employeeOffDaysApi.deleteEmployeeOffDays({
            id: defaultData.id,
          });
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Remove Off Days",
            body: "Off Days Removed Successfully.",
            onClose: onSubmit,
          });
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Remove Off Days",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Remove Off Days",
      body: `
            <span class="text-center">
              Do you confirm you want to remove this entry?
            </span>
          `,
      confirmColor: "danger",
    });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Block Days Off</ModalHeader>
      <ModalBody className="text-center">
        <DateRangePicker
          label="Dates"
          startDate={startDate}
          endDate={endDate}
          onSelection={onSelection}
        />
        <FormGroup className="d-flex justify-content-between align-items-center mt-3">
          <Label for="daysOffReason" className="mb-0">
            <span>Reason</span>
          </Label>
          <CustomInput
            id="daysOffReason"
            required={true}
            type="select"
            className="flex-grow-1 border rounded col-8 height-40p"
            name="daysOffReason"
            value={reason || ""}
            onChange={(e) => setReason(e.currentTarget.value)}
          >
            <option value="">Select a reason...</option>
            {REASONS.map((reason) => (
              <option key={reason.id} value={reason.id}>
                {reason.label}
              </option>
            ))}
          </CustomInput>
        </FormGroup>
        <FormGroup className="d-flex justify-content-between align-items-center mb-0">
          <Label for="employee" className="mb-0">
            Employee
          </Label>
          <SelectWrapper
            key={authContext.serviceLocationId}
            className="col-8"
            entity="employee"
            formatItemFunction={utils.getEmployeeOptionWithPreferredName}
            fetchFunction={employeesApi.getEmployees}
            fetchParameters={{
              pageSize: PAGE_SIZE,
              serviceLocationId: authContext.serviceLocationId,
              isActive: true,
              sortBy: "firstName",
            }}
            defaultSelected={selectedEmployee}
            onSelected={(employee) => setSelectedEmployee(employee)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div>
          <Button color={"secondary"} onClick={onClose}>
            Discard
          </Button>
          {defaultData ? (
            <Button color={"danger"} className="ml-2" onClick={onDelete}>
              Remove
            </Button>
          ) : null}
        </div>
        {loading ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            className="d-flex align-items-center"
            color={"primary"}
            onClick={doSubmit}
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default BlockDaysOffModal;
