import React from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";

import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { utils } from "../../../utils/utils";

const CustomerApproval = () => {
  const [workOrderDetails] = useWorkOrderDetails();

  if (!workOrderDetails.workOrder?.postContract) {
    return null;
  }

  const {
    signatureUrl,
    signatureName,
    signatureDate,
    updatedAt,
    customerNotPresent,
    customerNotPresentReason,
  } = workOrderDetails.workOrder.postContract;

  return (
    <ListGroup className="my-2 w-100">
      <ListGroupItem
        className="d-flex justify-content-between align-items-center bg-lighter font-500"
        tag="div"
      >
        <span>Customer Approval</span>
        {customerNotPresent ? (
          <Badge pill color="danger">
            Signature not provided
          </Badge>
        ) : null}
      </ListGroupItem>
      {customerNotPresent ? (
        <>
          <ListGroupItem className="d-flex flex-column">
            Reason: {customerNotPresentReason || "Not provided"}
          </ListGroupItem>
        </>
      ) : (
        <>
          <ListGroupItem className="d-flex justify-content-between align-items-start">
            <div className="d-flex flex-column flex-grow-1">
              <img
                alt={signatureName}
                className="my-3 col-12 col-md-6 col-lg-4 col-xl-3"
                src={signatureUrl}
              />
              <small>
                <i>{signatureName}</i>
              </small>
            </div>
            <Badge color="info">
              {utils.formatDateTime(
                signatureDate !== null ? signatureDate : updatedAt,
                "MM/DD/YYYY, h:mm a"
              )}
            </Badge>
          </ListGroupItem>
        </>
      )}
    </ListGroup>
  );
};

export default CustomerApproval;
