import React from "react";

import { Button } from "reactstrap";

import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const JSA_ITEM_TYPE = {
  TYPE_CHECKBOX: "TYPE_CHECKBOX",
  TYPE_TEXT: "TYPE_TEXT",
  TYPE_YESNO: "TYPE_YESNO",
};

const TRUE = "True";
const ACTIVE = "active";

const JSAItemAnswer = ({ item }) => {
  switch (item.type) {
    case JSA_ITEM_TYPE.TYPE_TEXT:
      return (
        <div className="my-3" key={item.id}>
          <h5>{item.content}</h5>
          <div className="border py-1 px-2 rounded col-12">
            <i className="text-break">{item.answer}</i>
          </div>
        </div>
      );
    case JSA_ITEM_TYPE.TYPE_YESNO:
      return (
        <div className="my-3" key={item.id}>
          <h5>{item.content}</h5>
          <div className="d-flex">
            <Button
              color="light"
              className={`col-6 border font-300 rounded ${
                item.answer === TRUE ? ACTIVE : ""
              }`}
            >
              Yes
            </Button>
            <Button
              color="light"
              className={`col-6 border font-300 rounded ${
                item.answer !== TRUE ? ACTIVE : ""
              }`}
            >
              No
            </Button>
          </div>
        </div>
      );
    case JSA_ITEM_TYPE.TYPE_CHECKBOX:
      return (
        <div
          key={item.id}
          className="mt-3 pb-2 border-bottom d-flex justify-content-between align-items-center"
        >
          <h5>{item.content}</h5>
          <FontAwesomeIcon
            className="text-success"
            icon={item.answer === TRUE ? faCheckCircle : faCircle}
            size="lg"
          />
        </div>
      );
    default:
      return null;
  }
};

export default JSAItemAnswer;
