import React from "react";

import { Card, CardBody, CardHeader } from "reactstrap";

import { useAuth } from "../../../providers/authProvider";

import Header from "../../../components/Header";
import { useWorkOrderKPIs } from "../../../providers/workOrdersKPIsProvider";
import { utils } from "../../../utils/utils";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";

const ROLE_CONTROLLER = 4;

const WorkOrdersCards = () => {
  const [authContext] = useAuth();
  const navigate = useNavigate();
  const [workOrderKPIsContext] = useWorkOrderKPIs();

  return authContext.currentUser.roleId !== ROLE_CONTROLLER ? (
    <Header className="row">
      <div className="col-12 col-md-6 col-lg-3">
        <Card>
          <CardHeader>
            <div className="text-uppercase pb-1 border-bottom text-center">
              To Be Scheduled
            </div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {workOrderKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/work-orders/pending")}
                className="cursor-pointer text-xl text-center font-500"
              >
                {utils.formatDecimal(workOrderKPIsContext.pending)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Card>
          <CardHeader>
            <div className="text-uppercase pb-1 border-bottom text-center">
              Scheduled
            </div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {workOrderKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/work-orders/scheduled")}
                className="cursor-pointer text-xl text-center font-500"
              >
                {utils.formatDecimal(workOrderKPIsContext.scheduled)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Card>
          <CardHeader>
            <div className="text-uppercase pb-1 border-bottom text-center">
              Waiting On Parts
            </div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {workOrderKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/work-orders/waiting-for-parts")}
                className="cursor-pointer text-xl text-center font-500"
              >
                {utils.formatDecimal(workOrderKPIsContext.waitingOnParts)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Card>
          <CardHeader>
            <div className="text-uppercase pb-1 border-bottom text-center">
              In Progress
            </div>
          </CardHeader>
          <CardBody className="pt-0 pb-3">
            {workOrderKPIsContext.loading ? (
              <Loader size="sm" />
            ) : (
              <div
                onClick={() => navigate("/work-orders/in-progress")}
                className="cursor-pointer text-xl text-center font-500"
              >
                {utils.formatDecimal(workOrderKPIsContext.inProgress)}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </Header>
  ) : null;
};

export default WorkOrdersCards;
