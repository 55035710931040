import React from "react";

import ServicePerformed from "./ServicePerformed";

import Attachments from "./Attachments";
import Materials from "./Materials";
import moment from "moment";

const GeneralService = ({ trip, date, materials, isInspection = false }) => {
  const employeeWorkDays = trip.workOrderEmployees
    .flatMap((workOrderEmployee) =>
      workOrderEmployee.employeeWorkDays.map((item) => ({
        ...item,
        workOrderEmployeeId: workOrderEmployee.id,
      }))
    )
    .filter((ewd) => moment(ewd.date).isSame(moment(date), "date"));

  const serviceNotes = employeeWorkDays
    .flatMap((ewd) =>
      ewd.workOrderServiceNotes.flatMap((sn) => {
        return {
          ...sn,
          employeeWorkDayId: ewd.id,
        };
      })
    )
    .filter((serviceNote) => !serviceNote.workOrderCraneId)
    .sort((x, y) =>
      moment(y.updatedAt).isBefore(moment(x.updatedAt)) ? 1 : -1
    );

  const attachments = employeeWorkDays
    .flatMap((ewd) => ewd.attachments)
    .filter((attachment) => !attachment.workOrderCraneId);

  const workOrderMaterials = employeeWorkDays
    .flatMap((ewd) => ewd.workOrderMaterials)
    .filter((workOrderMaterial) => !workOrderMaterial.workOrderCraneId);

  return (
    <>
      <h5 className="mt-3 pl-1 pb-2 border-bottom">General Service Work</h5>
      <div className="col-12 px-0">
        <ServicePerformed
          serviceNotes={serviceNotes}
          employeeWorkDayId={employeeWorkDays}
          workOrderCraneId={null}
        />
        {!isInspection && (
          <>
            <Attachments
              attachments={attachments}
              employeeWorkDayId={employeeWorkDays.filter(
                (ewd) => ewd.isLeadDay
              )}
            />
            <Materials
              workOrderMaterials={workOrderMaterials}
              employeeWorkDayId={employeeWorkDays.filter(
                (ewd) => ewd.isLeadDay
              )}
              materials={materials}
            />
          </>
        )}
      </div>
    </>
  );
};

export default GeneralService;
