import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  CustomInput,
} from "reactstrap";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import AdvanceTableWrapper from "../../../components/advanceTable/AdvanceTableWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTable from "../../../components/advanceTable/AdvanceTable";
import InformationModal from "../../../components/InformationModal";
import { commonApi } from "../../../services/commonServices";

const LAST_APP_RELEASE_VERSION = "LAST_APP_RELEASE_VERSION";

const columns = (onChangeSettingStatus) => [
  {
    accessor: "name",
    Header: "Setting",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
  },
  {
    accessor: "status",
    Header: "Status",
    Cell: (rowData) => {
      const setting = rowData.row.original;
      if (setting.name === LAST_APP_RELEASE_VERSION) {
        return "-";
      } else {
        return (
          <CustomInput
            checked={setting.status}
            onChange={(event) =>
              onChangeSettingStatus(setting, event.currentTarget.checked)
            }
            type="switch"
            id={setting.id}
            name={setting.id}
            label={setting.status ? "Enabled" : "Disabled"}
            className="cursor-pointer d-flex align-items-center small setting-switch"
          />
        );
      }
    },
  },
  {
    accessor: "value",
    Header: "Value",
    Cell: (rowData) => {
      return rowData.row.original.value || "-";
    },
  },
];

const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    commonApi
      .getSettings({})
      .then((settings) => {
        setLoading(false);
        setSettings(settings.data);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [refresh]);

  const onChangeSetting = (setting, status) => {
    setLoading(true);
    setting.status = status;
    const newSettings = [...settings];
    newSettings.splice(
      newSettings.findIndex((s) => s.id === setting.id),
      1,
      setting
    );
    setSettings(newSettings);
    commonApi
      .setSetting(setting)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(onChangeSetting)}
          data={settings}
          pageSize={10}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Settings</h3>
                <small className="text-muted ml-2 pt-1">
                  ({settings.length})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {settings.length ? (
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default Settings;
