import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import InformationModal from "../../components/InformationModal";
import { useAuth } from "../../providers/authProvider";

import { ACTIONS, useCustomers } from "../../providers/customersProvider";

import { customersApi } from "../../services/customerServices";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";

const EMPLOYEE_ROLE_MANAGER = 2;

const columns = (authContext) => [
  {
    accessor: "customerName",
    Header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 300,
    Cell: (rowData) => {
      const { customerName } = rowData.row.original;
      return customerName || "-";
    },
  },
  {
    accessor: "customerNo",
    Header: "Customer No.",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { customerNo } = rowData.row.original;
      return customerNo || "-";
    },
  },
  {
    accessor: "locations",
    Header: "Locations",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { locations } = rowData.row.original;
      return locations.length;
    },
  },
  {
    accessor: "craneCustomer",
    Header: "Cranes",
    disableSortBy: true,
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-center" },
    Cell: (rowData) => {
      const { craneCustomer } = rowData.row.original;
      return craneCustomer.length;
    },
  },
  {
    accessor: "workOrders",
    Header: "Work Orders",
    disableSortBy: true,
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-center" },
    Cell: (rowData) => {
      const { workOrders } = rowData.row.original;
      return workOrders.filter((wo) =>
        authContext.currentUser.roleId === EMPLOYEE_ROLE_MANAGER
          ? wo.serviceLocationId === authContext.serviceLocationId
          : true
      ).length;
    },
  },
];

const Customers = () => {
  const [customersContext, setCustomersContext] = useCustomers();
  const [authContext] = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          customersContext.sortBy === sortBy?.id &&
          customersContext.direction === direction
        ) {
          return;
        }
        setCustomersContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setCustomersContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [customersContext.direction, customersContext.sortBy, setCustomersContext]
  );

  const setCustomersContextCb = useCallback(
    (data) => setCustomersContext(data),
    [setCustomersContext]
  );

  const onSeeDetails = (customerId) =>
    navigate(`/customers/${customerId}/details`);

  useEffect(() => {
    setLoading(true);
    customersApi
      .getCustomers({
        search: customersContext.search,
        page: customersContext.page - 1,
        pageSize: customersContext.sizePerPage,
        sortBy: customersContext.sortBy,
        direction: customersContext.direction,
      })
      .then((customers) => {
        setCustomersContextCb({
          action: ACTIONS.GET_CUSTOMERS_SUCCESS,
          payload: { customers },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setCustomersContextCb,
    customersContext.sortBy,
    customersContext.direction,
    customersContext.sizePerPage,
    customersContext.page,
    customersContext.search,
    customersContext.refresh,
  ]);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(authContext)}
          data={customersContext.customers?.data || []}
          pageSize={customersContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: customersContext.sortBy,
            direction: customersContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Customers</h3>
                <small className="text-muted ml-2 pt-1">
                  ({customersContext.customers.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={customersContext.search}
                    onChange={(evt) =>
                      setCustomersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-secondary text-white border-left-0 border-secondary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={() =>
                    setCustomersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {customersContext.customers.data?.length ? (
                <AdvanceTable
                  table
                  onRowClick={(row) => onSeeDetails(row.original.id)}
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={customersContext.customers.count}
                pageCount={customersContext.customers.totalPages}
                currentPage={customersContext.page - 1}
                onPageChange={(page) =>
                  setCustomersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={customersContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setCustomersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default Customers;
