import {
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrashAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import ConfirmationModal from "../../components/ConfirmationModal";

import InformationModal from "../../components/InformationModal";

import { ACTIONS, useCranes } from "../../providers/cranesProvider";

import { cranesApi } from "../../services/craneServices";
import { CRANE_SERVICE_STATUS, utils } from "../../utils/utils";
import ModalPhotosCarousel from "../../components/admin/ModalPhotosCarousel";
import {
  useCraneConfigModal,
  ACTIONS as CRANE_CONFIG_ACTIONS,
} from "../../providers/craneConfigModalProvider";
import CraneConfigModal from "../../components/admin/cranes/craneConfig/CraneConfigModal";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import WorkOrdersAssociatedModal from "../../components/admin/cranes/WorkOrdersAssociatedModal";
import TooltipItem from "../../components/TooltipItem";
import { useNavigate } from "react-router-dom";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const ACTION_CREATE = "ACTION_CREATE";

const Cranes = () => {
  const [craneConfigModalContext, setCraneConfigModalContext] =
    useCraneConfigModal();
  const [cranesContext, setCranesContext] = useCranes();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModalPhotosCarousel, setShowModalPhotosCarousel] = useState(false);
  const navigate = useNavigate();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [workOrdersModal, setWorkOrdersModal] = useState({
    isOpen: false,
    craneId: null,
  });

  const columns = (onEdit, onDelete, onShowWorkOrders) => [
    {
      accessor: "ContactCode",
      Header: "Crane D#",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 100,
      Cell: (rowData) => {
        const { ContactCode } = rowData.row.original;
        return ContactCode || "Not Set";
      },
    },
    {
      accessor: "customer",
      Header: "Customer",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 150,
      Cell: (rowData) => {
        const { customer } = rowData.row.original;
        return customer?.customerName || "-";
      },
    },
    {
      accessor: "customerLocation",
      Header: "Customer Address",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 250,
      Cell: (rowData) => {
        const { customerLocation } = rowData.row.original;
        return utils.getAddress(customerLocation);
      },
    },
    {
      accessor: "UDF_EQ_DESCR",
      Header: "Equip. Descr.",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 110,
      Cell: (rowData) => {
        const { UDF_EQ_DESCR } = rowData.row.original;
        return UDF_EQ_DESCR || "-";
      },
    },
    {
      accessor: "UDF_EQ_LOC",
      Header: "Equip. Loc.",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      disableSortBy: true,
      width: 110,
      Cell: (rowData) => {
        const { UDF_EQ_LOC } = rowData.row.original;
        return UDF_EQ_LOC || "-";
      },
    },
    {
      accessor: "craneAttachments",
      Header: "Photos",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      disableSortBy: true,
      width: 110,
      Cell: (rowData) => {
        const { craneAttachments } = rowData.row.original;
        return (
          <Button
            className="rounded"
            size="sm"
            onClick={() => {
              setItems(
                craneAttachments.map((imgUrl, key) => ({
                  altText: "",
                  caption: "",
                  key: key,
                  src: imgUrl.contentUrl,
                }))
              );
              setShowModalPhotosCarousel(true);
            }}
          >
            See Photos
          </Button>
        );
      },
    },
    {
      accessor: "serviceStatus",
      Header: "Status",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 150,
      disableSortBy: true,
      Cell: (rowData) => {
        const { serviceStatus, workOrderCranes, id } = rowData.row.original;
        return (
          <>
            {serviceStatus === CRANE_SERVICE_STATUS["OUT OF SERVICE"] &&
            workOrderCranes.length > 0 ? (
              <TooltipItem
                id={id}
                title="This crane has work orders associated with it"
              >
                <Badge
                  color={
                    serviceStatus === CRANE_SERVICE_STATUS["IN SERVICE"]
                      ? "primary"
                      : "warning"
                  }
                  style={{ cursor: "pointer" }}
                >
                  <>
                    {utils.getNameOfCraneStatus({
                      SERVICE_STATUS: CRANE_SERVICE_STATUS,
                      craneServiceStatus: serviceStatus,
                    })}
                    <FontAwesomeIcon
                      size="md"
                      icon={faExclamationCircle}
                      className="ml-1"
                      color="white"
                    />
                  </>
                </Badge>
              </TooltipItem>
            ) : (
              <Badge
                color={
                  serviceStatus === CRANE_SERVICE_STATUS["IN SERVICE"]
                    ? "primary"
                    : "warning"
                }
              >
                {utils.getNameOfCraneStatus({
                  SERVICE_STATUS: CRANE_SERVICE_STATUS,
                  craneServiceStatus: serviceStatus,
                })}
              </Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "id",
      Header: "",
      disableSortBy: true,
      cellProps: {
        className: "text-right",
      },
      width: 250,
      Cell: (rowData) => (
        <div className="d-flex align-items-center justify-content-end">
          <Button
            size="sm"
            className="rounded mr-2 d-flex align-items-center"
            color="warning"
            onClick={() => onEdit(rowData.row.original.id)}
          >
            <FontAwesomeIcon icon={faPen} className="mr-1" />
            <span>Edit</span>
          </Button>
          <Button
            size="sm"
            className="rounded mr-2 d-flex align-items-center"
            color="danger"
            onClick={() => onDelete(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
            <span>Delete</span>
          </Button>
          <Button
            size="sm"
            className="rounded d-flex align-items-center"
            color="primary"
            onClick={() => onShowWorkOrders(rowData.row.original)}
          >
            <span>Work Orders</span>
          </Button>
        </div>
      ),
    },
  ];

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          cranesContext.sortBy === sortBy?.id &&
          cranesContext.direction === direction
        ) {
          return;
        }
        setCranesContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setCranesContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [cranesContext.direction, cranesContext.sortBy, setCranesContext]
  );

  const onCreate = () => {
    setCraneConfigModalContext({
      action: CRANE_CONFIG_ACTIONS.INIT,
      payload: { crane: null, action: ACTION_CREATE },
    });
    onSeeDetails();
  };

  const onEdit = async (craneId) => {
    try {
      onSeeDetails(craneId);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onDelete = (crane) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        try {
          await cranesApi.deleteCrane(crane);
          setCranesContext({ action: ACTIONS.REFRESH });
        } catch (err) {
          setInformationModal({
            isOpen: true,
            title: "Error",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Crane",
      body: `<div class="text-center">
          <p>Do you confirm you want to delete this crane?</p>
          <strong>${crane.ContactCode || "D# Not Set"}</strong>
        </div>`,
      confirmColor: "danger",
    });
  };

  const onShowWorkOrders = (crane) => {
    setWorkOrdersModal({
      isOpen: true,
      craneId: crane.id,
    });
  };

  const setCranesContextCb = useCallback(
    (data) => setCranesContext(data),
    [setCranesContext]
  );

  useEffect(() => {
    setLoading(true);
    cranesApi
      .getCranes({
        search: cranesContext.search,
        page: cranesContext.page - 1,
        pageSize: cranesContext.sizePerPage,
        sortBy: cranesContext.sortBy,
        direction: cranesContext.direction,
      })
      .then((cranes) => {
        setCranesContextCb({
          action: ACTIONS.GET_CRANES_SUCCESS,
          payload: { cranes },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setCranesContextCb,
    cranesContext.sortBy,
    cranesContext.direction,
    cranesContext.sizePerPage,
    cranesContext.page,
    cranesContext.search,
    cranesContext.refresh,
    craneConfigModalContext.refresh,
  ]);

  const onSeeDetails = (craneId = "new") =>
    navigate(`/cranes/${craneId}/details`);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(onEdit, onDelete, onShowWorkOrders)}
          data={cranesContext.cranes?.data || []}
          pageSize={cranesContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: cranesContext.sortBy,
            direction: cranesContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Cranes</h3>
                <small className="text-muted ml-2 pt-1">
                  ({cranesContext.cranes.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={cranesContext.search}
                    onChange={(evt) =>
                      setCranesContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-secondary text-white border-left-0 border-secondary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={onCreate}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button ml-2"
                  color="secondary"
                  onClick={() =>
                    setCranesContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {cranesContext.cranes.data?.length ? (
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={cranesContext.cranes.count}
                pageCount={cranesContext.cranes.totalPages}
                currentPage={cranesContext.page - 1}
                disableAll={true}
                onPageChange={(page) =>
                  setCranesContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={cranesContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setCranesContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : craneConfigModalContext.isOpen ? (
        <CraneConfigModal
          onSubmit={() =>
            setInformationModal({
              isOpen: true,
              title: `${
                craneConfigModalContext.action === ACTION_CREATE
                  ? "Create"
                  : "Update"
              } Crane`,
              body: `Crane ${
                craneConfigModalContext.action === ACTION_CREATE
                  ? "created"
                  : "updated"
              } successfully.`,
            })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : showModalPhotosCarousel ? (
        <ModalPhotosCarousel
          title={"Crane Photos"}
          items={items}
          onClose={() => {
            setShowModalPhotosCarousel(false);
            setItems([]);
          }}
        />
      ) : workOrdersModal.isOpen ? (
        <WorkOrdersAssociatedModal
          craneId={workOrdersModal.craneId}
          onClose={() =>
            setWorkOrdersModal({
              isOpen: false,
              craneId: null,
            })
          }
        />
      ) : null}
    </Container>
  );
};

export default Cranes;
