import React, { useEffect, useState } from "react";

import { Card, Col, CustomInput, FormGroup, Label } from "reactstrap";

import { customersApi } from "../../../../services/customerServices";

import { utils } from "../../../../utils/utils";

import {
  ACTIONS,
  TYPES,
  useEditRecurringWorkOrders,
} from "../../../../providers/editRecurringWorkOrdersProvider";
import { commonApi } from "../../../../services/commonServices";
import { useAuth } from "../../../../providers/authProvider";
import SelectWrapper from "../../../SelectWrapper";

const EMPLOYEE_ROLE_EXECUTIVE = 1;
const PAGE_SIZE = 999;

const BasicInfo = () => {
  const [authContext] = useAuth();
  const [editRecurringWorkOrdersContext, setEditRecurringWorkOrdersContext] =
    useEditRecurringWorkOrders();

  const [disabled, setDisabled] = useState();

  useEffect(() => {
    setDisabled(
      Object.keys(editRecurringWorkOrdersContext.selectedWorkOrders)?.length
    );
  }, [editRecurringWorkOrdersContext.selectedWorkOrders]);

  return (
    <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right pt-3 mb-2">
      <FormGroup className="col-12 d-flex align-items-center">
        <Col className="col-4 px-0">
          <Label for="customer">Customer</Label>
        </Col>
        <Col className="col-8 px-0">
          <SelectWrapper
            isDisabled={disabled}
            entity="customer"
            formatItemFunction={(c) => {
              return {
                label: c.customerName,
                value: c.id,
              };
            }}
            filterItemFunction={(c) => c.customerName}
            fetchFunction={customersApi.getCustomers}
            fetchParameters={{
              pageSize: PAGE_SIZE,
            }}
            defaultSelected={editRecurringWorkOrdersContext.selectedCustomer}
            onSelected={(customer) => {
              setEditRecurringWorkOrdersContext({
                action: ACTIONS.ON_SELECT_CUSTOMER,
                payload: { customer },
              });
              setEditRecurringWorkOrdersContext({
                action: ACTIONS.ON_SELECT_CUSTOMER_LOCATION,
                payload: { customerLocation: "" },
              });
            }}
          />
        </Col>
      </FormGroup>
      {editRecurringWorkOrdersContext.selectedCustomer ? (
        <FormGroup className="col-12 d-flex align-items-center">
          <Col className="col-4 px-0">
            <Label for="customerLocation">Customer Location</Label>
          </Col>
          <Col className="col-8 px-0">
            <SelectWrapper
              key={editRecurringWorkOrdersContext.selectedCustomer.id}
              isDisabled={disabled}
              entity="customer location"
              formatItemFunction={(c) => {
                return {
                  label: utils.getAddress(c),
                  value: c.id,
                };
              }}
              fetchFunction={customersApi.getCustomerLocations}
              fetchParameters={{
                pageSize: PAGE_SIZE,
                customerId: editRecurringWorkOrdersContext.selectedCustomer.id,
              }}
              defaultSelected={
                editRecurringWorkOrdersContext.selectedCustomerLocation
              }
              onSelected={(customerLocation) =>
                setEditRecurringWorkOrdersContext({
                  action: ACTIONS.ON_SELECT_CUSTOMER_LOCATION,
                  payload: { customerLocation },
                })
              }
            />
          </Col>
        </FormGroup>
      ) : null}
      <FormGroup className="col-12 d-flex align-items-center">
        <Col className="col-4 px-0">
          <Label for="customer">Service Location</Label>
        </Col>
        <Col className="col-8 px-0">
          <SelectWrapper
            isDisabled={disabled}
            entity="service location"
            formatItemFunction={(c) => {
              return {
                label: c.name,
                value: c.id,
              };
            }}
            fetchFunction={
              parseInt(authContext.currentUser.roleId) ===
              EMPLOYEE_ROLE_EXECUTIVE
                ? commonApi.getServiceLocations
                : () => authContext.currentUser.serviceLocations
            }
            fetchParameters={{
              pageSize: PAGE_SIZE,
            }}
            defaultSelected={
              editRecurringWorkOrdersContext.selectedServiceLocation
            }
            onSelected={(serviceLocation) =>
              setEditRecurringWorkOrdersContext({
                action: ACTIONS.ON_SELECT_SERVICE_LOCATION,
                payload: { serviceLocation },
              })
            }
          />
        </Col>
      </FormGroup>
      <FormGroup className="col-12 d-flex align-items-center">
        <Col className="col-4 px-0">
          <Label for="type">Type</Label>
        </Col>
        <Col className="col-8 px-0">
          <CustomInput
            id="typeSelect"
            disabled={disabled}
            required={true}
            type="select"
            name="type"
            value={editRecurringWorkOrdersContext.type}
            onChange={(e) =>
              setEditRecurringWorkOrdersContext({
                action: ACTIONS.ON_TYPE_CHANGE,
                payload: { type: e.target.value },
              })
            }
          >
            <option value={TYPES.TYPE_MONTHLY}>Monthly</option>
            <option value={TYPES.TYPE_QUARTERLY}>Quarterly</option>
          </CustomInput>
        </Col>
      </FormGroup>
      {disabled ? (
        <div className="col-12 text-center py-2 text-warning small font-500">
          Can't edit the basic info if there is one or more Work Orders assigned
          to this recurring Work Order.
        </div>
      ) : null}
    </Card>
  );
};

export default BasicInfo;
