import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const MAX_DAYS_SYNC_WARNING = 1;
const currentYear = new Date().getFullYear();

const Footer = () => {
  const [authContext] = useAuth();

  const isWarningSync =
    !authContext.currentUser.lastSyncedDate ||
    moment().diff(moment(authContext.currentUser.lastSyncedDate), "days") >
      MAX_DAYS_SYNC_WARNING;

  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs={8} className="text-left">
            <FontAwesomeIcon
              size="lg"
              icon={isWarningSync ? faExclamationTriangle : faCheck}
              className={`mr-1 text-${isWarningSync ? "warning" : "success"}`}
              style={{ paddingBottom: "1px" }}
            />
            <span className="ml-2">
              Last Sync:{" "}
              {authContext.currentUser.lastSyncedDate
                ? moment(authContext.currentUser.lastSyncedDate).format(
                    "MM/DD/YYYY, hh:mm a"
                  )
                : "N/A"}
            </span>
            <ul className="list-inline d-flex flex-column flex-lg-row flex-wrap">
              <li hidden className="list-inline-item">
                <Link to="dashboard" className="text-muted mr-1">
                  Soporte
                </Link>
              </li>
              <li hidden className="list-inline-item">
                <Link to="dashboard" className="text-muted mr-1">
                  Privacidad
                </Link>
              </li>
              <li hidden className="list-inline-item">
                <Link to="dashboard" className="text-muted mr-1">
                  Terminos del Servicio
                </Link>
              </li>
              <li hidden className="list-inline-item">
                <Link to="dashboard" className="text-muted">
                  Contacto
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={4} className="text-right">
            <p className="mb-0">
              &copy; {currentYear} -{" "}
              <a
                rel="noopener noreferrer"
                href="https://deshazo.com/"
                target="_blank"
                className="text-muted"
              >
                Deshazo
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
