import React, { useState } from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Alert, Button, Col, Input, Label } from "reactstrap";
import { awsApi } from "../../../../../../services/awsService";
import InformationModal from "../../../../../InformationModal";
import Loader from "../../../../../Loader";
import { cranesInspectionAttachmentApi } from "../../../../../../services/craneInspectionAttachmentServices";
import { v1 as uuidv1 } from "uuid";
import { utils } from "../../../../../../utils/utils";
import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../../../../../providers/workOrderDetailsProvider";
import ModalEdit from "../../Edition/ModalEdit";

const TYPE_IMAGE = "IMAGE";

const EditPhotosModal = ({
  attachments,
  craneInspectionAnswerId,
  isOpen,
  onToggle,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState(attachments);
  const [errors, setErrors] = useState();
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [, setWorkOrderDetails] = useWorkOrderDetails();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onEditCaption = async (photos) => {
    const photosToUpdate = photos.filter((photo) => photo.changedLabel);
    const updatePromises = photosToUpdate.map((photo) => {
      const postData = {
        id: photo.id,
        craneInspectionAnswerId: craneInspectionAnswerId,
        content: photo.content,
        label: photo.label,
        type: TYPE_IMAGE,
      };
      return cranesInspectionAttachmentApi.updateCraneInspectionAnswerAttachment(
        postData
      );
    });
    await Promise.all(updatePromises);
  };

  const onSubmit = async () => {
    setLoading(true);
    const upload = [
      ...photos
        .map((item, i) => ({
          ...item,
          fileName: `crane_inspection_attachment_${uuidv1()}_${i}`,
        }))
        .filter((img) => !img.id),
    ];

    const documents = upload.map((item) => ({
      ...item,
      method: "putObject",
    }));

    const promise = documents.length
      ? awsApi.signDocuments({ documents })
      : Promise.resolve();

    const signedURLs = await promise;
    const elements = (signedURLs || []).filter((sign) => sign.success);

    if (!elements.length) {
      onEditCaption(photos);
    } else {
      const imagesCreated = photos.filter((photo) =>
        attachments.some((attachment) => attachment.id === photo.id)
      );
      onEditCaption(imagesCreated);
    }

    let promises = elements
      .filter((element) =>
        upload.find((file) => file.fileName === element.fileName)
      )
      .map(async (element) => {
        const fileToUpload = upload.find(
          (file) => file.fileName === element.fileName
        );

        const file = await utils.srcToFile(
          fileToUpload.content,
          fileToUpload.fileName,
          fileToUpload.fileType
        );
        const data = {
          url: element.signedRequest,
          file: file,
          type: fileToUpload.fileType,
        };
        await awsApi.putDocumentsToS3(data);
        const postData = {
          craneInspectionAnswerId: craneInspectionAnswerId,
          content: element.url,
          label: fileToUpload.label,
          type: TYPE_IMAGE,
        };

        return cranesInspectionAttachmentApi.createCraneInspectionAnswerAttachment(
          postData
        );
      });

    if (deletedPhotos.length > 0) {
      promises = [
        ...promises,
        ...deletedPhotos.map((photo) =>
          cranesInspectionAttachmentApi.deletCraneInspectionAnswerAttachments(
            photo.id
          )
        ),
      ];
    }

    try {
      await Promise.all(promises);
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Update attachments",
        body: `The attached files were updated`,
        onClose: () => {
          setLoading(false);
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setLoading(false);
      if (err.isAxiosError) {
        setErrors(err.response.data);
        return;
      }
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const onDelete = (photo) => {
    setPhotos(photos.filter((value) => photo !== value));
    if (!photo.id) {
      return;
    }
    setDeletedPhotos([...deletedPhotos, photo]);
  };

  const onUpdateCaptionPhotos = (e, index) => {
    const updatedPhotosCaption = [...photos];
    updatedPhotosCaption[index].label = e.target.value;
    updatedPhotosCaption[index].changedLabel = true;
    setPhotos(updatedPhotosCaption);
  };

  return (
    <>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                })
          }
        />
      ) : (
        <ModalEdit
          isOpen={isOpen}
          onToggle={onToggle}
          title="Edit Photos"
          onCancel={onClose}
          onSave={onSubmit}
          isSaveDisabled={loading || (!photos.length && !deletedPhotos.length)}
        >
          {errors && (
            <Alert color="dark">
              <div className="p-2">
                {(errors || []).map((error) => (
                  <div key={error.param}>
                    - {error.param}: {error.msg}
                  </div>
                ))}
              </div>
            </Alert>
          )}
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <>
                <Col>
                  <Label for="Photos">
                    <span>Add Photos</span>
                  </Label>

                  <Input
                    required={false}
                    multiple={true}
                    type="file"
                    accept="image/gif, image/jpeg, image/png, image/jpg"
                    className="mb-1 text-truncate"
                    onChange={(event) => {
                      setPhotos([
                        ...photos,
                        ...[...event.currentTarget.files].map((file) => ({
                          fileName: file.name,
                          fileType: file.type,
                          content: URL.createObjectURL(file),
                        })),
                      ]);
                    }}
                  />
                </Col>
                <div className="d-flex flex-wrap">
                  {photos?.length
                    ? photos.map((photo, i) => (
                        <div className="col-3" key={i}>
                          <Button
                            className="close"
                            color="none"
                            onClick={() => onDelete(photo)}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} size="xs" />
                          </Button>
                          <img
                            alt=""
                            className="col-12 px-0 rounded photo-crane"
                            src={photo.content}
                          />
                          <Input
                            type="textarea"
                            bsSize="sm"
                            placeholder="Enter the caption"
                            required={false}
                            maxLength={50}
                            className="mt-1"
                            value={photo.label || ""}
                            style={{ maxHeight: "70px" }}
                            onChange={(e) => onUpdateCaptionPhotos(e, i)}
                          />
                        </div>
                      ))
                    : null}
                </div>
              </>
            )}
          </Row>
        </ModalEdit>
      )}
    </>
  );
};

export default EditPhotosModal;
