import React, { useEffect, useState } from "react";

import InformationModal from "../../InformationModal";
import {
  CustomInput,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from "reactstrap";

import Loader from "../../Loader";

import {
  STATUSES,
  useWorkOrderDetails,
} from "../../../providers/workOrderDetailsProvider";
import { recurringWorkOrdersApi } from "../../../services/recurringWorkOrdersServices";
import moment from "moment";

const TYPE_MONTHLY = "MONTHLY";
const TYPE_QUARTERLY = "QUARTERLY";

const MakeRecurringWorkOrderModal = ({ onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [loading, setLoading] = useState();
  const [existingLoading, setExistingLoading] = useState();
  const [recurring, setRecurring] = useState();
  const [existingRecurringWo, setExistingRecurringWo] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if (recurring?.length) {
      setExistingLoading(true);
      recurringWorkOrdersApi
        .getRecurringWorkOrders({
          customerId: workOrderDetails.workOrder.customerId,
          customerLocationId: workOrderDetails.workOrder.customerLocationId,
          serviceLocationId: workOrderDetails.workOrder.serviceLocationId,
          type: recurring,
        })
        .then((result) => {
          if (result.count > 0) {
            setExistingRecurringWo(result.data[0]);
          } else {
            setExistingRecurringWo();
          }
          setExistingLoading(false);
        });
    }
  }, [workOrderDetails.workOrder, recurring]);

  const doSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let recurringWo;
      if (existingRecurringWo) {
        recurringWo = existingRecurringWo;
      } else {
        recurringWo = await recurringWorkOrdersApi.createRecurringWorkOrder({
          customerId: workOrderDetails.workOrder.customerId,
          customerLocationId: workOrderDetails.workOrder.customerLocationId,
          serviceLocationId: workOrderDetails.workOrder.serviceLocationId,
          type: recurring,
        });
      }
      let completionDate = workOrderDetails.workOrder.endDate;
      if (
        workOrderDetails.workOrder.status.name === STATUSES.STATUS_COMPLETED
      ) {
        const completionDateLog = workOrderDetails.workOrder.statusLog
          .filter((s) => s.status.name === STATUSES.STATUS_COMPLETED)
          .sort((x, y) =>
            moment(x.createdAt).isBefore(moment(y.createdAt)) ? 1 : -1
          );
        if (completionDateLog?.length) {
          completionDate = completionDateLog[0].createdAt;
        }
      }
      if (!completionDate) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Make Work Order Recurring",
          body: "Work order doesn't have a completion/end date.",
        });
      }
      const quarter = moment(completionDate).get("quarter");
      const month = moment(completionDate).get("month") + 1;
      if (
        (recurring === TYPE_MONTHLY &&
          recurringWo.recurringWorkOrders &&
          recurringWo.recurringWorkOrders.find((rwo) => rwo.month === month)) ||
        (recurring === TYPE_QUARTERLY &&
          recurringWo.recurringWorkOrders &&
          recurringWo.recurringWorkOrders.find(
            (rwo) => rwo.quarter === quarter
          ))
      ) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Make Work Order Recurring",
          body: "Already exists a work order assigned to the corresponding period.",
        });
      }
      await recurringWorkOrdersApi.createWorkOrderRecurringWorkOrder({
        recurringWorkOrderId: recurringWo.id,
        workOrderId: workOrderDetails.workOrder.id,
        month: recurring === TYPE_MONTHLY ? month : null,
        quarter: recurring === TYPE_QUARTERLY ? quarter : null,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Make Work Order Recurring",
        body: "Work Order Made Recurring Successfully",
        onClose,
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Make Work Order Recurring",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "Work Order Made Recurring Successfully",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Make Work Order Recurring</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {existingLoading ? (
            <Loader size="sm" />
          ) : (
            <FormGroup className="d-flex justify-content-between mb-0">
              <Label className="text-left col-4 mb-0 d-flex align-items-center">
                Recurring
              </Label>
              <CustomInput
                id="recurringSelect"
                required={true}
                type="select"
                name="recurring"
                value={recurring}
                onChange={(e) => setRecurring(e.target.value)}
              >
                <option value={""}>Select One</option>
                <option value={TYPE_MONTHLY}>Monthly</option>
                <option value={TYPE_QUARTERLY}>Quarterly</option>
              </CustomInput>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Discard
          </Button>{" "}
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button
              className="d-flex align-items-center"
              color={"primary"}
              type={"submit"}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default MakeRecurringWorkOrderModal;
