import React, { useReducer, createContext, useContext } from "react";

const STEPS = {
  STEP_DATE: "STEP_DATE",
  STEP_TECHNICIANS: "STEP_TECHNICIANS",
};

const MODES = {
  MODE_WORK_ORDER: "MODE_WORK_ORDER",
  MODE_WORK_ORDER_TRIP: "MODE_WORK_ORDER_TRIP",
};

const initialState = {
  isOpen: false,
  currentData: null,
  mode: null,
  step: STEPS.STEP_DATE,
};

const ACTIONS = {
  DATA_UPDATE: "DATA_UPDATE",
  STEP_CHANGE: "STEP_CHANGE",
  ON_CLOSE: "ON_CLOSE",
  INIT: "INIT",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.INIT: {
      const { payload } = data;
      const { currentData, mode } = payload;

      return {
        ...initialState,
        isOpen: true,
        currentData,
        mode,
      };
    }

    case ACTIONS.STEP_CHANGE: {
      const { payload } = data;
      const { step } = payload;

      return {
        ...state,
        step,
      };
    }

    case ACTIONS.DATA_UPDATE: {
      const { payload } = data;
      const { currentData } = payload;

      return {
        ...state,
        currentData,
      };
    }

    case ACTIONS.ON_CLOSE: {
      const newState = { ...initialState };
      return newState;
    }

    default:
      throw new Error();
  }
};

const ScheduleModalContext = createContext(initialState);

const ScheduleModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ScheduleModalContext.Provider value={stateAndDispatch}>
      {children}
    </ScheduleModalContext.Provider>
  );
};

export const useScheduleModal = () => useContext(ScheduleModalContext);

export { ScheduleModalContext, ScheduleModalProvider, ACTIONS, STEPS, MODES };
