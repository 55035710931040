import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const DateRangeLabel = ({
  entity,
  startDate,
  endDate,
  background,
  className,
  children,
  isSchedule = false,
}) => {
  return (
    <div
      className={`d-flex mx-2 align-items-center justify-content-center ${className}`}
    >
      {entity ? <strong className="mr-2">{entity} Dates: </strong> : null}
      <div
        className="d-flex align-items-center px-2 py-1 border rounded"
        style={{ background }}
      >
        <FontAwesomeIcon
          icon={faCalendar}
          className="mr-2 text-primary"
          style={{ paddingBottom: "1px" }}
        />
        <span>
          {startDate ? moment(startDate).format("MM/DD/YYYY") : "Not Set"}
        </span>
      </div>
      <span className="mx-2">to</span>
      <div
        className="d-flex align-items-center px-2 py-1 border rounded"
        style={{ background }}
      >
        <FontAwesomeIcon
          icon={faCalendar}
          className="mr-2 text-primary"
          style={{ paddingBottom: "1px" }}
        />
        <span>
          {endDate ? moment(endDate).format("MM/DD/YYYY") : "Not Set"}
        </span>
      </div>
      {isSchedule ? children : null}
    </div>
  );
};

export default DateRangeLabel;
