import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Badge, Button, CustomInput } from "reactstrap";
import "./ScheduleHeader.css";
import DateRangeLabel from "../../../components/DateRangeLabel";
import BlockDaysOffModal from "./BlockDaysOffModal";

const MODE_THIS_WEEK = "MODE_THIS_WEEK";
const MODE_THIS_WEEK_NEXT = "MODE_THIS_WEEK_NEXT";
const MODE_THIS_MONTH = "MODE_THIS_MONTH";

const ScheduleHeader = ({
  currentDate,
  onClickPrevious,
  onClickNext,
  onRefresh,
  mode,
  handleMode,
  selectedStatus,
  loading,
  resources,
}) => {
  //todo use provider
  const [blockDaysOffModal, setBlockDaysOffModal] = useState();

  return (
    <div className="d-flex justify-content-between align-items-center pl-2">
      <DateRangeLabel
        background="white"
        title={"Showing"}
        startDate={currentDate.start}
        endDate={currentDate.end}
        isSchedule={true}
      >
        {selectedStatus.color &&
          selectedStatus.label &&
          resources > 0 &&
          !loading && (
            <Badge
              style={{
                backgroundColor: selectedStatus.color,
                marginLeft: "5px",
              }}
            >
              Showing {selectedStatus.label}
            </Badge>
          )}
      </DateRangeLabel>
      <div className="btn-arrow-group d-flex align-items-center">
        <Button
          size="sm"
          className="mr-3 flex-shrink-0 rounded"
          onClick={() => setBlockDaysOffModal(true)}
          disabled={loading}
        >
          Block Days Off
        </Button>
        <CustomInput
          className="mr-3"
          id="modeSelector"
          type="select"
          name="modeSelector"
          value={mode}
          onChange={(e) => {
            handleMode(e);
          }}
          disabled={loading}
        >
          <option value={MODE_THIS_WEEK}>This Week</option>
          <option value={MODE_THIS_WEEK_NEXT}>This Week and Next Week</option>
          <option value={MODE_THIS_MONTH}>This Month</option>
        </CustomInput>
        <Button
          onClick={onClickPrevious}
          className="btn-arrows-schedule text-primary border-primary bg-white border-right-radius-none"
          disabled={loading}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <Button
          onClick={onClickNext}
          className="btn-arrows-schedule text-primary border-primary bg-white border-left-radius-none"
          disabled={loading}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </div>
      {blockDaysOffModal ? (
        <BlockDaysOffModal
          onClose={() => {
            setBlockDaysOffModal();
          }}
          onSubmit={() => {
            setBlockDaysOffModal();
            onRefresh();
          }}
        />
      ) : null}
    </div>
  );
};
export default ScheduleHeader;
