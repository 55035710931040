import React, { useState } from "react";

import InformationModal from "../../InformationModal";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Button from "reactstrap/lib/Button";
import Loader from "../../Loader";
import ModalBody from "reactstrap/lib/ModalBody";

import { useNavigate } from "react-router-dom";
import { customersApi } from "../../../services/customerServices";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { workOrdersApi } from "../../../services/workOrdersServices";

import { Col, Label } from "reactstrap";
import SelectWrapper from "../../SelectWrapper";

const WorkOrderContactsModal = ({ onClose, onSubmit }) => {
  const navigate = useNavigate();

  const [workOrderDetails] = useWorkOrderDetails();
  const [loading, setLoading] = useState();

  const [selectedCustomerContacts, setSelectedCustomerContacts] = useState(
    workOrderDetails.workOrder.customerContacts
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async () => {
    if (!selectedCustomerContacts.length) {
      return setInformationModal({
        isOpen: true,
        title: "Edit Work Order Customer Contacts",
        body: "You must enter at least one contact",
      });
    }
    setLoading(true);
    try {
      await workOrdersApi.updateWorkOrderCustomerContacts({
        id: workOrderDetails.workOrder.id,
        customerContacts: selectedCustomerContacts.map((cc) => cc.id),
      });
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Edit Work Order Customer Contacts",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const getCustomerLocationContacts = async ({ id }) => {
    setLoading(true);
    const customerLocation = await customersApi.getCustomerLocations({
      id,
    });
    setLoading(false);
    return customerLocation.contacts;
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      rawBody={informationModal.rawBody}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              rawBody: false,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        Edit Work Order Customer Contacts
      </ModalHeader>
      <ModalBody>
        <Col sm="12">
          <Label for="Customer-Contacts">
            <span>Customer Contacts</span>
            <span className="text-danger ml-1">*</span>
          </Label>
          <SelectWrapper
            isRequired={true}
            key={workOrderDetails.workOrder.customerLocationId}
            isMulti={true}
            entity="customer contact"
            formatItemFunction={(c) => {
              return {
                label: c.name,
                value: c.id,
              };
            }}
            filterItemFunction={(c) => c.name}
            noOptionsAction={() =>
              setInformationModal({
                isOpen: true,
                rawBody: true,
                title: "Edit Work Order Customer Contacts",
                body: (
                  <div>
                    <span>
                      The location has no contacts added, please add one from
                      the customer details section
                    </span>
                    <span
                      className="ml-1 text-primary text-underline cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/customers/${workOrderDetails.workOrder.customerId}/details`
                        )
                      }
                    >
                      here.
                    </span>
                  </div>
                ),
                onClose,
              })
            }
            fetchFunction={getCustomerLocationContacts}
            fetchParameters={{
              id: workOrderDetails.workOrder.customerLocationId,
            }}
            defaultSelected={selectedCustomerContacts}
            onSelected={setSelectedCustomerContacts}
          />
        </Col>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div>
          <Button color="warning" onClick={onClose}>
            Discard
          </Button>
        </div>
        {loading ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            className="d-flex align-items-center"
            color={"success"}
            onClick={doSubmit}
          >
            Confirm
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default WorkOrderContactsModal;
