import React, { useState } from "react";

import {
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

import Loader from "../../../Loader";

import { workOrdersApi } from "../../../../services/workOrdersServices";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../../providers/workOrderDetailsProvider";
import InformationModal from "../../../InformationModal";

const VALUE_YES = "yes";
const VALUE_NO = "no";

const MaterialOrderedModal = ({ onClose, material }) => {
  const [, setWorkOrderDetails] = useWorkOrderDetails();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();
  const [received, setReceived] = useState(material.received);
  const [sagePoNumber, setSagePoNumber] = useState(material.sagePoNumber);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await workOrdersApi.updateMaterialOrdered({
        ...material,
        received,
        sagePoNumber,
      });
      setLoading(false);
      setWorkOrderDetails({
        action: ACTIONS.REFRESH,
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Material Ordered",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader close={closeBtn}>Edit Material Ordered</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          <FormGroup className="col-12 d-flex align-items-center px-0">
            <Label className="pl-0 col-6 text-left mb-0" for="sagePoNumber">
              Sage PO Number
              <small className="ml-2 text-danger">*</small>
            </Label>
            <Input
              placeholder="Enter a value.."
              className="col-6"
              type="text"
              maxLength="50"
              name="sagePoNumber"
              value={sagePoNumber}
              onChange={(e) => setSagePoNumber(e.currentTarget.value)}
              required
            />
          </FormGroup>
          <FormGroup className="mb-0 col-12 d-flex align-items-center px-0">
            <Label className="pl-0 col-6 text-left mb-0" for="receivedSelect">
              Received
              <small className="ml-2 text-danger">*</small>
            </Label>
            <CustomInput
              className="col-6"
              type="select"
              name="receivedSelect"
              id="receivedSelect"
              onChange={(e) => setReceived(e.currentTarget.value === VALUE_YES)}
              value={received ? VALUE_YES : VALUE_NO}
              required
            >
              <option value={""}>Select an option</option>
              <option value={VALUE_YES}>Yes</option>
              <option value={VALUE_NO}>No</option>
            </CustomInput>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button
              className="d-flex align-items-center"
              color={"success"}
              type={"submit"}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default MaterialOrderedModal;
