import {
  faChevronLeft,
  faChevronRight,
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  Badge,
  InputGroupText,
  CardFooter,
} from "reactstrap";

import CreateRecurringWorkOrderModal from "../../../components/admin/recurringWorkOrders/CreateRecurringWorkOrderModal";
import EditRecurringWorkOrderModal from "../../../components/admin/recurringWorkOrders/editRecurringWorkOrder/EditRecurringWorkOrderModal";

import ConfirmationModal from "../../../components/ConfirmationModal";

import InformationModal from "../../../components/InformationModal";
import { useAuth } from "../../../providers/authProvider";
import {
  useEditRecurringWorkOrders,
  ACTIONS as EDIT_ACTIONS,
  TYPES,
} from "../../../providers/editRecurringWorkOrdersProvider";

import {
  ACTIONS,
  MAX_PAGE_SIZE,
  useRecurringWorkOrders,
} from "../../../providers/recurringWorkOrdersProvider";

import { recurringWorkOrdersApi } from "../../../services/recurringWorkOrdersServices";
import { utils } from "../../../utils/utils";
import AdvanceTableWrapper from "../../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../../components/advanceTable/AdvanceTablePagination";

const TYPE_MONTHLY = "MONTHLY";
const TYPE_QUARTERLY = "QUARTERLY";

const TYPE_MAPPING = {
  [TYPE_MONTHLY]: "Monthly",
  [TYPE_QUARTERLY]: "Quarterly",
};

const columns = (currentMonth, onEdit, onDelete) => [
  {
    accessor: "customer",
    Header: "Customer",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 250,
    Cell: (rowData) => {
      const { customer } = rowData.row.original;
      return customer?.customerName || "-";
    },
  },
  {
    accessor: "customerLocation",
    Header: "Customer Location",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 300,
    Cell: (rowData) => {
      const { customerLocation } = rowData.row.original;
      return customerLocation?.shipToAddress1 || "-";
    },
  },
  {
    accessor: "serviceLocation",
    Header: "Service Location",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 200,
    Cell: (rowData) => {
      const { serviceLocation } = rowData.row.original;
      return serviceLocation?.name || "-";
    },
  },
  {
    accessor: "type",
    Header: "Job Type",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 200,
    Cell: (rowData) => {
      const { type } = rowData.row.original;
      return `Inspection / ${TYPE_MAPPING[type]}` || "-";
    },
  },
  {
    accessor: "id",
    Header: "Work Order #",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const currentWorkOrder = rowData.row.original.recurringWorkOrders.find(
        (rwo) => {
          return rowData.row.original.type === TYPES.TYPE_MONTHLY
            ? rwo.month === moment(currentMonth).get("month") + 1
            : rwo.quarter === moment(currentMonth).get("quarter");
        }
      );
      if (currentWorkOrder) {
        return currentWorkOrder?.workOrder.jobNo
          ? `#${currentWorkOrder.workOrder.jobNo}`
          : `Without WO# (${currentWorkOrder.workOrder.id})`;
      }
      return "-";
    },
  },
  {
    accessor: "recurringWorkOrders",
    Header: "Status",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const currentWorkOrder = rowData.row.original.recurringWorkOrders.find(
        (rwo) => {
          return rowData.row.original.type === TYPES.TYPE_MONTHLY
            ? rwo.month === moment(currentMonth).get("month") + 1
            : rwo.quarter === moment(currentMonth).get("quarter");
        }
      );
      if (currentWorkOrder) {
        return (
          <Badge
            color={utils.workOrderStatusColor(
              currentWorkOrder?.workOrder.status.id
            )}
          >
            {currentWorkOrder?.workOrder.status.name}
          </Badge>
        );
      }
      return "-";
    },
  },
  {
    accessor: "",
    Header: "Actions",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => (
      <div className="d-flex justify-content-center">
        <Button
          size="sm"
          className="rounded mr-3 d-flex align-items-center"
          color="warning"
          onClick={() => onEdit(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faPen} className="mr-1" />
          <span>Edit</span>
        </Button>
        <Button
          size="sm"
          className="rounded d-flex align-items-center"
          color="danger"
          onClick={() => onDelete(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
          <span>Delete</span>
        </Button>
      </div>
    ),
  },
];

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const RecurringWorkOrders = () => {
  const [authContext] = useAuth();
  const [recurringWorkOrdersContext, setRecurringWorkOrdersContext] =
    useRecurringWorkOrders();
  const [editRecurringWorkOrdersContext, setEditRecurringWorkOrdersContext] =
    useEditRecurringWorkOrders();

  const [loading, setLoading] = useState(false);
  const [createRecurringWorkOrderModal, setCreateRecurringWorkOrderModal] =
    useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const setRecurringWorkOrdersContextCb = useCallback(
    (data) => setRecurringWorkOrdersContext(data),
    [setRecurringWorkOrdersContext]
  );

  useEffect(() => {
    setLoading(true);
    recurringWorkOrdersApi
      .getRecurringWorkOrders({
        serviceLocationId: authContext.serviceLocationId,
        search: recurringWorkOrdersContext.search,
        page: recurringWorkOrdersContext.page - 1,
        pageSize: getSizePerPage(recurringWorkOrdersContext.sizePerPage),
      })
      .then((recurringWorkOrders) => {
        setRecurringWorkOrdersContextCb({
          action: ACTIONS.GET_RECURRING_WORKORDERS_SUCCESS,
          payload: { recurringWorkOrders },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setRecurringWorkOrdersContextCb,
    recurringWorkOrdersContext.sizePerPage,
    recurringWorkOrdersContext.page,
    recurringWorkOrdersContext.search,
    recurringWorkOrdersContext.refresh,
    authContext.serviceLocationId,
  ]);

  const onChangeMonth = (direction) => {
    const newMonth = moment(currentMonth);
    newMonth.add(direction, "month");
    setCurrentMonth(newMonth);
  };

  const onEdit = (recurringWorkOrder) =>
    setEditRecurringWorkOrdersContext({
      action: EDIT_ACTIONS.ON_OPEN_MODAL,
      payload: { recurringWorkOrder },
    });

  const onDelete = (workOrder) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        recurringWorkOrdersApi
          .deleteRecurringWorkOrder({ id: workOrder.id })
          .then(() =>
            setRecurringWorkOrdersContext({ action: ACTIONS.REFRESH })
          );
        setConfirmationModal(initConfirmationModal);
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Recurring Work Order",
      body: `<span class="text-center mb-0">Do you confirm you want to delete the selected recurring work order?</span>`,
      confirmColor: "danger",
    });
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(currentMonth, onEdit, onDelete)}
          data={recurringWorkOrdersContext.recurringWorkOrders?.data || []}
          pageSize={recurringWorkOrdersContext.sizePerPage}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Recurring Work Orders</h3>
                <small className="text-muted ml-2 pt-1">
                  ({recurringWorkOrdersContext.recurringWorkOrders.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={recurringWorkOrdersContext.search}
                    onChange={(evt) =>
                      setRecurringWorkOrdersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-secondary text-white border-left-0 border-secondary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <div className="d-flex justify-content-between align-items-center mr-3 col-6 col-md-5 col-lg-5 col-xl-4 px-0">
                  <Button
                    onClick={() => onChangeMonth(-1)}
                    className="rounded-circle square-32 d-flex align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                  <div className="form-control min-width-100 flex-grow-1 mx-1 d-flex align-items-center justify-content-center border rounded bg-white text-body">
                    <small>{currentMonth.format("MMMM")}</small>
                  </div>
                  <Button
                    onClick={() => onChangeMonth(1)}
                    className="rounded-circle square-32 d-flex align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={() => setCreateRecurringWorkOrderModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button ml-3"
                  color="secondary"
                  onClick={() =>
                    setRecurringWorkOrdersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {recurringWorkOrdersContext.recurringWorkOrders.data?.length ? (
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={
                  recurringWorkOrdersContext.recurringWorkOrders.count
                }
                pageCount={
                  recurringWorkOrdersContext.recurringWorkOrders.totalPages
                }
                currentPage={recurringWorkOrdersContext.page - 1}
                onPageChange={(page) =>
                  setRecurringWorkOrdersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={recurringWorkOrdersContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setRecurringWorkOrdersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : createRecurringWorkOrderModal ? (
        <CreateRecurringWorkOrderModal
          onSubmit={() => {
            setCreateRecurringWorkOrderModal(false);
            setRecurringWorkOrdersContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setCreateRecurringWorkOrderModal(false)}
        />
      ) : editRecurringWorkOrdersContext.isOpen ? (
        <EditRecurringWorkOrderModal />
      ) : null}
    </Container>
  );
};

export default RecurringWorkOrders;
