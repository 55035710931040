import React, { useState } from "react";

import InformationModal from "../../InformationModal";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Button from "reactstrap/lib/Button";
import Loader from "../../Loader";
import ModalBody from "reactstrap/lib/ModalBody";

import { customersApi } from "../../../services/customerServices";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { workOrdersApi } from "../../../services/workOrdersServices";

import { Col, Label } from "reactstrap";
import SelectWrapper from "../../SelectWrapper";
import { utils } from "../../../utils/utils";

const WorkOrderLocationModal = ({ onClose, onSubmit }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [loading, setLoading] = useState();

  const [selectedCustomerLocation, setSelectedCustomerLocation] = useState(
    workOrderDetails.workOrder.customerLocation
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async () => {
    if (!selectedCustomerLocation) {
      return setInformationModal({
        isOpen: true,
        title: "Edit Work Order Customer Location",
        body: "Please select a location.",
      });
    }
    setLoading(true);
    try {
      await workOrdersApi.updateWorkOrder({
        ...workOrderDetails.workOrder,
        customerLocationId: selectedCustomerLocation.id,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Edit Work Order Customer Location",
        body: "Location set successfully.",
        onClose: onSubmit,
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Edit Work Order Customer Location",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const getCustomerLocations = async (data) => {
    setLoading(true);
    const customerLocations = await customersApi.getCustomerLocations(data);
    setLoading(false);
    return customerLocations;
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      rawBody={informationModal.rawBody}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              rawBody: false,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        Edit Work Order Customer Location
      </ModalHeader>
      <ModalBody>
        <Col sm="12">
          <Label for="Customer-Location">
            <span>Customer Location</span>
            <span className="text-danger ml-1">*</span>
          </Label>
          <SelectWrapper
            isRequired={true}
            key={workOrderDetails.workOrder.customerLocationId}
            isMulti={false}
            entity="customer location"
            formatItemFunction={(c) => {
              return {
                label: utils.getAddress(c),
                value: c.id,
              };
            }}
            noOptionsAction={() =>
              setInformationModal({
                isOpen: true,
                rawBody: true,
                title: "Edit Work Order Customer Location",
                body: <span>The customer has no locations</span>,
                onClose,
              })
            }
            fetchFunction={getCustomerLocations}
            fetchParameters={{
              customerId: workOrderDetails.workOrder.customerId,
            }}
            defaultSelected={selectedCustomerLocation}
            onSelected={setSelectedCustomerLocation}
          />
        </Col>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div>
          <Button color="warning" onClick={onClose}>
            Discard
          </Button>
        </div>
        {loading ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            className="d-flex align-items-center"
            color={"success"}
            onClick={doSubmit}
          >
            Confirm
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default WorkOrderLocationModal;
