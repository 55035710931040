import React, { useReducer, createContext, useContext } from "react";

const ASSIGNATIONS_TAB = "ASSIGNATIONS_TAB";
const INFO_TAB = "INFO_TAB";

const TABS = {
  ASSIGNATIONS_TAB,
  INFO_TAB,
};

const TYPE_MONTHLY = "MONTHLY";
const TYPE_QUARTERLY = "QUARTERLY";

const TYPES = {
  TYPE_MONTHLY,
  TYPE_QUARTERLY,
};

const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const QUARTERS = [1, 2, 3, 4];

const initialState = {
  isOpen: false,
  recurringWorkOrder: null,
  activeTab: INFO_TAB,
  type: "",
  customerSearch: "",
  customerLocationSearch: "",
  customers: [],
  customerLocations: [],
  serviceLocations: [],
  selectedCustomer: "",
  selectedCustomerLocation: "",
  selectedServiceLocation: "",
  selectedWorkOrders: {},
  loadingCustomerSearch: false,
  loadingCustomerLocationSearch: false,
};

const ACTIONS = {
  ON_OPEN_MODAL: "ON_OPEN_MODAL",
  ON_CLOSE_MODAL: "ON_CLOSE_MODAL",
  ON_TAB_CHANGE: "ON_TAB_CHANGE",
  ON_TYPE_CHANGE: "ON_TYPE_CHANGE",
  ON_SELECT_CUSTOMER: "ON_SELECT_CUSTOMER",
  ON_SELECT_CUSTOMER_LOCATION: "ON_SELECT_CUSTOMER_LOCATION",
  ON_SELECT_SERVICE_LOCATION: "ON_SELECT_SERVICE_LOCATION",
  ON_SELECT_WORK_ORDER: "ON_SELECT_WORK_ORDER",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.ON_OPEN_MODAL: {
      const { payload } = data;
      const { recurringWorkOrder } = payload;

      return {
        ...state,
        isOpen: true,
        recurringWorkOrder,
        type: recurringWorkOrder.type,
        selectedCustomer: recurringWorkOrder.customer,
        selectedCustomerLocation: recurringWorkOrder.customerLocation,
        selectedServiceLocation: recurringWorkOrder.serviceLocation,
        selectedWorkOrders: recurringWorkOrder.recurringWorkOrders.reduce(
          (p, c) => {
            p[c.month || c.quarter] = c;
            return p;
          },
          {}
        ),
      };
    }

    case ACTIONS.ON_CLOSE_MODAL: {
      const newState = { ...initialState };
      return {
        ...newState,
      };
    }

    case ACTIONS.ON_TYPE_CHANGE: {
      const { payload } = data;
      const { type } = payload;

      return {
        ...state,
        type,
      };
    }

    case ACTIONS.ON_TAB_CHANGE: {
      const { payload } = data;
      const { tab } = payload;

      return {
        ...state,
        activeTab: tab,
      };
    }

    case ACTIONS.ON_SELECT_CUSTOMER: {
      const { payload } = data;
      const { customer } = payload;

      return {
        ...state,
        selectedCustomer: customer,
      };
    }

    case ACTIONS.ON_SELECT_CUSTOMER_LOCATION: {
      const { payload } = data;
      const { customerLocation } = payload;

      return {
        ...state,
        selectedCustomerLocation: customerLocation,
      };
    }

    case ACTIONS.ON_SELECT_SERVICE_LOCATION: {
      const { payload } = data;
      const { serviceLocation } = payload;

      return {
        ...state,
        selectedServiceLocation: serviceLocation,
      };
    }

    case ACTIONS.ON_SELECT_WORK_ORDER: {
      const { payload } = data;
      const { workOrder, period } = payload;

      const selectedWorkOrders = {
        ...state.selectedWorkOrders,
      };

      if (workOrder) {
        selectedWorkOrders[period] = workOrder;
      } else {
        delete selectedWorkOrders[period];
      }

      return {
        ...state,
        selectedWorkOrders,
      };
    }

    default:
      throw new Error();
  }
};

const EditRecurringWorkOrdersContext = createContext(initialState);

const EditRecurringWorkOrdersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <EditRecurringWorkOrdersContext.Provider value={stateAndDispatch}>
      {children}
    </EditRecurringWorkOrdersContext.Provider>
  );
};

export const useEditRecurringWorkOrders = () =>
  useContext(EditRecurringWorkOrdersContext);

export {
  EditRecurringWorkOrdersContext,
  EditRecurringWorkOrdersProvider,
  ACTIONS,
  TABS,
  TYPES,
  MONTHS,
  QUARTERS,
};
