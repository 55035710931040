import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Loader from "../Loader";

import InformationModal from "../InformationModal";
import SelectWrapper from "../SelectWrapper";
import { commonApi } from "../../services/commonServices";

const PAGE_SIZE = 30;

const AddActivityEmailModal = ({ submitFunction, onSubmit, onClose }) => {
  const [email, setEmail] = useState("");
  const [serviceLocation, setServiceLocation] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await submitFunction({
        email,
        serviceLocationIds: serviceLocation?.map((sl) => sl.id),
      });
      onSubmit();
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Add Activity Email",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Add Activity Email
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label for="email">
                    <span>Email</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Enter the email..."
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label className="d-flex flex-column">
                    <span>Service Locations</span>
                  </Label>
                  <SelectWrapper
                    entity="service location"
                    formatItemFunction={(c) => {
                      return {
                        label: c.name,
                        value: c.id,
                      };
                    }}
                    isMulti={true}
                    fetchFunction={commonApi.getServiceLocations}
                    fetchParameters={{
                      pageSize: PAGE_SIZE,
                    }}
                    defaultSelected={serviceLocation}
                    onSelected={(serviceLocation) =>
                      setServiceLocation(serviceLocation)
                    }
                  />
                  <div className="text-muted mt-1 small">
                    Leave empty to select all
                  </div>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddActivityEmailModal;
