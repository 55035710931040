import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 9999;
const INIT_PAGE_SIZE = 10;

const initialState = {
  recurringWorkOrders: {},
  sizePerPage: INIT_PAGE_SIZE,
  page: 1,
  search: "",
};

const ACTIONS = {
  SEARCH: "SEARCH",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_RECURRING_WORKORDERS_SUCCESS: "GET_RECURRING_WORKORDERS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_RECURRING_WORKORDERS_KPIS_SUCCESS: {
      const { payload } = data;
      const { kpis } = payload;

      return {
        ...state,
        kpis,
      };
    }

    case ACTIONS.GET_RECURRING_WORKORDERS_SUCCESS: {
      const { payload } = data;
      const { recurringWorkOrders } = payload;

      return {
        ...state,
        recurringWorkOrders,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction,
        page: 1,
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { search } = payload;

      return {
        ...state,
        search,
        page: 1,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: 1,
      };
    }

    default:
      throw new Error();
  }
};

const RecurringWorkOrdersContext = createContext(initialState);

const RecurringWorkOrdersProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <RecurringWorkOrdersContext.Provider value={stateAndDispatch}>
      {children}
    </RecurringWorkOrdersContext.Provider>
  );
};

export const useRecurringWorkOrders = () =>
  useContext(RecurringWorkOrdersContext);

export {
  RecurringWorkOrdersContext,
  RecurringWorkOrdersProvider,
  ACTIONS,
  MAX_PAGE_SIZE,
  INIT_PAGE_SIZE,
};
