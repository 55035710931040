import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import { useAuth } from "../../../../providers/authProvider";
import {
  useScheduleModal,
  ACTIONS,
  STEPS,
  MODES,
} from "../../../../providers/scheduleModalProvider";
import { workOrdersApi } from "../../../../services/workOrdersServices";
import InformationModal from "../../../InformationModal";

import Loader from "../../../Loader";
import TechSelector from "../TechSelector";

import {
  STATUSES,
  useWorkOrderDetails,
  ACTIONS as useWorkOrderDetailsActions,
} from "../../../../providers/workOrderDetailsProvider";

import {
  ScheduleModalTechniciansProvider,
  useScheduleModalTechnicians,
  ACTIONS as useScheduleModalTechniciansActions,
} from "../../../../providers/scheduleModalTechniciansProvider";
import ConfirmationModal from "../../../ConfirmationModal";

const SELECTOR_MODE_LEAD = 1;
const SELECTOR_MODE_DEFAULT = 2;
const SELECTOR_MODE_NEW = 3;

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const ScheduleModalTechnicians = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [authContext] = useAuth();

  const [scheduleModalContext, setScheduleModalContext] = useScheduleModal();

  const { currentData: workOrderTrip } = scheduleModalContext;

  const [scheduleModalTechniciansContext, setScheduleModalTechniciansContext] =
    useScheduleModalTechnicians();

  const [loading, setLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    const techShare = Boolean(
      (workOrderTrip.workOrderEmployees || []).find((woe) =>
        woe.employee.serviceLocations.find(
          (sl) =>
            sl.id !==
            (workOrderDetails.workOrder.serviceLocationId ||
              authContext.serviceLocationId)
        )
      )
    );
    setScheduleModalTechniciansContext({
      action: useScheduleModalTechniciansActions.SET_TECH_SHARE,
      payload: { techShare },
    });
  }, [
    authContext.serviceLocationId,
    setScheduleModalTechniciansContext,
    workOrderDetails.workOrder.serviceLocationId,
    workOrderTrip.workOrderEmployees,
  ]);

  useEffect(() => {
    const employees = (workOrderTrip.workOrderEmployees || []).map((woe) => ({
      id: woe.employeeId,
      name: `${woe.employee.firstName} ${woe.employee.lastName}`,
      isLead: woe.isLead,
      isActive: woe.employee.isActive,
      serviceLocations: woe.employee.serviceLocations,
    }));
    setScheduleModalTechniciansContext({
      action: useScheduleModalTechniciansActions.INIT_EMPLOYEES,
      payload: { employees },
    });
  }, [setScheduleModalTechniciansContext, workOrderTrip]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSubmit = async (rescheduleWO, callback) => {
    const touched =
      (workOrderTrip.workOrderEmployees || []).some((woe) => {
        return !scheduleModalTechniciansContext.employees.find(
          (e) => e.id === woe.employeeId && e.isLead === woe.isLead
        );
      }) ||
      scheduleModalTechniciansContext.employees.some(
        (e) =>
          !(workOrderTrip.workOrderEmployees || []).find(
            (woe) => e.id === woe.employeeId && e.isLead === woe.isLead
          )
      );

    if (!workOrderTrip || !workOrderTrip.id) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "The work order trip ID is missing. Please try again.",
        onClose: onClose,
      });
      return;
    }

    if (!touched && !rescheduleWO) {
      return callback();
    }
    setLoading(true);
    try {
      const updatedWorkOrderTrip = await workOrdersApi.updateWorkOrderTrip({
        rescheduleWO,
        id: workOrderTrip.id,
        techShare: scheduleModalTechniciansContext.techShare,
        employees: scheduleModalTechniciansContext.employees.map(
          (employee) => ({
            employeeId: employee.id,
            isLead: employee.isLead,
          })
        ),
      });
      setLoading(false);
      callback(updatedWorkOrderTrip);
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
        onClose: () => {
          setScheduleModalContext({
            action: ACTIONS.ON_CLOSE,
          });
        },
      });
    }
  };

  const onClose = () => {
    setWorkOrderDetails({
      action: useWorkOrderDetailsActions.REFRESH,
    });
    setScheduleModalContext({
      action: ACTIONS.ON_CLOSE,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const leadEmployee = scheduleModalTechniciansContext.employees.find(
    (employee) => employee.isLead
  );

  const nonLeadEmployees = scheduleModalTechniciansContext.employees.filter(
    (employee) => !employee.isLead
  );

  const key = scheduleModalTechniciansContext.employees
    .map((e) => e.id)
    .join("-");

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal
      {...confirmationModal}
      confirmButtonText="Yes"
      cancelButtonText="No"
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Assign Work Order</ModalHeader>
      <ModalBody className="text-center">
        <div className="d-flex justify-content-between align-items-center mb-3 py-2 rounded border bg-lighter px-2">
          <div className="col-4"></div>
          <small className="col-4 font-weight-bold text-muted text-center">
            Crew Member
          </small>
          <small className="col-4 font-weight-bold text-muted text-right">
            <CustomInput
              key={scheduleModalTechniciansContext.techShare}
              type="switch"
              id="techShareSwitch"
              name="techShareSwitch"
              label="Tech Share"
              defaultChecked={scheduleModalTechniciansContext.techShare}
              onClick={(e) =>
                setScheduleModalTechniciansContext({
                  action: useScheduleModalTechniciansActions.SET_TECH_SHARE,
                  payload: { techShare: e.currentTarget.checked },
                })
              }
            />
          </small>
        </div>
        <div
          className="d-flex flex-column"
          key={scheduleModalTechniciansContext.employees.length} //regenerate all the view to avoid strange duplicate bug
        >
          <TechSelector
            key={key}
            defaultEmployee={leadEmployee}
            mode={SELECTOR_MODE_LEAD}
          />
          {nonLeadEmployees.map((employee, i) => (
            <TechSelector
              key={`${key}-${i}}`}
              defaultEmployee={employee}
              mode={SELECTOR_MODE_DEFAULT}
            />
          ))}
          {leadEmployee ? <TechSelector mode={SELECTOR_MODE_NEW} /> : null}
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between mt-n3">
        <Button
          className="d-flex align-items-center"
          color={"secondary"}
          onClick={() => {
            const submitCallback = (workOrderTrip) => {
              setScheduleModalContext({
                action: ACTIONS.STEP_CHANGE,
                payload: { step: STEPS.STEP_DATE },
              });
              if (workOrderTrip) {
                setScheduleModalContext({
                  action: ACTIONS.DATA_UPDATE,
                  payload: { currentData: workOrderTrip },
                });
              }
              setWorkOrderDetails({
                action: useWorkOrderDetailsActions.REFRESH,
              });
            };
            onSubmit(false, submitCallback);
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="ml-2">Dates</span>
        </Button>{" "}
        {loading ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            className="d-flex align-items-center"
            color={"primary"}
            onClick={() => {
              const submitCallback = () => {
                const isReschedule =
                  workOrderDetails.workOrder.status.name ===
                  STATUSES.STATUS_WAITING_FOR_PARTS;
                setInformationModal({
                  isOpen: true,
                  title: isReschedule
                    ? "Reschedule Work Order"
                    : "Schedule Work Order Trip",
                  body: `Work Order ${
                    isReschedule ? "Rescheduled" : "Trip Scheduled"
                  } Successfully`,
                  onClose: () => {
                    setScheduleModalContext({
                      action: ACTIONS.ON_CLOSE,
                    });
                    setWorkOrderDetails({
                      action: useWorkOrderDetailsActions.REFRESH,
                    });
                  },
                });
              };
              if (
                scheduleModalContext.mode === MODES.MODE_WORK_ORDER_TRIP &&
                workOrderDetails.workOrder.status.name ===
                  STATUSES.STATUS_COMPLETED
              ) {
                setConfirmationModal({
                  isOpen: true,
                  onSubmit: () => onSubmit(true, submitCallback),
                  onClose: () => onSubmit(false, submitCallback),
                  title: `${
                    workOrderTrip.id ? "Update" : "Create"
                  } Work Order Trip`,
                  body: `
                          <span class="text-center">
                            Do you want to set this work order back to “In Progress”?
                          </span>
                        `,
                  confirmColor: "warning",
                });
              } else {
                onSubmit(false, submitCallback);
              }
            }}
          >
            <span>Finish</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

const ScheduleModalTechniciansWrapper = ({ ...props }) => (
  <ScheduleModalTechniciansProvider>
    <ScheduleModalTechnicians {...props} />
  </ScheduleModalTechniciansProvider>
);

export default ScheduleModalTechniciansWrapper;
