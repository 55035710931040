import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { inspectionPointsApi } from "../../../services/inspectionPointsServices";
import { awsApi } from "../../../services/awsService";
import { utils } from "../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import SelectWrapper from "../../SelectWrapper";

const MAX_FILE_SIZE = 1000 * 1000 * 1;
const MAX_GUIDANCE_TEXT = 10000;
const MAX_CONTENT_TEXT = 100;

const PAGE_SIZE = 30;

const TYPE_MULTIPLE_CHOICE = 1;
const TYPE_MEASUREMENT = 2;

const TYPE_FREQUENT = "FREQUENT";
const TYPE_PERIODIC = "PERIODIC";

const InspectionPointModal = ({ inspectionPointId, onClose, onSubmit }) => {
  const [inspectionPoint, setInspectionPoint] = useState({
    id: null,
    content: "",
    order: "",
    craneInspectionPointTypeId: "",
    inspectionPointGuidance: { contentUrl: "", text: "" },
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [inspectionType, setInspectionType] = useState(TYPE_FREQUENT);
  const [selectedSection, setSelectedSection] = useState();

  const [loading, setLoading] = useState();

  const [errors, setErrors] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();

    if (!selectedSection) {
      return setInformationModal({
        isOpen: true,
        title: "Create Inspection Point",
        body: "Please select a section.",
      });
    }

    setLoading(true);

    const data = {
      ...inspectionPoint,
      craneInspectionSectionId: selectedSection.id,
    };

    if (inspectionPoint.inspectionPointGuidance?.hasChangedFile) {
      const result = await awsApi.signDocuments({
        documents: [
          {
            ...inspectionPoint.inspectionPointGuidance,
            method: "putObject",
          },
        ],
      });

      if (result.length && result[0].success) {
        const signedURL = result[0];
        const fileToUpload = await utils.srcToFile(
          inspectionPoint.inspectionPointGuidance.contentUrl,
          inspectionPoint.inspectionPointGuidance.fileName,
          inspectionPoint.inspectionPointGuidance.fileType
        );
        const awsData = {
          url: signedURL.signedRequest,
          file: fileToUpload,
          type: inspectionPoint.inspectionPointGuidance.fileType,
        };
        await awsApi.putDocumentsToS3(awsData);
        data.inspectionPointGuidance = {
          ...inspectionPoint.inspectionPointGuidance,
          contentUrl: signedURL.url,
        };
      }
    }

    let promise;
    if (inspectionPoint.id) {
      promise = inspectionPointsApi.updateInspectionPoint(data);
    } else {
      promise = inspectionPointsApi.createInspectionPoint(data);
    }

    try {
      await promise;
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: `${inspectionPoint.id ? "Update" : "Create"} Inspection Point`,
        body: `Inspection Point ${
          inspectionPoint.id ? "updated" : "created"
        } successfully`,
        onClose: onSubmit,
      });
    } catch (err) {
      setLoading(false);
      if (err.isAxiosError) {
        setErrors(err.response.data);
        return;
      }
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  // Load InspectionPoint
  useEffect(() => {
    if (inspectionPointId) {
      setLoading(true);
      inspectionPointsApi
        .getInspectionPoints({ id: inspectionPointId })
        .then((inspectionPoint) => {
          setInspectionPoint({
            ...inspectionPoint,
          });
          setSelectedSection(inspectionPoint.inspectionSection);
          if (inspectionPoint.inspectionSection.condition) {
            const condition = JSON.parse(
              JSON.stringify(inspectionPoint.inspectionSection.condition)
            );
            setInspectionType(condition.inspectionType);
          }
        })
        .catch(() =>
          setInformationModal({
            isOpen: true,
            title: "Error",
            body: "There was an error with your request.",
          })
        )
        .finally(() => setLoading(false));
    }
  }, [inspectionPointId]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="md">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={
            <Button className="close" color="none" onClick={onClose}>
              &times;
            </Button>
          }
        >
          {inspectionPointId ? "Edit" : "Create"} Inspection Point
        </ModalHeader>
        <ModalBody>
          {errors && (
            <Alert color="dark">
              <div className="p-2">
                {(errors || []).map((error) => (
                  <div key={error.param}>{error.msg}</div>
                ))}
              </div>
            </Alert>
          )}
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <>
                <Col sm="12">
                  <FormGroup>
                    <Label for="content">
                      <span>Content</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      required={true}
                      type="text"
                      name="content"
                      maxLength="255"
                      value={inspectionPoint.content}
                      onChange={(e) => {
                        if (e.target.value.length > MAX_CONTENT_TEXT) {
                          return setInformationModal({
                            isOpen: true,
                            title: "Inspection Point",
                            body: "Content too long",
                          });
                        }
                        setInspectionPoint({
                          ...inspectionPoint,
                          content: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <Label for="order">
                      <span>Order</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      required={true}
                      type="number"
                      name="order"
                      min={1}
                      max={999999}
                      value={inspectionPoint.order}
                      onChange={(e) =>
                        setInspectionPoint({
                          ...inspectionPoint,
                          order: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup className="d-flex flex-column">
                    <Label for="inspectionType">
                      <div>
                        <span>Inspection Type</span>
                        <span className="text-danger ml-1">*</span>
                      </div>
                      <small className="text-warning">
                        The inspection type determines the available sections
                      </small>
                    </Label>
                    <div className="d-flex flex-grow-1 justify-content-start">
                      <div className="col-6 pl-0">
                        <Button
                          onClick={() => {
                            if (inspectionType !== TYPE_FREQUENT) {
                              setInspectionType(TYPE_FREQUENT);
                              setSelectedSection();
                            }
                          }}
                          color={
                            inspectionType === TYPE_FREQUENT
                              ? "primary"
                              : "white"
                          }
                          className={`col-12 outline-none ${
                            inspectionType === TYPE_FREQUENT
                              ? "border-white bg-primary text-white"
                              : "border-primary bg-white text-primary"
                          }`}
                        >
                          Frequent
                        </Button>
                      </div>
                      <div className="col-6 pr-0">
                        <Button
                          onClick={() => {
                            if (inspectionType !== TYPE_PERIODIC) {
                              setInspectionType(TYPE_PERIODIC);
                              setSelectedSection();
                            }
                          }}
                          color={
                            inspectionType === TYPE_PERIODIC
                              ? "primary"
                              : "white"
                          }
                          className={`col-12 outline-none ${
                            inspectionType === TYPE_PERIODIC
                              ? "border-white bg-primary text-white"
                              : "border-primary bg-white text-primary"
                          }`}
                        >
                          Periodic
                        </Button>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup className="d-flex flex-column">
                    <Label for="inspectionPointType">
                      <span>Inspection Point Type</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <div className="d-flex flex-grow-1 justify-content-start">
                      <div className="col-6 pl-0">
                        <Button
                          onClick={() =>
                            setInspectionPoint({
                              ...inspectionPoint,
                              craneInspectionPointTypeId: TYPE_MULTIPLE_CHOICE,
                            })
                          }
                          color={
                            inspectionPoint.craneInspectionPointTypeId ===
                            TYPE_MULTIPLE_CHOICE
                              ? "secondary"
                              : "white"
                          }
                          className={`col-12 outline-none ${
                            inspectionPoint.craneInspectionPointTypeId ===
                            TYPE_MULTIPLE_CHOICE
                              ? "border-white bg-secondary text-white"
                              : "border-secondary bg-white text-secondary"
                          }`}
                        >
                          Multiple Choice
                        </Button>
                      </div>
                      <div className="col-6 pr-0">
                        <Button
                          onClick={() =>
                            setInspectionPoint({
                              ...inspectionPoint,
                              craneInspectionPointTypeId: TYPE_MEASUREMENT,
                            })
                          }
                          color={
                            inspectionPoint.craneInspectionPointTypeId ===
                            TYPE_MEASUREMENT
                              ? "secondary"
                              : "white"
                          }
                          className={`col-12 outline-none ${
                            inspectionPoint.craneInspectionPointTypeId ===
                            TYPE_MEASUREMENT
                              ? "border-white bg-secondary text-white"
                              : "border-secondary bg-white text-secondary"
                          }`}
                        >
                          Measurement
                        </Button>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup className="d-flex flex-column">
                    <Label for="Section">
                      <span>Section</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <SelectWrapper
                      isRequired={true}
                      key={inspectionType}
                      entity="section"
                      formatItemFunction={(c) => {
                        return {
                          label: c.name,
                          value: c.id,
                        };
                      }}
                      fetchFunction={inspectionPointsApi.getInspectionSections}
                      fetchParameters={{
                        pageSize: PAGE_SIZE,
                        inspectionType,
                      }}
                      defaultSelected={selectedSection}
                      onSelected={(section) => setSelectedSection(section)}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup className="d-flex flex-column">
                    <Label for="guidanceText">
                      <span>Guidance Text</span>
                    </Label>
                    <ReactQuill
                      className="rounded border"
                      placeholder="Type something..."
                      theme={"snow"}
                      value={
                        inspectionPoint.inspectionPointGuidance?.text || ""
                      }
                      onChange={(text) => {
                        if (text.length > MAX_GUIDANCE_TEXT) {
                          return setInformationModal({
                            isOpen: true,
                            title: "Inspection Point",
                            body: "Guidance text too long",
                          });
                        }
                        setInspectionPoint({
                          ...inspectionPoint,
                          inspectionPointGuidance: {
                            ...inspectionPoint.inspectionPointGuidance,
                            text,
                          },
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <div className="d-flex col-12 px-0">
                  <Col className="col-6">
                    <FormGroup className="d-flex flex-column mb-0">
                      <Label for="inspectionPointGuidance">
                        <span>Guidance Attachment</span>
                      </Label>
                      <Input
                        multiple={true}
                        type="file"
                        accept="image/gif, image/jpeg, image/png, image/jpg"
                        name="inspectionPointGuidance"
                        onChange={(e) => {
                          if (!e.currentTarget.files.length) {
                            return setInspectionPoint({
                              ...inspectionPoint,
                              inspectionPointGuidance: {
                                contentUrl: "",
                                text: "",
                              },
                            });
                          }
                          const file = e.currentTarget.files[0];
                          if (file.size > MAX_FILE_SIZE) {
                            return setInformationModal({
                              isOpen: true,
                              title: "Inspection Point",
                              body: "File too large. Max 1MB.",
                            });
                          }
                          setInspectionPoint({
                            ...inspectionPoint,
                            inspectionPointGuidance: {
                              ...inspectionPoint.inspectionPointGuidance,
                              hasChangedFile: true,
                              fileName: file.name,
                              fileType: file.type,
                              contentUrl: URL.createObjectURL(file),
                            },
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {inspectionPoint.inspectionPointGuidance?.contentUrl ? (
                    <Col className="col-6">
                      <FormGroup className="d-flex flex-column mb-0">
                        <div className="d-flex justify-content-end">
                          <Button
                            style={{ transform: "translateX(10px)" }}
                            className="close"
                            color="none"
                            onClick={() => {
                              setInspectionPoint({
                                ...inspectionPoint,
                                inspectionPointGuidance: {
                                  ...inspectionPoint.inspectionPointGuidance,
                                  contentUrl: "",
                                },
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} size="xs" />
                          </Button>
                        </div>
                        <img
                          className="rounded border"
                          alt={"Attachment"}
                          src={
                            inspectionPoint.inspectionPointGuidance.contentUrl
                          }
                        />
                      </FormGroup>
                    </Col>
                  ) : null}
                </div>
              </>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default InspectionPointModal;
