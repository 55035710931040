import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Input,
  Form,
} from "reactstrap";
import Loader from "../../../../Loader";
import InformationModal from "../../../../InformationModal";
import { useEffect } from "react";
import { workOrdersApi } from "../../../../../services/workOrdersServices";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../../../providers/workOrderDetailsProvider";

const MaterialNoteFormModal = ({
  openModal,
  setOpenModal,
  workOrderMaterialsForm,
  employeeWorkDayId,
}) => {
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });
  const [loading, setLoading] = useState();
  const [materialNoteState, setMaterialNoteState] = useState({ note: "" });
  const [, setWorkOrderDetailsContext] = useWorkOrderDetails();

  useEffect(() => {
    setMaterialNoteState({ note: workOrderMaterialsForm.notes });
  }, [workOrderMaterialsForm]);

  const onClose = () => {
    setOpenModal(false);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await workOrdersApi.updateWorkOrderMaterialNote({
        id: workOrderMaterialsForm.id,
        workOrderCraneId: workOrderMaterialsForm.workOrderCraneId,
        notes: materialNoteState.note,
        employeeWorkDayId: employeeWorkDayId[0].id,
      });
      setOpenModal(false);
      setInformationModal({
        isOpen: true,
        title: "Material update",
        body: `The material was successfully updated`,
        onClose: () => {
          setLoading(false);
          setWorkOrderDetailsContext({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <Modal isOpen={openModal} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column align-items-start">
          <span>Edit Service Note</span>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <Input
            type="textarea"
            placeholder="Enter the note"
            required={true}
            maxLength={255}
            value={materialNoteState.note}
            onChange={(event) => {
              setMaterialNoteState({
                note: event.target.value,
              });
            }}
          />
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"primary"} onClick={onClose}>
            Discard
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"warning"} type="submit">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default MaterialNoteFormModal;
