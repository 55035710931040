import axios from "axios";
import config from "../config/config";

export const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const serviceNotesApi = {
  createWorkOrderServiceNote: (data) => {
    return axios(`/api/work-order-service-notes`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateWorkOrderServiceNote: (data) => {
    return axios(`/api/work-order-service-notes/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
