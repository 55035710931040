import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const payrollWeekApprovalApi = {
  getPayrollWeekApproval: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`/api/payroll-week-approval?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  approvePayrollWeek: (data) => {
    return axios(`/api/payroll-week-approval`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  undoPayrollWeekApproval: (data) => {
    return axios(`/api/payroll-week-approval/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
