import React, { useEffect, useState } from "react";

import { FormGroup } from "reactstrap";
import { employeesApi } from "../../../services/employeeServices";
import Label from "reactstrap/lib/Label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faToolbox,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import InformationModal from "../../InformationModal";
import TooltipItem from "../../TooltipItem";
import {
  useScheduleModalTechnicians,
  ACTIONS as useScheduleModalTechniciansActions,
} from "../../../providers/scheduleModalTechniciansProvider";
import { useAuth } from "../../../providers/authProvider";
import SelectWrapper from "../../SelectWrapper";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";

const PAGE_SIZE = 30;
const ROLE_TECHNICIAN = 3;

const MODE_LEAD = 1;
const MODE_DEFAULT = 2;
const MODE_NEW = 3;

const TechSelector = ({ defaultEmployee, mode }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [authContext] = useAuth();

  const serviceLocationId =
    workOrderDetails.workOrder.serviceLocationId ||
    authContext.serviceLocationId;

  const [scheduleModalTechniciansContext, setScheduleModalTechniciansContext] =
    useScheduleModalTechnicians();

  const [employee, setEmployee] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if (defaultEmployee) {
      setEmployee(defaultEmployee);
    } else {
      setEmployee(null);
    }
  }, [defaultEmployee]);

  const onEmployeeChange = (employee) => {
    if (employee) {
      employee.isLead = mode === MODE_LEAD;
      setScheduleModalTechniciansContext({
        action: useScheduleModalTechniciansActions.ON_SELECT_EMPLOYEE,
        payload: { employee, previousEmployee: defaultEmployee },
      });
    } else {
      setScheduleModalTechniciansContext({
        action: useScheduleModalTechniciansActions.ON_SELECT_EMPLOYEE,
        payload: { previousEmployee: defaultEmployee },
      });
    }
  };

  const isDifferentServiceLocation =
    defaultEmployee &&
    !defaultEmployee?.serviceLocations?.find(
      (sl) => sl.id === serviceLocationId
    );

  return (
    <FormGroup className="d-flex justify-content-between align-items-center">
      <Label
        className="text-left col-4 mb-0 d-flex align-items-center"
        for="name"
      >
        <FontAwesomeIcon
          icon={mode === MODE_LEAD ? faToolbox : faTools}
          className={`mr-2 text-${
            mode === MODE_LEAD ? "primary" : "secondary"
          }`}
          style={{ paddingBottom: "1px" }}
        />
        <div className="d-flex align-items-center">
          {mode === MODE_LEAD
            ? "Lead"
            : mode === MODE_DEFAULT
            ? "Technician"
            : mode === MODE_NEW
            ? "New"
            : ""}
          {isDifferentServiceLocation ? (
            <TooltipItem
              id={defaultEmployee.id}
              title={"Different Service Location"}
            >
              <FontAwesomeIcon
                size="sm"
                icon={faExclamationCircle}
                className={`ml-2 text-warning`}
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          ) : null}
        </div>
      </Label>
      <SelectWrapper
        key={`${serviceLocationId}-${scheduleModalTechniciansContext.techShare}`}
        entity="employee"
        filterItemFunction={(eop) =>
          !scheduleModalTechniciansContext.employees.find(
            (se) => se.id === eop.id
          ) && //if inactive, only remain the selected one
          (eop.isActive ||
            (defaultEmployee && !eop.isActive && eop.id === defaultEmployee.id))
        }
        formatItemFunction={(e) => {
          const employeeName = e.name || `${e.firstName} ${e.lastName}`;
          const status = !e.isActive ? " (Inactive)" : "";
          return {
            label: `${employeeName}${status}`,
            value: e.id,
          };
        }}
        fetchFunction={employeesApi.getEmployees}
        fetchParameters={{
          roleId: ROLE_TECHNICIAN,
          pageSize: PAGE_SIZE,
          serviceLocationId,
          techShare: scheduleModalTechniciansContext.techShare,
          sortBy: "firstName",
        }}
        defaultSelected={employee}
        onSelected={onEmployeeChange}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </FormGroup>
  );
};

export default TechSelector;
