import React, { useEffect, useState } from "react";

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table, Card, Col, CardHeader, CardBody } from "reactstrap";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import { useCraneConfigModal } from "../../../../providers/craneConfigModalProvider";
import CraneConfigCreateHoistModal from "./CraneConfigCreateHoistModal";
import { cranesApi } from "../../../../services/craneServices";
import { faTrashAlt, faInfo } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../../ConfirmationModal";
import TooltipItem from "../../../TooltipItem";

const HOIST_ONLY = "HOIST_ONLY";

const CraneConfigHoistsModal = ({
  craneHoists,
  setCraneHoists,
  craneStructure,
  deletedHoists,
  setDeletedHoists,
}) => {
  const [craneConfigModalContext] = useCraneConfigModal();

  const [hoistTypes, setHoistTypes] = useState([]);
  const [hoistManufacturers, setHoistManufacturers] = useState([]);
  const [hoistCapacities, setHoistCapacities] = useState([]);

  const [refreshManufacturers, setRefreshManufacturers] = useState(false);
  const [refreshCapacities, setRefreshCapacities] = useState(false);

  const [hoistToEdit, setHoistToEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [creationMode, setCreationMode] = useState();

  useEffect(() => {
    if (craneConfigModalContext.crane?.craneHoists) {
      setCraneHoists([...craneConfigModalContext.crane.craneHoists]);
    }
  }, [craneConfigModalContext.crane, setCraneHoists]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [hoistTypes, manufacturers, capacities] = await Promise.all([
          cranesApi.getHoistTypes({}),
          cranesApi.getManufacturers({}),
          cranesApi.getCapacities({}),
        ]);
        setHoistTypes(hoistTypes);
        setHoistManufacturers(manufacturers);
        setHoistCapacities(capacities);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchManufacturers = async () => {
      setLoading(true);
      const manufacturers = await cranesApi.getManufacturers({});
      setHoistManufacturers(manufacturers);
      setLoading(false);
    };
    if (refreshManufacturers) {
      fetchManufacturers();
      setRefreshManufacturers(false);
    }
  }, [refreshManufacturers]);

  useEffect(() => {
    const fetchCapacities = async () => {
      setLoading(true);
      const capacities = await cranesApi.getCapacities({});
      setHoistCapacities(capacities);
      setLoading(false);
    };
    if (refreshCapacities) {
      fetchCapacities();
      setRefreshCapacities(false);
    }
  }, [refreshCapacities]);

  const onClickEditHoist = (craneHoist) => {
    setHoistToEdit(craneHoist);
    setCreationMode(true);
  };

  const onCloseHoistModal = () => {
    setHoistToEdit(null);
    setCreationMode(false);
  };

  const onDelete = (craneHoist) => {
    if (craneConfigModalContext.crane?.id) {
      const isHoistOnly =
        !craneStructure || craneStructure.craneStructureTypeId === HOIST_ONLY;
      if (isHoistOnly && craneHoists?.length === 1) {
        return setInformationModal({
          isOpen: true,
          title: "Crane Hoists",
          body: "Hoist only crane must have at least one hoist.",
        });
      }
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        try {
          if (craneConfigModalContext.crane?.id) {
            setDeletedHoists([...deletedHoists, craneHoist]);
          }
          const hoists = [...craneHoists];
          if (craneConfigModalContext.crane?.id) {
            hoists.splice(
              hoists.findIndex((h) => h.id === craneHoist.id),
              1
            );
          } else {
            hoists.splice(
              hoists.findIndex((h) => h === craneHoist),
              1
            );
          }
          setCraneHoists(hoists);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Remove Crane Hoist",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Remove Crane Hoist",
      body: `
            <span class="text-center">
              Do you confirm you want to remove this crane hoist?
            </span>
          `,
      confirmColor: "warning",
    });
  };

  const onAddHoist = (craneHoist) => {
    setCreationMode(false);
    setCraneHoists([...craneHoists, craneHoist]);
  };

  const onEditHoist = (craneHoist) => {
    setCreationMode(false);
    setHoistToEdit(null);
    const newCraneHoists = craneHoists.map((hoist) =>
      hoist.id === craneHoist.id ? craneHoist : hoist
    );
    setCraneHoists(newCraneHoists);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + "...";
    }
    return text;
  };

  return (
    <>
      <Col className="flex-grow-1">
        <Card className="py-2">
          <CardHeader className="d-flex justify-content-between">
            <div className="mr-3 flex-grow-1">
              <div className="h4">
                <span>Hoists</span>
              </div>
            </div>
          </CardHeader>
          <CardBody className="pb-1 overflow-x-auto">
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Table className="col-12 px-0 border rounded mb-0 small">
                <thead>
                  <tr className="bg-lighter">
                    <th>Type</th>
                    <th>With Trolley</th>
                    <th>Manufacturer</th>
                    <th>Capacity</th>
                    <th>Model</th>
                    <th>Serial #</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {craneHoists.map((craneHoist, i) => (
                    <tr key={i}>
                      <td>
                        {craneHoist.craneHoistTypeName ||
                          craneHoist.craneHoistType.name}
                      </td>
                      <td>{craneHoist.withTrolley ? "Yes" : "No"}</td>
                      <td>
                        {craneHoist.manufacturerName ||
                          craneHoist.manufacturer.name}
                      </td>
                      <td>
                        {craneHoist.capacityName || craneHoist.capacity.name}
                      </td>
                      <td style={{ display: "block" }}>
                        {truncateText(craneHoist.model, 20)}
                        {craneHoist.model.length > 20 && (
                          <TooltipItem title={craneHoist.model}>
                            <FontAwesomeIcon
                              icon={faInfo}
                              className="ml-2 cursor-pointer text-primary"
                            />
                          </TooltipItem>
                        )}
                      </td>
                      <td>
                        {truncateText(craneHoist.serialNumber, 20)}
                        {craneHoist.serialNumber.length > 20 && (
                          <TooltipItem title={craneHoist.serialNumber}>
                            <FontAwesomeIcon
                              icon={faInfo}
                              className="ml-2 cursor-pointer text-primary"
                            />
                          </TooltipItem>
                        )}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faPen}
                          className="cursor-pointer text-warning mr-2"
                          onClick={() => onClickEditHoist(craneHoist)}
                        />
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="cursor-pointer text-danger"
                          onClick={() => onDelete(craneHoist)}
                        />
                      </td>
                    </tr>
                  ))}
                  {!craneHoists.length ? (
                    <tr>
                      <td colSpan={7}>No hoists added</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td colSpan={7}>
                      <Button
                        size="sm"
                        className="rounded"
                        color="success"
                        onClick={() => setCreationMode(true)}
                      >
                        Add Hoist
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : creationMode ? (
        <CraneConfigCreateHoistModal
          hoistToEdit={hoistToEdit}
          hoistTypes={hoistTypes}
          hoistManufacturers={hoistManufacturers}
          setRefreshManufacturers={setRefreshManufacturers}
          setRefreshCapacities={setRefreshCapacities}
          hoistCapacities={hoistCapacities}
          onClose={() => onCloseHoistModal()}
          onSubmit={(craneHoist) =>
            !hoistToEdit ? onAddHoist(craneHoist) : onEditHoist(craneHoist)
          }
        />
      ) : null}
    </>
  );
};

export default CraneConfigHoistsModal;
