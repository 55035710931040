import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const customersApi = {
  mergeLocations: (data) => {
    return axios(`/api/customer-locations/merge/${data.primary}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCustomers: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id ? `/api/customers/${data.id}` : `/api/customers?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCustomerLocations: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id
        ? `/api/customer-locations/${data.id}`
        : `/api/customer-locations?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateLocationCustomerContacts: (data) => {
    return axios(`/api/customer-contacts/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCustomerById: (customerId) => {
    return axios(`/api/customers/${customerId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  saveCustomer: (data) => {
    return axios(`/api/customers`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateCustomer: (data) => {
    return axios(`/api/customers/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateCustomerShowLocationName: (data) => {
    return axios(`/api/customers/showLocationName/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteCustomer: (data) => {
    return axios(`/api/customers/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
