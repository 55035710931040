import { handleError } from "./craneServices";
import axios from "axios";
import config from "../config/config";

export const awsApi = {
  signDocuments: (data) => {
    return axios(`/api/aws/sign`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  putDocumentsToS3: (data) => {
    return fetch(data.url, {
      method: "PUT",
      body: data.file,
      mode: "cors",
    });
  },
};
