import React, { useEffect, useState } from "react";

import {
  ListGroupItem,
  Col,
  Card,
  CardHeader,
  CardBody,
  CustomInput,
  Row,
} from "reactstrap";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import { useCraneConfigModal } from "../../../../providers/craneConfigModalProvider";
import { cranesApi } from "../../../../services/craneServices";
import CraneConfigPhotosModal from "./CraneConfigPhotosModal";

const CraneConfigControlsModal = ({
  craneControls,
  setCraneControls,
  runway,
  setRunway,
  photos,
  setPhotos,
  deletedPhotos,
  setDeletedPhotos,
}) => {
  const [craneConfigModalContext] = useCraneConfigModal();

  const [controls, setControls] = useState([]);

  const [loading, setLoading] = useState(false);
  const [controlsLoading, setControlsLoading] = useState({});
  const [runwayLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const controls = await cranesApi.getControls({});
      setControls(controls);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (craneConfigModalContext.crane?.craneControls) {
      setCraneControls([...craneConfigModalContext.crane.craneControls]);
    }
  }, [craneConfigModalContext.crane, setCraneControls]);

  const onControlChange = async (controlId, checked) => {
    setControlsLoading({ [controlId]: true });
    const craneControl = {
      controlId,
      craneId: craneConfigModalContext.crane
        ? craneConfigModalContext.crane.id
        : null,
    };
    if (checked) {
      setCraneControls([...craneControls, craneControl]);
    } else {
      const craneControl = craneControls.find(
        (cc) => cc.controlId === controlId
      );
      if (
        craneControl &&
        craneConfigModalContext.crane?.id &&
        craneControl.id
      ) {
        await cranesApi.deleteCraneControl({
          id: craneControl.id,
        });
      }
      const controls = [...craneControls];
      controls.splice(
        controls.findIndex((cc) => cc.controlId === controlId),
        1
      );
      setCraneControls(controls);
    }
    setControlsLoading({ [controlId]: false });
  };

  const onRunwayChange = async (value) => {
    setRunway(value);
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Col className="flex-grow-1">
      <Card className="py-2">
        <CardHeader className="d-flex justify-content-between">
          <div className="mr-3 flex-grow-1">
            <div className="h4">
              <span>Properties & Photos</span>
            </div>
          </div>
        </CardHeader>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <CardBody className="pb-1 overflow-x-auto">
            <Row className="no-gutters">
              <Col className="px-1" style={{ maxWidth: 270 }}>
                <Card className="py-2" style={{ width: 250 }}>
                  <CardHeader className="d-flex justify-content-between">
                    <div className="mr-3 flex-grow-1">
                      <div className="h4">
                        <span>Controls</span>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pb-1 overflow-x-auto">
                    {controls.map((control) => {
                      const checked = Boolean(
                        craneControls.find((cc) => cc.controlId === control.id)
                      );
                      return (
                        <ListGroupItem key={control.id}>
                          {controlsLoading[control.id] ? (
                            <Loader size="sm" align="start" />
                          ) : (
                            <CustomInput
                              type="switch"
                              id={`control_${control.id}`}
                              label={control.name}
                              checked={checked}
                              onChange={() =>
                                onControlChange(control.id, !checked)
                              }
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </ListGroupItem>
                      );
                    })}
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-1" style={{ maxWidth: 270 }}>
                <Card className="py-2" style={{ width: 250 }}>
                  <CardHeader className="d-flex justify-content-between">
                    <div className="mr-3 flex-grow-1">
                      <div className="h4">
                        <span>Runway</span>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pb-1 overflow-x-auto">
                    {runwayLoading ? (
                      <Loader size="sm" align="end" />
                    ) : (
                      <div className="d-flex flex-column align-items-start">
                        <label className="radio-container">
                          <input
                            type="radio"
                            name="runway"
                            checked={runway}
                            onChange={() => onRunwayChange(true)}
                            style={{ marginTop: "30px" }}
                          />
                          <span
                            className="checkmark ml-2"
                            style={{ marginBottom: "30px" }}
                          >
                            Yes
                          </span>
                        </label>
                        <label className="radio-container">
                          <input
                            type="radio"
                            name="runway"
                            checked={!runway}
                            onChange={() => onRunwayChange(false)}
                            style={{ marginTop: "30px" }}
                          />
                          <span className="checkmark ml-2">No</span>
                        </label>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-1">
                <Card className="py-2">
                  <CardHeader className="d-flex justify-content-between">
                    <div className="mr-3 flex-grow-1">
                      <div className="h4">
                        <span>Photos</span>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pb-1 overflow-x-auto">
                    <CraneConfigPhotosModal
                      photos={photos}
                      setPhotos={setPhotos}
                      deletedPhotos={deletedPhotos}
                      setDeletedPhotos={setDeletedPhotos}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </Col>
  );
};

export default CraneConfigControlsModal;
