import React from "react";
import TopRunningBridge from "../../../assets/img/crane_icons/002a74d7-1647-11ec-a23f-12f832d7c600.svg";
import UnderRunningBridge from "../../../assets/img/crane_icons/012a74d7-1647-11ec-a23f-12f832d7c601.svg";
import Jib from "../../../assets/img/crane_icons/022a74d7-1647-11ec-a23f-12f832d7c602.svg";
import Gantry from "../../../assets/img/crane_icons/032a74d7-1647-11ec-a23f-12f832d7c603.svg";
import SemiGantry from "../../../assets/img/crane_icons/semi-gantry.svg";
import HoistOnly from "../../../assets/img/crane_icons/null.svg";
import Monorail from "../../../assets/img/crane_icons/monorail.svg";

const TOP_RUNNING_BRIDGE = "002a74d7-1647-11ec-a23f-12f832d7c600";
const UNDER_RUNNING_BRIDGE = "012a74d7-1647-11ec-a23f-12f832d7c601";
const JIB = "022a74d7-1647-11ec-a23f-12f832d7c602";
const GANTRY = "032a74d7-1647-11ec-a23f-12f832d7c603";
const SEMI_GANTRY = "042a74d7-1647-11ec-a23f-12f832d7c604";
const MONORAIL = "052a74d7-1647-11ec-a23f-12f832d7c605";

const CraneName = ({ crane }) => {
  const renderCraneIcon = () => {
    switch (crane.craneStructure?.craneStructureTypeId) {
      case TOP_RUNNING_BRIDGE:
        return (
          <img
            style={{ height: "auto", width: "23px" }}
            src={TopRunningBridge}
            alt="crane"
          />
        );
      case UNDER_RUNNING_BRIDGE:
        return (
          <img
            style={{ height: "auto", width: "23px" }}
            src={UnderRunningBridge}
            alt="crane"
          />
        );
      case JIB:
        return (
          <img
            style={{ height: "auto", width: "23px" }}
            src={Jib}
            alt="crane"
          />
        );
      case GANTRY:
        return (
          <img
            style={{ height: "auto", width: "23px" }}
            src={Gantry}
            alt="crane"
          />
        );
      case SEMI_GANTRY:
        return (
          <img
            style={{ height: "auto", width: "23px" }}
            src={SemiGantry}
            alt="crane"
          />
        );
      case MONORAIL:
        return (
          <img
            style={{ height: "auto", width: "23px" }}
            src={Monorail}
            alt="crane"
          />
        );
      default:
        return (
          <img
            style={{ height: "auto", width: "23px" }}
            src={HoistOnly}
            alt="crane"
          />
        );
    }
  };

  return (
    <div className="d-flex align-items-center">
      {renderCraneIcon()}
      <span className="ml-2">{crane.ContactCode || "D# Not Set"}</span>
    </div>
  );
};

export default CraneName;
