import { Input, ListGroup, ListGroupItem } from "reactstrap";
import Loader from "../../../../../Loader";

const NoteBody = ({ answerNotes, setCraneInspectionAnswerNote, loading }) => {
  if (loading) {
    return <Loader size="sm" />;
  }

  return (
    <ListGroup>
      <ListGroupItem
        className="justify-content-center bg-lighter d-flex align-items-center"
        tag="div"
      >
        Notes
      </ListGroupItem>
      {answerNotes.length ? (
        answerNotes.map((note) => (
          <ListGroupItem
            key={note.id}
            className="d-flex justify-content-between"
            tag="div"
          >
            <Input
              type="textarea"
              placeholder="Enter the note"
              maxLength={255}
              value={
                answerNotes.find((item) => item.id === note.id)
                  ? answerNotes.find((item) => item.id === note.id).content
                  : note.content
              }
              bsSize="sm"
              onChange={(event) => {
                const updatedNotes = answerNotes.map((noteItem) => {
                  if (noteItem.id === note.id) {
                    return { ...noteItem, content: event.target.value };
                  }
                  return noteItem;
                });
                setCraneInspectionAnswerNote(updatedNotes);
              }}
            />
          </ListGroupItem>
        ))
      ) : (
        <ListGroupItem className="d-flex justify-content-between" tag="div">
          <small className="text-muted">No notes to show</small>
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

export default NoteBody;
