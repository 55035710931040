import React, { useState } from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";

import InformationModal from "../../../InformationModal";
import EditAttachmentsModal from "./Edition/EditAttachmentsModal";

const Attachments = ({ attachments = [], employeeWorkDayId }) => {
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const openImageModal = (url, label) => {
    setInformationModal({
      isOpen: true,
      title: "Photo Preview",
      rawBody: true,
      body: (
        <div className="d-flex flex-column text-center">
          <img className="img-fluid rounded" src={url} alt={label} />
          {label ? <span className="mt-2">{label}</span> : null}
        </div>
      ),
    });
  };

  function compareDates(a, b) {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateA - dateB;
  }

  return (
    <ListGroup className="my-3 col-12 px-0">
      <ListGroupItem
        className="d-flex justify-content-between align-items-center bg-lighter font-500"
        tag="div"
      >
        Attachments
        {attachments.length ? (
          <EditAttachmentsModal
            attachments={attachments.sort(compareDates)}
            employeeWorkDayId={employeeWorkDayId}
          />
        ) : null}
      </ListGroupItem>
      {attachments.length ? (
        <ListGroupItem
          className="d-flex flex-wrap justify-content-start mt-2 border-top"
          tag="div"
        >
          {attachments.sort(compareDates).map((attachment) => (
            <div
              key={attachment.id}
              className="rounded figure img-thumbnail cursor-pointer m-2 d-flex justify-content-end align-items-end"
              onClick={() =>
                openImageModal(attachment.contentUrl, attachment.label)
              }
              style={{
                backgroundPosition: "center",
                backgroundImage: `url(${attachment.contentUrl})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
                height: "160px",
                width: "160px",
              }}
            >
              <div className="d-flex flex-column justify-content-end align-items-end">
                {attachment.label ? (
                  <Badge
                    className="m-1 text-truncate"
                    style={{ maxWidth: "130px" }}
                  >
                    <small>{attachment.label}</small>
                  </Badge>
                ) : null}
                <Badge
                  className="m-1 pt-0 text-truncate"
                  style={{ maxWidth: "140px" }}
                >
                  {attachment.author ? (
                    <small>
                      By{" "}
                      {`${attachment.author.firstName} ${attachment.author.lastName}`}
                    </small>
                  ) : null}
                </Badge>
              </div>
            </div>
          ))}
        </ListGroupItem>
      ) : (
        <ListGroupItem
          className="d-flex justify-content-between align-items-center"
          tag="div"
        >
          <small className="text-muted">No attachments to show</small>
        </ListGroupItem>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </ListGroup>
  );
};

export default Attachments;
