import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Badge,
  CardFooter,
} from "reactstrap";

import ConfirmationModal from "../../../components/ConfirmationModal";
import InformationModal from "../../../components/InformationModal";

import { useAuth } from "../../../providers/authProvider";

import { ACTIONS, useHolidays } from "../../../providers/holidaysProvider";

import { holidaysApi } from "../../../services/holidayServices";

import moment from "moment";

import {
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTablePagination from "../../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../../components/advanceTable/AdvanceTable";
import HolidayModal from "../../../components/HolidayModal";

const columns = (onEdit, onDelete) => [
  {
    accessor: "name",
    Header: "Holiday Name",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { name } = rowData.row.original;
      return name || "-";
    },
  },
  {
    accessor: "date",
    Header: "Date",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { date } = rowData.row.original;
      return <Badge>{moment(date).format("MM-DD-YYYY")}</Badge>;
    },
  },
  {
    accessor: "id",
    Header: "",
    disableSortBy: true,
    cellProps: {
      className: "text-right",
    },
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        <Button
          size="sm"
          className="rounded"
          color="warning"
          onClick={() => onEdit(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faPen} className="mr-1" />
          <span>Edit</span>
        </Button>{" "}
        <Button
          size="sm"
          className="rounded ml-2"
          color="danger"
          onClick={() => onDelete(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
          <span>Delete</span>
        </Button>
      </div>
    ),
  },
];

const PayrollManagement = () => {
  const [holidaysContext, setHolidaysContext] = useHolidays();
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(true);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createHolidayModal, setCreateHolidayModal] = useState(false);

  const [editHolidayModal, setEditHolidayModal] = useState(false);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          holidaysContext.sortBy === sortBy?.id &&
          holidaysContext.direction === direction
        ) {
          return;
        }
        setHolidaysContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setHolidaysContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [holidaysContext.direction, holidaysContext.sortBy, setHolidaysContext]
  );

  const onEdit = (holiday) => setEditHolidayModal(holiday.id);

  const onDelete = (holiday) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        holidaysApi
          .deleteHoliday({
            id: holiday.id,
          })
          .finally(() => {
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Delete Holiday",
              body: "Holiday deleted successfully",
            });
            setHolidaysContext({
              action: ACTIONS.REFRESH,
            });
          })
          .catch((error) => {
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Delete Holiday",
              body:
                error?.response?.data[0].msg ||
                "There was an error with your request.",
            });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setHolidaysContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Delete Holiday",
      body: `<p class="text-center">Are you sure you want to delete this holiday?</p>
        <span>${holiday.name} - ${holiday.date}</span>`,
      confirmColor: "danger",
    });
  };

  useEffect(() => {
    setLoading(true);
    holidaysApi
      .getHolidays({
        page: holidaysContext.page - 1,
        pageSize: holidaysContext.sizePerPage,
        sortBy: holidaysContext.sortBy,
        direction: holidaysContext.direction,
      })
      .then((holidays) => {
        setLoading(false);
        setHolidaysContext({
          action: ACTIONS.GET_HOLIDAYS_SUCCESS,
          payload: { holidays },
        });
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setHolidaysContext,
    holidaysContext.sortBy,
    holidaysContext.direction,
    holidaysContext.sizePerPage,
    holidaysContext.page,
    holidaysContext.search,
    holidaysContext.refresh,
    authContext.serviceLocationId,
  ]);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(onEdit, onDelete)}
          data={holidaysContext.holidays?.data || []}
          pageSize={holidaysContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: holidaysContext.sortBy,
            direction: holidaysContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Payroll Management</h3>
                <small className="text-muted ml-2 pt-1">
                  ({holidaysContext.holidays.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={holidaysContext.search}
                    onChange={(evt) =>
                      setHolidaysContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-secondary text-white border-left-0 border-secondary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={() => setCreateHolidayModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button ml-3"
                  color="secondary"
                  onClick={() =>
                    setHolidaysContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {holidaysContext.holidays.data?.length ? (
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={holidaysContext.holidays.count}
                pageCount={holidaysContext.holidays.totalPages}
                currentPage={holidaysContext.page - 1}
                onPageChange={(page) =>
                  setHolidaysContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={holidaysContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setHolidaysContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createHolidayModal ? (
        <HolidayModal
          onClose={() => {
            setCreateHolidayModal(false);
          }}
          onSubmit={() => {
            setCreateHolidayModal(false);
            setInformationModal({
              isOpen: true,
              title: "Create Holiday",
              body: "Holiday created successfully.",
            });
            setHolidaysContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : editHolidayModal ? (
        <HolidayModal
          holidayId={editHolidayModal}
          onClose={() => {
            setEditHolidayModal(false);
          }}
          onSubmit={() => {
            setEditHolidayModal(false);
            setInformationModal({
              isOpen: true,
              title: "Update Holiday",
              body: "Holiday Updated Successfully.",
            });
            setHolidaysContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default PayrollManagement;
