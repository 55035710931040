import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../providers/authProvider";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { utils } from "../../../utils/utils";
import { scheduleApi } from "../../../services/scheduleServices";
import ScheduleHeader from "../../../components/admin/calendar/ScheduleHeader";
import InformationModal from "../../../components/InformationModal";
import Loader from "../../../components/Loader";
import {
  ACTIONS,
  MODES,
  useScheduleModal,
} from "../../../providers/scheduleModalProvider";
import EditScheduleModal from "../../../components/admin/workOrders/EditScheduleModal";
import BlockDaysOffModal from "../../../components/admin/calendar/BlockDaysOffModal";
import { useNavigate } from "react-router-dom";
import { workOrdersApi } from "../../../services/workOrdersServices";
import { STATUSES } from "../../../providers/workOrderDetailsProvider";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import tippy from "tippy.js";
import { useWorkOrderStatus } from "../../../providers/workOrderStatusProvider";

const moment = extendMoment(Moment);

const REASON_LABELS = {
  OFF_DAY: "Off Day",
  PTO: "PTO",
  WEEKENDS_OFF: "Weekends Off",
  ON_CALL: "On Call",
  OTHER: "Other",
};

const COLOR_GRAY = "#333";
const COLOR_BLUE = "#3B7DDD";
const COLOR_PINK = "#F8C8DC";
const COLOR_PURPLE = "#C3B1E1";
const COLOR_RED = "#dc3545";
const COLOR_GREEN = "#3b8c6b";
const COLOR_YELLOW = "#FDB914";
const COLOR_ORANGE = "#fd7e14";
const COLOR_LIGHT_GREY = "#818181";

const MODE_THIS_WEEK = "MODE_THIS_WEEK";
const MODE_THIS_WEEK_NEXT = "MODE_THIS_WEEK_NEXT";
const MODE_THIS_MONTH = "MODE_THIS_MONTH";

const WorkOrderDetails = ({ workOrderTrip, onSubmit, onClose }) => {
  const [, setScheduleModalContext] = useScheduleModal();
  const navigate = useNavigate();

  const [loading, setLoading] = useState();
  const [workOrder, setWorkOrder] = useState();

  const [dispatchNotes, setDispatchNotes] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const workOrder = await workOrdersApi.getWorkOrderById({
        id: workOrderTrip.workOrderId,
        excludeRelations: true,
      });
      setWorkOrder(workOrder);
      setDispatchNotes(workOrder.dispatchNotes);
      setLoading(false);
    };
    fetchData();
  }, [workOrderTrip.workOrderId]);

  const onEditSchedule = (workOrder) => {
    const id = workOrder.workOrderId || workOrder.id;
    setScheduleModalContext({
      action: ACTIONS.INIT,
      payload: {
        mode: MODES.MODE_WORK_ORDER,
        currentData: { ...workOrder, id },
      },
    });
  };

  const onGoToDetails = (workOrder) =>
    navigate(`/work-orders/${workOrder.workOrderId || workOrder.id}/details`);

  const onSaveServiceNote = async () => {
    setLoading(true);
    try {
      await workOrdersApi.updateWorkOrder({
        ...workOrder,
        dispatchNotes,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Update Work Order",
        body: "Work order updated successfully.",
        onClose: onSubmit,
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Work Order Details",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => {
        onClose();
        if (informationModal.onClose) {
          informationModal.onClose();
        }
      }}
    >
      &times;
    </Button>
  );

  return (
    <>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() => {
            setInformationModal((prevState) => ({
              ...prevState,
              isOpen: false,
              title: "",
              body: "",
              onClose: prevState.onClose,
            }));
          }}
        />
      ) : (
        <Modal isOpen={true} onClosed={onClose} size={"md"}>
          <ModalHeader close={closeBtn}>Work Order Details</ModalHeader>
          <ModalBody>
            {loading || !workOrder ? (
              <Loader size="sm" />
            ) : (
              <ListGroup>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0">Work Order #: </div>
                  <div className="min-width-50 d-flex justify-content-end text-right">
                    {workOrder.jobNo || "-"}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0">Trip: </div>
                  <div className="min-width-50 d-flex justify-content-end text-right">
                    {workOrderTrip.tripNumber || "-"}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0">Status: </div>
                  <div className="min-width-50 d-flex justify-content-end text-right">
                    {workOrder.status?.name || "-"}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0">Customer Name: </div>
                  <div className="min-width-50 d-flex justify-content-end text-right">
                    {workOrder.customerName || "-"}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0">Service Requested: </div>
                  <div className="min-width-50 d-flex justify-content-end text-right">
                    {workOrder.svcCommentText
                      ? workOrder.svcCommentText
                      : workOrder.comment
                      ? workOrder.comment
                      : "-"}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0 d-flex flex-column">
                    <span>Dispatch Notes:</span>
                  </div>
                  <div className="min-width-50 d-flex flex-column">
                    <Input
                      type="textarea"
                      onChange={(evt) => {
                        setDispatchNotes(evt.currentTarget.value);
                      }}
                      value={dispatchNotes}
                    />
                    {loading ? (
                      <Loader size="sm" />
                    ) : (
                      <Button
                        className="rounded py-0 mt-2"
                        size="sm"
                        onClick={onSaveServiceNote}
                      >
                        Save Note
                      </Button>
                    )}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0">Location: </div>
                  <div className="min-width-50 d-flex justify-content-end text-right">
                    {workOrder.location || "-"}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0">Trip Dates: </div>
                  <div className="min-width-50 d-flex justify-content-end text-right">
                    {utils.formatDate(workOrderTrip.startDate, "MM/DD/YYYY")} to{" "}
                    {utils.formatDate(workOrderTrip.endDate, "MM/DD/YYYY")}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <div className="flex-shrink-0">Work Order Dates: </div>
                  <div className="min-width-50 d-flex justify-content-end text-right">
                    {utils.formatDate(workOrder.startDate, "MM/DD/YYYY")} to{" "}
                    {utils.formatDate(workOrder.endDate, "MM/DD/YYYY")}
                  </div>
                </ListGroupItem>
                <ListGroupItem className="d-flex flex-column justify-content-center align-items-center">
                  <Button
                    className="col-12"
                    onClick={() => {
                      onEditSchedule(workOrder);
                    }}
                  >
                    Assign Work Order
                  </Button>
                  <Button
                    color="info"
                    className="col-12 mt-2"
                    onClick={() => {
                      onGoToDetails(workOrder);
                    }}
                  >
                    Go to Work Order
                  </Button>
                </ListGroupItem>
              </ListGroup>
            )}
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button
              color={"primary"}
              onClick={() => {
                onClose();
                if (informationModal.onClose) {
                  informationModal.onClose();
                }
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const WorkOrderDetailsToolTip = ({ tooltipData }) => {
  return (
    <ListGroup className="text-body small" style={{ width: "100%" }}>
      {!tooltipData.isDayOff ? (
        <>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Work Order #: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {tooltipData.workOrderTrip.workOrder?.jobNo || "-"}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Trip: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {tooltipData.workOrderTrip.tripNumber || "-"}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Status: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {tooltipData.workOrderTrip.workOrder?.status?.name || "-"}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Customer Name: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {tooltipData.customerName || "-"}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Service Requested: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-start ml-2">
              {tooltipData.workOrderTrip.workOrder?.svcCommentText ||
                tooltipData.workOrderTrip.workOrder?.comment ||
                "-"}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Dispatch Notes: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {tooltipData.workOrderTrip.workOrder?.dispatchNotes || "-"}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Location: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {tooltipData.location || "-"}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Trip Dates: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {`${utils.formatDate(
                tooltipData.workOrderTrip.startDate,
                "MM/DD/YYYY"
              )} to ${utils.formatDate(
                tooltipData.workOrderTrip.endDate,
                "MM/DD/YYYY"
              )}`}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Work Order Dates: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {tooltipData.workOrderTrip.workOrder
                ? `${utils.formatDate(
                    tooltipData.workOrderTrip.workOrder.startDate,
                    "MM/DD/YYYY"
                  )} to ${utils.formatDate(
                    tooltipData.workOrderTrip.workOrder.endDate,
                    "MM/DD/YYYY"
                  )}`
                : "-"}
            </div>
          </ListGroupItem>
        </>
      ) : (
        <>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Employee: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {tooltipData.employeeName}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Reason: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {REASON_LABELS[tooltipData.reason]}
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 text-muted">Dates: </div>
            <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
              {`${utils.formatDate(
                tooltipData.startDate,
                "MM/DD/YYYY"
              )} to ${utils.formatDate(tooltipData.endDate, "MM/DD/YYYY")}`}
            </div>
          </ListGroupItem>
        </>
      )}
    </ListGroup>
  );
};

const Schedule = () => {
  const [authContext] = useAuth();
  const [scheduleModalContext] = useScheduleModal();

  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState(true);
  const [mode, setMode] = useState(MODE_THIS_MONTH);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [workOrderTripDetailsModal, setWorkOrderTripDetailsModal] = useState();
  const [workOrderStatusContext] = useWorkOrderStatus();
  const [blockDaysOffModal, setBlockDaysOffModal] = useState();

  const [dates, setDates] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const [selectedStatus, setSelectedStatus] = useState({
    jobType: null,
    statusId: null,
    color: COLOR_LIGHT_GREY,
    label: "Any Status",
  });
  const [data, setData] = useState([]);
  const calendarRef = useRef(null);

  const handleClickPrevious = () => {
    let substractAmount, unit;
    if (mode === MODE_THIS_WEEK) {
      substractAmount = 1;
      unit = "week";
    } else if (mode === MODE_THIS_WEEK_NEXT) {
      substractAmount = 2;
      unit = "week";
    } else {
      substractAmount = 1;
      unit = "month";
    }
    setDates((prevState) => ({
      start: moment(prevState.start)
        .subtract(substractAmount, unit)
        .startOf(unit === "week" ? "isoWeek" : unit)
        .format("YYYY-MM-DD"),
      end: moment(prevState.end)
        .subtract(substractAmount, unit)
        .endOf(unit === "week" ? "isoWeek" : unit)
        .format("YYYY-MM-DD"),
    }));
  };

  const handleClickNext = () => {
    let substractAmount, unit;
    if (mode === MODE_THIS_WEEK) {
      substractAmount = 1;
      unit = "week";
    } else if (mode === MODE_THIS_WEEK_NEXT) {
      substractAmount = 2;
      unit = "week";
    } else {
      substractAmount = 1;
      unit = "month";
    }
    setDates((prevState) => ({
      start: moment(prevState.start)
        .add(substractAmount, unit)
        .startOf(unit === "week" ? "isoWeek" : unit)
        .format("YYYY-MM-DD"),
      end: moment(prevState.end)
        .add(substractAmount, unit)
        .endOf(unit === "week" ? "isoWeek" : unit)
        .format("YYYY-MM-DD"),
    }));
  };

  const getAdjustedEndDate = (endDate) => {
    return moment(endDate).add(1, "days").format("YYYY-MM-DD");
  };

  const handleMode = useCallback(
    (e) => {
      setMode(e.currentTarget.value);
      let addAmount, unit;
      if (e.currentTarget.value === MODE_THIS_WEEK) {
        addAmount = 0;
        unit = "week";
      } else if (e.currentTarget.value === MODE_THIS_WEEK_NEXT) {
        addAmount = 1;
        unit = "week";
      } else {
        addAmount = 0;
        unit = "month";
      }
      const startOfUnit = moment().startOf(unit === "week" ? "isoWeek" : unit);
      const endOfUnit = moment()
        .add(addAmount, unit)
        .endOf(unit === "week" ? "isoWeek" : unit);
      setDates({
        start: startOfUnit.format("YYYY-MM-DD"),
        end: endOfUnit.format("YYYY-MM-DD"),
      });
    },
    [setMode, setDates]
  );

  const getSchedules = useCallback(async (parameters) => {
    setLoading(true);
    try {
      const chartData = await scheduleApi.getSchedules(parameters);
      setData(chartData);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(dates.start);
    }
  }, [dates]);

  useEffect(() => {
    const init = async () => {
      const parameters = {
        startDate: dates.start,
        endDate: dates.end,
      };
      if (selectedStatus.jobType) {
        parameters.jobType = selectedStatus.jobType;
      }
      if (selectedStatus.statusId) {
        parameters.statusId = selectedStatus.statusId;
      }
      if (authContext.serviceLocationId) {
        parameters.serviceLocationId = authContext.serviceLocationId;
      }
      await getSchedules(parameters);
    };

    init();
  }, [
    dates,
    authContext.serviceLocationId,
    refresh,
    selectedStatus,
    getSchedules,
  ]);

  const resourceLabelDidMount = (info) => {
    const backgroundColor = info.resource.extendedProps.backgroundColor;
    const color = info.resource.extendedProps.color;
    if (backgroundColor && color) {
      info.el.style.backgroundColor = backgroundColor;
      info.el.style.color = color;
    }
  };

  const GroupInfoToolTip = ({ data }) => {
    return (
      <ListGroup className="text-body small" style={{ width: "100%" }}>
        <ListGroupItem className="d-flex justify-content-center align-items-center">
          <div className="flex-shrink-0">Group Information</div>
        </ListGroupItem>
        <ListGroupItem className="d-flex justify-content-between align-items-center">
          <div className="flex-shrink-0 text-muted">Location: </div>
          <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
            {data?.serviceLocationName || "-"}
          </div>
        </ListGroupItem>
        <ListGroupItem className="d-flex justify-content-between align-items-center">
          <div className="flex-shrink-0 text-muted">Order: </div>
          <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
            {data?.order || "-"}
          </div>
        </ListGroupItem>
        <ListGroupItem className="d-flex justify-content-between align-items-center">
          <div className="flex-shrink-0 text-muted">Color: </div>
          <div className="min-width-50 d-flex ms-3 justify-content-end ml-2">
            <Badge
              style={{
                backgroundColor: data.backgroundColor,
                width: "1.5em",
                height: "1.5em",
                display: "inline-block",
              }}
            />
          </div>
        </ListGroupItem>
      </ListGroup>
    );
  };

  const resourceGroupLabelDidMount = (info) => {
    const group =
      info.groupValue &&
      data.resources.find((r) => r.group === info.groupValue);
    if (group.group !== "Without group") {
      const content = utils.renderComponentToNode(GroupInfoToolTip, {
        data: group,
      });
      tippy(info.el, {
        content: content,
        allowHTML: true,
        placement: "top",
        animation: "fade",
      });
    }
  };

  return (
    <Container fluid className="flex-grow-1 d-flex flex-column">
      <Header className="mb-3 ">
        <HeaderTitle className="d-flex flex-wrap justify-content-between">
          <span className="col-12 px-0 col-lg-6 text-dark">
            Work Orders Schedule
          </span>
          <div className="d-flex justify-content-end mt-2 mt-lg-0 col-12 col-lg-6 px-0"></div>
        </HeaderTitle>
      </Header>
      <Row className="flex-grow-1">
        <Col className="flex-grow-1 d-flex flex-column">
          <Card className="flex-grow-1 d-flex flex-column">
            <CardBody className="d-flex flex-column border rounded p-0 m-2 flex-grow-1">
              <ScheduleHeader
                mode={mode}
                handleMode={handleMode}
                currentDate={dates}
                onClickPrevious={handleClickPrevious}
                onClickNext={handleClickNext}
                onRefresh={() => setRefresh(!refresh)}
                selectedStatus={selectedStatus}
                loading={loading}
                resources={data?.resources?.length}
              />
              {!data || loading ? (
                <Loader />
              ) : data.events?.length === 0 && data.resources?.length === 0 ? (
                <span className="text-center text-muted">
                  There is no data to show
                </span>
              ) : (
                <div className="d-flex flex-column h-100">
                  <FullCalendar
                    ref={calendarRef}
                    height="100%"
                    schedulerLicenseKey={
                      process.env.REACT_APP_FULL_CALENDAR_KEY
                    }
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                      resourceTimelinePlugin,
                    ]}
                    loading={loading}
                    initialView="custom"
                    views={{
                      custom: {
                        type: "resourceTimeline",
                        buttonText: "Custom",
                        visibleRange: {
                          start: dates.start,
                          end: getAdjustedEndDate(dates.end),
                        },
                      },
                    }}
                    slotDuration={{ days: 1 }}
                    resourceAreaHeaderContent="Technicians"
                    resourceAreaWidth={200}
                    headerToolbar={{
                      right: false,
                      left: false,
                      center: "title",
                    }}
                    firstDay={1}
                    resources={data.resources || []}
                    events={data.events || []}
                    resourceOrder="title"
                    resourceGroupField="group"
                    slotLabelFormat={{
                      weekday: "short", // 'Sun'
                      day: "2-digit", // '21'
                      month: "short", // 'Apr'
                    }}
                    eventTimeFormat={{
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    }}
                    eventClick={(info) => {
                      if (!info.event.extendedProps.tooltipData.isDayOff) {
                        setWorkOrderTripDetailsModal(
                          info.event.extendedProps.tooltipData.workOrderTrip
                        );
                      } else {
                        setBlockDaysOffModal(
                          info.event.extendedProps.tooltipData
                        );
                      }
                    }}
                    resourceLabelDidMount={resourceLabelDidMount}
                    resourceGroupLabelDidMount={resourceGroupLabelDidMount}
                    eventDidMount={(info) => {
                      const content = utils.renderComponentToNode(
                        WorkOrderDetailsToolTip,
                        {
                          tooltipData: info.event.extendedProps.tooltipData,
                        }
                      );
                      tippy(info.el, {
                        content: content,
                        allowHTML: true,
                        placement: "top",
                        animation: "fade",
                      });
                    }}
                  />
                  <div className="py-2 mx-2 d-flex flex-column">
                    {!data || loading ? (
                      <Loader />
                    ) : (
                      <ListGroup className="small">
                        <ListGroupItem
                          className="justify-content-center bg-lighter py-2 d-flex align-items-center font-weight-bold border-bottom-0"
                          tag="div"
                        >
                          <span>Filter By Calendar Color Indicators</span>
                        </ListGroupItem>
                        <ListGroup horizontal>
                          {[
                            {
                              label: "Any Status",
                              color: COLOR_LIGHT_GREY,
                              jobType: null,
                            },
                            {
                              label: "Other",
                              color: COLOR_GRAY,
                              jobType: "Other",
                            },
                            {
                              label: "Days Off",
                              color: COLOR_BLUE,
                              jobType: "Days Off",
                            },
                            {
                              label: "Installation - Scheduled",
                              color: COLOR_PINK,
                              jobType: "Installation",
                              status: STATUSES.STATUS_SCHEDULED,
                            },
                            {
                              label: "Installation - In Progress",
                              color: COLOR_PURPLE,
                              jobType: "Installation",
                              status: STATUSES.STATUS_IN_PROGRESS,
                            },
                            {
                              label: "SC - Pending",
                              color: COLOR_RED,
                              jobType: "Service Call",
                              status: STATUSES.STATUS_PENDING,
                            },
                            {
                              label: "SC - In Progress",
                              color: COLOR_GREEN,
                              jobType: "Service Call",
                              status: STATUSES.STATUS_IN_PROGRESS,
                            },
                            {
                              label: "SC - Scheduled",
                              color: COLOR_YELLOW,
                              jobType: "Service Call",
                              status: STATUSES.STATUS_SCHEDULED,
                            },
                            {
                              label: "Inspection",
                              color: COLOR_ORANGE,
                              jobType: "Inspection",
                            },
                          ].map(({ label, color, jobType, status }) => (
                            <ListGroupItem
                              key={label}
                              className="text-white justify-content-center py-2 d-flex align-items-center font-weight-bold flex-grow-1"
                              tag="div"
                              style={{
                                backgroundColor: color,
                                cursor: "pointer",
                                fontSize: 11,
                              }}
                              onClick={() => {
                                setSelectedStatus({
                                  jobType,
                                  statusId:
                                    workOrderStatusContext?.workOrderStatus.find(
                                      (workOrderStatus) =>
                                        workOrderStatus.name === status
                                    )?.id,
                                  color,
                                  label,
                                });
                              }}
                            >
                              <span>{label}</span>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </ListGroup>
                    )}
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {scheduleModalContext.isOpen ? (
        <EditScheduleModal />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : blockDaysOffModal ? (
        <BlockDaysOffModal
          defaultData={blockDaysOffModal}
          onClose={() => {
            setBlockDaysOffModal();
          }}
          onSubmit={() => {
            setBlockDaysOffModal();
            setRefresh(!refresh);
          }}
        />
      ) : workOrderTripDetailsModal ? (
        <WorkOrderDetails
          workOrderTrip={workOrderTripDetailsModal}
          onClose={() => setWorkOrderTripDetailsModal(false)}
          onSubmit={() => {
            setRefresh(!refresh);
          }}
        />
      ) : null}
    </Container>
  );
};

export default Schedule;
