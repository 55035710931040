import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
  CustomInput,
} from "reactstrap";

import InformationModal from "../../../components/InformationModal";

import Loader from "../../../components/Loader";

import { useAuth } from "../../../providers/authProvider";
import { workOrdersApi } from "../../../services/workOrdersServices";
import { useNavigate, useParams } from "react-router-dom";

const NoLeadTrips = () => {
  const [authContext] = useAuth();
  const { workOrderId } = useParams();
  const [loading, setLoading] = useState(true);
  const [noLeadTrips, setNoLeadTrips] = useState([]);
  const [refresh, setRefresh] = useState();
  const navigate = useNavigate();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await workOrdersApi.getNoLeadWorkOrderTrips({
          serviceLocationId: authContext.serviceLocationId,
          workOrderId,
        });
        setNoLeadTrips(result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [refresh, authContext.serviceLocationId, workOrderId]);

  const onSave = async (workOrderEmployeeId, date) => {
    try {
      setLoading(true);
      await workOrdersApi.updateEmployeeWorkDayLead({
        workOrderEmployeeId,
        date,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Update",
        body: "Work day updated successfully.",
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          setRefresh(!refresh);
        },
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const onGoToWo = (workOrderId) =>
    navigate(`/work-orders/${workOrderId}/details`);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Trip dates without lead</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="mr-3 rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader />
          ) : noLeadTrips.length ? (
            <ListGroup flush className="border-top">
              <ListGroupItem
                className="py-2 border-bottom font-weight-bold bg-lighter text-muted small d-flex justify-content-between"
                tag="div"
              >
                <div className="col-2 text-left">Work Order</div>
                <div className="col-4">Service Location</div>
                <div className="col-2 text-center">Trip Number</div>
                <div className="col-2 text-center">Date</div>
                <div className="col-2">Lead</div>
              </ListGroupItem>
              {noLeadTrips.map((noLeadTrip, index) => (
                <ListGroupItem
                  key={index}
                  className="py-2 border-bottom d-flex justify-content-between align-items-center"
                  tag="div"
                >
                  <div className="col-2 text-left">
                    <span
                      onClick={() => onGoToWo(noLeadTrip.workOrder.id)}
                      className="cursor-pointer text-link"
                    >
                      {noLeadTrip.workOrder.jobNo}
                    </span>
                  </div>
                  <div className="col-4">
                    {noLeadTrip.workOrder.serviceLocation.name}
                  </div>
                  <div className="col-2 text-center">
                    {noLeadTrip.tripNumber}
                  </div>
                  <div className="col-2 text-center">
                    {moment(noLeadTrip.date).format("MM/DD/YYYY")}
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <CustomInput
                      id={`${noLeadTrip.id}-${noLeadTrip.date}`}
                      type="select"
                      className="flex-grow-1 border rounded"
                      name={`${noLeadTrip.id}-${noLeadTrip.date}`}
                      value={noLeadTrip.lead || ""}
                      onChange={(e) => {
                        const unsavedItem = noLeadTrips.find(
                          (trip) =>
                            trip.lead &&
                            trip.id !== noLeadTrip.id &&
                            trip.date !== noLeadTrip.date
                        );
                        if (unsavedItem) {
                          return setInformationModal({
                            isOpen: true,
                            title: "Update trip date lead",
                            body: "Save the previous change in order to apply more.",
                          });
                        }
                        noLeadTrip.lead = e.currentTarget.value;
                        const newNoLeadTrips = [...noLeadTrips];
                        newNoLeadTrips.splice(index, 1, { ...noLeadTrip });
                        setNoLeadTrips(newNoLeadTrips);
                      }}
                    >
                      <option value="">Select a lead...</option>
                      {noLeadTrip.workOrderEmployees
                        .sort((x, y) =>
                          x.employee.firstName < y.employee.firstName ? -1 : 1
                        )
                        .map((workOrderEmployee) => (
                          <option
                            key={workOrderEmployee.id}
                            value={workOrderEmployee.id}
                          >
                            {`${workOrderEmployee.employee.firstName} ${workOrderEmployee.employee.lastName}`}
                          </option>
                        ))}
                    </CustomInput>
                    {noLeadTrip.lead ? (
                      <Button
                        className="ml-2 rounded"
                        size="sm"
                        onClick={() => onSave(noLeadTrip.lead, noLeadTrip.date)}
                      >
                        Save
                      </Button>
                    ) : null}
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <div className="text-center">No trips to show</div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default NoLeadTrips;
