import {
  faChevronDown,
  faChevronRight,
  faImages,
  faPenSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Collapse, Badge, ListGroup, ListGroupItem } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { utils } from "../../../../../utils/utils";
import InformationModal from "../../../../InformationModal";
import ModalPhotosCarousel from "../../../ModalPhotosCarousel";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../../../providers/workOrderDetailsProvider";
import { cranesInspectionAttachmentApi } from "../../../../../services/craneInspectionAttachmentServices";
import ModalEdit from "../Edition/ModalEdit";
import NoteBody from "./Edition/NoteBody";
import EditPhotos from "./Edition/EditPhotosModal";
import EditAnswerModal from "./Edition/EditAnswerModal";

const INSPECTION_ANSWER_COLOR = {
  1: "success",
  2: "warning",
  3: "danger",
  4: "danger",
  5: "primary",
};

const TYPE_NOTE = "NOTE";
const TYPE_IMAGE = "IMAGE";

const CranesInspectedSections = ({ crane, craneInspection, sections }) => {
  const [workOrderDetails, setWorkOrderDetailsContext] = useWorkOrderDetails();

  const [openSections, setOpenSections] = useState({});
  const [showModalPhotosCarousel, setShowModalPhotosCarousel] = useState();

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const [noteModal, setNoteModal] = useState({
    isOpen: false,
    title: "",
  });

  const [showModalEditPhotos, setShowModalEditPhotos] = useState(false);
  const [craneInspectionAnswerPhotos, setCraneInspectionAnswerPhotos] =
    useState({
      photos: [],
      craneInspectionAnswerId: null,
    });
  const [craneInspectionAnswerNote, setCraneInspectionAnswerNote] = useState(
    []
  );
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      for (const craneInspectionAttachment of craneInspectionAnswerNote) {
        await cranesInspectionAttachmentApi.updateCraneInspectionAnswerAttachment(
          {
            id: craneInspectionAttachment.id,
            craneInspectionAnswerId:
              craneInspectionAttachment.craneInspectionAnswerId,
            content: craneInspectionAttachment.content,
            type: craneInspectionAttachment.type,
          }
        );
      }
      setNoteModal({
        isOpen: false,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Note update",
        body: `${
          craneInspectionAnswerNote.length > 1
            ? "The notes were successfully updated"
            : "The note was successfully updated"
        }`,
        onClose: () => {
          setWorkOrderDetailsContext({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (error) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  return (
    <>
      {sections.length
        ? sections
            .filter(
              (section) =>
                !section.parentSectionId &&
                (utils.sectionMeetsCondition(
                  craneInspection,
                  null,
                  section,
                  crane,
                  sections
                ) ||
                  utils.sectionHasAnswers(
                    craneInspection.inspectionAnswers,
                    section
                  ))
            )
            .map((section, i) => {
              if (section.iterableBy) {
                const iterators = crane[`${section.iterableBy}s`];
                const iteratedSections = iterators.map(
                  (iterableItem, index) => {
                    const childSection = sections.find(
                      (s) =>
                        parseInt(s.parentSectionId) === section.id &&
                        (utils.sectionMeetsCondition(
                          craneInspection,
                          index,
                          s,
                          crane,
                          sections
                        ) ||
                          utils.sectionHasAnswers(
                            craneInspection.inspectionAnswers,
                            s
                          ))
                    );
                    const sectionId = `${section.id}-${index}`;
                    const childSectionId = `${childSection?.id}-${index}`;
                    return (
                      <div key={sectionId}>
                        <ListGroup className="my-2 col-12 px-0">
                          <ListGroupItem
                            className="d-flex justify-content-between align-items-center bg-lighter font-500 cursor-pointer rounded"
                            tag="div"
                            onClick={() =>
                              setOpenSections({
                                ...openSections,
                                [sectionId]: !openSections[sectionId],
                              })
                            }
                          >
                            <span>
                              {utils.getSectionName(
                                index + 1,
                                section,
                                crane,
                                workOrderDetails.workOrder
                              )}
                            </span>
                            <FontAwesomeIcon
                              icon={
                                openSections[sectionId]
                                  ? faChevronDown
                                  : faChevronRight
                              }
                            />
                          </ListGroupItem>
                          <Collapse
                            isOpen={openSections[sectionId]}
                            className="p-2 mt-2"
                          >
                            {section.inspectionPoints.map(
                              (inspectionPoint, j) => {
                                const answer =
                                  craneInspection.inspectionAnswers.find(
                                    (a) =>
                                      a.craneInspectionPointId ===
                                        inspectionPoint.id &&
                                      a.iteratorId === iterableItem.id
                                  );
                                const answerNotes =
                                  answer?.inspectionAnswerAttachments?.filter(
                                    (a) => a.type === TYPE_NOTE
                                  );
                                const answerPhotos =
                                  answer?.inspectionAnswerAttachments?.filter(
                                    (a) => a.type === TYPE_IMAGE
                                  );
                                const craneInspectionAnswerId =
                                  answerPhotos && answerPhotos.length
                                    ? answerPhotos[0].craneInspectionAnswerId
                                    : null;
                                const craneInspectionPointOptions =
                                  answer?.inspectionPoint
                                    ?.craneInspectionPointOptions;
                                const selectedOption =
                                  answer?.inspectionPointOption?.id;
                                return (
                                  <ListGroupItem
                                    key={`${i}-${j}`}
                                    className="d-flex justify-content-between align-items-center"
                                    tag="div"
                                  >
                                    <span>{inspectionPoint.content}</span>
                                    <div className="d-flex flex-column align-items-end">
                                      {answer?.inspectionPointOption ? (
                                        <div>
                                          <Badge
                                            color={
                                              INSPECTION_ANSWER_COLOR[
                                                answer.inspectionPointOption.id
                                              ]
                                            }
                                          >
                                            {answer.inspectionPointOption.name}
                                          </Badge>
                                          <EditAnswerModal
                                            craneInspectionPointOptions={
                                              craneInspectionPointOptions
                                            }
                                            selectedOption={selectedOption}
                                            answer={answer}
                                          />
                                        </div>
                                      ) : (
                                        answer?.value || "-"
                                      )}
                                      {answerNotes?.length ||
                                      answerPhotos?.length ? (
                                        <div className="d-flex align-items-center mt-2">
                                          {answerNotes?.length ? (
                                            <Button
                                              size="sm"
                                              className="py-0 px-1 rounded"
                                              onClick={() => {
                                                setCraneInspectionAnswerNote(
                                                  answerNotes
                                                );
                                                setNoteModal({
                                                  isOpen: true,
                                                  title: "Answer Notes",
                                                });
                                              }}
                                            >
                                              <span className="font-500">
                                                Notes
                                              </span>
                                              <FontAwesomeIcon
                                                icon={faPenSquare}
                                                className="ml-1"
                                              />
                                            </Button>
                                          ) : null}
                                          {answerPhotos?.length ? (
                                            <>
                                              <Button
                                                size="sm"
                                                className={`${
                                                  answerNotes?.length
                                                    ? "ml-2"
                                                    : ""
                                                } py-0 px-1 rounded`}
                                                onClick={() => {
                                                  setShowModalPhotosCarousel(
                                                    answerPhotos.map(
                                                      (imgUrl, key) => ({
                                                        altText: "",
                                                        caption: "",
                                                        key,
                                                        src: imgUrl.content,
                                                      })
                                                    )
                                                  );
                                                  setCraneInspectionAnswerPhotos(
                                                    {
                                                      photos:
                                                        answerPhotos || [],
                                                      craneInspectionAnswerId:
                                                        craneInspectionAnswerId,
                                                    }
                                                  );
                                                }}
                                              >
                                                <span className="font-500">
                                                  Photos
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={faImages}
                                                  className="ml-1"
                                                />
                                              </Button>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </div>
                                  </ListGroupItem>
                                );
                              }
                            )}
                          </Collapse>
                        </ListGroup>
                        {childSection ? (
                          <ListGroup className="my-2 col-12 px-0">
                            <ListGroupItem
                              className="d-flex justify-content-between align-items-center bg-lighter font-500 cursor-pointer rounded"
                              tag="div"
                              onClick={() =>
                                setOpenSections({
                                  ...openSections,
                                  [childSectionId]:
                                    !openSections[childSectionId],
                                })
                              }
                            >
                              <span>
                                {utils.getSectionName(
                                  index + 1,
                                  childSection,
                                  crane,
                                  workOrderDetails.workOrder
                                )}
                              </span>
                              <FontAwesomeIcon
                                icon={
                                  openSections[childSectionId]
                                    ? faChevronDown
                                    : faChevronRight
                                }
                              />
                            </ListGroupItem>
                            <Collapse
                              isOpen={openSections[childSectionId]}
                              className="p-2 mt-2"
                            >
                              {childSection.inspectionPoints.map(
                                (inspectionPoint, j) => {
                                  const answer =
                                    craneInspection.inspectionAnswers.find(
                                      (a) =>
                                        a.craneInspectionPointId ===
                                          inspectionPoint.id &&
                                        a.iteratorId === iterableItem.id
                                    );
                                  const answerNotes =
                                    answer?.inspectionAnswerAttachments?.filter(
                                      (a) => a.type === TYPE_NOTE
                                    );
                                  const answerPhotos =
                                    answer?.inspectionAnswerAttachments?.filter(
                                      (a) => a.type === TYPE_IMAGE
                                    );
                                  const craneInspectionAnswerId =
                                    answerPhotos && answerPhotos.length
                                      ? answerPhotos[0].craneInspectionAnswerId
                                      : null;
                                  const craneInspectionPointOptions =
                                    answer?.inspectionPoint
                                      ?.craneInspectionPointOptions;
                                  const selectedOption =
                                    answer?.inspectionPointOption?.id;
                                  return (
                                    <ListGroupItem
                                      key={`${i}-${j}`}
                                      className="d-flex justify-content-between align-items-center"
                                      tag="div"
                                    >
                                      <span>{inspectionPoint.content}</span>
                                      <div className="d-flex flex-column align-items-end">
                                        {answer?.inspectionPointOption ? (
                                          <div>
                                            <Badge
                                              color={
                                                INSPECTION_ANSWER_COLOR[
                                                  answer.inspectionPointOption
                                                    .id
                                                ]
                                              }
                                            >
                                              {
                                                answer.inspectionPointOption
                                                  .name
                                              }
                                            </Badge>
                                            <EditAnswerModal
                                              craneInspectionPointOptions={
                                                craneInspectionPointOptions
                                              }
                                              selectedOption={selectedOption}
                                              answer={answer}
                                            />
                                          </div>
                                        ) : (
                                          answer?.value || "-"
                                        )}
                                        {answerNotes?.length ||
                                        answerPhotos?.length ? (
                                          <div className="d-flex align-items-center mt-2">
                                            {answerNotes?.length ? (
                                              <Button
                                                size="sm"
                                                className="py-0 px-1 rounded"
                                                onClick={() => {
                                                  setCraneInspectionAnswerNote(
                                                    answerNotes
                                                  );
                                                  setNoteModal({
                                                    isOpen: true,
                                                    title: "Answer Notes",
                                                  });
                                                }}
                                              >
                                                <span className="font-500">
                                                  Notes
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={faPenSquare}
                                                  className="ml-1"
                                                />
                                              </Button>
                                            ) : null}
                                            {answerPhotos?.length ? (
                                              <Button
                                                size="sm"
                                                className={`${
                                                  answerNotes?.length
                                                    ? "ml-2"
                                                    : ""
                                                } py-0 px-1 rounded`}
                                                onClick={() => {
                                                  setShowModalPhotosCarousel(
                                                    answerPhotos.map(
                                                      (imgUrl, key) => ({
                                                        altText: "",
                                                        caption: "",
                                                        key,
                                                        src: imgUrl.content,
                                                      })
                                                    )
                                                  );
                                                  setCraneInspectionAnswerPhotos(
                                                    {
                                                      photos:
                                                        answerPhotos || [],
                                                      craneInspectionAnswerId:
                                                        craneInspectionAnswerId,
                                                    }
                                                  );
                                                }}
                                              >
                                                <span className="font-500">
                                                  Photos
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={faImages}
                                                  className="ml-1"
                                                />
                                              </Button>
                                            ) : null}
                                          </div>
                                        ) : null}
                                      </div>
                                    </ListGroupItem>
                                  );
                                }
                              )}
                            </Collapse>
                          </ListGroup>
                        ) : null}
                      </div>
                    );
                  }
                );
                return iteratedSections;
              } else {
                const sectionId = section.id;
                return (
                  <ListGroup key={sectionId} className="my-2 col-12 px-0">
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center bg-lighter font-500 cursor-pointer rounded"
                      tag="div"
                      onClick={() =>
                        setOpenSections({
                          ...openSections,
                          [sectionId]: !openSections[sectionId],
                        })
                      }
                    >
                      <span>
                        {utils.getSectionName(
                          i + 1,
                          section,
                          crane,
                          workOrderDetails.workOrder
                        )}
                      </span>
                      <FontAwesomeIcon
                        icon={
                          openSections[sectionId]
                            ? faChevronDown
                            : faChevronRight
                        }
                      />
                    </ListGroupItem>
                    <Collapse
                      isOpen={openSections[sectionId]}
                      className="p-2 mt-2"
                    >
                      {section.inspectionPoints.map((inspectionPoint, j) => {
                        const answer = craneInspection.inspectionAnswers.find(
                          (a) => a.craneInspectionPointId === inspectionPoint.id
                        );
                        const answerNotes =
                          answer?.inspectionAnswerAttachments?.filter(
                            (a) => a.type === TYPE_NOTE
                          );
                        const answerPhotos =
                          answer?.inspectionAnswerAttachments?.filter(
                            (a) => a.type === TYPE_IMAGE
                          );
                        const craneInspectionAnswerId =
                          answerPhotos && answerPhotos.length
                            ? answerPhotos[0].craneInspectionAnswerId
                            : null;
                        const craneInspectionPointOptions =
                          answer?.inspectionPoint?.craneInspectionPointOptions;
                        const selectedOption =
                          answer?.inspectionPointOption?.id;
                        return (
                          <ListGroupItem
                            key={`${i}-${j}`}
                            className="d-flex justify-content-between align-items-center"
                            tag="div"
                          >
                            <span>{inspectionPoint.content}</span>
                            <div className="d-flex flex-column align-items-end">
                              {answer?.inspectionPointOption ? (
                                <div>
                                  <Badge
                                    color={
                                      INSPECTION_ANSWER_COLOR[
                                        answer.inspectionPointOption.id
                                      ]
                                    }
                                  >
                                    {answer.inspectionPointOption.name}
                                  </Badge>
                                  <EditAnswerModal
                                    craneInspectionPointOptions={
                                      craneInspectionPointOptions
                                    }
                                    selectedOption={selectedOption}
                                    answer={answer}
                                  />
                                </div>
                              ) : (
                                answer?.value || "-"
                              )}
                              {answerNotes?.length || answerPhotos?.length ? (
                                <div className="d-flex align-items-center mt-2">
                                  {answerNotes?.length ? (
                                    <Button
                                      size="sm"
                                      className="py-0 px-1 rounded"
                                      onClick={() => {
                                        setCraneInspectionAnswerNote(
                                          answerNotes
                                        );
                                        setNoteModal({
                                          isOpen: true,
                                          title: "Answer Notes",
                                        });
                                      }}
                                    >
                                      <span className="font-500">Notes</span>
                                      <FontAwesomeIcon
                                        icon={faPenSquare}
                                        className="ml-1"
                                      />
                                    </Button>
                                  ) : null}
                                  {answerPhotos?.length ? (
                                    <Button
                                      size="sm"
                                      className={`${
                                        answerNotes?.length ? "ml-2" : ""
                                      } py-0 px-1 rounded`}
                                      onClick={() => {
                                        setShowModalPhotosCarousel(
                                          answerPhotos.map((imgUrl, key) => ({
                                            altText: "",
                                            caption: "",
                                            key,
                                            src: imgUrl.content,
                                          }))
                                        );
                                        setCraneInspectionAnswerPhotos({
                                          photos: answerPhotos || [],
                                          craneInspectionAnswerId:
                                            craneInspectionAnswerId,
                                        });
                                      }}
                                    >
                                      <span className="font-500">Photos</span>
                                      <FontAwesomeIcon
                                        icon={faImages}
                                        className="ml-1"
                                      />
                                    </Button>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          </ListGroupItem>
                        );
                      })}
                    </Collapse>
                  </ListGroup>
                );
              }
            })
        : null}
      {informationModal.isOpen && (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                })
          }
        />
      )}
      {showModalPhotosCarousel ? (
        <ModalPhotosCarousel
          title={"Answer Photos"}
          items={showModalPhotosCarousel}
          onClose={() => {
            setShowModalPhotosCarousel(false);
          }}
          editPhotos={true}
          onOpenModalEditPhotos={() => {
            setShowModalEditPhotos(true);
            setShowModalPhotosCarousel(false);
          }}
        />
      ) : null}
      {noteModal.isOpen && (
        <ModalEdit
          title={noteModal.title}
          isOpen={true}
          onCancel={() => setNoteModal({ isOpen: false })}
          onToggle={() => setNoteModal({ isOpen: false })}
          onSave={onSubmit}
          isSaveDisabled={loading || !craneInspectionAnswerNote.length}
        >
          <NoteBody
            answerNotes={craneInspectionAnswerNote}
            setCraneInspectionAnswerNote={setCraneInspectionAnswerNote}
            loading={loading}
          />
        </ModalEdit>
      )}
      {showModalEditPhotos ? (
        <EditPhotos
          attachments={craneInspectionAnswerPhotos.photos}
          craneInspectionAnswerId={
            craneInspectionAnswerPhotos.craneInspectionAnswerId
          }
          isOpen={true}
          onToggle={() => {
            setShowModalEditPhotos(false);
            setCraneInspectionAnswerPhotos({
              photos: [],
              craneInspectionAnswerId: null,
            });
          }}
          onClose={() => {
            setShowModalEditPhotos(false);
            setCraneInspectionAnswerPhotos({
              photos: [],
              craneInspectionAnswerId: null,
            });
          }}
        />
      ) : null}
    </>
  );
};

export default CranesInspectedSections;
