import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { holidaysApi } from "../services/holidayServices";

import InformationModal from "./InformationModal";

import Loader from "./Loader";

const HolidayModal = ({ holidayId, onClose, onSubmit }) => {
  const [holiday, setHoliday] = useState({
    name: "",
    date: "",
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const doSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (holiday.id) {
      const { id, name, date } = holiday;
      holidaysApi
        .updateHoliday({
          id,
          name,
          date,
        })
        .then(() => {
          setLoading(false);
          onSubmit();
        })
        .catch((err) => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Update Holiday",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        });
    } else {
      holidaysApi
        .createHoliday({
          ...holiday,
        })
        .then(() => {
          setLoading(false);
          onSubmit();
        })
        .catch((err) => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Create Holiday",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        });
    }
  };

  // Load Holiday
  useEffect(() => {
    if (holidayId) {
      setLoading(true);
      holidaysApi
        .getHolidays({ id: holidayId })
        .then((result) => {
          setLoading(false);
          setHoliday(result);
        })
        .catch(() => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Error",
            body: "There was an error with your request.",
          });
        });
    }
  }, [holidayId]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {holidayId ? "Edit" : "Create"} Holiday
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label>
                    Name
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    maxLength={100}
                    value={holiday.name}
                    onChange={(e) =>
                      setHoliday({
                        ...holiday,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Date
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="date"
                    name="date"
                    maxLength={100}
                    value={holiday.date}
                    onChange={(e) =>
                      setHoliday({
                        ...holiday,
                        date: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default HolidayModal;
