import React, { useState } from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";

import InformationModal from "../../../../InformationModal";
import CraneInspectionAttachmentForm from "../../../craneInspectionAttachments/CraneInspectionAttachmentForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import EditAttachmentsModal from "../Edition/EditAttachmentsModal";

const ATTACHMENT_TYPE_NOTE = "NOTE";
const ATTACHMENT_TYPE_IMAGE = "IMAGE";

const CraneInspectionAttachment = ({ craneInspection }) => {
  const [noteAttachmentModal, setNoteAttachmentModal] = useState();

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const openImageModal = (url, label) => {
    setInformationModal({
      isOpen: true,
      title: "Photo Preview",
      rawBody: true,
      body: (
        <div className="d-flex flex-column text-center">
          <img className="img-fluid rounded" src={url} alt={label} />
          {label ? <span className="mt-2">{label}</span> : null}
        </div>
      ),
    });
  };

  return (
    <ListGroup className="my-3 col-12 px-0">
      <ListGroupItem
        className="d-flex justify-content-between align-items-center bg-lighter font-500"
        tag="div"
      >
        Inspection Attachments
        {craneInspection.inspectionAttachments?.length ? (
          craneInspection.inspectionAttachments.every(
            (attachment) => attachment.type === ATTACHMENT_TYPE_IMAGE
          ) ? (
            <EditAttachmentsModal
              attachments={craneInspection.inspectionAttachments.filter(
                (attachment) => attachment.type === ATTACHMENT_TYPE_IMAGE
              )}
              isCraneInspected={true}
              craneInspectionId={craneInspection.id}
            />
          ) : null
        ) : null}
      </ListGroupItem>
      {craneInspection.inspectionAttachments?.length ? (
        <div className="mt-2 rounded">
          {craneInspection.inspectionAttachments
            .filter((attachment) => attachment.type === ATTACHMENT_TYPE_NOTE)
            .map((attachment) => (
              <ListGroupItem
                key={attachment.id}
                className="d-flex justify-content-between align-items-center"
                tag="div"
              >
                <div
                  className={`p-2 my-1 rounded col-12 d-flex justify-content-between align-items-start`}
                  style={{ backgroundColor: "#FDF1DB" }}
                >
                  <div className="flex-grow-1">
                    <span>{attachment.content}</span>
                  </div>
                  <div className="d-flex flex-column align-items-end justify-content-start h-100">
                    <FontAwesomeIcon
                      icon={faPen}
                      onClick={() => setNoteAttachmentModal(attachment)}
                      size="sm"
                      className="text-primary cursor-pointer ml-3"
                    />
                    <Badge className="m-1 pt-0">
                      {attachment.author ? (
                        <small>
                          By{" "}
                          {`${attachment.author.firstName} ${attachment.author.lastName}`}
                        </small>
                      ) : null}
                    </Badge>
                  </div>
                </div>
              </ListGroupItem>
            ))}
          {craneInspection.inspectionAttachments.some(
            (attachment) => attachment.type === ATTACHMENT_TYPE_IMAGE
          ) ? (
            <ListGroupItem
              className="d-flex flex-wrap justify-content-start mt-2 border-top"
              tag="div"
            >
              {craneInspection.inspectionAttachments.some(
                (attachment) => attachment.type === ATTACHMENT_TYPE_NOTE
              ) ? (
                <div className="m-2 d-flex justify-content-between align-items-end w-100">
                  <div></div>
                  <EditAttachmentsModal
                    attachments={craneInspection.inspectionAttachments.filter(
                      (attachment) => attachment.type === ATTACHMENT_TYPE_IMAGE
                    )}
                    isCraneInspected={true}
                    craneInspectionId={craneInspection.id}
                  />
                </div>
              ) : null}
              {craneInspection.inspectionAttachments
                .filter(
                  (attachment) => attachment.type === ATTACHMENT_TYPE_IMAGE
                )
                .map((attachment) => (
                  <div
                    key={attachment.id}
                    className="rounded figure img-thumbnail cursor-pointer m-2 d-flex justify-content-end align-items-end"
                    onClick={() =>
                      openImageModal(attachment.content, attachment.label)
                    }
                    style={{
                      backgroundPosition: "center",
                      backgroundImage: `url(${attachment.content})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      cursor: "pointer",
                      height: "160px",
                      width: "160px",
                    }}
                  >
                    {attachment.label ? (
                      <Badge className="m-1 text-truncate">
                        {attachment.label}
                      </Badge>
                    ) : null}
                    <Badge className="m-1 pt-0">
                      {attachment.author ? (
                        <small>
                          By{" "}
                          {`${attachment.author.firstName} ${attachment.author.lastName}`}
                        </small>
                      ) : null}
                    </Badge>
                  </div>
                ))}
            </ListGroupItem>
          ) : null}
        </div>
      ) : (
        <ListGroupItem
          className="d-flex justify-content-between align-items-center"
          tag="div"
        >
          <small className="text-muted">No attachments to show</small>
        </ListGroupItem>
      )}
      {noteAttachmentModal ? (
        <CraneInspectionAttachmentForm
          attachment={noteAttachmentModal}
          onClose={() => setNoteAttachmentModal()}
          onSubmit={(res) => {
            noteAttachmentModal.content = res.content; //hack to update real time without refresh
            setNoteAttachmentModal();
            setInformationModal({
              isOpen: true,
              title: "Update Note",
              body: "Note updated successfully",
            });
          }}
        />
      ) : (
        informationModal.isOpen && (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            rawBody={informationModal.rawBody}
            onClose={() =>
              informationModal.onClose
                ? informationModal.onClose()
                : setInformationModal({ isOpen: false, title: "", body: "" })
            }
          />
        )
      )}
    </ListGroup>
  );
};

export default CraneInspectionAttachment;
