import React, { useState } from "react";
import {
  useCraneConfigModal,
  MODES,
} from "../../../../providers/craneConfigModalProvider";

import InformationModal from "../../../InformationModal";
import CraneConfigControlsModal from "./CraneConfigControlsModal";
import CraneConfigGeneralModal from "./CraneConfigGeneralModal";
import CraneConfigHoistsModal from "./CraneConfigHoistsModal";
import CraneConfigPhotosModal from "./CraneConfigPhotosModal";
import CraneConfigStructureModal from "./CraneConfigStructureModal";

const CraneConfigModal = ({ onSubmit }) => {
  const [craneConfigModalContext] = useCraneConfigModal();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const getComponent = () => {
    switch (craneConfigModalContext.mode) {
      case MODES.MODE_GENERAL:
        return <CraneConfigGeneralModal />;
      case MODES.MODE_STRUCTURE:
        return <CraneConfigStructureModal />;
      case MODES.MODE_HOISTS:
        return <CraneConfigHoistsModal />;
      case MODES.MODE_CONTROLS:
        return <CraneConfigControlsModal />;
      case MODES.MODE_PHOTOS:
        return <CraneConfigPhotosModal onSubmit={onSubmit} />;
      default:
        throw Error;
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    getComponent()
  );
};

export default CraneConfigModal;
