import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import {
  useScheduleModal,
  ACTIONS,
  STEPS,
  MODES,
} from "../../../../providers/scheduleModalProvider";
import { workOrdersApi } from "../../../../services/workOrdersServices";
import DateRangePicker from "../../../DateRangePicker";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import {
  useWorkOrderDetails,
  ACTIONS as useWorkOrderDetailsActions,
} from "../../../../providers/workOrderDetailsProvider";
import DateRangeLabel from "../../../DateRangeLabel";

const browserOffset = moment().utcOffset();

const ScheduleModalDates = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [scheduleModalContext, setScheduleModalContext] = useScheduleModal();

  const { currentData } = scheduleModalContext;

  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [isTentative, setIsTentative] = useState(currentData.isTentative);

  const [startDate, setStartDate] = useState(
    currentData.startDate ? moment(currentData.startDate) : moment()
  );

  const [endDate, setEndDate] = useState(
    currentData.endDate ? moment(currentData.endDate) : moment()
  );

  const isTouched = () =>
    !moment(currentData.startDate).isSame(startDate, "date") ||
    !moment(currentData.endDate).isSame(endDate, "date");

  const onClose = () => {
    if (isTouched()) {
      setWorkOrderDetails({
        action: useWorkOrderDetailsActions.REFRESH,
      });
    }
    setScheduleModalContext({
      action: ACTIONS.ON_CLOSE,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSelection = (range) => {
    setStartDate(range.from);
    setEndDate(range.to);
  };

  const onNext = () => {
    if (scheduleModalContext.mode === MODES.MODE_WORK_ORDER_TRIP) {
      setScheduleModalContext({
        action: ACTIONS.STEP_CHANGE,
        payload: { step: STEPS.STEP_TECHNICIANS },
      });
    } else {
      setInformationModal({
        isOpen: true,
        title: "Update Work Order Schedule",
        body: "Work Order Updated Successfully",
        onClose: () => {
          setScheduleModalContext({
            action: ACTIONS.ON_CLOSE,
          });
          setWorkOrderDetails({
            action: useWorkOrderDetailsActions.REFRESH,
          });
        },
      });
    }
  };

  const onSubmit = async () => {
    if (!isTouched() && currentData.id) {
      return onNext();
    }
    if (!startDate || !endDate) {
      return setInformationModal({
        isOpen: true,
        title:
          scheduleModalContext.mode === MODES.MODE_WORK_ORDER_TRIP
            ? `${currentData.id ? "Update" : "Create"} Work Order Trip`
            : "Update Work Order Schedule",
        body: "Please enter start and end dates.",
      });
    }
    try {
      setLoading(true);
      let result;
      if (scheduleModalContext.mode === MODES.MODE_WORK_ORDER) {
        result = await workOrdersApi.updateWorkOrder({
          ...currentData,
          isTentative,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
        });
      } else {
        if (currentData.id) {
          result = await workOrdersApi.updateWorkOrderTrip({
            ...currentData,
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
          });
        } else {
          result = await workOrdersApi.createWorkOrderTrip({
            ...currentData,
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            workOrderId: workOrderDetails.workOrder.id,
          });
        }
      }
      setLoading(false);
      setScheduleModalContext({
        action: ACTIONS.DATA_UPDATE,
        payload: {
          currentData: {
            ...currentData,
            ...result,
          },
        },
      });
      onNext();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const title =
    scheduleModalContext.mode === MODES.MODE_WORK_ORDER_TRIP
      ? `${currentData.id ? "Update" : "Create"} Work Order Trip`
      : "Update Work Order Schedule";

  const nextButton =
    scheduleModalContext.mode === MODES.MODE_WORK_ORDER_TRIP ? (
      <>
        <span className="mr-2">Technicians</span>
        <FontAwesomeIcon icon={faChevronRight} />
      </>
    ) : (
      <span>Save</span>
    );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>{title}</ModalHeader>
      <ModalBody className="text-center">
        <DateRangePicker
          label={`${
            scheduleModalContext.mode === MODES.MODE_WORK_ORDER_TRIP
              ? "Trip"
              : "Work Order"
          } Dates`}
          startDate={
            startDate ? moment(startDate).utcOffset(browserOffset) : moment()
          }
          endDate={
            endDate ? moment(endDate).utcOffset(browserOffset) : moment()
          }
          onSelection={onSelection}
        />
        {scheduleModalContext.mode === MODES.MODE_WORK_ORDER ? (
          <FormGroup className="d-flex justify-content-between mb-0 mt-3">
            <Label className="font-500 text-left col-3 d-flex align-items-center">
              Status
            </Label>
            <div className="d-flex flex-grow-1 justify-content-start">
              <div className="col-6 pl-0">
                <Button
                  onClick={() => setIsTentative(true)}
                  color={isTentative ? "primary" : "white"}
                  className={`col-12 outline-none ${
                    isTentative
                      ? "border-white bg-primary text-white"
                      : "border-primary bg-white text-primary"
                  }`}
                >
                  Tentative
                </Button>
              </div>
              <div className="col-6 pr-0">
                <Button
                  onClick={() => setIsTentative(false)}
                  color={!isTentative ? "primary" : "white"}
                  className={`col-12 outline-none ${
                    !isTentative
                      ? "border-white bg-primary text-white"
                      : "border-primary bg-white text-primary"
                  }`}
                >
                  Scheduled
                </Button>
              </div>
            </div>
          </FormGroup>
        ) : null}
        {scheduleModalContext.mode === MODES.MODE_WORK_ORDER_TRIP ? (
          <DateRangeLabel
            className="mt-3"
            background="white"
            entity="Work Order"
            startDate={workOrderDetails.workOrder.startDate}
            endDate={workOrderDetails.workOrder.endDate}
          />
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>{" "}
        {loading ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            className="d-flex align-items-center"
            color={"primary"}
            onClick={onSubmit}
          >
            {nextButton}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ScheduleModalDates;
