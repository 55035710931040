import {
  faChevronDown,
  faChevronRight,
  faExclamationCircle,
  faImages,
  faPaperPlane,
  faPenSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Badge, Collapse, ListGroup, ListGroupItem, Button } from "reactstrap";
import { utils } from "../../../../../utils/utils";

import { workOrdersApi } from "../../../../../services/workOrdersServices";

import ContactModal from "../../../../../components/ContactModal";
import InformationModal from "../../../../../components/InformationModal";

import CraneInspectionAnswerBody from "./CraneInspectionAnswerBody";
import ModalPhotosCarousel from "../../../ModalPhotosCarousel";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../../../providers/workOrderDetailsProvider";
import ModalEdit from "../Edition/ModalEdit";
import NoteBody from "./Edition/NoteBody";
import EditPhotos from "./Edition/EditPhotosModal";
import EditAnswerModal from "./Edition/EditAnswerModal";
import { cranesInspectionAttachmentApi } from "../../../../../services/craneInspectionAttachmentServices";

const REPAIR_OPTION = 3;

const TYPE_NOTE = "NOTE";
const TYPE_IMAGE = "IMAGE";

const ActionsList = ({
  crane,
  craneInspection,
  sections,
  customerContacts,
}) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [isOpen, setIsOpen] = useState();
  const [showModalPhotosCarousel, setShowModalPhotosCarousel] = useState();

  const [initialEmails, setInitialEmails] = useState(
    customerContacts.map((cc) => cc.email)
  );
  const [sendingEmail, setSendingEmail] = useState();

  const [showContactModal, setShowContactModal] = useState({
    isOpen: false,
    title: "",
    isEmail: false,
    defaultRecipients: [],
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [noteModal, setNoteModal] = useState({
    isOpen: false,
    title: "",
  });

  const [showModalEditPhotos, setShowModalEditPhotos] = useState(false);
  const [craneInspectionAnswerPhotos, setCraneInspectionAnswerPhotos] =
    useState({
      photos: [],
      craneInspectionAnswerId: null,
    });
  const [craneInspectionAnswerNote, setCraneInspectionAnswerNote] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [, setWorkOrderDetailsContext] = useWorkOrderDetails();

  const items = craneInspection.inspectionAnswers.filter((a) => {
    const section = sections.find(
      (s) => s.id === a.inspectionPoint.craneInspectionSectionId
    );
    if (!section) {
      return false;
    }
    return (
      (utils.sectionMeetsCondition(
        craneInspection,
        null,
        section,
        crane,
        sections
      ) ||
        utils.sectionHasAnswers(craneInspection.inspectionAnswers, section)) &&
      a.craneInspectionPointOptionId === REPAIR_OPTION
    );
  });

  if (!items.length) {
    return null;
  }

  const sendEmail = (data) => {
    const emails = data.recipients.split(",").map((e) => e.trim());
    if (emails.every((email) => utils.validateEmail(email))) {
      setInitialEmails(emails);
      setSendingEmail(true);
      const craneInspectionAnswers = items.map((craneInspectionAnswer, i) => {
        const section = sections.find(
          (s) =>
            s.id ===
            craneInspectionAnswer.inspectionPoint.craneInspectionSectionId
        );
        const sectionName = utils.getSectionName(
          craneInspectionAnswer.iteratorIndex,
          section,
          crane,
          workOrderDetails.workOrder
        );
        return {
          sectionName: sectionName,
          content: craneInspectionAnswer.inspectionPoint.content,
          name: craneInspectionAnswer.inspectionPointOption.name,
          notes: craneInspectionAnswer.notes,
        };
      });
      workOrdersApi
        .sendNotificationEmail({
          recipients: emails,
          subject: data.subject,
          body: `
              ${data.content}
              <br /><br />
              ${CraneInspectionAnswerBody(craneInspectionAnswers)}
            `,
        })
        .then((res) => {
          setSendingEmail(false);
          setShowContactModal({
            isOpen: false,
          });
          setInformationModal({
            isOpen: true,
            title: "Send Action List by Email",
            body: "Email sent successfully",
            onClose: setInformationModal({
              isOpen: false,
              title: "",
              body: "",
            }),
          });
        })
        .catch((err) => {
          setSendingEmail(false);
          setShowContactModal({
            isOpen: false,
          });
          setInformationModal({
            isOpen: true,
            title: "Send Action List by Email",
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
            onClose: onCloseInformationModal,
          });
        });
    } else {
      setShowContactModal({
        isOpen: false,
      });
      setInformationModal({
        isOpen: true,
        title: "Send Action List by Email",
        body: "Please, enter a valid recipients",
        onClose: onCloseInformationModal,
      });
    }
  };

  const onCloseInformationModal = () => {
    setInformationModal({ isOpen: false, title: "", body: "" });
    setShowContactModal({
      isOpen: true,
      isEmail: true,
      title: "Send Action List by Email",
      defaultRecipients: [initialEmails],
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      for (const craneInspectionAttachment of craneInspectionAnswerNote) {
        await cranesInspectionAttachmentApi.updateCraneInspectionAnswerAttachment(
          {
            id: craneInspectionAttachment.id,
            craneInspectionAnswerId:
              craneInspectionAttachment.craneInspectionAnswerId,
            content: craneInspectionAttachment.content,
            type: craneInspectionAttachment.type,
          }
        );
      }
      setNoteModal({
        isOpen: false,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Note update",
        body: `${
          craneInspectionAnswerNote.length > 1
            ? "The notes were successfully updated"
            : "The note was successfully updated"
        }`,
        onClose: () => {
          setWorkOrderDetailsContext({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (error) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  return (
    <ListGroup className="my-2 col-12 px-0">
      <ListGroupItem
        className="d-flex justify-content-between align-items-center bg-lighter font-500 cursor-pointer rounded"
        tag="div"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="mr-2 text-danger"
          />
          <span>Actions List</span>
        </div>
        <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
      </ListGroupItem>
      <Collapse isOpen={isOpen} className="p-2 mt-2">
        <ListGroupItem
          className="d-flex justify-content-center align-items-center"
          tag="div"
        >
          <Button
            color="primary"
            size="sm"
            className="rounded"
            onClick={() => {
              setShowContactModal({
                isOpen: true,
                isEmail: true,
                title: "Send by Email",
                defaultRecipients: initialEmails,
              });
            }}
          >
            <span className="font-500">Send Actions List</span>
            <FontAwesomeIcon icon={faPaperPlane} className="ml-1" />
          </Button>
        </ListGroupItem>
        {items.map((craneInspectionAnswer, i) => {
          const section = sections.find(
            (s) =>
              s.id ===
              craneInspectionAnswer.inspectionPoint.craneInspectionSectionId
          );
          const sectionName = utils.getSectionName(
            craneInspectionAnswer.iteratorIndex,
            section,
            crane,
            workOrderDetails.workOrder
          );
          const answerNotes =
            craneInspectionAnswer.inspectionAnswerAttachments?.filter(
              (a) => a.type === TYPE_NOTE
            );
          const answerPhotos =
            craneInspectionAnswer.inspectionAnswerAttachments?.filter(
              (a) => a.type === TYPE_IMAGE
            );
          const craneInspectionPointOptions =
            craneInspectionAnswer?.inspectionPoint?.craneInspectionPointOptions;
          const selectedOption =
            craneInspectionAnswer.inspectionPointOption?.id;
          return (
            <ListGroupItem
              key={i}
              className="d-flex justify-content-between align-items-center"
              tag="div"
            >
              <div className="d-flex flex-column">
                <span className="font-500">{sectionName}</span>
                <span>{craneInspectionAnswer.inspectionPoint.content}</span>
              </div>
              <div>
                <div className="d-flex flex-column align-items-end">
                  {craneInspectionAnswer.inspectionPointOption ? (
                    <div>
                      <Badge color={"danger"} className="py-1">
                        {craneInspectionAnswer.inspectionPointOption.name}
                      </Badge>
                      <EditAnswerModal
                        craneInspectionPointOptions={
                          craneInspectionPointOptions
                        }
                        selectedOption={selectedOption}
                        answer={craneInspectionAnswer}
                      />
                    </div>
                  ) : (
                    craneInspectionAnswer.value
                  )}
                  <div className="d-flex align-items-center mt-2">
                    {answerNotes?.length || answerPhotos?.length ? (
                      <>
                        {answerNotes?.length ? (
                          <Button
                            size="sm"
                            className="py-0 px-1 rounded"
                            onClick={() => {
                              setCraneInspectionAnswerNote(answerNotes);
                              setNoteModal({
                                isOpen: true,
                                title: "Answer Notes",
                              });
                            }}
                          >
                            <span className="font-500">Notes</span>
                            <FontAwesomeIcon
                              icon={faPenSquare}
                              className="ml-1"
                            />
                          </Button>
                        ) : null}
                        {answerPhotos?.length ? (
                          <>
                            <Button
                              size="sm"
                              className={`${
                                answerNotes?.length ? "ml-2" : ""
                              } py-0 px-1 rounded`}
                              onClick={() => {
                                setShowModalPhotosCarousel(
                                  answerPhotos.map((imgUrl, key) => ({
                                    altText: "",
                                    caption: "",
                                    key,
                                    src: imgUrl.content,
                                  }))
                                );
                                setCraneInspectionAnswerPhotos({
                                  photos: answerPhotos || [],
                                  craneInspectionAnswerId:
                                    craneInspectionAnswer.id,
                                });
                              }}
                            >
                              <span className="font-500">Photos</span>
                              <FontAwesomeIcon
                                icon={faImages}
                                className="ml-1"
                              />
                            </Button>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </ListGroupItem>
          );
        })}
      </Collapse>
      {showContactModal.isOpen && (
        <ContactModal
          {...showContactModal}
          doSubmit={sendEmail}
          loading={sendingEmail}
          recipientsEditable={true}
          multipleRecipients={true}
          onClose={() =>
            setShowContactModal({
              isOpen: false,
            })
          }
        />
      )}
      {informationModal.isOpen && (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      )}
      {noteModal.isOpen && (
        <ModalEdit
          title={noteModal.title}
          isOpen={true}
          onCancel={() => setNoteModal({ isOpen: false })}
          onToggle={() => setNoteModal({ isOpen: false })}
          onSave={onSubmit}
          isSaveDisabled={loading || !craneInspectionAnswerNote.length}
        >
          <NoteBody
            answerNotes={craneInspectionAnswerNote}
            setCraneInspectionAnswerNote={setCraneInspectionAnswerNote}
            loading={loading}
          />
        </ModalEdit>
      )}
      {showModalPhotosCarousel ? (
        <ModalPhotosCarousel
          title={"Answer Photos"}
          items={showModalPhotosCarousel}
          onClose={() => {
            setShowModalPhotosCarousel(false);
          }}
          editPhotos={true}
          onOpenModalEditPhotos={() => {
            setShowModalEditPhotos(true);
            setShowModalPhotosCarousel(false);
          }}
        />
      ) : null}
      {showModalEditPhotos ? (
        <EditPhotos
          attachments={craneInspectionAnswerPhotos.photos}
          craneInspectionAnswerId={
            craneInspectionAnswerPhotos.craneInspectionAnswerId
          }
          isOpen={true}
          onToggle={() => {
            setShowModalEditPhotos(false);
            setCraneInspectionAnswerPhotos({
              photos: [],
              craneInspectionAnswerId: null,
            });
          }}
          onClose={() => {
            setShowModalEditPhotos(false);
            setCraneInspectionAnswerPhotos({
              photos: [],
              craneInspectionAnswerId: null,
            });
          }}
        />
      ) : null}
    </ListGroup>
  );
};

export default ActionsList;
