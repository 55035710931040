import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { workOrdersApi } from "../../../../../services/workOrdersServices";
import Loader from "../../../../Loader";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../../../providers/workOrderDetailsProvider";
import InformationModal from "../../../../InformationModal";
import ModalEdit from "./ModalEdit";

const EditMaterialsModal = ({
  actualMaterial,
  actualQuantity,
  workOrderMaterialId,
  workOrderMaterialsId,
  currentMaterialsList,
  materials,
}) => {
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(actualMaterial);
  const [quantity, setQuantity] = useState(actualQuantity);
  const [, setWorkOrderDetailsContext] = useWorkOrderDetails();

  const onClose = () => {
    setOpenModal(false);
  };

  const handleMaterialChange = (event) => {
    const selectedMaterialId = parseInt(event.target.value, 10);
    setSelectedMaterial(selectedMaterialId);
  };

  const handleQuantityChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue !== "" && !isNaN(parseInt(inputValue))) {
      inputValue = parseInt(inputValue).toString();
    }

    if (
      inputValue === "" ||
      (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 100000)
    ) {
      setQuantity(inputValue);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const findMaterialCurrentList = currentMaterialsList.find(
        (item) => item.material.id === selectedMaterial
      );
      if (findMaterialCurrentList && actualMaterial !== selectedMaterial) {
        setOpenModal(false);
        setInformationModal({
          isOpen: true,
          title: "Error editing the material",
          body: `The material you are trying to update already exists, try selecting another`,
        });
        setLoading(false);
        setOpenModal(true);
      } else {
        await workOrdersApi.updateWorkOrderMaterial({
          id: workOrderMaterialId,
          workOrderMaterialsId: workOrderMaterialsId,
          materialId: selectedMaterial,
          count: quantity ? quantity : 0,
        });
        setOpenModal(false);
        setInformationModal({
          isOpen: true,
          title: "Material update",
          body: `The material was successfully updated`,
          onClose: () => {
            setLoading(false);
            setWorkOrderDetailsContext({
              action: ACTIONS.REFRESH,
            });
          },
        });
      }
    } catch (error) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  };

  const selectedMaterialObject = materials?.find(
    (material) => material.id === selectedMaterial
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <>
      <Button
        size="sm"
        className="rounded ml-2"
        color="warning"
        onClick={() => setOpenModal(true)}
      >
        <FontAwesomeIcon icon={faPen} className="mr-1" />
        <span>Edit</span>
      </Button>
      <ModalEdit
        isOpen={openModal}
        onToggle={() => setOpenModal(false)}
        title="Edit Materials"
        onCancel={onClose}
        onSave={onSubmit}
        isSaveDisabled={
          loading || materials.length === 0 || !selectedMaterialObject
            ? true
            : false
        }
      >
        {loading ? (
          <Loader size="sm" />
        ) : materials.length > 0 ? (
          <>
            <FormGroup>
              {selectedMaterialObject ? (
                <Label for="materialSelect">Select a material</Label>
              ) : null}
              <Input
                type="select"
                name="material"
                id="materialSelect"
                value={selectedMaterial}
                onChange={handleMaterialChange}
              >
                {!selectedMaterialObject ? (
                  <option value="">Select a material...</option>
                ) : null}
                {materials.map((material) => (
                  <option key={material.id} value={material.id}>
                    {material.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            {selectedMaterial && selectedMaterialObject && (
              <FormGroup>
                <Label for="quantity">
                  Quantity for {selectedMaterialObject.name}
                </Label>
                <Input
                  type="number"
                  id="quantity"
                  value={quantity}
                  onChange={handleQuantityChange}
                  style={{ width: 110 }}
                  min={0}
                  max={100000}
                />
              </FormGroup>
            )}
          </>
        ) : (
          "There are no materials to select"
        )}
      </ModalEdit>
    </>
  );
};

export default EditMaterialsModal;
