import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import {
  CustomInput,
  Input,
  Form,
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
} from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import { useCraneConfigModal } from "../../../../providers/craneConfigModalProvider";
import { cranesApi } from "../../../../services/craneServices";
import CraneConfigCapacityModal from "./CraneConfigCapacityModal";
import CraneConfigManufacturerModal from "./CraneConfigManufacturerModal";
import { findDOMNode } from "react-dom";

const HOIST_ONLY = "HOIST_ONLY";

const OTHER_MANUFACTURER_VALUE = "OTHER_MANUFACTURER";
const OTHER_CAPACITY_VALUE = "OTHER_CAPACITY";
const STRUCTURE_MODE = "Structure";

const CraneConfigStructureModal = forwardRef((props, ref) => {
  const [craneConfigModalContext] = useCraneConfigModal();
  const { craneStructure, setCraneStructure } = props;

  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [capacities, setCapacities] = useState([]);
  const [craneStructureTypes, setCraneStructureTypes] = useState([]);
  const [craneStructureSubTypes, setCraneStructureSubTypes] = useState([]);
  const [craneStructureTypeSubTypes, setCraneStructureTypeSubTypes] = useState(
    []
  );
  const [requiredFields, setRequiredFields] = useState([]);
  const [isManufacturerCreated, setIsManufacturerCreated] = useState(false);
  const [isCapacityCreated, setIsCapacityCreated] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createCapacityModal, setCreateCapacityModal] = useState(false);
  const [createManufacturerModal, setCreateManufacturerModal] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (craneConfigModalContext.crane) {
      const craneStructure = craneConfigModalContext.crane.craneStructure || {
        craneStructureTypeId: HOIST_ONLY,
      };
      setCraneStructure({
        ...craneStructure,
        craneId: craneConfigModalContext.crane.id,
      });
    }
  }, [craneConfigModalContext.crane, setCraneStructure]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          manufacturers,
          capacities,
          craneStructureTypes,
          craneStructureSubTypes,
          craneStructureTypeSubTypes,
        ] = await Promise.all([
          cranesApi.getManufacturers({}),
          cranesApi.getCapacities({}),
          cranesApi.getCraneStructureTypes({}),
          cranesApi.getCraneStructureSubTypes({}),
          cranesApi.getCraneStructureTypeSubTypes({}),
        ]);
        setManufacturers(manufacturers);
        setCapacities(capacities);
        setCraneStructureTypes(craneStructureTypes);
        setCraneStructureSubTypes(craneStructureSubTypes);
        setCraneStructureTypeSubTypes(craneStructureTypeSubTypes);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isManufacturerCreated) {
      const fetchManufacturers = async () => {
        setLoading(true);
        const manufacturers = await cranesApi.getManufacturers({});
        setManufacturers(manufacturers);
        setLoading(false);
        setIsManufacturerCreated(false);
      };
      fetchManufacturers();
    }
  }, [craneStructure.manufacturer, isManufacturerCreated]);

  useEffect(() => {
    if (isCapacityCreated) {
      const fetchCapacities = async () => {
        setLoading(true);
        const capacities = await cranesApi.getCapacities({});
        setCapacities(capacities);
        setLoading(false);
        setIsCapacityCreated(false);
      };
      fetchCapacities();
    }
  }, [craneStructure.capacity, isCapacityCreated]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        const formDOMNode = findDOMNode(formRef.current);
        const requiredFieldsNotCompleted = [];
        formDOMNode.querySelectorAll("[required]").forEach((input) => {
          if (!input.value.trim()) {
            requiredFieldsNotCompleted.push(input.name);
          }
        });
        if (requiredFieldsNotCompleted.length === 0) {
          formDOMNode.dispatchEvent(
            new Event("submit", { cancelable: false, bubbles: true })
          );
          return true;
        } else {
          setRequiredFields(requiredFieldsNotCompleted);
          return false;
        }
      }
    },
  }));

  const getCraneStructureTypeSubTypes = (craneStructure) => {
    const fitleredCraneStructureSubTypes = craneStructureSubTypes.filter(
      (subType) =>
        craneStructureTypeSubTypes.find(
          (typeSubTypes) =>
            typeSubTypes.craneStructureTypeId ===
              craneStructure.craneStructureTypeId &&
            typeSubTypes.craneStructureSubTypeId === subType.id
        )
    );
    return fitleredCraneStructureSubTypes;
  };

  const isValidManufacturer = (name) => {
    return !manufacturers.find((manufacturer) => manufacturer.name === name);
  };

  const isValidCapacity = (name) => {
    return !capacities.find((capacity) => capacity.name === name);
  };

  const handleRequiredFields = (fieldName, fieldValue) => {
    if (requiredFields.includes(fieldName)) {
      setRequiredFields(requiredFields.filter((field) => field !== fieldName));
    } else if (fieldValue === "") {
      if (!requiredFields.includes(fieldName)) {
        setRequiredFields([...requiredFields, fieldName]);
      }
    }
  };

  return (
    <>
      <Col className="flex-grow-1">
        <Card className="py-2">
          <CardHeader className="d-flex justify-content-between">
            <div className="mr-3 flex-grow-1">
              <div className="h4">
                <span>Crane Structure</span>
              </div>
            </div>
          </CardHeader>
          <CardBody className="pb-1 overflow-x-auto">
            <Form ref={formRef}>
              <Row>
                {loading ? (
                  <Loader size="sm" />
                ) : (
                  <>
                    <Col sm="3">
                      <FormGroup
                        className={`d-flex flex-column align-items-start ${
                          craneStructure.craneStructureTypeId === HOIST_ONLY
                            ? "mb-0"
                            : ""
                        }`}
                      >
                        <Label>
                          <span>Structure Type</span>
                          <span className="text-danger ml-1">*</span>
                        </Label>
                        <CustomInput
                          id="craneStructureType"
                          required={true}
                          type="select"
                          name="craneStructureType"
                          value={craneStructure.craneStructureTypeId}
                          onChange={(e) => {
                            const selected = craneStructureTypes.find(
                              (m) => m.id === e.currentTarget.value
                            );
                            setCraneStructure({
                              ...craneStructure,
                              craneId: craneStructure.craneId,
                              craneStructureTypeId: e.currentTarget.value,
                              craneStructureType: selected,
                              craneStructureSubTypeId: null,
                              craneStructureSubType: null,
                            });
                            handleRequiredFields(e.target.name, e.target.value);
                          }}
                          invalid={requiredFields.includes(
                            "craneStructureType"
                          )}
                        >
                          <option value="">
                            Select a crane structure type...
                          </option>
                          {craneStructureTypes.map((m) => (
                            <option key={m.id} value={m.id}>
                              {m.name}
                            </option>
                          ))}
                          <option value={HOIST_ONLY}>Hoist Only</option>
                        </CustomInput>
                      </FormGroup>
                    </Col>
                    {getCraneStructureTypeSubTypes(craneStructure).length ? (
                      <Col sm="3">
                        <FormGroup className="d-flex flex-column align-items-start">
                          <Label>
                            <span>Structure Sub Type</span>
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <CustomInput
                            id="craneStructureSubType"
                            required={true}
                            type="select"
                            name="craneStructureSubType"
                            value={craneStructure.craneStructureSubTypeId || ""}
                            onChange={(e) => {
                              const craneStructureSubTypes =
                                getCraneStructureTypeSubTypes(craneStructure);
                              const selected = craneStructureSubTypes?.find(
                                (m) => m.id === e.currentTarget.value
                              );
                              setCraneStructure({
                                ...craneStructure,
                                craneStructureSubTypeId: selected?.id,
                                craneStructureSubType: selected,
                              });
                              handleRequiredFields(
                                e.target.name,
                                e.target.value
                              );
                            }}
                            invalid={requiredFields.includes(
                              "craneStructureSubType"
                            )}
                          >
                            <option value="">
                              Select a crane structure sub type...
                            </option>
                            {getCraneStructureTypeSubTypes(craneStructure).map(
                              (m) => (
                                <option key={m.id} value={m.id}>
                                  {m.name}
                                </option>
                              )
                            )}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    ) : null}
                    {craneStructure.craneStructureTypeId !== HOIST_ONLY ? (
                      <>
                        <Col sm="3">
                          <FormGroup className="d-flex flex-column align-items-start">
                            <Label>
                              <span>Manufacturer</span>
                              <span className="text-danger ml-1">*</span>
                            </Label>
                            <CustomInput
                              id="manufacturer"
                              required={true}
                              type="select"
                              name="manufacturer"
                              value={craneStructure.manufacturerId || ""}
                              onChange={(e) => {
                                if (
                                  e.currentTarget.value ===
                                  OTHER_MANUFACTURER_VALUE
                                ) {
                                  setCreateManufacturerModal(true);
                                } else {
                                  const selected = manufacturers.find(
                                    (m) => m.id === e.currentTarget.value
                                  );
                                  setCraneStructure({
                                    ...craneStructure,
                                    manufacturerId: selected?.id,
                                    manufacturer: selected,
                                  });
                                  handleRequiredFields(
                                    e.target.name,
                                    e.target.value
                                  );
                                }
                              }}
                              invalid={requiredFields.includes("manufacturer")}
                            >
                              <option value="">Select a manufacturer...</option>
                              <option value={OTHER_MANUFACTURER_VALUE}>
                                Other
                              </option>
                              {manufacturers.map((m) => (
                                <option key={m.id} value={m.id}>
                                  {m.name}
                                </option>
                              ))}
                            </CustomInput>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup className="d-flex flex-column align-items-start">
                            <Label>
                              <span>Capacity</span>
                              <span className="text-danger ml-1">*</span>
                            </Label>
                            <CustomInput
                              id="capacity"
                              required={true}
                              type="select"
                              name="capacity"
                              value={craneStructure.capacityId || ""}
                              onChange={(e) => {
                                if (
                                  e.currentTarget.value === OTHER_CAPACITY_VALUE
                                ) {
                                  setCreateCapacityModal(true);
                                } else {
                                  const selected = capacities.find(
                                    (c) => c.id === e.currentTarget.value
                                  );
                                  setCraneStructure({
                                    ...craneStructure,
                                    capacityId: selected?.id,
                                    capacity: selected,
                                  });
                                  handleRequiredFields(
                                    e.target.name,
                                    e.target.value
                                  );
                                }
                              }}
                              invalid={requiredFields.includes("capacity")}
                            >
                              <option value="">Select a capacity...</option>
                              <option value={OTHER_CAPACITY_VALUE}>
                                Other
                              </option>
                              {capacities.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {c.name}
                                </option>
                              ))}
                            </CustomInput>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup className="d-flex flex-column align-items-start">
                            <Label>
                              <span>Model</span>
                            </Label>
                            <Input
                              placeholder="Enter the model"
                              type="text"
                              name="model"
                              value={craneStructure.model || ""}
                              onChange={(e) =>
                                setCraneStructure({
                                  ...craneStructure,
                                  model: e.currentTarget.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup className="d-flex flex-column align-items-start mb-0">
                            <Label>
                              <span>Serial #</span>
                            </Label>
                            <Input
                              placeholder="Enter the serial #"
                              type="text"
                              name="serialNumber"
                              value={craneStructure.serialNumber || ""}
                              onChange={(e) =>
                                setCraneStructure({
                                  ...craneStructure,
                                  serialNumber: e.currentTarget.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </>
                    ) : null}
                  </>
                )}
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : createCapacityModal ? (
        <CraneConfigCapacityModal
          mode={STRUCTURE_MODE}
          isValid={isValidCapacity}
          onClose={() => setCreateCapacityModal(false)}
          onCreated={(createdCapacity) => {
            setCreateCapacityModal(false);
            setCraneStructure({
              ...craneStructure,
              capacityId: createdCapacity?.id,
              capacity: createdCapacity,
            });
            setIsCapacityCreated(true);
          }}
        />
      ) : createManufacturerModal ? (
        <CraneConfigManufacturerModal
          mode={STRUCTURE_MODE}
          isValid={isValidManufacturer}
          onClose={() => setCreateManufacturerModal(false)}
          onCreated={(createdManufacturer) => {
            setCreateManufacturerModal(false);
            setCraneStructure({
              ...craneStructure,
              manufacturerId: createdManufacturer?.id,
              manufacturer: createdManufacturer,
            });
            setIsManufacturerCreated(true);
          }}
        />
      ) : null}
    </>
  );
});

export default CraneConfigStructureModal;
