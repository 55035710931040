import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Input,
  Form,
  FormFeedback,
} from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import { cranesApi } from "../../../../services/craneServices";

const STRUCTURE_MODE = "Structure";

const CraneConfigManufacturerModal = ({
  mode,
  onClose,
  onCreated,
  isValid,
}) => {
  const [manufacturer, setManufacturer] = useState("");

  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isValid(manufacturer)) {
      setInvalid(true);
    } else {
      setLoading(true);
      try {
        setLoading(true);
        const createdManufacturer = await cranesApi.createManufacturer({
          name: manufacturer,
          equipmentList: mode === STRUCTURE_MODE ? ["CRANE"] : ["HOIST"],
        });
        setLoading(false);
        onCreated(createdManufacturer);
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>{mode} Manufacturer</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="text-center">
          <FormGroup className="d-flex flex-column align-items-start">
            <Label for="model">
              <span>Name</span>
            </Label>
            <Input
              placeholder="Enter the name.."
              invalid={invalid}
              type="text"
              name="name"
              value={manufacturer}
              onChange={(e) => {
                setInvalid(false);
                setManufacturer(e.currentTarget.value);
              }}
            />
            <FormFeedback>This manufacturer already exists</FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button
              disabled={loading || !manufacturer}
              color={"primary"}
              type="submit"
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CraneConfigManufacturerModal;
