import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Table,
} from "reactstrap";
import CraneName from "../cranes/CraneName";

const CustomerLocationCranesModal = ({ customerLocation, onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Customer Location Cranes</ModalHeader>
      <ModalBody>
        <ListGroup>
          <ListGroupItem
            className="justify-content-center bg-lighter d-flex align-items-center font-weight-bold"
            tag="div"
          >
            Cranes
          </ListGroupItem>
          {(customerLocation.cranes || []).length ? (
            <Table className="col-12 px-0 border rounded mb-0">
              <thead className="small text-muted">
                <tr className="bg-lighter">
                  <th className="text-left">Name</th>
                  <th className="text-right">Location</th>
                </tr>
              </thead>
              <tbody>
                {customerLocation.cranes.map((crane) => {
                  return (
                    <tr key={crane.id}>
                      <td className="text-left">
                        <CraneName crane={crane} />
                      </td>
                      <td className="text-right">
                        {crane.UDF_EQ_LOC ? crane.UDF_EQ_LOC : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <ListGroupItem className="d-flex justify-content-between" tag="div">
              <small className="text-muted">No cranes to show</small>
            </ListGroupItem>
          )}
        </ListGroup>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button color={"primary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CustomerLocationCranesModal;
