import React, { useReducer, createContext, useContext } from "react";
import { utils } from "../utils/utils";

const initialState = {
  workOrder: null,
  materialsOrdered: [],
  activeTab: null,
  activeDate: null,
  activeCrane: null,
  employeeWorkDaysByDate: null,
};

const JOB_TAB = "JOB_TAB";
const WORK_PERFORMED_TAB = "WORK_PERFORMED_TAB";
const REPORT_TAB = "REPORT_TAB";

const STATUSES = {
  STATUS_PENDING: "Pending",
  STATUS_SCHEDULED: "Scheduled",
  STATUS_IN_PROGRESS: "In Progress",
  STATUS_WAITING_FOR_PARTS: "Waiting for Parts",
  STATUS_COMPLETED: "Completed",
  STATUS_READY_TO_INVOICE: "Ready to Invoice",
  STATUS_INVOICED: "Invoiced",
};

const TABS = {
  JOB_TAB,
  WORK_PERFORMED_TAB,
  REPORT_TAB,
};

const getDefaultTabWO = (workOrder) => {
  if (!workOrder) {
    return JOB_TAB;
  }
  return workOrder.status.name === STATUSES.STATUS_PENDING ||
    workOrder.status.name === STATUSES.STATUS_SCHEDULED
    ? JOB_TAB
    : WORK_PERFORMED_TAB;
};

const ACTIONS = {
  RESET: "RESET",
  REFRESH: "REFRESH",
  CHANGE_TAB: "CHANGE_TAB",
  CHANGE_DATE: "CHANGE_DATE",
  CHANGE_CRANE: "CHANGE_CRANE",
  GET_WORKORDER_SUCCESS: "GET_WORKORDER_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_WORKORDER_SUCCESS: {
      const { payload } = data;
      const { workOrder } = payload;

      const employeeWorkDaysByDate = {};

      //its ok here, each date will have only one work day and trip
      const workOrderEmployees = workOrder.workOrderTrips.flatMap(
        (e) => e.workOrderEmployees
      );

      workOrderEmployees.forEach((workOrderEmployee) => {
        workOrderEmployee.employeeWorkDays
          .map((employeeWorkDay) => ({
            ...employeeWorkDay,
            workOrderEmployeeId: workOrderEmployee.id,
          }))
          .forEach((employeeWorkDay) => {
            const key = utils.formatDate(employeeWorkDay.date);
            employeeWorkDay.workOrderTripId = workOrderEmployee.workOrderTripId;
            if (employeeWorkDaysByDate[key]) {
              employeeWorkDaysByDate[key].push(employeeWorkDay);
            } else {
              employeeWorkDaysByDate[key] = [employeeWorkDay];
            }
          });
      });

      const materialsOrdered = Object.keys(employeeWorkDaysByDate)
        .flatMap((key) => employeeWorkDaysByDate[key])
        .reduce(
          (p, c) => [
            ...p,
            ...c.materialsOrdered.map((mo) => ({
              ...mo,
              employeeWorkDayId: c.id,
            })),
          ],
          []
        );

      return {
        ...state,
        activeTab: state.activeTab || getDefaultTabWO(workOrder),
        workOrder,
        employeeWorkDaysByDate,
        materialsOrdered,
        activeDate:
          state.activeDate ||
          Object.keys(employeeWorkDaysByDate).sort(utils.sortDays)[0],
      };
    }

    case ACTIONS.REFRESH: {
      const newState = {
        ...state,
        refresh: !state.refresh,
      };

      return { ...newState };
    }

    case ACTIONS.RESET: {
      const newState = {
        ...initialState,
        activeTab: state.activeTab,
      };

      return { ...newState };
    }

    case ACTIONS.CHANGE_TAB: {
      const { payload } = data;
      const { tab } = payload;

      return {
        ...state,
        activeTab: tab,
      };
    }

    case ACTIONS.CHANGE_DATE: {
      const { payload } = data;
      const { date } = payload;

      return {
        ...state,
        activeDate: date,
      };
    }

    case ACTIONS.CHANGE_CRANE: {
      const { payload } = data;
      const { activeCrane } = payload;

      return {
        ...state,
        activeCrane,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const workOrderDetails = createContext(initialState);

const WorkOrderDetailsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <workOrderDetails.Provider value={stateAndDispatch}>
      {children}
    </workOrderDetails.Provider>
  );
};

export const useWorkOrderDetails = () => useContext(workOrderDetails);

export { workOrderDetails, WorkOrderDetailsProvider, ACTIONS, STATUSES, TABS };
