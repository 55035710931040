import React, { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipItem = ({
  position = "top",
  id,
  isOpen,
  style,
  children,
  title,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState();

  useEffect(() => {
    setTooltipOpen(isOpen);
  }, [isOpen]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <span id={"tooltip-" + id} style={style}>
        {children}
      </span>
      <Tooltip
        placement={position}
        isOpen={tooltipOpen}
        target={"tooltip-" + id}
        toggle={toggle}
      >
        {title}
      </Tooltip>
    </span>
  );
};

export default TooltipItem;
