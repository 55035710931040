import React, { useState } from "react";

import {
  faCheck,
  faChevronDown,
  faChevronRight,
  faExclamationCircle,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Badge, Button, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";

import InformationModal from "../../../InformationModal";
import MaterialOrderedModal from "./MaterialOrderedModal";

const MaterialsOrdered = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [isCollapseOpen, setIsCollapseOpen] = useState();
  const [editMaterialModal, setEditMaterialModal] = useState({
    isOpen: false,
    material: null,
  });

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const openImageModal = (url, description) => {
    setInformationModal({
      isOpen: true,
      title: "Photo Preview",
      rawBody: true,
      body: (
        <div className="d-flex flex-column text-center">
          <img className="img-fluid rounded" src={url} alt={description} />
          {description ? <span className="mt-2">{description}</span> : null}
        </div>
      ),
    });
  };

  return workOrderDetails.materialsOrdered.length ? (
    <div className="d-flex flex-column mt-2">
      <Button
        color={
          workOrderDetails.materialsOrdered.filter((mo) => !mo.received).length
            ? "warning"
            : "success"
        }
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        style={{ width: "100%" }}
      >
        <div className="d-flex justify-content-between m-1 align-middle">
          <div className="px-0 col-3 d-flex align-items-center justify-content-start">
            {!workOrderDetails.materialsOrdered.filter((mo) => !mo.received)
              .length ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faExclamationCircle} />
            )}
          </div>
          <span>Material Needed</span>
          <div className="px-0 col-3 d-flex align-items-center justify-content-end">
            <FontAwesomeIcon
              icon={isCollapseOpen ? faChevronDown : faChevronRight}
              fixedWidth
              className="my-1"
            />
          </div>
        </div>
      </Button>
      <Collapse isOpen={isCollapseOpen} className="my-2">
        {workOrderDetails.materialsOrdered.map((materialOrdered, index) => (
          <ListGroup key={index} className="d-flex col-12 px-0 my-2">
            <ListGroupItem
              className="bg-lighter d-flex justify-content-between align-items-center font-500"
              tag="div"
            >
              <div className="col-3 px-0">
                {materialOrdered.received ? (
                  <Badge className="mr-2" color="success">
                    Received
                  </Badge>
                ) : (
                  <Badge className="mr-2" color="danger">
                    Not received
                  </Badge>
                )}
              </div>
              <span>Parts Needed</span>
              <div className="col-3 d-flex justify-content-end align-items-center px-0">
                <Button
                  size="sm"
                  className="rounded"
                  color="info"
                  onClick={() =>
                    setEditMaterialModal({
                      isOpen: true,
                      material: materialOrdered,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faTools} className="mr-1" />
                  <span>Resolve</span>
                </Button>
              </div>
            </ListGroupItem>
            <ListGroupItem className="d-flex align-items-stretch" tag="div">
              <div
                className="col-3 px-2 py-1 my-1 rounded"
                style={{ backgroundColor: "#FDF1DB" }}
              >
                <small className="text-muted text-break">
                  {materialOrdered.note || "No Description"}
                </small>
              </div>
              <div className="d-flex flex-grow-1 ml-4 flex-wrap">
                {materialOrdered.materialAttachments.length ? (
                  materialOrdered.materialAttachments.map(
                    (materialAttachment) => (
                      <div
                        key={materialAttachment.id}
                        className="figure img-thumbnail cursor-pointer m-1"
                        onClick={() =>
                          openImageModal(materialAttachment.contentUrl)
                        }
                        style={{
                          backgroundPosition: "center",
                          backgroundImage: `url(${materialAttachment.contentUrl})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          cursor: "pointer",
                          height: "160px",
                          width: "160px",
                        }}
                      ></div>
                    )
                  )
                ) : (
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center"
                    tag="div"
                  >
                    <small className="text-muted">No photos to show</small>
                  </ListGroupItem>
                )}
              </div>
            </ListGroupItem>
          </ListGroup>
        ))}
      </Collapse>
      {informationModal.isOpen && (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
          }
        />
      )}
      {editMaterialModal.isOpen ? (
        <MaterialOrderedModal
          onClose={() =>
            setEditMaterialModal({
              isOpen: false,
              material: null,
            })
          }
          material={editMaterialModal.material}
        />
      ) : null}
    </div>
  ) : null;
};

export default MaterialsOrdered;
