import axios from "axios";
import config from "../config/config";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const authApi = {
  login: (data) =>
    axios("/api/auth/w/sign-in", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data: {
        email: data.email,
        password: data.password,
      },
    }).then((result) => result.data),
  logout: () =>
    axios(`/api/auth/logout`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
    }).then((result) => result.data),
  validate: () =>
    axios(`/api/auth/w/validate`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err)),
};
