import React, { useState } from "react";

import InformationModal from "../../InformationModal";
import {
  CustomInput,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from "reactstrap";

import Loader from "../../Loader";

import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { useWorkOrderStatus } from "../../../providers/workOrderStatusProvider";
import { workOrdersApi } from "../../../services/workOrdersServices";

const ChangeStatusModal = ({ onClose, onSubmit }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [workOrderStatusContext] = useWorkOrderStatus();

  const [loading, setLoading] = useState();
  const [status, setStatus] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await workOrdersApi.updateWorkOrder({
        ...workOrderDetails.workOrder,
        statusId: status,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Change Work Order Status",
        body: "Work Order status changed successfully",
        onClose: onSubmit,
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Change Work Order Status",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Change Work Order Status</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <FormGroup className="d-flex justify-content-between mb-0">
              <Label className="text-left col-4 mb-0 d-flex align-items-center">
                New Status <small className="ml-1 text-danger">*</small>
              </Label>
              <CustomInput
                id="statusSelect"
                required={true}
                type="select"
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={""}>Select One</option>
                {workOrderStatusContext.workOrderStatus.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Discard
          </Button>{" "}
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button
              className="d-flex align-items-center"
              color={"primary"}
              type={"submit"}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ChangeStatusModal;
