import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 100;
const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const initialState = {
  calendarGroupings: {},
  sizePerPage: INIT_PAGE_SIZE,
  page: INIT_PAGE,
  search: "",
  sortBy: "",
  direction: "asc",
};

const ACTIONS = {
  SORT: "SORT",
  SEARCH: "SEARCH",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_CALENDAR_GROUPINGS_SUCCESS: "GET_CALENDAR_GROUPINGS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_CALENDAR_GROUPINGS_SUCCESS: {
      const { payload } = data;
      const { calendarGroupings } = payload;

      return {
        ...state,
        calendarGroupings,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { search } = payload;

      return {
        ...state,
        search,
        page: INIT_PAGE,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: INIT_PAGE,
      };
    }

    default:
      throw new Error();
  }
};

const CalendarGroupingsContext = createContext(initialState);

const CalendarGroupingsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CalendarGroupingsContext.Provider value={stateAndDispatch}>
      {children}
    </CalendarGroupingsContext.Provider>
  );
};

export const useCalendarGroupings = () => useContext(CalendarGroupingsContext);

export {
  CalendarGroupingsContext,
  CalendarGroupingsProvider,
  ACTIONS,
  MAX_PAGE_SIZE,
};
