import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  CustomInput,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { recurringWorkOrdersApi } from "../../../services/recurringWorkOrdersServices";
import { customersApi } from "../../../services/customerServices";

import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { utils } from "../../../utils/utils";
import { useAuth } from "../../../providers/authProvider";
import { commonApi } from "../../../services/commonServices";
import SelectWrapper from "../../SelectWrapper";

const EMPLOYEE_ROLE_EXECUTIVE = 1;

const TYPE_MONTHLY = "MONTHLY";
const TYPE_QUARTERLY = "QUARTERLY";

const PAGE_SIZE = 999;

const CreateRecurringWorkOrderModal = ({ onSubmit, onClose }) => {
  const [authContext] = useAuth();

  const [recurringWo, setRecurringWo] = useState({
    customerId: "",
    customerLocationId: "",
    type: TYPE_MONTHLY,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedCustomerLocation, setSelectedCustomerLocation] = useState();
  const [selectedServiceLocation, setSelectedServiceLocation] = useState(
    authContext.currentUser.serviceLocations
  );

  const [loading, setLoading] = useState();

  const [errors, setErrors] = useState();

  const doSubmit = (e) => {
    e.preventDefault();
    if (!selectedCustomer) {
      return setInformationModal({
        isOpen: true,
        title: "Create Recurring Work Order",
        body: "Please select a customer.",
      });
    }
    if (!selectedCustomerLocation) {
      return setInformationModal({
        isOpen: true,
        title: "Create Recurring Work Order",
        body: "Please select a customer location.",
      });
    }
    setLoading(true);
    const data = {
      ...recurringWo,
      customerId: selectedCustomer.id,
      customerLocationId: selectedCustomerLocation.id,
      serviceLocationId: selectedServiceLocation.id,
    };
    recurringWorkOrdersApi
      .createRecurringWorkOrder(data)
      .then(() => {
        setLoading(false);
        onSubmit();
      })
      .catch((err) => {
        setLoading(false);
        if (err.isAxiosError) {
          setErrors(err.response.data);
          return;
        }
        setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={
            <Button className="close" color="none" onClick={onClose}>
              &times;
            </Button>
          }
        >
          Create Recurring WorkOrder
        </ModalHeader>
        <ModalBody>
          {errors && (
            <Alert color="dark">
              <div className="p-2">
                {(errors || []).map((error) => (
                  <div key={error.param}>{error.msg}</div>
                ))}
              </div>
            </Alert>
          )}
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <>
                <Col sm="12" className="mb-3">
                  <Label>
                    Customer
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <SelectWrapper
                    isRequired={true}
                    entity="customer"
                    formatItemFunction={(c) => {
                      return {
                        label: c.customerName,
                        value: c.id,
                      };
                    }}
                    filterItemFunction={(c) => c.customerName}
                    fetchFunction={customersApi.getCustomers}
                    fetchParameters={{
                      pageSize: PAGE_SIZE,
                    }}
                    defaultSelected={selectedCustomer}
                    onSelected={(customer) => {
                      setSelectedCustomer(customer);
                      setSelectedCustomerLocation("");
                    }}
                  />
                </Col>
                {selectedCustomer ? (
                  <Col sm="12" className="mb-3">
                    <Label>
                      Customer Location
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <SelectWrapper
                      isRequired={true}
                      key={selectedCustomer.id}
                      entity="customer location"
                      formatItemFunction={(c) => {
                        return {
                          label: utils.getAddress(c),
                          value: c.id,
                        };
                      }}
                      filterItemFunction={(c) =>
                        c.customerId === selectedCustomer.id
                      }
                      fetchFunction={customersApi.getCustomerLocations}
                      fetchParameters={{
                        pageSize: PAGE_SIZE,
                        customerId: selectedCustomer.id,
                      }}
                      defaultSelected={selectedCustomerLocation}
                      onSelected={(customerLocation) =>
                        setSelectedCustomerLocation(customerLocation)
                      }
                    />
                  </Col>
                ) : null}
                <Col sm="12" className="mb-3">
                  <Label>
                    Service Location
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <SelectWrapper
                    isRequired={true}
                    entity="service location"
                    formatItemFunction={(c) => {
                      return {
                        label: c.name,
                        value: c.id,
                      };
                    }}
                    fetchFunction={
                      parseInt(authContext.currentUser.roleId) ===
                      EMPLOYEE_ROLE_EXECUTIVE
                        ? commonApi.getServiceLocations
                        : () => authContext.currentUser.serviceLocations
                    }
                    fetchParameters={{
                      pageSize: PAGE_SIZE,
                    }}
                    defaultSelected={selectedServiceLocation}
                    onSelected={(serviceLocation) =>
                      setSelectedServiceLocation(serviceLocation)
                    }
                  />
                </Col>
                <Col sm="12">
                  <FormGroup className="mb-0">
                    <Label>
                      Type
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <CustomInput
                      id="typeSelect"
                      required={true}
                      type="select"
                      name="type"
                      value={recurringWo.type}
                      onChange={(e) =>
                        setRecurringWo({
                          ...recurringWo,
                          type: e.target.value,
                        })
                      }
                    >
                      <option value={TYPE_MONTHLY}>Monthly</option>
                      <option value={TYPE_QUARTERLY}>Quarterly</option>
                    </CustomInput>
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateRecurringWorkOrderModal;
