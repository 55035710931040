import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";

import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { Badge, Button, ListGroup, ListGroupItem } from "reactstrap";

import { utils } from "../../../../utils/utils";

import JSAModal from "../JSAModal";
import JSAModalEditEmployee from "./Edition/JSAModalEditEmployee";

const JSAAnalysisSection = ({ date, trip, totalJSAItems }) => {
  const [JSAModalOpen, setJSAModalOpen] = useState();

  const employeeWorkDays = trip.workOrderEmployees
    .flatMap((workOrderEmployee) =>
      workOrderEmployee.employeeWorkDays.map((item) => ({
        ...item,
        workOrderEmployeeId: workOrderEmployee.id,
      }))
    )
    .filter((ewd) => moment(ewd.date).isSame(moment(date), "date"));

  const employees = trip.workOrderEmployees
    .sort((workOrderEmployee) => (workOrderEmployee.isLead ? -1 : 1))
    .filter((workOrderEmployee) => workOrderEmployee.employee.isActive);

  return (
    <>
      <ListGroup className="my-2 col-12 px-0">
        <ListGroupItem className="d-flex justify-content-between align-items-center bg-lighter font-weight-bold">
          Job Safety Analysis
        </ListGroupItem>
        {trip.workOrderEmployees.map((workOrderEmployee) => {
          const employeeWorkDay = employeeWorkDays.find(
            (employeeWorkDay) =>
              employeeWorkDay.workOrderEmployeeId === workOrderEmployee.id
          );
          if (!employeeWorkDay) {
            return null;
          }
          //todo use a dedicated endpoint to get jsaAnswers and dont overload getWorkOrderById
          const JSAAnswers = employeeWorkDay.jsaAnswers;
          const { employee } = workOrderEmployee;
          const completedAt =
            JSAAnswers?.length === totalJSAItems && totalJSAItems !== 0
              ? utils.formatDate(
                  moment.max(
                    JSAAnswers.map((JSAAnswer) => moment(JSAAnswer.updatedAt))
                  ),
                  "MM/DD/YYYY [at] h:mm a"
                )
              : null;
          const author = completedAt ? JSAAnswers[0].author : null;
          const completedBy = author
            ? `${author?.firstName} ${author?.lastName}`
            : null;
          return (
            <ListGroupItem
              key={employeeWorkDay.id}
              className="d-flex justify-content-between align-items-center"
            >
              {completedAt ? (
                <div className="col-12 px-0 d-flex justify-content-between align-items-center">
                  <div>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success mr-2"
                    />
                    <span>
                      Completed on {completedAt} by {completedBy}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    {employeeWorkDay.isLeadDay ? (
                      <Badge pill className="mr-2">
                        Lead
                      </Badge>
                    ) : null}
                    <Badge
                      pill
                      color="white"
                      className="border border-primary text-primary"
                    >
                      {`${employee.firstName} ${employee.lastName}`}
                    </Badge>
                    <Button
                      size="sm"
                      className="rounded ml-2"
                      color="info"
                      onClick={() => setJSAModalOpen(employeeWorkDay)}
                    >
                      See More
                    </Button>
                    {employees &&
                    !employees.every(
                      (workOrderEmployee) =>
                        workOrderEmployee.employeeId === employee.id
                    ) ? (
                      <JSAModalEditEmployee
                        actualEmployee={author.id}
                        JSAAnswers={JSAAnswers}
                        employeeWorkDayId={employeeWorkDay.id}
                        employees={employees}
                      />
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="col-12 px-0 d-flex justify-content-between align-items-center">
                  <div>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-warning mr-2"
                    />
                    <span>Not Complete</span>
                  </div>
                  <div className="d-flex align-items-center">
                    {employeeWorkDay.isLeadDay ? (
                      <Badge pill className="mr-2">
                        Lead
                      </Badge>
                    ) : null}
                    <Badge
                      pill
                      color="white"
                      className="border border-primary text-primary"
                    >
                      {`${employee.firstName} ${employee.lastName}`}
                    </Badge>
                  </div>
                </div>
              )}
            </ListGroupItem>
          );
        })}
      </ListGroup>
      {JSAModalOpen ? (
        <JSAModal
          employeeWorkDay={JSAModalOpen}
          onClose={() => setJSAModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default JSAAnalysisSection;
