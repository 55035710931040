import React, { useState } from "react";

import { faPen, faCalendar, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Row, ListGroup, ListGroupItem, Badge } from "reactstrap";

import Label from "reactstrap/lib/Label";

import InformationModal from "../../../components/InformationModal";
import EditScheduleModal from "../../../components/admin/workOrders/EditScheduleModal";
import EditDetailsModal from "../../../components/admin/workOrders/EditDetailsModal";
import {
  ACTIONS,
  useScheduleModal,
  MODES,
} from "../../../providers/scheduleModalProvider";
import {
  ACTIONS as WORK_ORDER_DETAILS,
  useWorkOrderDetails,
  STATUSES,
} from "../../../providers/workOrderDetailsProvider";

import CraneName from "../cranes/CraneName";
import { utils } from "../../../utils/utils";
import WorkOrderContactsModal from "./WorkOrderContactsModal";
import WorkOrderTrip from "./WorkOrderTrip";
import DateRangeLabel from "../../DateRangeLabel";
import WorkOrderLocationModal from "./WorkOrderLocationModal";

const WorkOrderJobDetails = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [scheduleModalContext, setScheduleModalContext] = useScheduleModal();

  const [editDetailsModal, setEditDetailsModal] = useState();
  const [contactsModal, setContactsModal] = useState();
  const [locationModal, setLocationModal] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onEditSchedule = () =>
    setScheduleModalContext({
      action: ACTIONS.INIT,
      payload: {
        currentData: workOrderDetails.workOrder,
        mode: MODES.MODE_WORK_ORDER,
      },
    });

  const onEditDetails = () => setEditDetailsModal(true);

  const onAddTrip = () =>
    setScheduleModalContext({
      action: ACTIONS.INIT,
      payload: {
        currentData: {},
        mode: MODES.MODE_WORK_ORDER_TRIP,
      },
    });

  const onRescheduleWO = () => {
    if (workOrderDetails.materialsOrdered.filter((mo) => !mo.received).length) {
      return setInformationModal({
        isOpen: true,
        title: "Reschedule Work Order",
        body: "There is material needed that hasn't been received. You can't reschedule. Please mark the material as received and try again.",
        onClose: null,
      });
    }
    setScheduleModalContext({
      action: ACTIONS.INIT,
      payload: {
        currentData: {},
        mode: MODES.MODE_WORK_ORDER_TRIP,
      },
    });
  };

  return (
    <div className="col-12">
      <div className="col-12 mt-4">
        <div className="h4 mb-2 pb-2 border-bottom d-flex justify-content-between align-items-center">
          <span>Work Order Schedule</span>
          <div>
            {workOrderDetails.workOrder.status.name ===
            STATUSES.STATUS_WAITING_FOR_PARTS ? (
              <Button
                size="sm"
                className="rounded mr-2"
                onClick={onRescheduleWO}
              >
                <FontAwesomeIcon icon={faCalendar} className="mr-1" />
                <span>Reschedule WO</span>
              </Button>
            ) : null}
            <Button
              size="sm"
              className="rounded"
              color="warning"
              onClick={onEditSchedule}
            >
              <FontAwesomeIcon icon={faPen} className="mr-1" />
              <span>Edit</span>
            </Button>
            {workOrderDetails.workOrder.status.name !==
            STATUSES.STATUS_WAITING_FOR_PARTS ? (
              <Button
                size="sm"
                className="rounded ml-2"
                color="success"
                onClick={onAddTrip}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                <span>Add Trip</span>
              </Button>
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-nowrap my-3 align-items-center">
          <Label className="col-2 text-underline mb-0">WO Dates</Label>
          <Row>
            <DateRangeLabel
              background="#F6FAFF"
              startDate={workOrderDetails.workOrder.startDate}
              endDate={workOrderDetails.workOrder.endDate}
            />
          </Row>
        </div>
        {workOrderDetails.workOrder.workOrderTrips
          .sort((x, y) => x.tripNumber - y.tripNumber)
          .map((trip) => (
            <WorkOrderTrip trip={trip} key={trip.id} />
          ))}
      </div>
      <div className="col-12 mt-2 mb-4">
        <div className="h4 mb-4 pb-2 border-bottom d-flex justify-content-between align-items-center">
          <span>Work Order Details</span>
          <Button
            size="sm"
            className="rounded"
            color="warning"
            onClick={onEditDetails}
          >
            <FontAwesomeIcon icon={faPen} className="mr-1" />
            <span>Edit</span>
          </Button>
        </div>
        <div className="d-flex flex-nowrap mb-4">
          <Label className="col-2 text-underline mb-0">Service Requested</Label>
          <div>
            <span>
              {utils.getWoServiceRequested(workOrderDetails.workOrder)}
            </span>
          </div>
        </div>
        <div className="d-flex flex-nowrap mb-4">
          <Label className="col-2 text-underline mb-0">PO#</Label>
          <div>
            <span>{workOrderDetails.workOrder.customerPONo || "-"}</span>
          </div>
        </div>
        <div className="d-flex flex-nowrap mb-4">
          <Label className="col-2 text-underline mb-0">Cranes</Label>
          <Row>
            {workOrderDetails.workOrder.workOrderCranes?.length ? (
              workOrderDetails.workOrder.workOrderCranes.map(({ crane }) => (
                <ListGroup className="mx-2 my-1" key={crane.id}>
                  <ListGroupItem
                    className="text-center bg-lighter d-flex align-items-center"
                    tag="div"
                  >
                    <CraneName crane={crane} />
                  </ListGroupItem>
                  <ListGroupItem className="text-center d-none" tag="div">
                    <small>{crane.ContactCode || "D# Not Set"}</small>
                  </ListGroupItem>
                </ListGroup>
              ))
            ) : (
              <div className="d-flex align-items-center col-12">No Cranes</div>
            )}
          </Row>
        </div>
        <div className="d-flex flex-nowrap border-bottom pb-4 mb-4 align-items-center">
          <Label className="col-2 text-underline mb-0">Job Type</Label>
          <Badge
            color={utils.getJobTypeColor(workOrderDetails.workOrder.jobType)}
          >
            {workOrderDetails.workOrder.jobType}
          </Badge>
        </div>
        <div className="d-flex flex-nowrap pb-3">
          <Label className="col-2 text-underline mb-0">Customer Location</Label>
          <div className="d-flex align-items-start justify-content-between flex-grow-1">
            <Row>
              {workOrderDetails.workOrder.customerLocation ? (
                utils.getAddress(workOrderDetails.workOrder.customerLocation)
              ) : (
                <div className="d-flex align-items-center col-12">
                  No Location
                </div>
              )}
            </Row>
            <Button
              size="sm"
              className="rounded"
              color="warning"
              onClick={() => setLocationModal(true)}
            >
              <FontAwesomeIcon icon={faPen} className="mr-1" />
              <span>
                {workOrderDetails.workOrder.customerLocation ? "Edit" : "Set"}
              </span>
            </Button>
          </div>
        </div>
        <div className="d-flex flex-nowrap pb-3">
          <Label className="col-2 text-underline mb-0">Customer Contacts</Label>
          <div className="d-flex align-items-start justify-content-between flex-grow-1">
            <Row>
              {!workOrderDetails.workOrder.customerLocation ? (
                <div className="d-flex align-items-center col-12 px-0">
                  Select a location
                </div>
              ) : workOrderDetails.workOrder.customerContacts?.length ? (
                workOrderDetails.workOrder.customerContacts.map(
                  (customerContact) => (
                    <ListGroup className="mx-2" key={customerContact.id}>
                      <ListGroupItem
                        className="text-center bg-lighter"
                        tag="div"
                      >
                        <span>{customerContact.name}</span>
                      </ListGroupItem>
                      <ListGroupItem className="text-center" tag="div">
                        <small>{customerContact.email}</small>
                      </ListGroupItem>
                      <ListGroupItem className="text-center" tag="div">
                        <small>{customerContact.phone}</small>
                      </ListGroupItem>
                    </ListGroup>
                  )
                )
              ) : (
                <div className="d-flex align-items-center col-12 px-0">
                  No Contacts
                </div>
              )}
            </Row>
            <Button
              disabled={!workOrderDetails.workOrder.customerLocation}
              size="sm"
              className="rounded"
              color="warning"
              onClick={() => setContactsModal(true)}
            >
              <FontAwesomeIcon icon={faPen} className="mr-1" />
              <span>Edit</span>
            </Button>
          </div>
        </div>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : scheduleModalContext.isOpen ? (
        <EditScheduleModal />
      ) : editDetailsModal ? (
        <EditDetailsModal onClose={() => setEditDetailsModal(false)} />
      ) : contactsModal ? (
        <WorkOrderContactsModal
          onClose={() => setContactsModal(false)}
          onSubmit={() => {
            setContactsModal(false);
            setWorkOrderDetails({
              action: WORK_ORDER_DETAILS.REFRESH,
            });
          }}
        />
      ) : locationModal ? (
        <WorkOrderLocationModal
          onClose={() => setLocationModal(false)}
          onSubmit={() => {
            setLocationModal(false);
            setWorkOrderDetails({
              action: WORK_ORDER_DETAILS.REFRESH,
            });
          }}
        />
      ) : null}
    </div>
  );
};

export default WorkOrderJobDetails;
