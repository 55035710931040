import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Input,
  Form,
} from "reactstrap";

import { cranesInspectionAttachmentApi } from "../../../services/craneInspectionAttachmentServices";

import Loader from "../../Loader";

const CraneInspectionAttachmentForm = ({ onClose, attachment, onSubmit }) => {
  const [loading, setLoading] = useState();
  const [attachmentState, setAttachmentState] = useState(attachment);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const res =
      await cranesInspectionAttachmentApi.updateCraneInspectionAttachment(
        attachmentState
      );
    setLoading(false);
    onSubmit(res);
  };

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column align-items-start">
          <span>Edit Note</span>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <Input
            type="textarea"
            placeholder="Enter the note"
            required={true}
            maxLength={255}
            value={attachmentState.content}
            onChange={(event) => {
              setAttachmentState({
                ...attachmentState,
                content: event.target.value,
              });
            }}
          />
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"primary"} onClick={onClose}>
            Discard
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"warning"} type="submit">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CraneInspectionAttachmentForm;
