import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalEdit = ({
  isOpen,
  onToggle,
  title,
  children,
  onCancel,
  onSave,
  isSaveDisabled,
}) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onToggle}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader close={closeBtn}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="primary" onClick={onCancel}>
          Discard
        </Button>
        <Button
          className="d-flex align-items-center"
          color="warning"
          onClick={onSave}
          disabled={isSaveDisabled}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
