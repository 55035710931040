import React, { useEffect, useState } from "react";
import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { useAuth } from "../providers/authProvider";
import { authApi } from "../services/authServices";
import Loader from "../components/Loader";
import Hamburger from "../components/Hamburger";
import { ACTIONS, useWorkOrderKPIs } from "../providers/workOrdersKPIsProvider";
import { workOrdersApi } from "../services/workOrdersServices";
import { Navigate } from "react-router-dom";
import Sidebar from "../pages/admin/sidebar/Sidebar";
import {
  useWorkOrderStatus,
  ACTIONS as STATUS_ACTIONS,
} from "../providers/workOrderStatusProvider";

const Admin = ({ roles, children }) => {
  const [authContext, setAuthContext] = useAuth();
  const [, setWorkOrderKPIsContext] = useWorkOrderKPIs();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [loading, setLoading] = useState();
  const [, setWorkOrderStatusContext] = useWorkOrderStatus();

  useEffect(() => {
    const fetchData = async () => {
      setWorkOrderKPIsContext({
        action: ACTIONS.GET_DATA_LOADING,
        payload: true,
      });
      const kpis = await workOrdersApi.getWorkOrdersKPIs({
        serviceLocationId: authContext.serviceLocationId,
      });
      setWorkOrderKPIsContext({
        action: ACTIONS.GET_DATA_SUCCESS,
        payload: { kpis },
      });
    };
    fetchData();
  }, [authContext.serviceLocationId, setWorkOrderKPIsContext]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await authApi.validate();
      if (!result) {
        setLoading(false);
        setRedirectToLogin(true);
      } else {
        setAuthContext({ currentUser: result });
        setLoading(false);
      }
    };
    if (!authContext.currentUser) {
      fetchData();
    }
  }, [authContext.currentUser, setAuthContext]);

  useEffect(() => {
    const fetchData = async () => {
      const workOrderStatus = await workOrdersApi.getWorkOrderStatus();
      setWorkOrderStatusContext({
        action: STATUS_ACTIONS.GET_WORK_ORDER_STATUS_SUCCESS,
        payload: { workOrderStatus },
      });
    };
    fetchData();
  }, [setWorkOrderStatusContext]);

  if (
    authContext.currentUser &&
    roles &&
    roles.indexOf(authContext.currentUser.roleId) < 0
  ) {
    return <Navigate to="/error/401" />;
  }

  return loading || !authContext.currentUser ? (
    <Loader />
  ) : redirectToLogin ? (
    <Navigate to={`/auth/sign-in`} />
  ) : (
    <React.Fragment>
      <Wrapper className="overflow-visible min-width-992px">
        <Sidebar />
        <Main className="min-width-lg d-flex flex-column justify-content-between">
          <Hamburger />
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Admin;
