import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faPaperPlane,
  faCog,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Row, Button, Col } from "reactstrap";
import { reportsApi } from "../../../../services/reportsServices";
import { pdfjs, Document, Page } from "react-pdf";
import Loader from "../../../Loader";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";

import ContactModal from "../../../ContactModal";
import InformationModal from "../../../InformationModal";
import { utils } from "../../../../utils/utils";
import TrolleyOptionsModal from "./TrolleyOptionsModal";
import download from "downloadjs";
import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TYPE_INSPECTION = "Inspection";

const WorkPerformedReport = ({ workOrderCrane = null }) => {
  const crane = workOrderCrane?.crane;

  const [workOrderDetails] = useWorkOrderDetails();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [report, setReport] = useState();
  const [loading, setLoading] = useState();
  const [sendingEmail, setSendingEmail] = useState();
  const [initialEmail, setInitialEmail] = useState(
    workOrderDetails.workOrder.emailAddress || ""
  );

  const [showContactModal, setShowContactModal] = useState({
    isOpen: false,
    title: "",
    isEmail: false,
    defaultRecipients: [],
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [hideTrolleyHoistSection, setHideTrolleyHoistSection] = useState(false);
  const [showTrolleyOptionsModal, setShowTrolleyOptionsModal] = useState(false);

  const onDownload = async () => {
    if (workOrderDetails.workOrder.jobType === TYPE_INSPECTION) {
      //INSPECTION: [DnumberKEY] [WO Number] [Customer] [Inspection Type] [Customer Equipment #] [Inspection Date] [ServiceLocation]
      const dates = (workOrderCrane?.craneInspections || [])
        .map((craneInspection) => craneInspection.employeeWorkDay.date)
        .join(",");
      const inspectionTypes = (workOrderCrane?.craneInspections || [])
        .map((craneInspection) => craneInspection.type)
        .join(",");
      const name = [
        crane?.ContactCode,
        `job#${workOrderDetails.workOrder.jobNo}`,
        workOrderDetails.workOrder.customerWorkOrder?.customerName,
        crane?.UDF_EQ_DESCR,
        inspectionTypes,
        workOrderDetails.workOrder.jobType,
        dates,
        workOrderDetails.workOrder.serviceLocation?.name,
      ]
        .filter(Boolean)
        .join("-");
      download(report, `${name}.pdf`, "application/pdf");
    } else {
      //SERVICE: [DNumberKEY] [WO #] [Customer] [Date] Service Ticket [Customer Equipment #]
      const trips = workOrderDetails.workOrder.workOrderTrips.sort((x, y) =>
        moment(x.startDate).isBefore(moment(y.startDate), "date") ? -1 : 1
      );
      const employeeWorkDays = trips
        .flatMap((trip) => trip.workOrderEmployees)
        .flatMap((workOrderEmployee) => workOrderEmployee.employeeWorkDays);

      const dates = Object.keys(
        employeeWorkDays
          .map((employeeWorkDay) => employeeWorkDay.date)
          .reduce((p, c) => {
            p[c] = c;
            return p;
          }, {})
      ).join(",");
      const dNumbers = workOrderDetails.workOrder.workOrderCranes
        .map((workOrderCranes) => workOrderCranes.crane.ContactCode)
        .join(",");
      const descriptions = workOrderDetails.workOrder.workOrderCranes
        .map((workOrderCranes) => workOrderCranes.crane.UDF_EQ_DESCR)
        .join(",");
      const name = [
        dNumbers,
        `job#${workOrderDetails.workOrder.jobNo}`,
        workOrderDetails.workOrder.customerWorkOrder?.customerName,
        descriptions,
        workOrderDetails.workOrder.jobType,
        dates,
        "Service Ticket",
      ]
        .filter(Boolean)
        .join("-");
      download(report, `${name}.pdf`, "application/pdf");
    }
  };

  useEffect(() => {
    const fetchReport = async () => {
      try {
        setLoading(true);
        const queryData = {
          workOrderId: workOrderDetails.workOrder.id,
          craneId: crane?.id,
          hideTrolleyHoistSection,
          browserTimeOffset: moment().utcOffset() / 60,
        };
        if (workOrderDetails.workOrder.jobType === TYPE_INSPECTION && !crane) {
          queryData.summary = true;
        }
        const result = await reportsApi.workOrderReport(queryData);
        const report = new Blob([result], { type: "application/pdf" });
        setLoading(false);
        setReport(report);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchReport();
  }, [workOrderDetails.workOrder, crane, hideTrolleyHoistSection]);

  const sendEmailReport = async (data) => {
    const recipients = data.recipients;
    const recipientsList = recipients.split(",").map((e) => e.trim());
    const allValidRecipients = recipientsList.filter((recipient) =>
      utils.validateEmail(recipient)
    );
    if (allValidRecipients.length === recipientsList.length) {
      try {
        setInitialEmail(recipients);
        setSendingEmail(true);
        await reportsApi.sendWorkOrderReport({
          workOrderId: workOrderDetails.workOrder.id,
          recipient: recipientsList,
          subject: data.subject,
          body: data.content,
          emailSignature: data.emailSignature,
          browserTimeOffset: moment().utcOffset() / 60,
        });
        setSendingEmail(false);
        setShowContactModal({ isOpen: false });
        setInformationModal({
          isOpen: true,
          title: "Send Report by Email",
          body: "Email sent successfully",
          onClose: setInformationModal({ isOpen: false, title: "", body: "" }),
        });
      } catch (err) {
        setSendingEmail(false);
        setShowContactModal({ isOpen: false });
        setInformationModal({
          isOpen: true,
          title: "Send Report by Email",
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
          onClose: onCloseInformationModal,
        });
      }
    } else {
      setShowContactModal({ isOpen: false });
      setInformationModal({
        isOpen: true,
        title: "Send Report by Email",
        body: `Please, enter ${
          recipientsList.length === 1 ? "a valid recipient" : "valid recipients"
        }`,
        onClose: onCloseInformationModal,
      });
    }
  };

  const onCloseInformationModal = () => {
    setInformationModal({ isOpen: false, title: "", body: "" });
    setShowContactModal({
      isOpen: true,
      isEmail: true,
      title: "Send Report by Email",
      defaultRecipients: [initialEmail],
    });
  };

  const onOpenTrolleyOptionsModal = () => setShowTrolleyOptionsModal(true);
  const onCloseTrolleyOptionsModal = () => setShowTrolleyOptionsModal(false);
  const onSaveTrolleyOptionsModal = (include) => {
    setHideTrolleyHoistSection(!include);
    onCloseTrolleyOptionsModal();
  };

  return (
    <Row className="d-flex">
      <Col className="flex-grow-1 px-1">
        {loading ? (
          <div className="my-2">
            <Loader size="sm" />
          </div>
        ) : !report ? (
          <div className="text-center text-muted mt-4">
            No data has been recorded
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-end mb-2">
              {crane ? (
                <Button
                  color="primary"
                  size="sm"
                  className="rounded"
                  onClick={onOpenTrolleyOptionsModal}
                >
                  <span className="font-500">Trolley Hoist</span>
                  <FontAwesomeIcon icon={faCog} className="ml-1" />
                </Button>
              ) : null}
              <Button
                color="primary"
                size="sm"
                className="rounded ml-1"
                onClick={onDownload}
              >
                <span className="font-500">PDF</span>
                <FontAwesomeIcon icon={faDownload} className="ml-1" />
              </Button>
              <Button
                color="primary"
                size="sm"
                className="rounded ml-1"
                onClick={() =>
                  setShowContactModal({
                    isOpen: true,
                    isEmail: true,
                    title: "Send Report by Email",
                    defaultRecipients: [initialEmail],
                  })
                }
              >
                <span className="font-500">Send Report</span>
                <FontAwesomeIcon icon={faPaperPlane} className="ml-1" />
              </Button>
            </div>
            {report ? (
              <Document
                className="d-flex justify-content-center"
                file={report}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                <Page className="border rounded" pageNumber={pageNumber} />
              </Document>
            ) : (
              <Loader size="sm" />
            )}
            <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
              <Button
                className="rounded"
                onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <span>
                Page {pageNumber} of {numPages}
              </span>
              <Button
                className="rounded"
                onClick={() =>
                  setPageNumber(Math.min(pageNumber + 1, numPages))
                }
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          </div>
        )}
      </Col>
      {showContactModal.isOpen ? (
        <ContactModal
          {...showContactModal}
          doSubmit={sendEmailReport}
          loading={sendingEmail}
          recipientsEditable={true}
          multipleRecipients={true}
          onClose={() =>
            setShowContactModal({
              isOpen: false,
            })
          }
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : showTrolleyOptionsModal ? (
        <TrolleyOptionsModal
          defaultValue={!hideTrolleyHoistSection}
          onSave={onSaveTrolleyOptionsModal}
          onClose={onCloseTrolleyOptionsModal}
        />
      ) : null}
    </Row>
  );
};

export default WorkPerformedReport;
