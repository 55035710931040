import React, { useCallback, useEffect, useState } from "react";

import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import InformationModal from "./InformationModal";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const SelectWrapper = ({
  className = "",
  isMulti,
  isDisabled,
  filterItemFunction = (item) => item,
  formatItemFunction = (item) => item,
  noOptionsAction = () => null,
  fetchFunction,
  fetchParameters = {},
  onSelected,
  defaultSelected,
  entity,
  isRequired,
  menuPortalTarget = null,
  name = null,
}) => {
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const formatOptionsFunction = useCallback(
    (items = []) =>
      items
        .filter(filterItemFunction)
        .map((item) => formatItemFunction(item))
        .sort((x, y) => y.order - x.order),
    [formatItemFunction, filterItemFunction]
  );

  const [rawOptions, setRawOptions] = useState();

  const [loading, setLoading] = useState();
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    if (!selectedItem && defaultSelected) {
      const items = Array.isArray(defaultSelected)
        ? defaultSelected.map((item) => formatItemFunction(item))
        : formatItemFunction(defaultSelected);
      setSelectedItem(items);
    }
  }, [
    defaultSelected,
    formatOptionsFunction,
    formatItemFunction,
    selectedItem,
  ]);

  const fetchOptions = useCallback(
    async (search = "") => {
      setLoading(true);
      const result = await fetchFunction({
        ...fetchParameters,
        search,
      });
      const rawOptions = (result?.data || result || []).sort((x, y) => {
        if (fetchParameters?.entity === "calendarGroupingEmployee") {
          const nameA = x.firstName.toLowerCase();
          const nameB = y.firstName.toLowerCase();
          return nameA.localeCompare(nameB);
        }
        return y.order - x.order;
      });
      if (!rawOptions.length) {
        noOptionsAction();
      }
      setRawOptions(rawOptions);
      const options = formatOptionsFunction(rawOptions);
      setLoading(false);
      return options;
    },
    [fetchFunction, fetchParameters, formatOptionsFunction, noOptionsAction]
  );

  useEffect(() => {
    if (!rawOptions) {
      fetchOptions();
    }
  }, [fetchOptions, rawOptions]);

  const onSelect = (selected) => {
    setSelectedItem(selected);
    const isArray = Array.isArray(selected);
    let items = [];
    if (isArray) {
      //need to handle the items that were here before, are selected but are not included in rawOptions bc of search
      const missingSelectedItems = selected.filter((sItem) => {
        const id = isNaN(sItem?.value) ? sItem?.value : parseInt(sItem?.value);
        return !rawOptions?.find((item) => item.id === id);
      });
      const extendedOptions = [
        ...(rawOptions || []),
        ...missingSelectedItems.map((item) => ({
          id: item.value,
          ContactCode: item.label,
        })),
      ];
      items = extendedOptions.filter((item) =>
        selected?.find((sItem) => {
          const id = isNaN(sItem?.value)
            ? sItem?.value
            : parseInt(sItem?.value);
          return id === item.id;
        })
      );
    } else {
      items = rawOptions?.filter((item) => {
        const id = isNaN(selected?.value)
          ? selected?.value
          : parseInt(selected?.value);
        return id === item.id;
      });
    }
    onSelected(isMulti ? items : items[0]);
  };

  const getArticle = (entity) => {
    if (!entity) return "a";
    const vowels = ["a", "e", "i", "o", "u"];
    return vowels.includes(entity[0].toLowerCase()) ? "an" : "a";
  };

  return (
    <>
      <AsyncSelect
        isMulti={isMulti}
        isDisabled={isDisabled}
        placeholder={
          <span className="text-muted">
            Search for {getArticle(entity)} {entity}..
          </span>
        }
        noOptionsMessage={() => `No ${entity}s found`}
        styles={style}
        className={`${className} flex-grow-1 border rounded text-left min-width-250 px-0`}
        cacheOptions
        defaultOptions={true}
        loadOptions={fetchOptions}
        closeMenuOnSelect={true}
        components={animatedComponents}
        value={selectedItem}
        isSearchable
        isClearable
        onChange={onSelect}
        isLoading={loading}
        required={isRequired}
        menuPortalTarget={menuPortalTarget}
        name={name}
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

export default SelectWrapper;
