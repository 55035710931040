import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const recurringWorkOrdersApi = {
  getRecurringWorkOrders: (data) => {
    const query = utils.buildQueryString(data);
    return axios(`/api/recurring-work-orders?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getRecurringWorkOrdersById: (id) => {
    const request = {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    };
    return axios(`/api/recurring-work-orders/${id}`, request)
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateRecurringWorkOrder: (data) => {
    return axios(`/api/recurring-work-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createRecurringWorkOrder: (data) => {
    return axios(`/api/recurring-work-orders`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteRecurringWorkOrder: (data) => {
    return axios(`/api/recurring-work-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createWorkOrderRecurringWorkOrder: (data) => {
    return axios(`/api/work-order-recurring-work-orders`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteWorkOrderRecurringWorkOrder: (data) => {
    return axios(`/api/work-order-recurring-work-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
