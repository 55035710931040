import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { customersApi } from "../../../../services/customerServices";
import { CRANE_SERVICE_STATUS, utils } from "../../../../utils/utils";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import SelectWrapper from "../../../SelectWrapper";
import { findDOMNode } from "react-dom";
// import { cranesApi } from "../../../../services/craneServices";

const PAGE_SIZE = 999;

const CraneConfigGeneralModal = forwardRef((props, ref) => {
  const { crane, setCrane, loading, setLoading, isCreate } = props;

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [selectedCustomer, setSelectedCustomer] = useState();

  const [selectedCustomerLocation, setSelectedCustomerLocation] = useState();

  const [requiredFields, setRequiredFields] = useState([]);
  const formRef = useRef(null);
  const [isInvalidContactCode, setIsInvalidContactCode] = useState(false);

  useEffect(() => {
    if (!isCreate && crane && Object.keys(crane).length > 0) {
      setSelectedCustomer(crane.customer);
      setSelectedCustomerLocation(crane.customerLocation);
    } else if (!isCreate && (!crane || Object.keys(crane).length === 0)) {
      setLoading(true);
    }
  }, [isCreate, crane, setLoading]);

  // useEffect(() => {
  //   if (isCreate) {
  //     setLoading(true);
  //     const createCraneNumber = async () => {
  //       try {
  //         const craneNumber = await cranesApi.createCraneNumber();
  //         setCrane((prevCrane) => ({
  //           ...prevCrane,
  //           ContactCode: craneNumber.dnumber,
  //         }));
  //       } catch (err) {
  //         setInformationModal({
  //           isOpen: true,
  //           title: "Error",
  //           body:
  //             err?.response?.data?.message ||
  //             "There was an error with your request.",
  //         });
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     createCraneNumber();
  //   }
  // }, [isCreate, setLoading, setCrane]);

  useEffect(() => {
    if (selectedCustomer) {
      setSelectedCustomerLocation(null);
    }
  }, [selectedCustomer]);

  const validateContactCode = (value) => {
    const regex = /^[A-Za-z]\d{0,6}$/;
    return regex.test(value);
  };

  const validateContactCodeForm = (value) => {
    const regex = /^[A-Za-z]\d{6}$/;
    return regex.test(value);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        const formDOMNode = findDOMNode(formRef.current);
        const requiredFieldsNotCompleted = [];
        formDOMNode.querySelectorAll("[required]").forEach((input) => {
          if (!input.value.trim()) {
            requiredFieldsNotCompleted.push(input.name);
          }
        });
        const isValidContactCode = validateContactCodeForm(crane.ContactCode);
        if (requiredFieldsNotCompleted.length === 0 && isValidContactCode) {
          formDOMNode.dispatchEvent(
            new Event("submit", { cancelable: false, bubbles: true })
          );
          setIsInvalidContactCode(false);
          return true;
        } else {
          setIsInvalidContactCode(!isValidContactCode);
          setRequiredFields(requiredFieldsNotCompleted);
          return false;
        }
      }
    },
  }));

  const handleRequiredFields = (fieldName, fieldValue) => {
    if (requiredFields.includes(fieldName) && fieldValue.trim() !== "") {
      setRequiredFields(requiredFields.filter((field) => field !== fieldName));
    } else if (fieldValue.trim() === "") {
      if (!requiredFields.includes(fieldName)) {
        setRequiredFields([...requiredFields, fieldName]);
      }
    }
  };

  return (
    <>
      <Col className="flex-grow-1">
        <Card className="py-2">
          <CardHeader className="d-flex justify-content-between">
            <div className="mr-3 flex-grow-1">
              <div className="h4">
                <span>Crane Information</span>
              </div>
            </div>
          </CardHeader>
          <CardBody className="pb-1 overflow-x-auto">
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Form ref={formRef}>
                <div className="d-flex justify-content-start mb-3">
                  {crane.id ? (
                    <Badge
                      className="d-flex justify-content-center align-items-center"
                      style={{ fontSize: 13 }}
                    >
                      {crane.craneStructure?.craneStructureType?.name ||
                        "Hoist Only"}
                    </Badge>
                  ) : null}
                  <Badge
                    color={
                      crane.serviceStatus ===
                      CRANE_SERVICE_STATUS["OUT OF SERVICE"]
                        ? "warning"
                        : "primary"
                    }
                    className="ml-2"
                  >
                    <FormGroup className="mb-0 d-flex justify-content-center align-items-center">
                      <Label
                        for="serviceStatus"
                        className="mb-0 text-center"
                        style={{ marginRight: "10px" }}
                      >
                        <span style={{ fontSize: 13 }}>Status</span>
                        <span className="text-danger ml-1">*</span>
                      </Label>
                      <Input
                        placeholder="Enter description"
                        required={true}
                        type="select"
                        name="serviceStatus"
                        value={crane.serviceStatus || ""}
                        onChange={(e) =>
                          setCrane({
                            ...crane,
                            serviceStatus: e.target.value,
                          })
                        }
                        className="w-auto"
                      >
                        {Object.keys(CRANE_SERVICE_STATUS).map((key) => {
                          return (
                            <option
                              value={CRANE_SERVICE_STATUS[key]}
                              key={CRANE_SERVICE_STATUS[key]}
                              defaultValue={CRANE_SERVICE_STATUS["IN SERVICE"]}
                            >
                              {key}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Badge>
                </div>
                <Row>
                  {loading ? (
                    <Loader size="sm" />
                  ) : (
                    <>
                      <Col sm="3" className="mb-3">
                        <Label for="Customer">
                          <span>Customer</span>
                          <span className="text-danger ml-1">*</span>
                        </Label>
                        <SelectWrapper
                          key={selectedCustomer?.id}
                          isRequired={true}
                          entity="customer"
                          formatItemFunction={(c) => ({
                            label: c.customerName || c.customerNo,
                            value: c.id,
                          })}
                          filterItemFunction={(c) =>
                            c.customerName || c.customerNo
                          }
                          fetchFunction={customersApi.getCustomers}
                          fetchParameters={{
                            pageSize: PAGE_SIZE,
                          }}
                          defaultSelected={selectedCustomer}
                          onSelected={(customer) => {
                            setSelectedCustomer(customer);
                            setCrane({
                              ...crane,
                              customerId: customer?.id,
                              customer,
                            });
                            setSelectedCustomerLocation("");
                            if (requiredFields.includes("customer")) {
                              setRequiredFields(
                                requiredFields.filter(
                                  (field) => field !== "customer"
                                )
                              );
                            }
                          }}
                          menuPortalTarget={document.body}
                          name="customer"
                        />
                        {requiredFields.includes("customer") && (
                          <Label for="Customer">
                            <span></span>
                            <span
                              className="text-danger"
                              style={{ fontSize: 12 }}
                            >
                              This field is required
                            </span>
                          </Label>
                        )}
                      </Col>
                      {selectedCustomer ? (
                        <Col sm="3" className="mb-3">
                          <Label for="Customer-Location">
                            <span>Customer Location</span>
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <SelectWrapper
                            isRequired={true}
                            key={selectedCustomer?.id}
                            entity="customer location"
                            formatItemFunction={(c) => ({
                              label: utils.getAddress(c),
                              value: c.id,
                            })}
                            filterItemFunction={(c) =>
                              c.customerId === selectedCustomer.id
                            }
                            fetchFunction={customersApi.getCustomerLocations}
                            fetchParameters={{
                              customerId: selectedCustomer.id,
                              pageSize: PAGE_SIZE,
                            }}
                            defaultSelected={selectedCustomerLocation}
                            onSelected={(customerLocation) => {
                              setSelectedCustomerLocation(customerLocation);
                              setCrane({
                                ...crane,
                                customerLocationId: customerLocation?.id,
                                customerLocation,
                              });
                              if (requiredFields.includes("customerLocation")) {
                                setRequiredFields(
                                  requiredFields.filter(
                                    (field) => field !== "customerLocation"
                                  )
                                );
                              }
                            }}
                            menuPortalTarget={document.body}
                            name="customerLocation"
                          />
                          {requiredFields.includes("customerLocation") && (
                            <Label for="customerLocation">
                              <span></span>
                              <span
                                className="text-danger"
                                style={{ fontSize: 12 }}
                              >
                                This field is required
                              </span>
                            </Label>
                          )}
                        </Col>
                      ) : null}
                      <Col sm="3">
                        <FormGroup>
                          <Label for="ContactCode">
                            <span>Crane D#</span>
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <Input
                            placeholder="Enter crane D#"
                            required={true}
                            type="text"
                            name="ContactCode"
                            value={crane.ContactCode || ""}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              const formattedValue =
                                value.charAt(0).toUpperCase() + value.slice(1);
                              if (
                                validateContactCode(formattedValue) ||
                                formattedValue === ""
                              ) {
                                setCrane({
                                  ...crane,
                                  ContactCode: formattedValue,
                                });
                              }
                              handleRequiredFields(name, value);
                              setIsInvalidContactCode(
                                !validateContactCodeForm(formattedValue)
                              );
                            }}
                            maxLength={7}
                            invalid={
                              requiredFields.includes("ContactCode") ||
                              isInvalidContactCode
                            }
                          />
                          <FormFeedback>
                            The Contact Code must start with a letter and be
                            followed by up to 6 digits.
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label for="UDF_EQ_LOC">
                            <span>Equipment Location</span>
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <Input
                            placeholder="Enter plant location"
                            required={true}
                            type="text"
                            name="UDF_EQ_LOC"
                            value={crane.UDF_EQ_LOC || ""}
                            onChange={(e) => {
                              setCrane({
                                ...crane,
                                UDF_EQ_LOC: e.target.value,
                              });
                              handleRequiredFields(
                                e.target.name,
                                e.target.value
                              );
                            }}
                            maxLength={25}
                            invalid={requiredFields.includes("UDF_EQ_LOC")}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup className="mb-0">
                          <Label for="UDF_EQ_DESCR">
                            <span>Equipment Description</span>
                            <span className="text-danger ml-1">*</span>
                          </Label>
                          <Input
                            placeholder="Enter description"
                            required={true}
                            type="text"
                            name="UDF_EQ_DESCR"
                            value={crane.UDF_EQ_DESCR || ""}
                            onChange={(e) => {
                              setCrane({
                                ...crane,
                                UDF_EQ_DESCR: e.target.value,
                              });
                              handleRequiredFields(
                                e.target.name,
                                e.target.value
                              );
                            }}
                            maxLength={25}
                            invalid={requiredFields.includes("UDF_EQ_DESCR")}
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            )}
          </CardBody>
        </Card>
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => {
            setInformationModal({ isOpen: false, title: "", body: "" });
            window.location.reload();
          }}
        />
      ) : null}
    </>
  );
});

export default CraneConfigGeneralModal;
