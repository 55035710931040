import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const notificationApi = {
  getEmailNotifications: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id
        ? `/api/email-notifications/${data.id}`
        : `/api/email-notifications?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  sendActivityEmails: (data) => {
    return axios(`/api/reports/activity`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
      responseType: "blob",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createActivityEmail: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/activity-emails`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getActivityEmails: () => {
    return axios(`/api/activity-emails`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteActivityEmails: (data) => {
    return axios(`/api/activity-emails/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createEmailNotification: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/email-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateEmailNotifications: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/email-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getChangeOrderNotifications: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id
        ? `/api/change-order-notifications/${data.id}`
        : `/api/change-order-notifications?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createChangeOrderNotification: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/change-order-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateChangeOrderNotifications: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/change-order-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
