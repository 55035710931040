import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

export const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const cranesApi = {
  getControls: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id ? `/api/controls/${data.id}` : `/api/controls?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createCraneControl: (data) => {
    return axios("/api/crane-controls", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteCraneControl: (data) => {
    return axios(`/api/crane-controls/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createCraneHoist: (data) => {
    return axios("/api/crane-hoists", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createCraneNumber: () => {
    return axios("/api/crane-number", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateCraneHoist: (data) => {
    return axios(`/api/crane-hoists/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteCraneHoist: (data) => {
    return axios(`/api/crane-hoists/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getHoistTypes: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id
        ? `/api/crane-hoist-types/${data.id}`
        : `/api/crane-hoist-types?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCraneStructureTypeSubTypes: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id
        ? `/api/crane-structure-type-sub-types/${data.id}`
        : `/api/crane-structure-type-sub-types?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCraneStructureSubTypes: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id
        ? `/api/crane-structure-sub-types/${data.id}`
        : `/api/crane-structure-sub-types?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCraneStructureTypes: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id
        ? `/api/crane-structure-types/${data.id}`
        : `/api/crane-structure-types?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCapacities: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id ? `/api/capacities/${data.id}` : `/api/capacities?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getManufacturers: (data) => {
    const query = utils.buildQueryString(data);
    return axios(
      data.id ? `/api/manufacturers/${data.id}` : `/api/manufacturers?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  getCranes: (data) => {
    const query = utils.buildQueryString(data);
    return axios(data.id ? `/api/cranes/${data.id}` : `/api/cranes?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createCrane: (data) => {
    return axios(`/api/cranes`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteCraneStructure: (data) => {
    return axios(`/api/crane-structures/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateCraneStructure: (data) => {
    return axios(`/api/crane-structures/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createCraneStructure: (data) => {
    return axios(`/api/crane-structures`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  updateCrane: (data) => {
    return axios(`/api/cranes/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  deleteCrane: (data) => {
    return axios(`/api/cranes/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },

  deleteCraneAttachments: (data) => {
    return axios(`/api/crane-attachments/${data}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },

  createCraneAttachments: (data) => {
    return axios("/api/crane-attachments", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createCapacity: (data) => {
    return axios("/api/capacities", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
  createManufacturer: (data) => {
    return axios("/api/manufacturers", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
