const CraneInspectionAnswerBody = (craneInspectionAnswers) => {
  return craneInspectionAnswers.reduce((response, craneInspectionAnswer) => {
    return (
      response +
      `
                <p>${craneInspectionAnswer.sectionName}</p>
                <p>${craneInspectionAnswer.content}</p>
                <p>
                    <span style="color: rgb(255, 255, 255); background-color: rgb(220, 53, 69); padding: 2px 5px; border-radius: 3px">
                        ${craneInspectionAnswer.name}
                    </span>
                </p>
                <br />
              `
    );
  }, "");
};

export default CraneInspectionAnswerBody;
