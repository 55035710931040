import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
  Input,
  Form,
  Badge,
} from "reactstrap";
import Loader from "../../../components/Loader";
import { workTimesApi } from "../../../services/workTimesServices";
import { utils } from "../../../utils/utils";
import { otherTimesApi } from "../../../services/otherTimesServices";
import InformationModal from "../../../components/InformationModal";
import TooltipItem from "../../../components/TooltipItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { billableTimesApi } from "../../../services/billableTimesServices";
import ConfirmationModal from "../../../components/ConfirmationModal";
import moment from "moment";

const OTHER_TIME = "OTHER_TIME";
const OTHER_TIME_TYPE_LUNCH = 5;

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const EditEmployeeTime = ({
  times,
  employee,
  onClose,
  onSubmit,
  mode,
  timeLocked,
  isNewTimeEntry,
  billableTimesException = [],
  onDelete,
  onDeleteWorkTime,
  date,
}) => {
  const [loading, setLoading] = useState();
  const [localEmployeeTimes, setLocalEmployeeTimes] = useState([]);
  const [currentEmployeeTimes, setCurrentEmployeeTimes] = useState([]);
  const [localEmployeeBillableTime, setLocalEmployeeBillableTime] = useState(
    billableTimesException.length > 0
      ? billableTimesException
      : [
          {
            startTime: "",
            endTime: "",
            driveTime: null,
            perDiem: "N/A",
            touched: false,
          },
        ]
  );
  const PER_DIEM_OPTIONS = ["Yes", "No", "N/A"];

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    setCurrentEmployeeTimes(localEmployeeTimes.filter((time) => !time.deleted));
  }, [localEmployeeTimes]);

  useEffect(() => {
    const data = times.map((time) => {
      const data = {
        ...time,
        employee: `${employee.firstName} ${employee.lastName}`,
        author: time.author,
        editor: time.editor,
      };
      if (mode !== OTHER_TIME) {
        data.employeeWorkDayId = time.employeeWorkDayId;
      }
      return data;
    });
    setLocalEmployeeTimes(data);
    setCurrentEmployeeTimes(data);
  }, [employee, times, mode]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const updateWorkTime = (newValue, isBlur = false) => {
    let [hours, minutes] = newValue.split(":").map((part) => parseInt(part));
    const formattedMinutes = minutes.toString();
    const adjustedMinutes = Math.round(minutes / 15) * 15;
    if (isBlur && formattedMinutes.length === 1) {
      minutes = 0;
    }
    const currentDate = moment
      .utc(date)
      .hours(hours)
      .minutes(formattedMinutes.length === 2 ? adjustedMinutes : minutes)
      .seconds(0)
      .milliseconds(0);
    return currentDate.toISOString();
  };

  const onTimeChanged = (event, field, time, isBlur = false) => {
    let newValue = event.target.value;
    if (field === "startTime" || field === "endTime") {
      if (!newValue) {
        newValue = null;
      } else {
        newValue = updateWorkTime(newValue, isBlur);
      }
    }
    const editedWorkTime = {
      ...time,
      [field]: newValue,
      touched: true,
      deleted: false,
    };
    if (
      field === "endTime" &&
      moment(editedWorkTime.endTime).isBefore(moment(editedWorkTime.startTime))
    ) {
      editedWorkTime.endTime = moment(editedWorkTime.endTime)
        .add(1, "days")
        .toISOString();
    }
    const newLocalData = JSON.parse(JSON.stringify(localEmployeeTimes));
    newLocalData.splice(
      localEmployeeTimes.findIndex((d) => d.id === editedWorkTime.id),
      1,
      editedWorkTime
    );
    if (field === "startTime" || field === "endTime") {
      setCurrentEmployeeTimes((prev) => {
        const newTimes = prev.map((t) =>
          t.id === editedWorkTime.id ? editedWorkTime : t
        );
        return newTimes;
      });
    }
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(newLocalData)));
  };

  const onTimeBillableChanged = (event, field, time, isBlur = false) => {
    let newValue = event.target.value;
    if (field === "startTime" || field === "endTime") {
      if (!newValue) {
        newValue = null;
      } else {
        newValue = updateWorkTime(newValue, isBlur);
      }
    }
    const editedWorkTime = {
      ...time,
      [field]: newValue,
      touched: true,
    };
    if (
      field === "endTime" &&
      moment(editedWorkTime.endTime).isBefore(moment(editedWorkTime.startTime))
    ) {
      editedWorkTime.endTime = moment(editedWorkTime.endTime)
        .add(1, "days")
        .toISOString();
    }
    const newLocalData = JSON.parse(JSON.stringify(localEmployeeBillableTime));
    newLocalData.splice(
      localEmployeeBillableTime.findIndex((d) => d.id === editedWorkTime.id),
      1,
      editedWorkTime
    );
    setLocalEmployeeBillableTime(JSON.parse(JSON.stringify(newLocalData)));
  };

  function hasBillableTimeData(time) {
    if (
      time.startTime ||
      time.endTime ||
      time.driveTime ||
      time.perDiem !== "N/A"
    ) {
      return true;
    }
  }

  const isNextDay = (endTime, startTime) => {
    return moment
      .utc(endTime, "YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      .isAfter(moment.utc(startTime, "YYYY-MM-DDTHH:mm:ss.SSS[Z]"), "day");
  };

  const doSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      //todo move to bulk update
      await Promise.all(
        localEmployeeTimes
          .filter((time) => time.touched)
          .map((time) => {
            if (mode === OTHER_TIME) {
              return otherTimesApi.updateOtherTime(time);
            } else {
              return workTimesApi.updateWorkTime(time);
            }
          })
      );
      if (
        localEmployeeBillableTime.length > 0 &&
        mode !== OTHER_TIME &&
        isNewTimeEntry
      ) {
        await Promise.all(
          localEmployeeBillableTime
            .filter(
              (time) =>
                time.touched &&
                time.driveTime &&
                time.startTime &&
                time.endTime &&
                time.perDiem
            )
            .map((time) => {
              if (!localEmployeeTimes[0].endTime) {
                throw new Error(
                  "You cannot save or edit billable time exception, if the end time has not been completed"
                );
              }
              if (time.id) {
                return billableTimesApi.updateBillableTime(time);
              } else {
                time.employeeWorkDayId =
                  localEmployeeTimes[0].employeeWorkDayId;
                return billableTimesApi.createBillableTime(time);
              }
            })
        );
      }
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: `Edit ${mode === OTHER_TIME ? "" : "Work "} Time Entries`,
        body:
          err?.response?.data[0].msg ||
          err.message ||
          "There was an error with your request.",
      });
    }
  };

  const onRemoveBillableTime = async (event) => {
    event.preventDefault();
    setConfirmationModal({
      isOpen: true,
      body: `
      <span class="text-center">
        Confirm you would like to delete this information:
        <br/>
        <br/>
        <strong>Start Time: ${utils.convertTo12HourFormat(
          localEmployeeBillableTime[0].startTime
        )} </strong>
        <br/>
        <strong>End Time: ${utils.convertTo12HourFormat(
          localEmployeeBillableTime[0].endTime
        )} </strong>
        <br/>
        <br/>
        <strong>This action can not be undone.</strong>
      </span>
    `,
      title: `Are you sure you want to delete Billable Time data?`,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          await billableTimesApi.deleteBillableTime({
            id: localEmployeeBillableTime[0].id,
          });
          setConfirmationModal(initConfirmationModal);
          onDelete();
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: `Delete Billable Time Exception`,
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
    });
  };

  const onRemoveTime = async (event) => {
    event.preventDefault();
    setConfirmationModal({
      isOpen: true,
      body: `
      <span class="text-center">
         Confirm you would like to delete this information:
        <br/>
        <br/>
         <strong>Start Time: ${utils.convertTo12HourFormat(
           currentEmployeeTimes[0].startTime
         )} </strong>
        <br/>
        <strong>End Time: ${
          currentEmployeeTimes[0].endTime
            ? utils.convertTo12HourFormat(currentEmployeeTimes[0].endTime)
            : "N/A"
        }
        </strong>
        <br/>
        <br/>
        Note: Billable Time will also be removed
        <br/>
        <br/>
        <strong>This action can not be undone.</strong>
      </span>
    `,
      title: `Are you sure you want to delete the entered time?`,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          await workTimesApi.deleteWorkTime({
            id: localEmployeeTimes[0].id,
          });
          setConfirmationModal(initConfirmationModal);
          onDeleteWorkTime();
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: `Delete Work Time`,
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() => {
        setInformationModal({ isOpen: false, title: "", body: "" });
      }}
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal size="xl" isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column align-items-start">
          <span>Edit {mode === OTHER_TIME ? "" : "Work "}Time Entries</span>
          {timeLocked ? (
            <Badge className="bg-white border-warning text-warning border d-flex">
              Time Locked
              <TooltipItem
                position="bottom"
                className="ml-1"
                id="payroll-tooltip"
                title="This payroll week is locked"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-1 text-warning"
                />
              </TooltipItem>
            </Badge>
          ) : null}
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit} onKeyDown={handleKeyDown}>
        <ModalBody>
          <Table className="border col-12 px-0 mb-0 small">
            <thead>
              {mode !== OTHER_TIME && isNewTimeEntry ? (
                <tr className="bg-lighter">
                  <th>Employee</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Work Time Status</th>
                  <th>Note</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                </tr>
              ) : (
                <tr className="bg-lighter">
                  <th>Employee</th>
                  {mode === OTHER_TIME ? <th>Type</th> : null}
                  <th>{mode === OTHER_TIME ? "" : "Reg. "}Hours</th>
                  <th>OT. Hours</th>
                  <th>{mode === OTHER_TIME ? "" : "Work "}Time Status</th>
                  <th>Note</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                </tr>
              )}
            </thead>
            <tbody>
              {currentEmployeeTimes.length ? (
                currentEmployeeTimes.map((time, index) => {
                  return (
                    <tr key={index}>
                      <td>{time.employee}</td>
                      {mode === OTHER_TIME ? (
                        <td>{time.otherTimeType.name}</td>
                      ) : null}
                      {isNewTimeEntry ? (
                        <>
                          <td>
                            <Input
                              type="time"
                              placeholder="Enter start time"
                              required={true}
                              step={900}
                              value={utils.formatTimeWithSeconds(
                                time.startTime
                              )}
                              onChange={(event) =>
                                onTimeChanged(event, "startTime", time, false)
                              }
                              onBlur={(event) =>
                                onTimeChanged(event, "startTime", time, true)
                              }
                            />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Input
                                type="time"
                                placeholder="Enter end time"
                                step={900}
                                value={utils.formatTimeWithSeconds(
                                  time.endTime
                                )}
                                onChange={(event) =>
                                  onTimeChanged(event, "endTime", time, false)
                                }
                                onBlur={(event) =>
                                  onTimeChanged(event, "endTime", time, true)
                                }
                              />
                              {isNextDay(time.endTime, time.startTime) && (
                                <TooltipItem
                                  title={"End time will be for the next day"}
                                >
                                  <Badge className="ml-2">+1</Badge>
                                </TooltipItem>
                              )}
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            <Input
                              type="number"
                              placeholder="Enter the hours"
                              required={true}
                              max={20}
                              min={0}
                              disabled={
                                mode === OTHER_TIME &&
                                time.otherTimeType.id === OTHER_TIME_TYPE_LUNCH
                              }
                              step={0.1}
                              value={time.hours}
                              onChange={(event) =>
                                onTimeChanged(event, "hours", time)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="number"
                              placeholder="Enter the hours"
                              required={true}
                              max={20}
                              disabled={
                                mode === OTHER_TIME &&
                                time.otherTimeType.id === OTHER_TIME_TYPE_LUNCH
                              }
                              min={0}
                              step={0.1}
                              value={time.overtimeHours}
                              onChange={(event) =>
                                onTimeChanged(event, "overtimeHours", time)
                              }
                            />
                          </td>
                        </>
                      )}
                      <td>{time.status}</td>
                      <td>
                        <Input
                          maxLength="255"
                          type="text"
                          id="note"
                          name="note"
                          placeholder="Enter the note..."
                          value={time.note || ""}
                          onChange={(event) =>
                            onTimeChanged(event, "note", time)
                          }
                        />
                      </td>
                      <td>
                        {time.author ? (
                          <div className="d-flex flex-column">
                            <small>{`${time.author.firstName} ${time.author.lastName}`}</small>
                            <small>
                              {utils.formatDateTime(
                                time.createdAt,
                                "MM/DD/YYYY, h:mm a"
                              )}
                            </small>
                          </div>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {time.editor ? (
                          <div className="d-flex flex-column">
                            <small>{`${time.editor.firstName} ${time.editor.lastName}`}</small>
                            <small>
                              {utils.formatDateTime(
                                time.updatedAt,
                                "MM/DD/YYYY, h:mm a"
                              )}
                            </small>
                          </div>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="col-12 px-0 text-muted text-center">
                      No {mode === OTHER_TIME ? "" : "work "}times to show
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {mode !== OTHER_TIME && isNewTimeEntry && (
            <>
              <h5 className="mt-3">Billable Time Exception</h5>
              <div className="small text-danger">
                <span className="text-danger">
                  *Changes made below will be shown on the Customer's Service
                  Ticket
                </span>
              </div>
              <Table className="border col-12 px-0 mb-0 small">
                <thead>
                  <tr className="bg-lighter">
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Drive Time</th>
                    <th>Per Diem</th>
                    {localEmployeeBillableTime[0].id ? <th></th> : null}
                  </tr>
                </thead>
                <tbody>
                  {localEmployeeBillableTime.length
                    ? localEmployeeBillableTime.map((time, index) => {
                        return (
                          <tr key={index}>
                            <>
                              <td>
                                <Input
                                  type="time"
                                  placeholder="Enter start time"
                                  step={900}
                                  value={utils.formatTimeWithSeconds(
                                    time.startTime
                                  )}
                                  onChange={(event) =>
                                    onTimeBillableChanged(
                                      event,
                                      "startTime",
                                      time,
                                      false
                                    )
                                  }
                                  onBlur={(event) =>
                                    onTimeBillableChanged(
                                      event,
                                      "startTime",
                                      time,
                                      true
                                    )
                                  }
                                  required={hasBillableTimeData(time)}
                                />
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Input
                                    type="time"
                                    placeholder="Enter end time"
                                    step={900}
                                    value={utils.formatTimeWithSeconds(
                                      time.endTime
                                    )}
                                    onChange={(event) =>
                                      onTimeBillableChanged(
                                        event,
                                        "endTime",
                                        time,
                                        false
                                      )
                                    }
                                    onBlur={(event) =>
                                      onTimeBillableChanged(
                                        event,
                                        "endTime",
                                        time,
                                        true
                                      )
                                    }
                                    required={hasBillableTimeData(time)}
                                  />
                                  {isNextDay(time.endTime, time.startTime) && (
                                    <TooltipItem
                                      title={
                                        "End time will be for the next day"
                                      }
                                    >
                                      <Badge className="ml-2">+1</Badge>
                                    </TooltipItem>
                                  )}
                                </div>
                              </td>
                              <td style={{ width: 210 }}>
                                <Input
                                  maxLength="2"
                                  type="number"
                                  id="driveTime"
                                  name="driveTime"
                                  placeholder="Enter drive time..."
                                  value={time.driveTime || ""}
                                  step={0.1}
                                  onChange={(event) =>
                                    onTimeBillableChanged(
                                      event,
                                      "driveTime",
                                      time
                                    )
                                  }
                                  required={hasBillableTimeData(time)}
                                />
                              </td>
                              <td>
                                <Input
                                  type="select"
                                  id="perDiem"
                                  name="perDiem"
                                  value={time.perDiem || "N/A"}
                                  onChange={(event) =>
                                    onTimeBillableChanged(
                                      event,
                                      "perDiem",
                                      time
                                    )
                                  }
                                >
                                  {PER_DIEM_OPTIONS.map((option, index) => {
                                    return (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </td>
                              {localEmployeeBillableTime[0].id ? (
                                <td align="right">
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={(evt) => onRemoveBillableTime(evt)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      size="sm"
                                      className="cursor-pointer"
                                    />
                                  </Button>
                                </td>
                              ) : null}
                            </>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div className="d-flex">
            <Button color={"primary"} onClick={onClose}>
              Discard
            </Button>
            {isNewTimeEntry && mode !== OTHER_TIME && (
              <Button
                color="danger"
                className="ml-2"
                onClick={(evt) => onRemoveTime(evt)}
              >
                Delete Time
              </Button>
            )}
          </div>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"warning"} type="submit">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditEmployeeTime;
