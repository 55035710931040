import React, { useState } from "react";

import InformationModal from "../../InformationModal";
import { CustomInput, FormGroup, Tooltip } from "reactstrap";
import {
  faCog,
  faPencilAlt,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "reactstrap/lib/Form";
import Label from "reactstrap/lib/Label";
import { cranesApi } from "../../../services/craneServices";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Button from "reactstrap/lib/Button";
import Loader from "../../Loader";
import ModalBody from "reactstrap/lib/ModalBody";
import Input from "reactstrap/lib/Input";
import { workOrdersApi } from "../../../services/workOrdersServices";
import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../../providers/workOrderDetailsProvider";
import SelectWrapper from "../../SelectWrapper";
import { CRANE_SERVICE_STATUS } from "../../../utils/utils";

const PAGE_SIZE = 30;

const EditDetailsModal = ({ onClose }) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [loading, setLoading] = useState();

  const [selectedCranes, setSelectedCranes] = useState(
    workOrderDetails.workOrder.workOrderCranes.map((woc) => woc.crane)
  );

  const [serviceRequested, setServiceRequested] = useState(
    workOrderDetails.workOrder.svcCommentText
  );

  const [customerPONo, setCustomerPONo] = useState(
    workOrderDetails.workOrder.customerPONo
  );

  const [quotedJob, setQuotedJob] = useState(
    workOrderDetails.workOrder.quotedJob
  );

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await workOrdersApi.updateWorkOrderCranes({
        id: workOrderDetails.workOrder.id,
        cranes: selectedCranes.map((c) => c.id),
      });
      await workOrdersApi.updateWorkOrder({
        ...workOrderDetails.workOrder,
        svcCommentText: serviceRequested,
        customerPONo,
        quotedJob: quotedJob,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Work Order Details",
        body: "Work Order Updated Successfully",
        onClose: () => {
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Work Order Details",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const onAddAllCranes = async () => {
    setLoading(true);
    const allCranes = await cranesApi.getCranes({
      pageSize: 99999,
      customerId: workOrderDetails.workOrder.customerId,
      customerLocationId: workOrderDetails.workOrder.customerLocation.id,
    });
    const newCranes = allCranes.data.filter(
      (c) => !selectedCranes.find((sc) => sc.id === c.id)
    );
    setSelectedCranes([...selectedCranes, ...newCranes]);
    setLoading(false);
  };

  const handleSwitchChange = (e) => {
    const isChecked = e.currentTarget.checked;
    setQuotedJob(isChecked);

    if (isChecked) {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 6000);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader close={closeBtn}>Edit Work Order Details</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          <FormGroup className="d-flex justify-content-between align-items-center">
            <Label className="text-left col-4 mb-0 d-flex flex-column align-items-start">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faCog}
                  className="mr-2 text-primary"
                  style={{ paddingBottom: "1px" }}
                />
                <span>Cranes</span>
                <span className="text-muted ml-1">
                  ({selectedCranes.length})
                </span>
              </div>
              {selectedCranes.length <
              workOrderDetails.workOrder.customerLocation.cranes.length ? (
                <Button
                  size="sm"
                  className="rounded mt-1"
                  onClick={onAddAllCranes}
                >
                  Add All Cranes
                </Button>
              ) : null}
            </Label>
            <SelectWrapper
              key={
                workOrderDetails.workOrder.customerId + selectedCranes.length
              }
              isMulti={true}
              entity="crane"
              filterItemFunction={(crane) =>
                !selectedCranes.find((sc) => sc.id === crane.id) &&
                crane.serviceStatus === CRANE_SERVICE_STATUS["IN SERVICE"]
              }
              formatItemFunction={(c) => {
                return {
                  label: `${c.ContactCode || "D# Not Set"}${
                    c.UDF_EQ_LOC ? " - " + c.UDF_EQ_LOC : ""
                  }`,
                  value: c.id,
                };
              }}
              fetchFunction={cranesApi.getCranes}
              fetchParameters={{
                pageSize: Math.max(selectedCranes.length, PAGE_SIZE),
                customerId: workOrderDetails.workOrder.customerId,
                customerLocationId:
                  workOrderDetails.workOrder.customerLocation.id,
              }}
              defaultSelected={selectedCranes}
              onSelected={(value) => {
                setSelectedCranes(value);
              }}
            />
          </FormGroup>
          <FormGroup className="d-flex justify-content-between">
            <Label className="text-left col-4 mb-0 d-flex align-items-center">
              <FontAwesomeIcon
                icon={faPencilAlt}
                className="mr-2 text-primary"
                style={{ paddingBottom: "1px" }}
              />
              <span>Service Requested</span>
            </Label>
            <Input
              className="flex-grow-1"
              type="textarea"
              rows={4}
              maxLength="10000"
              name="svcCommentText"
              value={serviceRequested || ""}
              onChange={(e) => setServiceRequested(e.currentTarget.value)}
              required
            />
          </FormGroup>
          <FormGroup className="d-flex justify-content-between">
            <Label className="text-left col-4 mb-0 d-flex align-items-center">
              <FontAwesomeIcon
                icon={faPencilAlt}
                className="mr-2 text-primary"
                style={{ paddingBottom: "1px" }}
              />
              <span>PO#</span>
            </Label>
            <Input
              className="flex-grow-1"
              type="text"
              maxLength="100"
              name="customerPONo"
              value={customerPONo || ""}
              onChange={(e) => setCustomerPONo(e.currentTarget.value)}
            />
          </FormGroup>
          <FormGroup className="d-flex justify-content-between mb-0">
            <Label className="text-left col-4 mb-0 d-flex align-items-center">
              <FontAwesomeIcon
                icon={faBookmark}
                className="mr-2 text-primary"
                style={{ paddingBottom: "1px" }}
              />
              <span id="quotedJobLabel">Quoted Job</span>
            </Label>

            <small className="col-12 font-weight-bold text-muted text-left">
              <CustomInput
                type="switch"
                id="quotedJob"
                name="quotedJob"
                defaultChecked={quotedJob || false}
                onClick={handleSwitchChange}
              />
            </small>

            <Tooltip
              placement="left"
              isOpen={tooltipOpen}
              target="quotedJobLabel"
              toggle={toggleTooltip}
            >
              If a work order is set as a "Quoted Job", no time entries will be
              displayed on the customer PDF report
            </Tooltip>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Close
          </Button>{" "}
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button
              className="d-flex align-items-center"
              color={"primary"}
              type={"submit"}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditDetailsModal;
