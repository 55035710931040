import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import CustomCheckbox from "../../../CustomCheckbox";

const TrolleyOptionsModal = ({ defaultValue, onSave, onClose }) => {
  const [includeTrolleyHoist, setIncludeTrolleyHoist] = useState(defaultValue);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onChangeTrolleyHoist = () =>
    setIncludeTrolleyHoist(!includeTrolleyHoist);

  return (
    <Modal isOpen={true} onClosed={onClose} size="md">
      <ModalHeader close={closeBtn}>Trolley Hoist Options</ModalHeader>
      <ModalBody>
        <ListGroup>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center cursor-pointer"
            onClick={onChangeTrolleyHoist}
          >
            <div className="flex-shrink-0">
              Include trolley section into hoist section
            </div>
            <div className="min-width-50 d-flex justify-content-end">
              <CustomCheckbox checked={includeTrolleyHoist} />
            </div>
          </ListGroupItem>
        </ListGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>{" "}
        <Button color={"primary"} onClick={() => onSave(includeTrolleyHoist)}>
          Save
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default TrolleyOptionsModal;
