import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Button, CustomInput } from "reactstrap";
import InformationModal from "../../../../InformationModal";
import { useState } from "react";
import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../../../../providers/workOrderDetailsProvider";
import Loader from "../../../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JSAAnswerApi } from "../../../../../services/JSAAnswerService";
import ModalEdit from "./ModalEdit";

const JSAModalEditEmployee = ({
  actualEmployee,
  JSAAnswers,
  employeeWorkDayId,
  employees,
}) => {
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const [selectedEmployee, setselectedEmployee] = useState({
    employeeId: "",
  });
  const [loading, setLoading] = useState(false);
  const [, setWorkOrderDetails] = useWorkOrderDetails();
  const [openModal, setOpenModal] = useState(false);

  const onClose = () => {
    setOpenModal(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const updates = JSAAnswers.map((jsaAnswer) => ({
        id: jsaAnswer.id,
        answer: jsaAnswer.answer,
        jsaItemId: jsaAnswer.jsaItem.id,
        employeeWorkDayId: employeeWorkDayId,
        createdBy: selectedEmployee.employeeId,
      }));
      await JSAAnswerApi.updateJSAAnswers({ updates: updates });
      setOpenModal(false);
      setInformationModal({
        isOpen: true,
        title: "JSA technical update",
        body: `The technician was successfully updated`,
        onClose: () => {
          setLoading(false);
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setLoading(false);
      setOpenModal(false);
      setInformationModal({
        isOpen: true,
        title: `JSA technical update`,
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    } finally {
      setLoading(false);
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <>
      <Button
        size="sm"
        className="rounded ml-2"
        color="warning"
        onClick={() => setOpenModal(true)}
      >
        <FontAwesomeIcon icon={faPen} className="mr-1" />
        <span>Edit</span>
      </Button>
      <ModalEdit
        isOpen={openModal}
        onToggle={() => setOpenModal(false)}
        title="Edit name of who completed the JSA"
        onCancel={onClose}
        onSave={onSubmit}
        isSaveDisabled={
          loading || !selectedEmployee.employeeId || employees?.length === 0
            ? true
            : false
        }
      >
        {loading ? (
          <Loader size="sm" />
        ) : employees?.length ? (
          <CustomInput
            id="technicianName"
            required={true}
            type="select"
            name="technicianName"
            value={selectedEmployee.employeeId}
            onChange={(e) => {
              const newEmployeeId = e.target.value;
              setselectedEmployee((prevselectedEmployee) => ({
                ...prevselectedEmployee,
                employeeId: newEmployeeId,
              }));
            }}
          >
            <option value="" disabled>
              Select a technician...
            </option>
            {employees.map((employee) =>
              employee.employeeId !== actualEmployee ? (
                <option key={employee.employeeId} value={employee.employeeId}>
                  {employee.employee.firstName} {employee.employee.lastName}
                </option>
              ) : null
            )}
          </CustomInput>
        ) : (
          "There are no technicians to select"
        )}
      </ModalEdit>
    </>
  );
};

export default JSAModalEditEmployee;
