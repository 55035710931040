import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";

import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { v4 as uuidv4 } from "uuid";

import "./assets/scss/_app.scss";
import "tippy.js/dist/tippy.css";

import App from "./App";

import {
  PointElement,
  LinearScale,
  CategoryScale,
  Chart,
  LineElement,
} from "chart.js";
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);

const container = document.getElementById("root");
const root = createRoot(container);

const uniqueIdentifier = uuidv4();

Sentry.init({
  dsn: "https://0955e2ea90b94062a1ee2a510e9cb4ce@o912544.ingest.sentry.io/5950458",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [window.location.origin],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || "local",
  "web-version": process.env.REACT_APP_VERSION || "undefined",
});

root.render(
  <React.StrictMode>
    <App version={uniqueIdentifier} />
  </React.StrictMode>
);
