import React, { useReducer, createContext, useContext } from "react";

const MODES = {
  MODE_GENERAL: "MODE_GENERAL",
  MODE_STRUCTURE: "MODE_STRUCTURE",
  MODE_HOISTS: "MODE_HOISTS",
  MODE_CONTROLS: "MODE_CONTROLS",
  MODE_PHOTOS: "MODE_PHOTOS",
};

const initialState = {
  isOpen: false,
  crane: null,
  mode: MODES.MODE_GENERAL,
  refresh: false,
  action: null,
};

const ACTIONS = {
  SET_CRANE: "SET_CRANE",
  MODE_CHANGE: "MODE_CHANGE",
  ON_CLOSE: "ON_CLOSE",
  INIT: "INIT",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.INIT: {
      const { payload } = data;
      const { crane, action } = payload;

      return {
        ...initialState,
        isOpen: false,
        crane,
        action,
      };
    }

    case ACTIONS.MODE_CHANGE: {
      const { payload } = data;
      const { mode, crane } = payload;

      return {
        ...state,
        mode,
        crane: crane || state.crane,
      };
    }

    case ACTIONS.SET_CRANE: {
      const { payload } = data;
      const { crane } = payload;

      return {
        ...state,
        crane,
      };
    }

    case ACTIONS.ON_CLOSE: {
      const newState = {
        ...initialState,
        refresh: !state.refresh,
      };
      return newState;
    }

    default:
      throw new Error();
  }
};

const CraneConfigModalContext = createContext(initialState);

const CraneConfigModalProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CraneConfigModalContext.Provider value={stateAndDispatch}>
      {children}
    </CraneConfigModalContext.Provider>
  );
};

export const useCraneConfigModal = () => useContext(CraneConfigModalContext);

export { CraneConfigModalContext, CraneConfigModalProvider, ACTIONS, MODES };
