import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "reactstrap/lib/Button";
import moment from "moment";

const WeekSelector = ({ loading, monday, setMonday, weeksPeriod = 1 }) => {
  const previousMonday = () =>
    loading ? false : setMonday(moment(monday).add(weeksPeriod * -1, "week"));

  const nextMonday = () =>
    loading ? false : setMonday(moment(monday).add(weeksPeriod, "week"));

  const getSunday = () =>
    moment(monday)
      .endOf("isoWeek")
      .add(weeksPeriod - 1, "weeks");

  return (
    <div className="d-flex justify-content-center ml-3">
      <Button color="primary" onClick={previousMonday}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
      <strong className="mx-1 p-2 border rounded small font-weight-bold text-center flex-shrink-0">
        {monday.format("MM/DD/YYYY")}
        {" - "}
        {getSunday().format("MM/DD/YYYY")}
      </strong>
      <Button
        color="primary"
        onClick={nextMonday}
        disabled={getSunday().isAfter(moment())}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </div>
  );
};

export default WeekSelector;
