import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend } from "chart.js";
import moment from "moment";

ChartJS.register(Tooltip, Legend);

const DailyActivityChart = ({ timePerDate }) => {
  const dates = Object.keys(timePerDate).sort((x, y) =>
    moment(x).isAfter(moment(y)) ? 1 : -1
  );

  const data = {
    labels: dates,
    datasets: [
      {
        label: "Active Users",
        data: dates.map((date) => timePerDate[date].active),
        borderColor: "#FF6383",
        backgroundColor: "#FFB3C2",
      },
      {
        label: "Inactive Users",
        data: dates.map(
          (date) => timePerDate[date].total - timePerDate[date].active
        ),
        borderColor: "#37A2EB",
        backgroundColor: "#A0D0F6",
      },
    ],
  };

  return (
    <div style={{ minHeight: 400 }}>
      <Line
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false, // Desactiva el mantenimiento del aspecto para que puedas especificar una altura máxima
        }}
      />
    </div>
  );
};

export default DailyActivityChart;
