import React, { useState } from "react";
import {
  useScheduleModal,
  STEPS,
} from "../../../providers/scheduleModalProvider";

import InformationModal from "../../InformationModal";
import ScheduleModalDates from "./editSchedule/ScheduleModalDates";
import ScheduleModalTechnicians from "./editSchedule/ScheduleModalTechnicians";

const EditScheduleModal = () => {
  const [scheduleModalContext] = useScheduleModal();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const getComponent = () => {
    switch (scheduleModalContext.step) {
      case STEPS.STEP_DATE:
        return <ScheduleModalDates />;
      case STEPS.STEP_TECHNICIANS:
        return <ScheduleModalTechnicians />;
      default:
        throw Error;
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    getComponent()
  );
};

export default EditScheduleModal;
