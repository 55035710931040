import React, { useState } from "react";

import InformationModal from "../../InformationModal";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Button from "reactstrap/lib/Button";
import Loader from "../../Loader";
import ModalBody from "reactstrap/lib/ModalBody";
import Table from "reactstrap/lib/Table";
import { Input, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { customersApi } from "../../../services/customerServices";

const CustomerLocationContactsModal = ({
  customerLocation,
  onClose,
  onSubmit,
}) => {
  const [loading, setLoading] = useState();
  const [newContact, setNewContact] = useState({ open: false });
  const [customerContacts, setCustomerContacts] = useState(
    customerLocation.contacts
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async () => {
    if (!customerContacts.length) {
      setCustomerContacts(customerLocation.contacts);
      return setInformationModal({
        isOpen: true,
        title: "Location Customer Contacts",
        body: "You must enter at least one contact",
      });
    }
    setLoading(true);
    try {
      await customersApi.updateLocationCustomerContacts({
        id: customerLocation.id,
        customerContacts,
      });
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Location Customer Contacts",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onDiscard = () => setNewContact({ open: false });

  const onRemove = (index) => {
    let newCustomerContacts = [...customerContacts];
    newCustomerContacts.splice(index, 1);
    setCustomerContacts(newCustomerContacts);
  };

  const onSave = (event) => {
    event.preventDefault();
    const { name, email, phone } = newContact;
    setCustomerContacts([
      ...customerContacts,
      {
        name,
        email,
        phone,
      },
    ]);
    setNewContact({ open: false });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader close={closeBtn}>Edit Contacts for Location</ModalHeader>
      <ModalBody className="text-center">
        <Form onSubmit={onSave}>
          <Table className="col-12 px-0 border rounded mb-0" size="sm">
            <thead>
              <tr className="bg-lighter small text-muted">
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                {customerContacts.length || newContact.open ? (
                  <th className="min-width-50"></th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {customerContacts.map((customerContact, index) => (
                <tr key={index}>
                  <td>{customerContact.name}</td>
                  <td>{customerContact.email}</td>
                  <td>{customerContact.phone}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="text-danger cursor-pointer"
                      onClick={() => onRemove(index)}
                    />
                  </td>
                </tr>
              ))}
              {!customerContacts.length && !newContact.open ? (
                <tr>
                  <td colSpan="4">No customer contacts</td>
                </tr>
              ) : null}
              {newContact.open ? (
                <tr>
                  <td>
                    <Input
                      required={true}
                      placeholder="Name.."
                      onChange={(event) =>
                        setNewContact({
                          ...newContact,
                          name: event.currentTarget.value,
                        })
                      }
                    />
                  </td>
                  <td>
                    <Input
                      required={true}
                      placeholder="Email.."
                      type="email"
                      onChange={(event) =>
                        setNewContact({
                          ...newContact,
                          email: event.currentTarget.value.trim(),
                        })
                      }
                    />
                  </td>
                  <td>
                    <Input
                      required={true}
                      placeholder="Phone.."
                      onChange={(event) =>
                        setNewContact({
                          ...newContact,
                          phone: event.currentTarget.value,
                        })
                      }
                    />
                  </td>
                  <td>
                    <Button
                      className="rounded"
                      size="sm"
                      color={"danger"}
                      onClick={onDiscard}
                    >
                      Discard
                    </Button>
                    <Button
                      className="ml-2 rounded"
                      size="sm"
                      color={"success"}
                      type="submit"
                    >
                      Save
                    </Button>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </Form>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div>
          <Button color="warning" onClick={onClose}>
            Discard Changes
          </Button>
          {!newContact.open ? (
            <Button
              className="ml-2"
              color="info"
              onClick={() => setNewContact({ open: true })}
            >
              Add New
            </Button>
          ) : null}
        </div>
        {loading ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            className="d-flex align-items-center"
            color={"success"}
            onClick={doSubmit}
          >
            Confirm Changes
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CustomerLocationContactsModal;
