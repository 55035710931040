import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  Button,
  InputGroupText,
  CardFooter,
} from "reactstrap";

import { inspectionPointsApi } from "../../../services/inspectionPointsServices";

import InformationModal from "../../../components/InformationModal";

import {
  ACTIONS,
  MAX_PAGE_SIZE,
  useInspectionPointsManager,
} from "../../../providers/inspectionPointsManagerProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import InspectionPointModal from "../../../components/admin/inspectionPointsManager/InspectionPointModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import AdvanceTable from "../../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/advanceTable/AdvanceTableWrapper";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const columns = (onEdit, onDelete) => [
  {
    accessor: "content",
    Header: "Content",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 300,
    Cell: (rowData) => {
      const { content } = rowData.row.original;
      return content || "-";
    },
  },
  {
    accessor: "order",
    Header: "Order",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 100,
    Cell: (rowData) => {
      const { order } = rowData.row.original;
      return order || "-";
    },
  },
  {
    accessor: "inspectionSection.name",
    Header: "Section",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 400,
    Cell: (rowData) => {
      const { inspectionSection } = rowData.row.original;
      return inspectionSection.name || "-";
    },
  },
  {
    accessor: "id",
    Header: "Actions",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => (
      <div className="text-center d-flex justify-content-center">
        <Button
          onClick={() => onEdit(rowData.row.original.id)}
          size="sm"
          className="rounded d-flex align-items-center"
          color="warning"
        >
          <FontAwesomeIcon icon={faPen} className="mr-1" />
          <span>Edit</span>
        </Button>
        <Button
          onClick={() => onDelete(rowData.row.original.id)}
          size="sm"
          className="rounded d-flex align-items-center ml-2"
          color="danger"
        >
          <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
          <span>Delete</span>
        </Button>
      </div>
    ),
  },
];

const InspectionPointsManager = () => {
  const [inspectionPointManagerContext, setInspectionPointsManagerContext] =
    useInspectionPointsManager();
  const [loading, setLoading] = useState(true);

  const [createPointModal, setCreatePointModal] = useState();
  const [editPointModal, setEditPointModal] = useState();
  const [selectedPoint, setSelectedPoint] = useState();

  const getSizePerPage = (size) => (size === "All" ? MAX_PAGE_SIZE : size);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          inspectionPointManagerContext.sortBy === sortBy?.id &&
          inspectionPointManagerContext.direction === direction
        ) {
          return;
        }
        setInspectionPointsManagerContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setInspectionPointsManagerContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      inspectionPointManagerContext.direction,
      inspectionPointManagerContext.sortBy,
      setInspectionPointsManagerContext,
    ]
  );

  const setInspectionPointsManagerContextCb = useCallback(
    (data) => setInspectionPointsManagerContext(data),
    [setInspectionPointsManagerContext]
  );

  const onEdit = (inspectionPointId) => {
    setSelectedPoint(inspectionPointId);
    setEditPointModal(true);
  };

  const onDelete = (inspectionPointId) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        try {
          await inspectionPointsApi.deleteInspectionPoint({
            id: inspectionPointId,
          });
          setInspectionPointsManagerContext({
            action: ACTIONS.REFRESH,
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Delete Inspection Point",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Delete Inspection Point",
      body: `
        <span class="text-center">
          Do you confirm you want to remove the inspection point?
        </span>
      `,
      confirmColor: "danger",
    });
  };

  useEffect(() => {
    setLoading(true);
    inspectionPointsApi
      .getInspectionPoints({
        search: inspectionPointManagerContext.search,
        page: inspectionPointManagerContext.page - 1,
        pageSize: getSizePerPage(inspectionPointManagerContext.sizePerPage),
        sortBy: inspectionPointManagerContext.sortBy,
        direction: inspectionPointManagerContext.direction,
      })
      .then((inspectionPoints) => {
        setInspectionPointsManagerContextCb({
          action: ACTIONS.GET_INSPECTION_POINTS_SUCCESS,
          payload: { inspectionPoints },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setInspectionPointsManagerContextCb,
    inspectionPointManagerContext.sortBy,
    inspectionPointManagerContext.direction,
    inspectionPointManagerContext.sizePerPage,
    inspectionPointManagerContext.page,
    inspectionPointManagerContext.search,
    inspectionPointManagerContext.refresh,
  ]);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(onEdit, onDelete)}
          data={inspectionPointManagerContext.inspectionPoints?.data || []}
          pageSize={inspectionPointManagerContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: inspectionPointManagerContext.sortBy,
            direction: inspectionPointManagerContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Inspection Points Manager</h3>
                <small className="text-muted ml-2 pt-1">
                  ({inspectionPointManagerContext.inspectionPoints.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={inspectionPointManagerContext.search}
                    onChange={(evt) =>
                      setInspectionPointsManagerContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-secondary text-white border-left-0 border-secondary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={() => setCreatePointModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button ml-3"
                  color="secondary"
                  onClick={() =>
                    setInspectionPointsManagerContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {inspectionPointManagerContext.inspectionPoints.data?.length ? (
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={
                  inspectionPointManagerContext.inspectionPoints.count
                }
                pageCount={
                  inspectionPointManagerContext.inspectionPoints.totalPages
                }
                currentPage={inspectionPointManagerContext.page - 1}
                onPageChange={(page) =>
                  setInspectionPointsManagerContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={inspectionPointManagerContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setInspectionPointsManagerContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : createPointModal ? (
        <InspectionPointModal
          onSubmit={() => {
            setCreatePointModal(false);
            setInspectionPointsManagerContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => {
            setCreatePointModal(false);
          }}
        />
      ) : editPointModal ? (
        <InspectionPointModal
          inspectionPointId={selectedPoint}
          onSubmit={() => {
            setEditPointModal(false);
            setSelectedPoint();
            setInspectionPointsManagerContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => {
            setEditPointModal(false);
            setSelectedPoint();
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default InspectionPointsManager;
