import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../Loader";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { Badge, Button, Collapse, ListGroup, ListGroupItem } from "reactstrap";

import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../../../providers/workOrderDetailsProvider";
import { craneInspectionsApi } from "../../../../services/inspectionServices";
import CranesInspectedSections from "./cranesInspected/CranesInspectedSections";
import ActionsList from "./cranesInspected/ActionsList";
import SafetyIssuesList from "./cranesInspected/SafetyIssuesList";
import CraneName from "../../cranes/CraneName";
import CraneInspectionAttachment from "./cranesInspected/CraneInspectionAttachment";

const INSPECTION_STATUS_IN_PROGRESS = "IN_PROGRESS";

const CranesInspected = ({ trip, date }) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [sections, setSections] = useState([]);
  const [craneInspections, setCraneInspections] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const sections = await craneInspectionsApi.getCraneInspectionSections();
      if (sections && sections.length > 0) {
        setSections(sections.sort((x, y) => x.order - y.order));
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const craneInspections =
        await craneInspectionsApi.getCraneInspectionsByTripId({
          workOrderTripId: trip.id,
          date,
          pageSize: 999,
        });
      if (craneInspections && craneInspections.data) {
        setCraneInspections(craneInspections.data);
      }
      setLoading(false);
    };
    fetchData();
  }, [date, trip.id]);

  const toggleCrane = (craneInspectionId) => {
    if (workOrderDetails.activeCrane !== craneInspectionId) {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_CRANE,
        payload: {
          activeCrane: craneInspectionId,
        },
      });
    } else {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_CRANE,
        payload: {
          activeCrane: null,
        },
      });
    }
  };

  const cranesInspected = workOrderDetails.workOrder.workOrderCranes
    ?.map((workOrderCrane) => {
      const craneCraneInspections = craneInspections.filter(
        (craneInspection) =>
          craneInspection.workOrderCraneId === workOrderCrane.id
      );
      if (!craneCraneInspections.length) {
        return null;
      }
      const crane = workOrderCrane.crane;
      return craneCraneInspections.map((craneInspection, i) => {
        const headerElements = [
          <Badge key={i}>
            By{" "}
            {`${craneInspection.author?.firstName} ${craneInspection.author?.lastName}`}
          </Badge>,
        ];
        if (craneInspection.status === INSPECTION_STATUS_IN_PROGRESS) {
          headerElements.push(
            <Badge
              key={`${i}-${headerElements.length}`}
              className="ml-2"
              color={
                craneInspection.status === INSPECTION_STATUS_IN_PROGRESS
                  ? "warning"
                  : "success"
              }
            >
              {craneInspection.status === INSPECTION_STATUS_IN_PROGRESS
                ? "Inspection In Progress"
                : "Inspection Completed"}
            </Badge>
          );
        }
        const isOpen = workOrderDetails.activeCrane === craneInspection.id;
        return (
          <ListGroupItem key={i}>
            <Button
              onClick={() => toggleCrane(craneInspection.id)}
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-row justify-content-between m-1 align-items-center">
                <CraneName crane={crane} />
                <div className="d-flex align-items-center">
                  <Badge>{`${craneInspection.author.firstName} ${craneInspection.author.lastName}`}</Badge>
                  <FontAwesomeIcon
                    icon={isOpen ? faChevronDown : faChevronRight}
                    fixedWidth
                    className="my-1"
                  />
                </div>
              </div>
            </Button>
            <Collapse isOpen={isOpen} className="p-2">
              {craneInspection && isOpen ? (
                <div className="d-flex flex-column">
                  <CraneInspectionAttachment
                    craneInspection={craneInspection}
                    headerElements={headerElements}
                  />
                  <SafetyIssuesList
                    crane={crane}
                    craneInspection={craneInspection}
                    sections={sections}
                    customerContacts={
                      workOrderDetails.workOrder.customerContacts
                    }
                  />
                  <CranesInspectedSections
                    crane={crane}
                    craneInspection={craneInspection}
                    sections={sections}
                  />
                  <ActionsList
                    crane={crane}
                    craneInspection={craneInspection}
                    sections={sections}
                    customerContacts={
                      workOrderDetails.workOrder.customerContacts
                    }
                  />
                </div>
              ) : (
                <div className="text-muted small d-flex justify-content-center align-items-center p-2 border rounded bg-lighter">
                  No inspection to show
                </div>
              )}
            </Collapse>
          </ListGroupItem>
        );
      });
    })
    .filter(Boolean);

  return (
    <>
      {loading ? (
        <Loader size="sm" />
      ) : (
        <ListGroup className="my-2 col-12 px-0">
          <ListGroupItem className="d-flex justify-content-between align-items-center bg-lighter font-weight-bold">
            Cranes Inspected
          </ListGroupItem>
          {workOrderDetails.workOrder.workOrderCranes?.length ? (
            cranesInspected.length ? (
              cranesInspected
            ) : (
              <ListGroupItem className="d-flex justify-content-center align-items-center small text-muted">
                No cranes inspected
              </ListGroupItem>
            )
          ) : (
            <ListGroupItem className="d-flex justify-content-center align-items-center small text-muted">
              No cranes assigned to trip
            </ListGroupItem>
          )}
        </ListGroup>
      )}
    </>
  );
};

export default CranesInspected;
