import React, { useEffect, useState } from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Button, Col, Input } from "reactstrap";
import Label from "reactstrap/lib/Label";
import InformationModal from "../../../InformationModal";
import { useCraneConfigModal } from "../../../../providers/craneConfigModalProvider";

const CraneConfigPhotosModal = ({
  photos,
  setPhotos,
  deletedPhotos,
  setDeletedPhotos,
}) => {
  const [craneConfigModalContext] = useCraneConfigModal();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if (craneConfigModalContext.crane?.craneAttachments) {
      setPhotos([...craneConfigModalContext.crane.craneAttachments]);
    }
  }, [craneConfigModalContext.crane, setPhotos]);

  const onDelete = (photo) => {
    setPhotos(photos.filter((value) => photo !== value));
    if (!photo.id) {
      return;
    }
    setDeletedPhotos([...deletedPhotos, photo]);
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Row>
      <Col>
        <Label for="Photos">
          <span style={{ cursor: "pointer" }}>Add Photos</span>
        </Label>
        <Input
          required={false}
          multiple={true}
          type="file"
          id="Photos"
          accept="image/gif, image/jpeg, image/png, image/jpg"
          className="mb-1 text-truncate"
          onChange={(event) => {
            setPhotos([
              ...photos,
              ...[...event.currentTarget.files].map((file) => ({
                fileName: file.name,
                fileType: file.type,
                contentUrl: URL.createObjectURL(file),
              })),
            ]);
          }}
        />
      </Col>
      <div className="d-flex flex-wrap">
        {photos?.length
          ? photos.map((photo, i) => (
              <div className="col-3" key={i}>
                <Button
                  className="close"
                  color="none"
                  onClick={() => onDelete(photo)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} size="xs" />
                </Button>
                <img
                  alt=""
                  className="col-12 px-0 rounded photo-crane"
                  src={photo.contentUrl}
                />
              </div>
            ))
          : null}
      </div>
    </Row>
  );
};

export default CraneConfigPhotosModal;
